/**
 * This file was automatically generated by `TranslationsCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source English locale translations at
 * `src/locales/en/*.json`, and run `yarn i18n:codegen` to regenerate this file.
 */

import enAccount from './en/account.json';
import enActionBar from './en/actionBar.json';
import enAddFromFriendsRegistry from './en/addFromFriendsRegistry.json';
import enAddGiftFromStoresPopup from './en/addGiftFromStoresPopup.json';
import enAddToCalendar from './en/addToCalendar.json';
import enAdminDashboard from './en/adminDashboard.json';
import enAdminDashboardV2 from './en/adminDashboardV2.json';
import enAdminDashboardV3 from './en/adminDashboardV3.json';
import enBnpl from './en/bnpl.json';
import enBookingAssistant from './en/bookingAssistant.json';
import enBookmarklet from './en/bookmarklet.json';
import enCatalogRegistry from './en/catalogRegistry.json';
import enCompanyPolicies from './en/companyPolicies.json';
import enContactCollector from './en/contactCollector.json';
import enCreateBabyRegistry from './en/createBabyRegistry.json';
import enCreateEvent from './en/createEvent.json';
import enCreateRegistry from './en/createRegistry.json';
import enCreateWedding from './en/createWedding.json';
import enCurationsRegistry from './en/curationsRegistry.json';
import enCustomDomain from './en/customDomain.json';
import enDates from './en/dates.json';
import enDiscountedRates from './en/discountedRates.json';
import enEmailPreferences from './en/emailPreferences.json';
import enEmailsAndEcards from './en/emailsAndEcards.json';
import enErrors from './en/errors.json';
import enEventCountdown from './en/eventCountdown.json';
import enEventMenu from './en/eventMenu.json';
import enEventSite from './en/eventSite.json';
import enEventSiteApps from './en/eventSiteApps.json';
import enExampleConfigUsage from './en/exampleConfigUsage.json';
import enFocusPoint from './en/focusPoint.json';
import enGeneralFormValidation from './en/generalFormValidation.json';
import enGuestKit from './en/guestKit.json';
import enGuestRegistry from './en/guestRegistry.json';
import enGuestSiteCustom from './en/guestSiteCustom.json';
import enGuestSiteTravel from './en/guestSiteTravel.json';
import enGuestSiteWelcome from './en/guestSiteWelcome.json';
import enGuestUnlock from './en/guestUnlock.json';
import enHeaderHelpMenu from './en/headerHelpMenu.json';
import enHotelBlock from './en/hotelBlock.json';
import enHotelReminder from './en/hotelReminder.json';
import enIdeabook from './en/ideabook.json';
import enIdeas from './en/ideas.json';
import enImpermissible from './en/impermissible.json';
import enJoykit from './en/joykit.json';
import enLayoutEngine from './en/layoutEngine.json';
import enMarcom from './en/marcom.json';
import enPageNotFound from './en/pageNotFound.json';
import enPageSettingsDialog from './en/pageSettingsDialog.json';
import enPlanningAccommodations from './en/planningAccommodations.json';
import enPostOffice from './en/postOffice.json';
import enProductSelectionDialog from './en/productSelectionDialog.json';
import enPublishReminder from './en/publishReminder.json';
import enRegistryChecklist from './en/registryChecklist.json';
import enRegistryOnboardingSurvey from './en/registryOnboardingSurvey.json';
import enRegistryOnboardingSurveyV2 from './en/registryOnboardingSurveyV2.json';
import enRegistryOverview from './en/registryOverview.json';
import enRetargetTravelDialog from './en/retargetTravelDialog.json';
import enRsvpReminder from './en/rsvpReminder.json';
import enSettings from './en/settings.json';
import enShareDialog from './en/shareDialog.json';
import enSharedComponents from './en/sharedComponents.json';
import enSharedRegistry from './en/sharedRegistry.json';
import enSlapAdsRegistry from './en/slapAdsRegistry.json';
import enSsrServer from './en/ssrServer.json';
import enStationery from './en/stationery.json';
import enStationeryTemplateCategory from './en/stationeryTemplateCategory.json';
import enStays from './en/stays.json';
import enSuggestions from './en/suggestions.json';
import enThanksForRsvp from './en/thanksForRSVP.json';
import enTranslation from './en/translation.json';
import enTravelEditor from './en/travelEditor.json';
import enUniversalRecommendations from './en/universalRecommendations.json';
import enVendor from './en/vendor.json';
import enVerifyEmail from './en/verifyEmail.json';
import enWebsiteDesigner from './en/websiteDesigner.json';
import enWebsiteEditor from './en/websiteEditor.json';
import enWebsiteOverview from './en/websiteOverview.json';
import enWebsitePages from './en/websitePages.json';
import enWebsitePreview from './en/websitePreview.json';
import enWeddingParty from './en/weddingParty.json';
import enYourRegistry from './en/yourRegistry.json';
import esCreateRegistry from './es/createRegistry.json';
import esEmailsAndEcards from './es/emailsAndEcards.json';
import esEventCountdown from './es/eventCountdown.json';
import esGuestSiteWelcome from './es/guestSiteWelcome.json';
import esWeddingParty from './es/weddingParty.json';
import esYourRegistry from './es/yourRegistry.json';
import { TOptions } from 'i18next';
export type TranslateFunction = (options?: TOptions) => string;

export type LeafNodeValue = string | TranslateFunction;

export type AccountTranslations<T extends LeafNodeValue = TranslateFunction> = {
  signin: {
    /**
     * @default 'Sign in with Google'
     */
    google: T;

    /**
     * @default 'Continue with Facebook'
     */
    facebook: T;

    /**
     * @default 'Sign in to Joy'
     */
    signInToJoy: T;

    /**
     * @default 'Don't have an account?'
     */
    donthaveaccount: T;

    /**
     * @default 'Create an account'
     */
    createAnAccount: T;

    /**
     * @default 'Email'
     */
    enterEmail: T;

    /**
     * @default 'Password'
     */
    enterPassword: T;

    /**
     * @default 'Sign In'
     */
    signin: T;

    /**
     * @default 'Continue with your email and password'
     */
    or: T;
  };
  signup: {
    /**
     * @default 'Sign up with Google'
     */
    google: T;

    /**
     * @default 'Continue with Facebook'
     */
    facebook: T;

    /**
     * @default 'Sign Up for Joy'
     */
    signUpForJoy: T;

    /**
     * @default 'Sign Up'
     */
    signup: T;
  };

  /**
   * @default 'Already signed up? <signInLink/>'
   */
  alreadyAccount: T;

  forgotpassword: {
    /**
     * @default 'Forgot your password'
     */
    forgotPassword: T;

    /**
     * @default 'Send password reset'
     */
    sendPasswordReset: T;
  };

  /**
   * @default 'By signing up, you agree to our <termsLink/> and <privacyLink/>.'
   */
  termsandprivacy: T;

  /**
   * @default 'Terms'
   */
  terms: T;

  /**
   * @default 'Privacy Policy'
   */
  privacyPolicy: T;

  /**
   * @default ' See '
   */
  privacyNoticeSee: T;

  /**
   * @default 'CA Privacy Notice'
   */
  privacyNotice: T;

  formWarnings: {
    /**
     * @default 'Invalid email'
     */
    invalidEmail: T;

    /**
     * @default 'Required'
     */
    required: T;

    /**
     * @default 'The email or password you entered is incorrect.'
     */
    invalidCredentials: T;
  };

  /**
   * @default 'Sign out'
   */
  signout: T;

  /**
   * @default 'You are signed in as {{name}}'
   */
  signedinas: T;

  /**
   * @default 'Joy admin'
   */
  youJoyAdmin: T;

  /**
   * @default 'You are not a Joy admin'
   */
  youNotJoyAdmin: T;

  /**
   * @default 'You need to sign in with your withjoy.com account'
   */
  youNeedToJoyLogin: T;

  eventPicker: {
    /**
     * @default 'Create New Event'
     */
    createNew: T;

    /**
     * @default 'Current Event'
     */
    currentEvent: T;
  };
  verifyEmailSuccess: {
    /**
     * @default 'We’ve verified your email. Thanks!'
     */
    message: T;
  };
  sentResetPassword: {
    /**
     * @default 'Change Password'
     */
    title: T;

    /**
     * @default 'OK'
     */
    confirmButton: T;

    /**
     * @default 'We sent an email to {{email}} with instructions to change your password. '
     */
    sentEmail: T;

    /**
     * @default 'If this was a mistake, just ignore the email and your password will remain the same.'
     */
    ignoreClause: T;
  };
  deleteUser: {
    /**
     * @default 'Delete Account'
     */
    title: T;

    /**
     * @default 'Delete Account'
     */
    confirmButton: T;

    /**
     * @default 'Deleting your account is irreversible. If you delete your account, we will not be able to restore it.'
     */
    message: T;

    /**
     * @default 'Type DELETE to confirm you want to delete your account:'
     */
    fieldTitle: T;
  };
  mustLeaveEvents: {
    /**
     * @default 'Delete Account'
     */
    title: T;

    /**
     * @default 'Before deleting your account, please delete any events you have created and leave any events you have joined.'
     */
    message: T;

    /**
     * @default 'Learn More'
     */
    linkText: T;

    /**
     * @default 'OK'
     */
    confirmButton: T;
  };
  leaveEvent: {
    /**
     * @default 'Are you sure?'
     */
    title: T;

    /**
     * @default 'Are you sure you’d like to leave this event?'
     */
    titleV2: T;

    /**
     * @default 'Please confirm you’d like to leave this event.'
     */
    message: T;

    /**
     * @default 'Leave Event'
     */
    confirmButton: T;

    /**
     * @default 'Cancel'
     */
    cancelButton: T;

    /**
     * @default 'Event Name'
     */
    label: T;

    /**
     * @default 'Please Note'
     */
    warningLabel: T;

    /**
     * @default 'If you leave this event we will remove it from your Joy account.'
     */
    warningMessage: T;

    /**
     * @default 'You have successfully left this event'
     */
    successToast: T;

    /**
     * @default 'We were unable to remove you from this event'
     */
    failureToast: T;

    /**
     * @default 'close dialog'
     */
    closeDialog: T;
  };
  deleteEvent: {
    /**
     * @default 'Are you sure you’d like to delete this event?'
     */
    title: T;

    /**
     * @default 'Delete Event'
     */
    confirmButton: T;

    /**
     * @default 'Cancel'
     */
    cancelButton: T;

    /**
     * @default 'Deleting your event will permanently remove your entire event from Joy. This includes all event details, your website, invitations, photos and guest list.'
     */
    confirmMessage: T;

    /**
     * @default 'If you would like to continue, please type'
     */
    confirm1: T;

    /**
     * @default 'in the field below to permanently delete your event from Joy.'
     */
    confirm2: T;

    /**
     * @default 'Please Note:'
     */
    warningLabel: T;

    /**
     * @default 'Deleting your event is an irreversible change.'
     */
    warningMessage: T;

    /**
     * @default 'Your event has been successfully deleted.'
     */
    successToast: T;

    /**
     * @default 'close dialog'
     */
    closeDialog: T;
  };
  deleteEventSuccess: {
    /**
     * @default 'We’re sorry to see you go!'
     */
    title: T;

    /**
     * @default 'Done'
     */
    confirmButton: T;

    /**
     * @default 'Your event was deleted successfully.'
     */
    confirmMessage: T;

    /**
     * @default 'Before you go, would you mind sharing why you’re leaving? Your feedback would be greatly appreciated.'
     */
    message: T;

    /**
     * @default 'I deleted my Joy event because...'
     */
    placeholderText: T;
  };
  deleteEventFailure: {
    /**
     * @default 'An error occured'
     */
    title: T;

    /**
     * @default 'We were unable to delete the event.'
     */
    message: T;

    /**
     * @default 'OK'
     */
    confirmButton: T;
  };
  leaveEventFailure: {
    /**
     * @default 'An error occured'
     */
    title: T;

    /**
     * @default 'We were unable to remove you from the event.'
     */
    message: T;

    /**
     * @default 'OK'
     */
    confirmButton: T;
  };
  savePersonInfoFailure: {
    /**
     * @default 'An error occured'
     */
    title: T;

    /**
     * @default 'Unfortunately your changes could not be saved.'
     */
    message: T;

    /**
     * @default 'OK'
     */
    confirmButton: T;

    /**
     * @default 'Your email could not be updated to {{targetEmail}}, because an account with that email address already exists.'
     */
    userAlreadyExists: T;

    /**
     * @default 'If you want to use {{targetEmail}} for an event, you can <userAlreadyExistsLink/> to the account with that email address.'
     */
    userAlreadyExistsSuggestion: T;

    /**
     * @default 'transfer ownership of the event'
     */
    userAlreadyExistsLinkText: T;
  };
  eventsPage: {
    /**
     * @default 'What kind of event would you like to create?'
     */
    createDialogTitle: T;

    /**
     * @default 'Upcoming Events'
     */
    myEventsTitle: T;

    /**
     * @default 'Past Events'
     */
    pastEventsTitle: T;

    newEventOptions: {
      /**
       * @default 'Baby Registry'
       */
      baby: T;

      /**
       * @default 'Wedding'
       */
      wedding: T;
    };
    findEventButton: {
      /**
       * @default 'Find Event'
       */
      value: T;
    };
    createEventButton: {
      /**
       * @default 'Create Event'
       */
      value: T;
    };
    copyLink: {
      /**
       * @default 'Copy Link'
       */
      buttonTitle: T;

      /**
       * @default '✨ Link Copied to Clipboard ✨'
       */
      toastMessage: T;
    };
    editEvent: {
      /**
       * @default 'Edit'
       */
      buttonTitle: T;
    };
    rsvpEvent: {
      /**
       * @default 'RSVP'
       */
      buttonTitle: T;
    };
    shareEvent: {
      /**
       * @default 'Share'
       */
      buttonTitle: T;
    };
    previewEvent: {
      /**
       * @default 'Preview'
       */
      buttonTitle: T;
    };
    deleteEvent: {
      /**
       * @default 'Delete'
       */
      buttonTitle: T;
    };
    leaveEvent: {
      /**
       * @default 'Leave This Event'
       */
      buttonTitle: T;
    };

    /**
     * @default 'Coming up! 🎉'
     */
    eventComingUp: T;

    /**
     * @default 'Attending'
     */
    eventAttending: T;

    /**
     * @default 'Not Attending'
     */
    eventNotAttending: T;

    /**
     * @default 'Event'
     */
    event: T;

    /**
     * @default 'Manage Event'
     */
    manageEvent: T;

    status: {
      /**
       * @default 'Owner'
       */
      owner: T;

      /**
       * @default 'Admin'
       */
      admin: T;

      /**
       * @default 'Collaborator'
       */
      collaborator: T;

      /**
       * @default 'Guest'
       */
      guest: T;
    };
  };
  accountManageNav: {
    /**
     * @default 'Events'
     */
    eventsNavTitle: T;

    /**
     * @default 'Account'
     */
    account: T;
  };
  personalInfoForm: {
    /**
     * @default 'Email address'
     */
    emailTitle: T;

    /**
     * @default 'Password'
     */
    passwordTitle: T;

    /**
     * @default 'Change Password'
     */
    passwordChangeActionText: T;

    /**
     * @default 'First name'
     */
    firstNameTitle: T;

    /**
     * @default 'Last name'
     */
    lastNameTitle: T;

    /**
     * @default 'Save'
     */
    submitButtonText: T;

    /**
     * @default 'Your personal info has been updated'
     */
    submitToastText: T;
  };

  /**
   * @default 'Delete Account'
   */
  deleteAccountButtonText: T;

  verfiyEmailWarning: {
    /**
     * @default 'Email address needs verification. <verifyLink/>'
     */
    message: T;

    /**
     * @default 'Verify email address'
     */
    linkText: T;
  };
  MFASettings: {
    /**
     * @default 'Turn Off Two-Factor Authentication'
     */
    disableButtonTitle: T;

    /**
     * @default 'Set Up Two-Factor Authentication'
     */
    enableButtonTitle: T;

    /**
     * @default 'Please contact <Link/> for assistance.'
     */
    notSupportedText: T;

    /**
     * @default 'Two-Factor Authentication not available for this account.'
     */
    notSupportedTitle: T;

    /**
     * @default 'Enable Two-Factor Authentication'
     */
    title: T;

    /**
     * @default 'Two-Factor Authentication is On'
     */
    titleEnabled: T;

    /**
     * @default 'Your account is protected with a required login code from your authentication app.'
     */
    enabledTextDescription: T;

    /**
     * @default 'Protect your account with a login code when you sign in on a device we don’t recognize.'
     */
    description: T;

    /**
     * @default 'Two-factor authentication is now enabled'
     */
    successMFA: T;

    /**
     * @default 'Two-factor is now enabled'
     */
    successMFAMobile: T;

    /**
     * @default 'Two-factor authentication has been disabled'
     */
    successMFAReseted: T;

    /**
     * @default 'Two-factor is now disabled'
     */
    successMFAResetedMobile: T;
  };
  resetMFA: {
    /**
     * @default 'Two-Factor Authentication is Enabled'
     */
    title: T;

    /**
     * @default 'Remove Two-Factor Authentication'
     */
    confirmButton: T;

    /**
     * @default 'Cancel'
     */
    cancelButton: T;

    /**
     * @default 'Your account is more secure when you need a password and a login code to sign in. If you remove this extra layer of security, you will only be asked for a password when you sign in. You will have to sign in again after removing two-factor authentication.'
     */
    message: T;
  };
  noEventsSection: {
    /**
     * @default 'There are no events for {{email}}.'
     */
    noEventsForEmail: T;

    /**
     * @default 'If you created an event and expected to find it here, <signInLink/>.'
     */
    incorrectAccount: T;

    /**
     * @default 'try signing in a different way'
     */
    incorrectSignInLinkText: T;

    /**
     * @default 'The email we sent from “Joy Team” with subject “Welcome to Joy” will tell you how to sign in.'
     */
    accountEmailHint: T;

    /**
     * @default 'Create your first event'
     */
    heading: T;

    /**
     * @default 'Get your wedding plans started or add the perfect gifts for your baby registry.'
     */
    description: T;
  };
};

export type ActionBarTranslations<T extends LeafNodeValue = TranslateFunction> = {
  text: {
    /**
     * @default '{{date}}'
     */
    date: T;

    /**
     * @default 'Hello {{guestName}}. '
     */
    personalizedText: T;
  };
  buttons: {
    /**
     * @default 'Not You?'
     */
    buttonNotYou: T;
  };
};

export type AddFromFriendsRegistryTranslations<T extends LeafNodeValue = TranslateFunction> = {
  banner: {
    /**
     * @default 'Only with Joy'
     */
    eyebrow: T;

    /**
     * @default 'Add gifts from'
     */
    title: T;

    /**
     * @default 'anyone’s registry'
     */
    titleAnyones: T;

    /**
     * @default 'a friend’s registry'
     */
    titleFriends: T;

    /**
     * @default 'a family’s registry'
     */
    titleFamily: T;

    /**
     * @default 'a celebrity’s registry'
     */
    titleCelebrities: T;

    /**
     * @default 'We’ll collect their gifts so you can pick what to add.'
     */
    content: T;

    /**
     * @default 'Registry URL'
     */
    inputTitle: T;

    /**
     * @default 'https://myfriendsregistry.com/gifts'
     */
    inputPlaceholder: T;

    /**
     * @default 'Supported Registries: Babylist, Amazon, Target'
     */
    inputHint: T;

    /**
     * @default 'Get Started'
     */
    ctaText: T;

    errors: {
      /**
       * @default 'This URL seems incorrect.'
       */
      registryUrlInvalid: T;

      /**
       * @default 'Please enter URL.'
       */
      registryUrlRequired: T;

      reasonCode: {
        /**
         * @default 'Sorry! We don’t support that platform or retailer yet.'
         */
        unsupportedDomain: T;

        /**
         * @default 'Oops! This URL seems not right. Copy the link once again and give it another go!'
         */
        invalidRegistryUrl: T;

        /**
         * @default 'Uh oh, it looks like the registry exists, but it's not public yet.'
         */
        registryIsPrivate: T;

        /**
         * @default 'Oops! Something went wrong. Please try again later!'
         */
        registryUnavailable: T;
      };
    };
  };
  scannedRegistriesTracker: {
    /**
     * @default 'Your Friends’ Registries'
     */
    title: T;

    /**
     * @default 'Browse Registries'
     */
    titleMobile: T;

    /**
     * @default 'Only the items you select will be added to your Registry.'
     */
    textMobile: T;

    table: {
      /**
       * @default '{{registryName}} Registry'
       */
      registry: T;

      /**
       * @default 'Registry Name'
       */
      registryName: T;

      /**
       * @default 'Registry URL'
       */
      registryURL: T;

      /**
       * @default 'Scan Date'
       */
      scanDate: T;

      /**
       * @default 'Progress'
       */
      progress: T;

      /**
       * @default 'View Gifts'
       */
      viewGifts: T;
    };

    /**
     * @default 'Show All'
     */
    showAll: T;

    status: {
      /**
       * @default 'Failed'
       */
      failed: T;

      /**
       * @default 'Scanning'
       */
      inProgress: T;

      /**
       * @default 'Scanned'
       */
      ready: T;
    };
    menu: {
      /**
       * @default 'Browse Gifts'
       */
      share: T;

      /**
       * @default 'View Friend’s Registry'
       */
      preview: T;

      /**
       * @default 'Remove Registry'
       */
      delete: T;
    };
    shareRegistryDialog: {
      /**
       * @default 'Share this Registry Page'
       */
      title: T;

      /**
       * @default 'Gifts Scan Page'
       */
      subTitle: T;

      /**
       * @default 'Open New Tab'
       */
      openNewTabButton: T;

      /**
       * @default '✨ Registry Link Copied to Clipboard ✨'
       */
      toastText: T;
    };
    deleteRegistryDialog: {
      /**
       * @default 'Are you sure you want to remove {{registryName}} from your Add from Friend's Registry list?'
       */
      title: T;

      /**
       * @default 'Deleting this registry will remove it from the list of registries from which you can add gifts. If you change your mind, you can always add the registry again in the future.'
       */
      text: T;

      /**
       * @default 'Note: This will not affect the gifts that you already added to your Registry.'
       */
      note: T;

      /**
       * @default 'Delete Registry'
       */
      deleteCta: T;

      /**
       * @default 'Cancel'
       */
      cancelCta: T;

      /**
       * @default 'All Done!'
       */
      deleteConfirmTitle: T;

      /**
       * @default '{{registryName}} was deleted successfully.'
       */
      deleteConfirmSubTitle: T;

      /**
       * @default 'If you change your mind, you can always enter the Registry URL and scan it again.'
       */
      deleteConfirmText: T;

      /**
       * @default 'Done'
       */
      deleteConfirmCta: T;
    };
  };
  scannedRegistriesWidget: {
    /**
     * @default 'Add From Friend’s Registry'
     */
    title: T;

    /**
     * @default '{{registryName}} Registry'
     */
    registryName: T;

    /**
     * @default 'See All'
     */
    seeAllCta: T;

    /**
     * @default 'Add New Registry'
     */
    addNewRegistryCta: T;

    emptyState: {
      /**
       * @default 'Add gifts from your friend’s registry. We’ll collect their gifts so you can pick what to add.'
       */
      message: T;

      /**
       * @default 'Get Started'
       */
      ctaText: T;
    };
  };
  howItWorks: {
    /**
     * @default 'How It Works'
     */
    title: T;

    /**
     * @default 'Start with adding gifts from the registries of those around you, it’s so simple and fun.'
     */
    text: T;

    steps: [
      {
        /**
         * @default 'Step One'
         */
        eyebrow: T;

        /**
         * @default 'Get your friend's registry link.'
         */
        title: T;

        /**
         * @default 'Copy the link from a friend’s registry and paste it at the top of this page.'
         */
        text: T;
      },
      {
        /**
         * @default 'Step Two'
         */
        eyebrow: T;

        /**
         * @default 'Let the scanning magic happen.'
         */
        title: T;

        /**
         * @default 'We’ll gather your friend’s registry gifts, so you choose which gifts you’d like to add to yours.'
         */
        text: T;
      },
      {
        /**
         * @default 'Step Three'
         */
        eyebrow: T;

        /**
         * @default 'Browse gifts to add to your registry.'
         */
        title: T;

        /**
         * @default 'Select the gifts you want to add to your registry.'
         */
        text: T;
      },
      {
        /**
         * @default 'Step Four'
         */
        eyebrow: T;

        /**
         * @default 'Add gifts to your registry.'
         */
        title: T;

        /**
         * @default 'Click on the Add Gifts button and that’s it. You’ve successfully added gifts to your Baby Registry.'
         */
        text: T;
      }
    ];
  };
  faqs: {
    /**
     * @default 'Frequently Asked Questions'
     */
    title: T;

    questions: {
      baby: [
        {
          /**
           * @default '1'
           */
          id: T;

          /**
           * @default 'Which platforms do you currently support?'
           */
          Q: T;

          /**
           * @default 'We currently support Amazon, Target and Babylist! we also support more stores from our wedding registry product.'
           */
          A: T;
        },
        {
          /**
           * @default '2'
           */
          id: T;

          /**
           * @default 'How long does it take to gather gifts from the external registry?'
           */
          Q: T;

          /**
           * @default 'It takes around 10-30 seconds for most registries, but it can take longer for larger registries. Hang tight, it shouldn’t take too long.'
           */
          A: T;
        },
        {
          /**
           * @default '3'
           */
          id: T;

          /**
           * @default 'What if I accidentally close the page when the gifts are being gathered?'
           */
          Q: T;

          /**
           * @default 'Scanning will continue in the background. You can always access the page again by clicking on the banner on the Shop Homepage or from the widget on the left side of your Manage Page.'
           */
          A: T;
        },
        {
          /**
           * @default '4'
           */
          id: T;

          /**
           * @default 'Do I have to use a friend’s registry?'
           */
          Q: T;

          /**
           * @default 'No, it could be also be a registry from a family member, coworker, or influencer. Friends, family, and coworkers shared their registry links with us when we had our own little ones. Finding and picking gifts can be overwhelming who better to trust than those around you who have done it first! Now you can use this feature to shop their registries and quickly draw inspiration for your own.'
           */
          A: T;
        },
        {
          /**
           * @default '5'
           */
          id: T;

          /**
           * @default 'Are these registries publicly visible to my gift-givers?'
           */
          Q: T;

          /**
           * @default 'No, they are only visible to you and other admins of your registry. Registries in the “Add From Friends” feature are never shown to your gift-givers, or on your public registry. This feature allows you to more easily shop from your friend’s registries.'
           */
          A: T;
        },
        {
          /**
           * @default '6'
           */
          id: T;

          /**
           * @default 'How many registries can I bring in?'
           */
          Q: T;

          /**
           * @default 'You can add as many registries as you want, that’s totally up to you. If the registry you scanned was updated, you can delete it and rescan it.'
           */
          A: T;
        },
        {
          /**
           * @default '7'
           */
          id: T;

          /**
           * @default 'Do I have to add all the gifts from the registry?'
           */
          Q: T;

          /**
           * @default 'No, you can pick and choose which items to add. Nothing is stopping you from adding them all though with one click if that’s what you want.'
           */
          A: T;
        },
        {
          /**
           * @default '8'
           */
          id: T;

          /**
           * @default 'If I delete a scanned Registry from Add From Friend’s Registry page, does it delete the gifts in my registry?'
           */
          Q: T;

          /**
           * @default 'No, deleting the scanned registry will keep the items you added in your registry. When you delete one of these Add From Friend’s Registries, you won’t see it in the list and you will not be able to add more items to your registry from your friend’s registry, unless you decide to scan it again.'
           */
          A: T;
        }
      ];

      wedding: [
        {
          /**
           * @default '1'
           */
          id: T;

          /**
           * @default 'Which platforms do you currently support?'
           */
          Q: T;

          /**
           * @default 'We currently support All the same registries that we offer for syncing including Amazon, Target, Crate & Barrel and many more.'
           */
          A: T;
        },
        {
          /**
           * @default '2'
           */
          id: T;

          /**
           * @default 'How long does it take to gather gifts from the external registry?'
           */
          Q: T;

          /**
           * @default 'It takes around 10-30 seconds for most registries, but it can take longer for larger registries. Hang tight, it shouldn’t take too long.'
           */
          A: T;
        },
        {
          /**
           * @default '3'
           */
          id: T;

          /**
           * @default 'What if I accidentally close the page when the gifts are being gathered?'
           */
          Q: T;

          /**
           * @default 'Scanning will continue in the background. You can always access the page again by clicking on the banner on the Shop Homepage or from the widget on the left side of your Manage Page.'
           */
          A: T;
        },
        {
          /**
           * @default '4'
           */
          id: T;

          /**
           * @default 'Do I have to use a friend’s registry?'
           */
          Q: T;

          /**
           * @default 'No, it could be also be a registry from a family member, coworker, or influencer. Finding and picking gifts can be overwhelming who better to trust than those around you who have done it first! Now you can use this feature to shop their registries and quickly draw inspiration for your own wedding registry.'
           */
          A: T;
        },
        {
          /**
           * @default '5'
           */
          id: T;

          /**
           * @default 'Are these registries publicly visible to my gift-givers?'
           */
          Q: T;

          /**
           * @default 'No, they are only visible to you and other admins of your registry. Registries in the “Add From Friends” feature are never shown to your gift-givers, or on your public registry. This feature allows you to more easily shop from your friend’s registries.'
           */
          A: T;
        },
        {
          /**
           * @default '6'
           */
          id: T;

          /**
           * @default 'How many registries can I bring in?'
           */
          Q: T;

          /**
           * @default 'You can add as many registries as you want, that’s totally up to you. If the registry you scanned was updated, you can delete it and rescan it. '
           */
          A: T;
        },
        {
          /**
           * @default '7'
           */
          id: T;

          /**
           * @default 'Do I have to add all the gifts from the registry?'
           */
          Q: T;

          /**
           * @default 'No, you can pick and choose which items to add. Nothing is stopping you from adding them all though with one click if that’s what you want. '
           */
          A: T;
        },
        {
          /**
           * @default '8'
           */
          id: T;

          /**
           * @default 'If I delete a scanned Registry from Add From Friend’s Registry page, does it delete the gifts in my registry?'
           */
          Q: T;

          /**
           * @default 'No, deleting the scanned registry will keep the items you added in your registry. When you delete one of these Add From Friend’s Registries, you won’t see it in the list and you will not be able to add more items to your registry from your friend’s registry, unless you decide to scan it again.'
           */
          A: T;
        }
      ];
    };
  };
  scanned: {
    /**
     * @default 'Back'
     */
    backCta: T;

    /**
     * @default 'Scanned {{scannedDate}}'
     */
    scannedDate: T;

    /**
     * @default 'Latest gift scan: {{scannedDate}}'
     */
    latestScannedDate: T;

    /**
     * @default 'Add from {{registryName}}'
     */
    addFromTitle: T;

    /**
     * @default 'How do I edit a gift?'
     */
    howToEditGift: T;

    /**
     * @default 'Select All'
     */
    selectAllCta: T;

    /**
     * @default 'Select Gifts to Add'
     */
    selectGiftsCta: T;

    /**
     * @default 'Add {{count}} Gift'
     */
    addGiftCta: T;

    /**
     * @default 'Add {{count}} Gifts'
     */
    addGiftsCta: T;

    /**
     * @default 'You can edit gift details, such as quantity, title, etc. after you add the gift to your registry.'
     */
    tooltip: T;

    /**
     * @default 'How to add a gift'
     */
    tooltipAlt: T;

    /**
     * @default 'Cash Fund'
     */
    cashFund: T;

    /**
     * @default '🎉 Yay! We’ve added {{count}} gift to your registry!'
     */
    addedItemToRegistry: T;

    /**
     * @default '🎉 Yay! We’ve added {{count}} gifts to your registry!'
     */
    addedItemsToRegistry: T;

    giftsNotFound: {
      /**
       * @default 'This Registry doesn’t seem to have any items.'
       */
      message: T;

      /**
       * @default 'Please check again'
       */
      ctaText: T;
    };
  };
  scannerDialog: {
    inProgress: {
      /**
       * @default 'Gathering gifts for your inspiration…'
       */
      title: T;

      /**
       * @default 'This will only take a moment.'
       */
      text: T;
    };
    failed: {
      /**
       * @default 'Gathering gifts for your inspiration…'
       */
      title: T;

      /**
       * @default 'This will only take a moment.'
       */
      text: T;
    };
    ready: {
      /**
       * @default 'All done!'
       */
      title: T;

      /**
       * @default 'Now you can select gifts you’d like to add.'
       */
      text: T;
    };

    /**
     * @default 'Select Gifts'
     */
    ctaText: T;
  };
};

export type AddGiftFromStoresPopupTranslations<T extends LeafNodeValue = TranslateFunction> = {
  areYounInterestedPopup: {
    /**
     * @default 'Are You Interested in Adding Gifts from These Stores?'
     */
    title: T;

    /**
     * @default 'Yes, I am!'
     */
    primaryButtonText: T;

    /**
     * @default 'Not Interested'
     */
    secondaryButtonText: T;
  };
  browseGiftPopup: {
    /**
     * @default 'Get It All And More With Joy Registry.'
     */
    title: T;

    /**
     * @default 'Shop Joy and all your favorite retailers in one place.'
     */
    message1: T;

    /**
     * @default 'Create unlimited cash funds with zero-fees'
     */
    message2: T;

    /**
     * @default 'Add gifts from any store and share with one link'
     */
    message3: T;

    /**
     * @default 'Browse Gifts'
     */
    primaryButtonText: T;

    /**
     * @default 'Remind Me Later'
     */
    secondaryButtonText: T;
  };
  registryReminder: {
    /**
     * @default 'Registry Reminder'
     */
    title: T;

    /**
     * @default 'Share your phone number and/or email, and we’ll remind you about <b>creating your wedding registry.</b>'
     */
    subTitle: T;

    /**
     * @default 'Phone Number'
     */
    phoneNumber: T;

    /**
     * @default 'Remind Me In'
     */
    remindMeIn: T;

    /**
     * @default 'Edit'
     */
    edit: T;

    /**
     * @default 'Remind me about other milestones'
     */
    remindMeAboutOtherMilestones: T;

    /**
     * @default 'Notify Me'
     */
    notifyMe: T;

    /**
     * @default 'By clicking notify me, you consent the use of your information in accordance with Joy’s privacy policy.'
     */
    footer: T;

    /**
     * @default 'Phone number is not valid'
     */
    phoneNumberNotValid: T;
  };
  youAreAllSet: {
    /**
     * @default 'You’re All Set'
     */
    title: T;

    /**
     * @default 'We’ll send you a reminder about your wedding registry {{reminderTime}} before your event.'
     */
    subTitle: T;

    /**
     * @default 'All Done'
     */
    buttonText: T;
  };
};

export type AddToCalendarTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Google'
   */
  google: T;

  /**
   * @default 'iCal'
   */
  ical: T;

  /**
   * @default 'Outlook'
   */
  outlook: T;

  /**
   * @default 'Cancel'
   */
  cancel: T;

  /**
   * @default 'Apple'
   */
  apple: T;
};

export type AdminDashboardTranslations<T extends LeafNodeValue = TranslateFunction> = {
  sections: {
    website: {
      /**
       * @default 'Website'
       */
      title: T;

      children: {
        design: {
          /**
           * @default 'Design'
           */
          title: T;

          children: {};
        };
        photos: {
          /**
           * @default 'Photos'
           */
          title: T;

          children: {};
        };
        eventDetails: {
          /**
           * @default 'Event Details'
           */
          title: T;

          children: {};
        };
        editPages: {
          /**
           * @default 'Edit Pages'
           */
          title: T;

          children: {
            welcome: {
              /**
               * @default 'Welcome'
               */
              title: T;

              children: {};
            };
            video: {
              /**
               * @default 'Video'
               */
              title: T;

              children: {};
            };
            story: {
              /**
               * @default 'Story'
               */
              title: T;

              children: {};
            };
            schedule: {
              /**
               * @default 'Schedule'
               */
              title: T;

              children: {};
            };
            travel: {
              /**
               * @default 'Travel'
               */
              title: T;

              children: {};
            };
            guest: {
              /**
               * @default 'Guest Q&A'
               */
              title: T;

              children: {};
            };
            weddingParty: {
              /**
               * @default 'Wedding Party'
               */
              title: T;

              children: {};
            };
            registry: {
              /**
               * @default 'Registry'
               */
              title: T;

              children: {};
            };
            addNewPage: {
              /**
               * @default 'Add New Page'
               */
              title: T;

              children: {};
            };
            managePages: {
              /**
               * @default 'Manage Pages'
               */
              title: T;

              children: {};
            };
          };
        };
      };
    };
    registry: {
      /**
       * @default 'Registry'
       */
      title: T;

      children: {
        manage: {
          /**
           * @default 'Manage'
           */
          title: T;

          children: {};
        };
        shop: {
          /**
           * @default 'Shop'
           */
          title: T;

          children: {};
        };
      };
    };
    planning: {
      /**
       * @default 'Planning'
       */
      title: T;

      children: {
        schedule: {
          /**
           * @default 'Schedule'
           */
          title: T;

          children: {};
        };
        guestList: {
          /**
           * @default 'Guest List'
           */
          title: T;

          children: {};
        };
        rsvp: {
          /**
           * @default 'RSVP Questions'
           */
          title: T;

          children: {};
        };
        send: {
          /**
           * @default 'Send'
           */
          title: T;

          children: {
            invitation: {
              /**
               * @default 'Invitation eCard'
               */
              title: T;

              children: {};
            };
            saveTheDate: {
              /**
               * @default 'Save the Date eCard'
               */
              title: T;

              children: {};
            };
            changeTheDate: {
              /**
               * @default 'Change the Date eCard'
               */
              title: T;

              children: {};
            };
            custom: {
              /**
               * @default 'Custom eCard'
               */
              title: T;

              children: {};
            };
            reminder: {
              /**
               * @default 'Reminder Email'
               */
              title: T;

              children: {};
            };
            general: {
              /**
               * @default 'General Message Email'
               */
              title: T;

              children: {};
            };
            thankyou: {
              /**
               * @default 'Thank You Note'
               */
              title: T;

              children: {};
            };
          };
        };
      };
    };
  };
};

export type AdminDashboardV2Translations<T extends LeafNodeValue = TranslateFunction> = {
  inviteAdminDialog: {
    /**
     * @default 'Event Admins'
     */
    title: T;
  };
  visibility: {
    /**
     * @default 'On'
     */
    on: T;

    /**
     * @default 'Off'
     */
    off: T;

    /**
     * @default 'RSVP On'
     */
    rsvpOn: T;

    /**
     * @default 'RSVP Off'
     */
    rsvpOff: T;

    /**
     * @default 'Hidden'
     */
    hidden: T;

    /**
     * @default 'Public'
     */
    public: T;

    /**
     * @default 'Password Protected'
     */
    passwordProtected: T;
  };
  text: {
    /**
     * @default 'Loading admin dashboard'
     */
    loading: T;

    /**
     * @default 'Share event information'
     */
    shareEvent: T;

    /**
     * @default 'New'
     */
    newBadge: T;
  };
  sections: {
    registry: {
      /**
       * @default 'Registry'
       */
      title: T;

      children: {
        overview: {
          /**
           * @default 'Overview'
           */
          title: T;

          /**
           * @default 'Registry'
           */
          experimentTitle: T;

          children: {};
        };
        track: {
          /**
           * @default 'Track & Thank'
           */
          title: T;

          children: {};
        };
        manage: {
          /**
           * @default 'Manage Registry'
           */
          title: T;

          children: {};
        };
        shop: {
          /**
           * @default 'Add Gifts'
           */
          title: T;

          /**
           * @default 'Add Gifts'
           */
          experimentTitle: T;

          children: {};
        };
      };
    };
    website: {
      /**
       * @default 'Website'
       */
      title: T;

      children: {
        websiteOverview: {
          /**
           * @default 'Overview'
           */
          title: T;

          children: {};
        };
        design: {
          /**
           * @default 'Design'
           */
          title: T;

          children: {};
        };
        roomBlocks: {
          /**
           * @default 'Hotels'
           */
          title: T;

          children: {};
        };
        photos: {
          /**
           * @default 'Photos'
           */
          title: T;

          children: {};
        };
        editPages: {
          /**
           * @default 'Edit Pages'
           */
          title: T;

          children: {
            welcome: {
              /**
               * @default 'Home'
               */
              title: T;

              children: {};
            };
            video: {
              /**
               * @default 'Video'
               */
              title: T;

              children: {};
            };
            story: {
              /**
               * @default 'Story & Tidbits'
               */
              title: T;

              children: {};
            };
            schedule: {
              /**
               * @default 'Schedule'
               */
              title: T;

              children: {};
            };
            travel: {
              /**
               * @default 'Travel'
               */
              title: T;

              children: {};
            };
            accommodations: {
              /**
               * @default 'Accommodations'
               */
              title: T;

              /**
               * @default 'Where to Stay'
               */
              titleV2: T;

              children: {};
            };
            guest: {
              /**
               * @default 'Guest Q & A'
               */
              title: T;

              children: {};
            };
            weddingParty: {
              /**
               * @default 'Wedding Party'
               */
              title: T;

              children: {};
            };
            registry: {
              /**
               * @default 'Registry'
               */
              title: T;

              children: {};
            };
            addNewPage: {
              /**
               * @default 'Add New Page'
               */
              title: T;

              children: {};
            };
            managePages: {
              /**
               * @default 'Manage Pages'
               */
              title: T;

              children: {};
            };
          };
        };
      };
    };
    invites: {
      /**
       * @default 'Cards & Messaging'
       */
      title: T;

      children: {
        saveTheDates: {
          /**
           * @default 'Save the Dates'
           */
          title: T;

          children: {
            print: {
              /**
               * @default 'Paper Cards'
               */
              title: T;

              children: {};
            };
            digital: {
              /**
               * @default 'Digital Cards'
               */
              title: T;

              children: {};
            };
          };
        };
        invitations: {
          /**
           * @default 'Invitations'
           */
          title: T;

          children: {
            print: {
              /**
               * @default 'Paper Cards'
               */
              title: T;

              children: {};
            };
            digital: {
              /**
               * @default 'Digital Cards'
               */
              title: T;

              children: {};
            };
          };
        };
        thankYou: {
          /**
           * @default 'Thank You Cards'
           */
          title: T;

          children: {};
        };
        customCards: {
          /**
           * @default 'Custom Card'
           */
          title: T;

          children: {};
        };
        print: {
          /**
           * @default 'Paper Cards'
           */
          title: T;

          children: {
            invitations: {
              /**
               * @default 'Invitations'
               */
              title: T;

              children: {};
            };
            saveTheDates: {
              /**
               * @default 'Save the Dates'
               */
              title: T;

              children: {};
            };
            thankYou: {
              /**
               * @default 'Thank You'
               */
              title: T;

              children: {};
            };
          };
        };
        digital: {
          /**
           * @default 'Digital Cards'
           */
          title: T;

          children: {
            invitations: {
              /**
               * @default 'Invitations'
               */
              title: T;

              children: {};
            };
            saveTheDates: {
              /**
               * @default 'Save the Dates'
               */
              title: T;

              children: {};
            };
            customCard: {
              /**
               * @default 'Custom Cards'
               */
              title: T;

              children: {};
            };
          };
        };
        emails: {
          /**
           * @default 'Emails'
           */
          title: T;

          children: {
            reminder: {
              /**
               * @default 'Reminder Email'
               */
              title: T;

              children: {};
            };
            general: {
              /**
               * @default 'General Message'
               */
              title: T;

              children: {};
            };
          };
        };
        messaging: {
          /**
           * @default 'Messaging'
           */
          title: T;

          children: {
            rsvp: {
              /**
               * @default 'RSVP Reminder'
               */
              title: T;

              children: {};
            };
            reminder: {
              /**
               * @default 'Reminder Email'
               */
              title: T;

              children: {};
            };
            hotel: {
              /**
               * @default 'Hotel Reminder'
               */
              title: T;

              children: {};
            };
            general: {
              /**
               * @default 'General Message'
               */
              title: T;

              children: {};
            };
          };
        };
      };
    };
    guestList: {
      /**
       * @default 'Planning & RSVP'
       */
      title: T;

      children: {
        guestList: {
          /**
           * @default 'Guest List'
           */
          title: T;

          children: {};
        };
        contactCollector: {
          /**
           * @default 'Contact Collector'
           */
          title: T;

          children: {};
        };
        rsvp: {
          /**
           * @default 'Manage RSVP'
           */
          title: T;

          children: {};
        };
        weddingShop: {
          /**
           * @default 'Decor & Favors'
           */
          title: T;

          children: {};
        };
        hotels: {
          /**
           * @default 'Hotels'
           */
          title: T;

          children: {
            roomBlocks: {
              /**
               * @default 'Room Blocks'
               */
              title: T;

              children: {};
            };
            honeymoon: {
              /**
               * @default 'Honeymoon'
               */
              title: T;

              children: {};
            };
          };
        };
      };
    };
  };
  valuePropositions: {
    postWeddingDiscount: {
      /**
       * @default 'Registry Completion Discount'
       */
      title: T;

      /**
       * @default 'Save 20% on all remaining items in your registry.'
       */
      description: T;
    };
    priceMatchPromise: {
      /**
       * @default 'Price Match Promise'
       */
      title: T;

      /**
       * @default 'If you find a lower price on Registry items, we’ll match it!'
       */
      description: T;
    };
    freeShipping: {
      /**
       * @default 'Free Shipping'
       */
      title: T;

      /**
       * @default 'Enjoy free standard shipping on all your registry gifts.'
       */
      description: T;
    };
    easyReturns: {
      /**
       * @default 'Easy Returns'
       */
      title: T;

      /**
       * @default 'Free and easy online returns let you get the gift you want'
       */
      description: T;
    };
    general: {
      /**
       * @default 'Get Started'
       */
      cta: T;
    };
  };
  printEarlyAccessDialog: {
    /**
     * @default 'Find Your Perfect Match'
     */
    eyebrow: T;

    /**
     * @default 'With Joy Paper, you can customize gorgeous Save the Dates and wedding invitations as unique as your love story.'
     */
    title: T;

    features: [
      /**
       * @default 'Match your wedding website'
       */
      T,
      /**
       * @default 'Add your own design'
       */
      T,
      /**
       * @default 'QR code for simple sharing'
       */
      T,
      /**
       * @default 'Premium invitation suites'
       */
      T,
      /**
       * @default 'Send paper & digital cards'
       */
      T,
      /**
       * @default 'Endless customization'
       */
      T
    ];

    /**
     * @default 'Browse Card Designs'
     */
    cta: T;

    legacyContent: {
      /**
       * @default 'Introducing'
       */
      eyebrow: T;

      /**
       * @default 'Joy Paper'
       */
      title: T;

      /**
       * @default 'Explore Designs'
       */
      cta: T;

      promo: {
        /**
         * @default 'Get {{percentageDiscount}} off to print your favorite Save the Dates and Invitations with the exclusive discount.'
         */
        description: T;
      };
      nonPromo: {
        /**
         * @default 'Design and print your favorite Save the Dates and Invitations.'
         */
        description: T;
      };
    };
  };
  addressReminderBanner: {
    /**
     * @default 'Registry - Avoid missing packages and awkward texts, we’ll take care of the rest.'
     */
    description: T;

    /**
     * @default 'Add Shipping Address'
     */
    buttonLabel: T;
  };
  multiProductPromoBanner: {
    /**
     * @default 'Learn More'
     */
    learnMoreCta: T;

    valueProps: {
      hotelBlocks: {
        /**
         * @default 'Room Blocks by Joy. Get discounted hotel rates with no commitment.'
         */
        valuePropMessage: T;

        /**
         * @default 'Introducing Room Block Discounts'
         */
        title: T;

        /**
         * @default 'Pick hotels near your venue and take care of your guests with great rates and no fees.'
         */
        description: T;

        /**
         * @default 'Explore Hotels'
         */
        ctaText: T;
      };
      printAndDigital: {
        /**
         * @default 'Create Beautiful Paper and Digital Save the Dates and Invitations.'
         */
        valuePropMessage: T;

        /**
         * @default 'Paper and Digital Cards'
         */
        title: T;

        /**
         * @default 'Match your wedding website or pick your favorite Save the Date or Invitation design.'
         */
        description: T;

        /**
         * @default 'Explore Designs'
         */
        ctaText: T;
      };
      registry: {
        /**
         * @default 'Meet the Everything Registry. Add Gifts from Anywhere.'
         */
        valuePropMessage: T;

        /**
         * @default 'Meet the Everything Registry'
         */
        title: T;

        /**
         * @default 'Shop the top brands, cash funds and experiences all in one place. Add gifts from any store.'
         */
        description: T;

        /**
         * @default 'Browse Gifts'
         */
        ctaText: T;
      };
    };
  };
  multiProductPromoDialog: {
    /**
     * @default 'Unlock the Best of Joy'
     */
    dialogTitle: T;

    /**
     * @default 'Discover how we can help plan your wedding.'
     */
    dialogDescription: T;

    /**
     * @default 'Next'
     */
    nextCta: T;

    steps: {
      hotelBlocks: {
        /**
         * @default 'Hotel Room Blocks'
         */
        stepperLabel: T;

        /**
         * @default 'Hotel Room Blocks'
         */
        title: T;

        /**
         * @default 'Pick hotels near your venue and let our free concierge agent negotiate exclusive rates for you and your guests.'
         */
        description: T;

        /**
         * @default 'Explore Hotels'
         */
        ctaText: T;
      };
      printAndDigital: {
        /**
         * @default 'Paper & Digital Cards'
         */
        stepperLabel: T;

        /**
         * @default 'Paper and Digital Cards'
         */
        title: T;

        /**
         * @default 'Match your wedding website or pick your favorite Save the Date or Invitation design to celebrate your special day.'
         */
        description: T;

        /**
         * @default 'Explore Designs'
         */
        ctaText: T;
      };
      registry: {
        /**
         * @default 'Universal Registry'
         */
        stepperLabel: T;

        /**
         * @default 'Meet the Everything Registry'
         */
        title: T;

        /**
         * @default 'Shop the top brands, cash funds and experiences all in one place. Add gifts from any store.'
         */
        description: T;

        /**
         * @default 'Browse Gifts'
         */
        ctaText: T;
      };
    };
  };
  checklist: {
    progressBar: {
      /**
       * @default 'Planning Checklist'
       */
      titleExpanded: T;

      /**
       * @default '{{count}} Days til the big day!'
       */
      titleCollapsedUntil: T;

      /**
       * @default 'Tomorrow is the big day!'
       */
      titleCollapseTomorrow: T;

      /**
       * @default 'Today is the big day!'
       */
      titleCollapsedToday: T;

      /**
       * @default 'Congratulations on your wedding!'
       */
      titleCollapsedPast: T;

      /**
       * @default '0 of 6 Complete'
       */
      counter0: T;

      /**
       * @default '1 of 6 Complete ✨ Keep it up!'
       */
      counter1: T;

      /**
       * @default '2 of 6 Complete 🌈 Nice work!'
       */
      counter2: T;

      /**
       * @default '3 of 6 Complete 🎉 Halfway there!'
       */
      counter3: T;

      /**
       * @default '4 of 6 Complete 🔥 You’re on fire!'
       */
      counter4: T;

      /**
       * @default '5 of 6 Complete 😎 Nearly there!'
       */
      counter5: T;

      /**
       * @default '6 of 6 Complete 💍 Time to party!'
       */
      counter6: T;

      /**
       * @default 'Planning Checklist'
       * @param {number} count - The number of ocurrencies.
       * - singular: Planning Checklist
       * - plural: {{count}} Days left
       */
      countdown: T;

      /**
       * @default 'Today is the day!'
       */
      countdownToday: T;

      /**
       * @default 'Congratulations!'
       */
      countdownCongrats: T;
    };
    reminderButton: {
      /**
       * @default 'Plan It'
       */
      noDate: T;

      /**
       * @default 'Change Date'
       */
      changeDate: T;
    };
    groupModal: {
      /**
       * @default 'Visit our Help Center'
       */
      helpCenter: T;

      /**
       * @default 'Contact Support'
       */
      support: T;

      items: {
        reviewEventDetails: {
          /**
           * @default 'Make sure to set up the basics'
           */
          subtitle: T;

          /**
           * @default 'Because it never hurts to triple check!'
           */
          tip: T;
        };
        createWeddingWebsite: {
          /**
           * @default 'All the details for you and your guests.'
           */
          subtitle: T;

          /**
           * @default 'Most Joy couples start here and finish pre-invites.'
           */
          tip: T;
        };
        saveTheDate: {
          /**
           * @default 'A mix of paper and digital cards is most common.'
           */
          subtitle: T;

          /**
           * @default 'Most Joy couples do this 8-12 months before their event.'
           */
          tip: T;
        };
        readyYourRegistry: {
          /**
           * @default 'Finish before invites and avoid the awkward.'
           */
          subtitle: T;

          /**
           * @default 'Most Joy couples do this 4 months before their event.'
           */
          tip: T;
        };
        beTheSuperHost: {
          /**
           * @default 'Help your guests save and celebrate.'
           */
          subtitle: T;

          /**
           * @default 'Most Joy couples do this 4 months before their event.'
           */
          tip: T;
        };
        sendOutInvitations: {
          /**
           * @default 'Guests will ask you about registry and accommodations so it is best finish these first.'
           */
          subtitle: T;

          /**
           * @default 'Most Joy couples do this 6-8 weeks before their event.'
           */
          tip: T;
        };
      };
    };
  };
  stats: {
    progressType: {
      /**
       * @default 'Goal'
       */
      goal: T;
    };
    guestList: {
      /**
       * @default 'Guest List'
       */
      title: T;
    };
    potentialGuests: {
      /**
       * @default 'Potential Guests'
       */
      cta: T;

      /**
       * @default 'Edit Guest List'
       */
      ctaHover: T;
    };
    contactsCollected: {
      /**
       * @default 'Contacts Collected'
       */
      cta: T;

      /**
       * @default 'Open Collector'
       */
      ctaHover: T;
    };
    saveTheDates: {
      /**
       * @default 'Save the Dates'
       */
      title: T;
    };
    printsOrdered: {
      /**
       * @default 'Prints Ordered'
       */
      cta: T;

      /**
       * @default 'Start Design'
       */
      ctaHover: T;
    };
    digitalCardsSent: {
      /**
       * @default 'Digital Cards Sent'
       */
      cta: T;

      /**
       * @default 'Send Digital Cards'
       */
      ctaHover: T;
    };
    hotels: {
      /**
       * @default 'Hotels'
       */
      title: T;
    };
    hotelWishList: {
      /**
       * @default 'Hotel Wish List'
       */
      cta: T;

      /**
       * @default 'Select Hotels'
       */
      ctaHover: T;
    };
    roomsRequested: {
      /**
       * @default 'Rooms Requested'
       */
      cta: T;

      /**
       * @default 'Contact Concierge'
       */
      ctaHover: T;
    };
    registry: {
      /**
       * @default 'Registry'
       */
      title: T;
    };
    giftsAvailable: {
      /**
       * @default 'Gifts Available'
       */
      cta: T;

      /**
       * @default 'Manage Gifts'
       */
      ctaHover: T;
    };
    reservedAndPurchased: {
      /**
       * @default 'Reserved & Purchased'
       */
      cta: T;

      /**
       * @default 'View Gifts Received'
       */
      ctaHover: T;
    };
    thankYouNotesSent: {
      /**
       * @default 'Thank You Notes Sent'
       */
      cta: T;

      /**
       * @default 'Send Thanks'
       */
      ctaHover: T;
    };
    cashReceived: {
      /**
       * @default 'Cash Received'
       */
      cta: T;

      /**
       * @default 'View Gifts Received & Transfer Funds'
       */
      ctaHover: T;
    };
    rsvp: {
      /**
       * @default 'RSVP'
       */
      title: T;
    };
    attending: {
      /**
       * @default 'Attending'
       */
      cta: T;

      /**
       * @default 'Open RSVPs'
       */
      ctaHover: T;
    };
    declined: {
      /**
       * @default 'Declined'
       */
      cta: T;

      /**
       * @default 'Open RSVPs'
       */
      ctaHover: T;
    };
    pending: {
      /**
       * @default 'Pending'
       */
      cta: T;

      /**
       * @default 'Open RSVPs'
       */
      ctaHover: T;
    };
    invitations: {
      /**
       * @default 'Invitations'
       */
      title: T;
    };
  };
  websitePreview: {
    /**
     * @default '{{count}} Public Page'
     * @param {number} count - The number of ocurrencies.
     * - singular: {{count}} Public Page
     * - plural: {{count}} Public Pages
     */
    publicPage: T;

    /**
     * @default '{{count}} Protected'
     */
    protectedPage: T;

    /**
     * @default '{{count}} Off'
     */
    off: T;

    /**
     * @default 'Edit Design'
     */
    editDesignCta: T;

    /**
     * @default 'View Website'
     */
    viewWebsiteCta: T;
  };
  reEngagementPopup: {
    hotelBlock: {
      /**
       * @default 'Finish requesting your room block'
       */
      title: T;

      /**
       * @default '{{numberOfHotels}} hotels selected'
       */
      subTitle: T;
    };
    print: {
      /**
       * @default 'Your drafts are waiting — finalize your card'
       */
      title: T;

      subTitle: {
        /**
         * @default '{{numberOfDrafts}} draft in progress'
         */
        singular: T;

        /**
         * @default '{{numberOfDrafts}} drafts in progress'
         */
        plural: T;
      };

      /**
       * @default 'Your Save The Dates are waiting'
       */
      saveTheDateTitle: T;

      /**
       * @default 'Your Invitations are waiting'
       */
      invitationTitle: T;

      /**
       * @default 'Your Thank You Cards are waiting'
       */
      thankYouTitle: T;

      /**
       * @default 'Add the finishing touches.'
       */
      cardSubtile: T;
    };
    hotelReminder: {
      /**
       * @default 'Remind your guests about accommodations'
       */
      title: T;

      /**
       * @default 'Send a friendly reminder.'
       */
      subTitle: T;
    };
  };
};

export type AdminDashboardV3Translations<T extends LeafNodeValue = TranslateFunction> = {
  mainHeader: {
    /**
     * @default 'Wedding Planner'
     */
    title: T;

    /**
     * @default 'Preview Site'
     */
    previewCta: T;

    /**
     * @default 'Share'
     */
    shareCta: T;

    /**
     * @default 'Support'
     */
    supportCta: T;
  };
  mainEventDetails: {
    /**
     * @default 'Set Event Date'
     */
    dateCta: T;

    /**
     * @default 'Set Event Location'
     */
    locationCta: T;

    /**
     * @default 'Set Event Venue'
     */
    venueCta: T;

    /**
     * @default 'Add Event Venue'
     */
    venueAddCta: T;
  };
  mainStats: {
    /**
     * @default 'Potential Guests'
     */
    potentialGuestsLabel: T;

    /**
     * @default 'Edit Guest List'
     */
    potentialGuestsCta: T;

    /**
     * @default 'Attending Guests'
     */
    attendingLabel: T;

    /**
     * @default 'Open RSVPs'
     */
    attendingCta: T;

    /**
     * @default 'Gifts Available'
     */
    giftsAvailableLabel: T;

    /**
     * @default 'Manage Gifts'
     */
    giftsAvailableCta: T;
  };
  dashboardAccordion: {
    promoCards: {
      /**
       * @default 'Maybe Later'
       */
      maybeLaterCta: T;

      website: {
        /**
         * @default 'Your website is ready.'
         */
        title: T;

        /**
         * @default 'Customize your design or begin making these pages your own.'
         */
        subtitle: T;

        /**
         * @default 'Design Your Website'
         */
        primaryCta: T;

        /**
         * @default 'Edit Pages'
         */
        secondaryCta: T;
      };
      planningRsvp: {
        /**
         * @default 'Collect everyone’s contact info, instantly.'
         */
        title: T;

        /**
         * @default 'Build your guest list faster by sending friends a magic link or uploading phone contacts.'
         */
        subtitle: T;

        /**
         * @default 'Start Collecting Contacts'
         */
        primaryCta: T;

        /**
         * @default 'Upload Contacts with Joy App'
         */
        secondaryCta: T;
      };
      saveTheDates: {
        /**
         * @default 'Save that Date!'
         */
        title: T;

        /**
         * @default 'Whether you’re sending prints, digital, or both, we’ll help you do it in style.'
         */
        subtitle: T;

        /**
         * @default 'Start Save the Dates'
         */
        primaryCta: T;
      };
      invitations: {
        /**
         * @default 'Start Inviting & Delighting'
         */
        title: T;

        /**
         * @default 'Design the perfect card in print, digital, or both—your big day starts here.'
         */
        subtitle: T;

        /**
         * @default 'Start Invitations'
         */
        primaryCta: T;
      };
      travel: {
        /**
         * @default 'Wedding travel has never been easier.'
         */
        title: T;

        /**
         * @default 'Give your guests all the details and hook them up with the best rates.'
         */
        subtitle: T;

        /**
         * @default 'Select Hotels'
         */
        primaryCta: T;
      };
      registry: {
        /**
         * @default 'Your universal registry is waiting.'
         */
        title: T;

        /**
         * @default 'Any gifts. Any store. All yours.'
         */
        subtitle: T;

        /**
         * @default 'Build Your Registry'
         */
        primaryCta: T;

        /**
         * @default 'I already have a Registry'
         */
        secondaryCta: T;
      };
    };
    onOffBadge: {
      /**
       * @default 'On'
       */
      onBadgeLabel: T;

      /**
       * @default 'Off'
       */
      offBadgeLabel: T;
    };
    badges: {
      /**
       * @default 'RSVP'
       */
      rsvp: T;

      /**
       * @default 'RSVP is'
       */
      rsvpIs: T;

      /**
       * @default 'Registry'
       */
      registry: T;
    };
    items: {
      /**
       * @default 'Website'
       */
      website: T;

      /**
       * @default 'Planning & RSVP'
       */
      planningRsvp: T;

      /**
       * @default 'Save the Dates'
       */
      saveTheDates: T;

      /**
       * @default 'Registry'
       */
      registry: T;

      /**
       * @default 'Travel'
       */
      travel: T;

      /**
       * @default 'Invitations'
       */
      invitations: T;

      /**
       * @default 'Contact Guests'
       */
      contactGuests: T;
    };
    displayStatus: {
      /**
       * @default '{{count}} Public Page'
       * @param {number} count - The number of ocurrencies.
       * - singular: {{count}} Public Page
       * - plural: {{count}} Public Pages
       */
      publicPage: T;

      /**
       * @default '{{count}} Protected'
       */
      protectedPage: T;

      /**
       * @default '{{count}} Hidden'
       */
      off: T;

      /**
       * @default '{{page}} is Public'
       */
      isPublic: T;

      /**
       * @default '{{page}} is Password Protected'
       */
      isProtected: T;

      /**
       * @default '{{page}} is Hidden'
       */
      isOff: T;
    };
    menu: {
      /**
       * @default 'New'
       */
      newBadgeLabel: T;

      menuItems: {
        /**
         * @default 'Design'
         */
        website: T;

        /**
         * @default 'Photos'
         */
        photos: T;

        /**
         * @default 'Hotels'
         */
        hotels: T;

        /**
         * @default 'Edit Pages'
         */
        editPages: T;

        /**
         * @default 'Guest List'
         */
        guestList: T;

        /**
         * @default 'Contact Collector'
         */
        contactCollector: T;

        /**
         * @default 'Manage RSVP'
         */
        manageRSVP: T;

        /**
         * @default 'Gifts & Decor'
         */
        giftsAndDecor: T;

        /**
         * @default 'Design & Send'
         */
        designAndSend: T;

        /**
         * @default 'Overview'
         */
        overview: T;

        /**
         * @default 'Add Gifts'
         */
        addGifts: T;

        /**
         * @default 'Manage'
         */
        manage: T;

        /**
         * @default 'Track & Thanks'
         */
        trackAndThanks: T;

        /**
         * @default 'Hotels'
         */
        roomBlocks: T;

        /**
         * @default 'Travel Page'
         */
        travelPage: T;

        /**
         * @default 'Where to Stay'
         */
        whereToStay: T;

        /**
         * @default 'Send Message to Guests'
         */
        sendMessageToGuests: T;

        /**
         * @default 'Remind Guests to RSVP'
         */
        remindGuestsToRSVP: T;

        /**
         * @default 'Remind Guests to Book'
         */
        sendBookHotelReminder: T;

        /**
         * @default 'Thank You Cards'
         */
        thankYouCards: T;

        /**
         * @default 'Custom Card'
         */
        customCard: T;

        /**
         * @default 'Overview'
         */
        websiteOverview: T;
      };
    };
    statCards: {
      progressType: {
        /**
         * @default 'Goal'
         */
        goal: T;
      };
      potentialGuests: {
        /**
         * @default 'Potential Guests'
         */
        cta: T;

        /**
         * @default 'Edit Guest List'
         */
        ctaHover: T;
      };
      contactsCollected: {
        /**
         * @default 'Contacts Collected'
         */
        cta: T;

        /**
         * @default 'Open Collector'
         */
        ctaHover: T;
      };
      printsOrdered: {
        /**
         * @default 'Prints Ordered'
         */
        cta: T;

        /**
         * @default 'Start Design'
         */
        ctaHover: T;
      };
      digitalCardsSent: {
        /**
         * @default 'Digital Cards Sent'
         */
        cta: T;

        /**
         * @default 'Send Digital Cards'
         */
        ctaHover: T;
      };
      hotelWishList: {
        /**
         * @default 'Hotel Wish List'
         */
        cta: T;

        /**
         * @default 'Select Hotels'
         */
        ctaHover: T;
      };
      roomsRequested: {
        /**
         * @default 'Rooms Requested'
         */
        cta: T;

        /**
         * @default 'Contact Concierge'
         */
        ctaHover: T;
      };
      giftsAvailable: {
        /**
         * @default 'Gifts Available'
         */
        cta: T;

        /**
         * @default 'Manage Gifts'
         */
        ctaHover: T;
      };
      reservedAndPurchased: {
        /**
         * @default 'Reserved & Purchased'
         */
        cta: T;

        /**
         * @default 'View Gifts Received'
         */
        ctaHover: T;
      };
      thankYouNotesSent: {
        /**
         * @default 'Thank You Notes Sent'
         */
        cta: T;

        /**
         * @default 'Send Thanks'
         */
        ctaHover: T;
      };
      cashReceived: {
        /**
         * @default 'Cash Received'
         */
        cta: T;

        /**
         * @default 'View Gifts Received & Transfer Funds'
         */
        ctaHover: T;
      };
      attending: {
        /**
         * @default 'Attending'
         */
        cta: T;

        /**
         * @default 'Open RSVPs'
         */
        ctaHover: T;
      };
      declined: {
        /**
         * @default 'Declined'
         */
        cta: T;

        /**
         * @default 'Open RSVPs'
         */
        ctaHover: T;
      };
      pending: {
        /**
         * @default 'Pending'
         */
        cta: T;

        /**
         * @default 'Open RSVPs'
         */
        ctaHover: T;
      };
    };
  };
  eventVenueTooltip: {
    /**
     * @default 'New'
     */
    newFlag: T;

    /**
     * @default 'Add Your Venue'
     */
    title: T;

    /**
     * @default 'Update Your Venue'
     */
    titleUpdate: T;

    /**
     * @default 'Include it on your website, invitations, and other guest facing communication.'
     */
    content: T;
  };
};

export type BnplTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Settings'
   */
  settingsTitle: T;

  /**
   * @default 'Edit your business profile and linked bank accounts'
   */
  settingsSubTitle: T;

  /**
   * @default 'Edit what coulples, guests and peers see on Joy'
   */
  editProfileText: T;

  /**
   * @default 'Edit Profile'
   */
  editProfileButtonText: T;

  /**
   * @default 'Update your account information such as login email and password'
   */
  editAccountText: T;

  /**
   * @default 'Update Account'
   */
  editAccountButtonText: T;

  /**
   * @default 'Linked Bank Accounts'
   */
  linkBankAccountTitle: T;

  /**
   * @default 'Link a bank account'
   */
  linkBankAcountButtonSingular: T;

  /**
   * @default 'Link another bank account'
   */
  linkBankAcountButtonPlural: T;
};

export type BookingAssistantTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Open {{site}} with your details in a new tab.'
   */
  placeTitle: T;

  /**
   * @default 'Exclusive prices. Top brands.'
   */
  joyChoiceSubtitle: T;
};

export type BookmarkletTranslations<T extends LeafNodeValue = TranslateFunction> = {
  bookmarklet: {
    /**
     * @default 'You can add items to your registry from any store!'
     */
    title: T;

    /**
     * @default 'Drag the button below to your Bookmarks bar to get started'
     */
    subtitle: T;

    /**
     * @default 'Add to Joy'
     */
    button: T;

    /**
     * @default 'Please drag and drop this button to the Bookmarks bar'
     */
    dragAlert: T;

    steps: [
      {
        /**
         * @default 'Make sure your Bookmarks bar is visible'
         */
        step: T;

        /**
         * @default 'In Chrome's top menu, go to View and make sure there's a checkmark next to Always Show Bookmarks Bar.'
         */
        description: T;
      },
      {
        /**
         * @default 'Click and drag the Add to Joy button to your Bookmarks bar'
         */
        step: T;

        /**
         * @default ' '
         */
        description: T;
      },
      {
        /**
         * @default 'Now try it out!'
         */
        step: T;

        /**
         * @default 'Visit any store and click your new Registry button.'
         */
        description: T;
      }
    ];
  };
  addRegistry: {
    /**
     * @default 'Sign in'
     */
    signIn: T;
  };
  validation: {
    /**
     * @default 'Invalid URL'
     */
    invalidDomain: T;

    /**
     * @default 'Required'
     */
    required: T;
  };
  joyButtonDialog: {
    /**
     * @default 'Easily add items from any store with the Joy bookmark button'
     */
    title: T;

    /**
     * @default 'Drag the button below to your browser’s Bookmark bar to save it.'
     */
    subtitle: T;

    /**
     * @default 'Add to Joy'
     */
    addToJoy: T;

    /**
     * @default 'All done? Let’s test it out'
     */
    alreadyAdded: T;

    /**
     * @default 'Read the guide'
     */
    watchTurtorial: T;

    /**
     * @default 'Nice work! Now try testing it by adding the item below'
     */
    trySubtitle: T;

    /**
     * @default 'Try Adding This Item'
     */
    tryButton: T;

    /**
     * @default 'Start over'
     */
    startOver: T;

    /**
     * @default 'Still need help?'
     */
    stillNeedHelp: T;
  };
  addGiftDialog: {
    /**
     * @default 'Choose your registry'
     */
    multipleEventSelectTitle: T;

    /**
     * @default 'Add Gift'
     */
    actionText: T;

    /**
     * @default 'Choose Image'
     */
    giftImageLabel: T;

    /**
     * @default 'Title'
     */
    nameInputLabel: T;

    /**
     * @default 'Price'
     */
    priceInputLabel: T;

    /**
     * @default 'Qty'
     */
    qtyInputLabel: T;

    /**
     * @default 'Gift Type'
     */
    typeInputLabel: T;

    /**
     * @default 'Note to Family and Friends'
     */
    noteInputLabel: T;

    /**
     * @default 'Add an optional note here to help your gift-givers.'
     */
    noteInputPlaceholder: T;
  };
  successContent: {
    /**
     * @default 'Awesome!'
     */
    successTitle: T;

    /**
     * @default '{{title}} has been successfully added to your registry.'
     */
    successMessage: T;

    /**
     * @default 'Go to registry'
     */
    successCta: T;

    /**
     * @default 'add your shipping address'
     */
    shippingAddressLinkText: T;

    /**
     * @default 'Don’t forget to <shippingAddressLink/> so gift givers know where to send your gift!'
     */
    shippingAddressReminderText: T;
  };
  failContent: {
    /**
     * @default 'Your Registry'
     */
    yourRegistry: T;

    /**
     * @default 'Item Could Not Be Added'
     */
    failTitle: T;

    /**
     * @default 'This item failed to be added. Go to <br /><yourRegistry/> to manually add this item'
     */
    failMessage: T;
  };
  noAuthDialog: {
    /**
     * @default 'You are no longer signed in to Joy.'
     */
    title: T;

    /**
     * @default 'Login at withjoy.com and try again.'
     */
    subtitle: T;

    /**
     * @default 'Sign In'
     */
    signIn: T;

    /**
     * @default 'Connect your Joy account first to start adding items to your registry.'
     */
    notConnected: T;

    /**
     * @default 'Connect'
     */
    connect: T;

    /**
     * @default 'Your Joy account is now connected, you can start adding gifts to your registry!'
     */
    accountConnected: T;
  };
  safariComingDialog: {
    /**
     * @default 'The Joy button is coming to Safari soon'
     */
    title: T;

    /**
     * @default 'In the meantime, you can use the browser button on Chrome, Firefox, or Microsoft Edge'
     */
    subtitle: T;

    /**
     * @default 'Still need help?'
     */
    stillNeedHelp: T;
  };
  customItemDataLabels: {
    /**
     * @default 'Product'
     */
    gift: T;

    /**
     * @default 'Charity'
     */
    charity: T;

    /**
     * @default 'Cash Fund'
     */
    cash: T;
  };
  browserButton: {
    /**
     * @default 'This store doesn’t work with the Joy Browser button. Copy the URL and add this item manually on your manage page in Joy.'
     */
    scriptLoadError: T;
  };
  popupTrigger: {
    /**
     * @default 'Popup May Have Been Blocked'
     */
    heading: T;

    /**
     * @default 'Click the button below to launch Add to Joy in another window'
     */
    instructions: T;

    /**
     * @default 'Open Add to Joy'
     */
    button: T;

    /**
     * @default 'Looks like we couldn't get past your popup blocker. Could you please disable it just so we can figure out what product you're trying to add to your registry?'
     */
    error: T;
  };
};

export type CatalogRegistryTranslations<T extends LeafNodeValue = TranslateFunction> = {
  addProduct: {
    /**
     * @default 'Add to Registry'
     */
    addToRegistry: T;

    /**
     * @default 'Add to Cart'
     */
    addToCart: T;

    /**
     * @default 'The item was added to your registry!'
     */
    notificationText: T;
  };
  sidebarNav: {
    /**
     * @default 'Shop All {{categoryName}}'
     */
    showAll: T;

    /**
     * @default 'Back'
     */
    back: T;

    /**
     * @default 'Shop'
     */
    shop: T;

    /**
     * @default 'Departments'
     */
    departmentTitle: T;
  };
  productDetailPage: {
    /**
     * @default 'Sold by'
     */
    soldBy: T;

    /**
     * @default 'Fulfilled by'
     */
    fulfilledBy: T;

    /**
     * @default 'Exclusively with Joy'
     */
    joyExclusive: T;

    /**
     * @default 'In Stock and Ready to Ship'
     */
    inStockAndReadyToShip: T;

    /**
     * @default 'Buy Now'
     */
    buyNow: T;

    /**
     * @default 'Quantity'
     */
    quantity: T;

    /**
     * @default 'Why We Love It'
     */
    whyWeLoveIt: T;

    /**
     * @default 'Specs'
     */
    specs: T;

    /**
     * @default 'Description'
     */
    description: T;

    /**
     * @default 'Return to {{title}}'
     */
    returnTo: T;

    /**
     * @default 'Please select a {{label}}'
     */
    pleaseSelect: T;

    /**
     * @default 'View Details'
     */
    viewDetails: T;

    /**
     * @default 'Quick Add'
     */
    quickAddToRegistry: T;

    /**
     * @default 'Coming Soon'
     */
    comingSoonItemTitle: T;

    /**
     * @default 'This item will be available on Joy soon. You can add to your registry now.'
     */
    comingSoonItemNote: T;

    /**
     * @default 'No longer available'
     */
    discontinuedItemTitle: T;

    /**
     * @default 'This item is no longer available in our catalog.'
     */
    discontinuedItemNote: T;

    /**
     * @default 'Back in Stock Soon'
     */
    backorderedItemTitle: T;

    /**
     * @default 'This item is temporarily out of stock. We're expecting it to be back soon. You can still add it to your registry.'
     */
    backorderedItemNote: T;

    /**
     * @default 'Or'
     */
    buttonsDividerText: T;
  };
  emptyCategoryContent: {
    /**
     * @default 'We're stocking our shelves'
     */
    title: T;

    /**
     * @default 'In the meantime, you can add gifts from any online store to your registry using the Joy App'
     */
    subtitle: T;

    /**
     * @default 'Add Gifts'
     */
    iosButton: T;
  };
  babyCatalogEmptyStateContent: {
    /**
     * @default 'Coming Soon!'
     */
    title: T;

    /**
     * @default 'We're working on bringing the best collection of <b>{{selectedCategory}}</b> for you.'
     */
    subtitle: T;
  };
  cashFundCatalog: {
    /**
     * @default 'Edit and Add'
     */
    editAndAdd: T;

    /**
     * @default '{{title}} added'
     */
    fundAddedTitle: T;

    /**
     * @default 'You'll be notified when friends and family start contributing!'
     */
    fundSuccessSubtitle: T;

    /**
     * @default 'Create Your Own {{fund}}'
     */
    createYourOwn: T;

    /**
     * @default 'You May Also Like'
     */
    collectionMembersTitle: T;

    /**
     * @default 'View Registry'
     */
    viewRegistry: T;
  };
  seo: {
    wedding: {
      /**
       * @default 'Shop | Joy | The All-In-One Wedding Registry'
       */
      shopIndexTitle: T;

      /**
       * @default 'Shop on Joy to create the best all-in-one wedding registry experience from hundreds of brands across kitchen, tabletop, bed & bath, home, travel, cash funds, charities, experiences and more.'
       */
      shopIndexDescription: T;

      /**
       * @default '{{category}} | Joy'
       */
      shopCategoryTitle: T;

      /**
       * @default 'Shop from {{category}} on Joy to build the best all-in-one wedding registry experience for you and your guests.'
       */
      shopCategoryDescription: T;

      /**
       * @default 'Curations | Joy | The All-In-One Wedding Registry'
       */
      shopCurationIndexTitle: T;

      /**
       * @default 'Shop from Joy Curations created by our team of registry experts to build the best all-in-one wedding registry experience for you and your guests.'
       */
      shopCurationIndexDescription: T;

      /**
       * @default '{{curation}} | Joy'
       */
      shopCurationDetailsTitle: T;

      /**
       * @default 'Shop from {{curation}} on Joy to build the best all-in-one wedding registry experience for you and your guests.'
       */
      shopCurationDetailsDescription: T;

      /**
       * @default 'Brands | Joy'
       */
      shopBrandIndexTitle: T;

      /**
       * @default 'Shop from Brands on Joy to build the best all-in-one wedding registry experience for you and your guests.'
       */
      shopBrandIndexDescription: T;

      /**
       * @default ' {{brand}} | Joy'
       */
      shopBrandDetailsTitle: T;

      /**
       * @default 'Shop {{brand}} on Joy to build the best all-in-one wedding registry experience for you and your guests.'
       */
      shopBrandDetailsDescription: T;
    };
    baby: {
      /**
       * @default 'Baby Shop | Joy | The Universal Baby Registry'
       */
      shopIndexTitle: T;

      /**
       * @default 'Shop on Joy to add everything for you and your new bundle of Joy from  hundreds of brands across clothing, strollers & car seats, travel & gear, feeding & nursing, nursery, health & safety, bath & potty, toys & activity, and cash funds.'
       */
      shopIndexDescription: T;

      /**
       * @default '{{category}} | Joy'
       */
      shopCategoryTitle: T;

      /**
       * @default 'Shop from {{category}} on Joy to build a complete baby registry for you and your new bundle of Joy'
       */
      shopCategoryDescription: T;

      /**
       * @default 'Curations | Joy | The Universal Baby Registry'
       */
      shopCurationIndexTitle: T;

      /**
       * @default 'Shop from Joy Curations created by our team of baby registry experts to build the complete registry for your new bundle of Joy.'
       */
      shopCurationIndexDescription: T;

      /**
       * @default '{{curation}} | Joy'
       */
      shopCurationDetailsTitle: T;

      /**
       * @default 'Shop from {{curation}} on Joy to build the best all-in-one wedding registry experience for you and your guests.'
       */
      shopCurationDetailsDescription: T;

      /**
       * @default 'Brands | Joy'
       */
      shopBrandIndexTitle: T;

      /**
       * @default 'Shop from Brands on Joy to build the best all-in-one wedding registry experience for you and your guests.'
       */
      shopBrandIndexDescription: T;

      /**
       * @default '{{brand}} | Joy'
       */
      shopBrandDetailsTitle: T;

      /**
       * @default 'Shop {{brand}} on Joy to build the best all-in-one wedding registry experience for you and your guests.'
       */
      shopBrandDetailsDescription: T;
    };
  };
  currencyWarningDialog: {
    /**
     * @default 'Delivery for these items is not available outside the United States.'
     */
    title: T;

    /**
     * @default 'Instead add items from stores that can ship to you in two easy ways:'
     */
    subtitle: T;

    /**
     * @default 'Instead add items from stores that can ship to you via the Joy App.'
     */
    mobileSubtitle: T;

    /**
     * @default 'Add items to your registry from other retailers'
     */
    ctaOne: T;

    /**
     * @default 'Install Browser Button'
     */
    ctaOneButton: T;

    /**
     * @default 'Paste a link to an item and fill out the details'
     */
    ctaTwo: T;

    /**
     * @default 'Add Item Manually'
     */
    ctaTwoButton: T;

    /**
     * @default 'Get the App'
     */
    mobileButton: T;

    /**
     * @default 'Dismiss'
     */
    dismiss: T;

    /**
     * @default 'Add Item Anyway'
     */
    addAnyway: T;
  };
  refinementsPanel: {
    /**
     * @default 'Refine'
     */
    refineFilters: T;

    /**
     * @default 'Clear All'
     */
    clearAll: T;

    clearAllFilters: {
      /**
       * @default 'Clear {{count}} filter'
       */
      messageWithCountOne: T;

      /**
       * @default 'Clear {{count}} filters'
       */
      messageWithCountMoreThanOne: T;
    };

    /**
     * @default 'Filter'
     */
    filter: T;

    /**
     * @default 'Filters'
     */
    filters: T;

    /**
     * @default 'Hide Filter'
     */
    hideFilter: T;

    /**
     * @default 'Hide Filters'
     */
    hideFilters: T;

    /**
     * @default 'Price: >${{minValue}}'
     */
    priceGraterThan: T;

    /**
     * @default 'Price: <=${{maxValue}}'
     */
    priceLowerThan: T;

    /**
     * @default 'Price: ${{minValue}} - ${{maxValue}}'
     */
    priceRange: T;

    /**
     * @default 'Price: '
     */
    pricePrefix: T;

    /**
     * @default '${{maxValue}} or less'
     */
    priceAndLess: T;

    /**
     * @default '${{minValue}} or more'
     */
    priceAndMore: T;

    /**
     * @default '${{minValue}} - ${{maxValue}}'
     */
    priceBucketRange: T;

    /**
     * @default 'Custom'
     */
    custom: T;

    /**
     * @default 'Show Filter'
     */
    showFilter: T;

    /**
     * @default 'Show Filters'
     */
    showFilters: T;

    /**
     * @default 'Sort'
     */
    sort: T;

    sortByPriceOptions: {
      /**
       * @default 'Recommended'
       */
      featured: T;

      /**
       * @default 'Price: Low to High'
       */
      priceAsc: T;

      /**
       * @default 'Price: High to Low'
       */
      priceDesc: T;
    };
  };
  searchBar: {
    /**
     * @default 'Search brands, products, and more'
     */
    searchInputPlaceholder: T;

    /**
     * @default 'Search'
     */
    searchInputPlaceholderMobile: T;

    /**
     * @default 'Couples are searching for…'
     */
    coupleTrendsTitle: T;

    /**
     * @default '{{quantity}} in the last hour'
     */
    lastHour: T;
  };
  searchHeader: {
    /**
     * @default 'Searching for...'
     */
    titleSearching: T;

    /**
     * @default 'Showing results for'
     */
    titleShowResult: T;

    /**
     * @default 'Item{{plural}} Found'
     */
    subTitle: T;

    /**
     * @default 'No items were found matching'
     */
    subTitleNoItemsFound: T;

    /**
     * @default '{{count}} item{{pluralization}} for “{{searchTerm}}”'
     */
    itemsFoundV2: T;

    breadcrumbs: {
      /**
       * @default 'Shop'
       */
      root: T;

      /**
       * @default 'Search Results'
       */
      current: T;
    };
    noSearchResults: {
      /**
       * @default 'Add From Any Store'
       */
      hed: T;

      /**
       * @default 'You can add top items from any store'
       */
      dek: T;
    };
  };
  searchResult: {
    /**
     * @default 'Modify your search'
     */
    modifySearchButtonText: T;

    /**
     * @default 'Or'
     */
    modifySearchText: T;

    modifySearchExplore: {
      /**
       * @default 'Explore Popular Couple Picks'
       */
      wedding: T;

      /**
       * @default 'Explore Popular Picks For Your Baby'
       */
      baby: T;
    };
  };
  curationList: {
    /**
     * @default 'Top Curations'
     */
    title: T;

    /**
     * @default 'Browse All'
     */
    cta: T;

    /**
     * @default 'Explore More Curations'
     */
    title2: T;

    /**
     * @default 'See All Curations'
     */
    cta2: T;

    /**
     * @default 'Show All {{total}}'
     */
    cta3: T;
  };
  categoryRecommendationsCarousel: {
    /**
     * @default 'Explore {{title}}'
     */
    explore: T;

    /**
     * @default 'Show All {{length}}'
     */
    showAll: T;

    /**
     * @default 'Explore {{title}} Top Picks'
     */
    explorePopularStores: T;
  };
  curationHeroHeader: {
    billboard: {
      /**
       * @default 'Shop the Curation'
       */
      exploreButton: T;

      /**
       * @default 'Watch the Video'
       */
      watchButton: T;

      /**
       * @default 'Shop {{brandName}}'
       */
      shopButton: T;
    };
  };
  curationHero: {
    /**
     * @default 'Read more'
     */
    readMore: T;

    /**
     * @default 'less'
     */
    less: T;
  };
  browseCategoryBanner: {
    /**
     * @default 'Browse All Gifts'
     */
    browseButton: T;

    /**
     * @default 'Interested in more gifts from {{categoryTitle}}?'
     */
    bannerText: T;
  };
  recommendedProductsSection: {
    wedding: {
      /**
       * @default 'Your Personalized Picks'
       */
      sectionTitle: T;
    };
    baby: {
      /**
       * @default 'Baby Registry Essentials'
       */
      sectionTitle: T;
    };
  };
  brandPage: {
    /**
     * @default 'Show more'
     */
    showMore: T;

    /**
     * @default 'Less'
     */
    less: T;
  };
  productTile: {
    /**
     * @default '{{lowerBound}} - {{upperBound}}'
     */
    priceRange: T;
  };
  brandsMarquee: {
    /**
     * @default 'Over 10,000+ items from all the best brands'
     */
    weddingTitle: T;

    /**
     * @default 'Top items from all the best brands'
     */
    babyRegistryTitle: T;

    /**
     * @default 'Shop Your Favorite Brands'
     */
    titleOverview: T;
  };
  curationsSection: {
    /**
     * @default 'View'
     */
    button: T;

    /**
     * @default 'Gift Inspo '
     */
    titleR1: T;

    /**
     * @default 'From the Experts'
     */
    titleR2: T;

    /**
     * @default 'Registry recommendations you'll love (and why you need them).'
     */
    subtitle: T;
  };
  categoryPage: {
    /**
     * @default 'Browse'
     */
    buttonHover: T;
  };
  productGrid: {
    /**
     * @default 'Explore All Products'
     */
    exploreAllProducts: T;

    /**
     * @default 'Explore all {{categoryTitle}}'
     */
    exploreAllCategory: T;

    /**
     * @default 'Top {{title}} Gifts Added <br />by Joy Couples'
     */
    popularCategory: T;

    /**
     * @default 'Explore More {{parentTitle}}'
     */
    exploreMore: T;

    /**
     * @default 'Explore More'
     */
    exploreMoreTitle: T;
  };
  storefrontNavigator: {
    /**
     * @default 'New'
     */
    badgeNew: T;

    /**
     * @default 'Joy Wedding'
     */
    labelStorefrontWedding: T;

    /**
     * @default 'Joy Baby'
     */
    labelStorefrontBaby: T;
  };
  departmentNavSection: {
    /**
     * @default 'Browse by Department'
     */
    title: T;

    /**
     * @default 'Explore {{categoryTitle}}'
     */
    exploreCategory: T;
  };
  valuePropsTopBar: {
    wedding: {
      shipping: {
        /**
         * @default 'Free Shipping'
         */
        title: T;
      };
      completionDiscount: {
        /**
         * @default 'Registry Completion Discount'
         */
        title: T;
      };
      priceMatchPromise: {
        /**
         * @default 'Price Match Promise'
         */
        title: T;
      };
      easyReturns: {
        /**
         * @default 'Easy Returns'
         */
        title: T;
      };
    };
    baby: {
      shipping: {
        /**
         * @default 'Free Shipping'
         */
        title: T;
      };
      completionDiscount: {
        /**
         * @default '20% Completion Discount'
         */
        title: T;
      };
      priceMatchPromise: {
        /**
         * @default 'Price Match Promise'
         */
        title: T;
      };
      easyReturns: {
        /**
         * @default 'Easy Returns'
         */
        title: T;
      };
    };
  };
  valuePropsSection: {
    /**
     * @default 'Every Joy Registry Deserves'
     */
    title: T;

    /**
     * @default 'Perks of Registering with Joy'
     */
    titleOverview: T;

    /**
     * @default 'Free Shipping'
     */
    freeShipping: T;

    /**
     * @default 'Delay Shipping If You Need It'
     */
    delayShipping: T;

    /**
     * @default 'AI-Assisted Thank You Notes'
     */
    thankYouNotes: T;

    /**
     * @default '20% Completion Discount'
     */
    postWeddingDiscount: T;

    /**
     * @default '20% Completion Discount'
     */
    completionDiscountBaby: T;

    /**
     * @default 'Zero-Fee Cash Funds'
     */
    zeroFeeCashFunds: T;

    /**
     * @default 'Universal Registry'
     */
    universalRegistry: T;

    /**
     * @default 'Price Match Promise'
     */
    priceMatchPromise: T;

    /**
     * @default 'Easy Returns'
     */
    easyReturns: T;
  };
  productRating: {
    /**
     * @default ''
     */
    noLabel: T;

    /**
     * @default '{{averageRating}}'
     */
    averageRating: T;

    /**
     * @default '{{averageRating}} ({{totalReviewCount}} Reviews)'
     */
    averageRatingWithCount: T;

    /**
     * @default '{{averageRating}} ({{totalReviewCount}})'
     */
    averageRatingWithCountShort: T;

    /**
     * @default '({{totalReviewCount}} Reviews)'
     */
    totalReviewCount: T;

    /**
     * @default '({{totalReviewCount}})'
     */
    totalReviewCountShort: T;
  };
  catalogItemCustomerReviewsSection: {
    wedding: {
      /**
       * @default 'Ratings & Reviews'
       */
      sectionTitle: T;
    };
    baby: {
      /**
       * @default 'Ratings & Reviews'
       */
      sectionTitle: T;
    };
    statsSummary: {
      /**
       * @default '{{reviewCount}} Reviews'
       */
      totalReviewCount: T;
    };
    reviewList: {
      /**
       * @default 'Reviews'
       */
      header: T;

      /**
       * @default 'Show More'
       */
      showMoreButton: T;

      /**
       * @default 'Anonymous'
       */
      unknownReviewAuthor: T;

      /**
       * @default 'Originally posted on {{source}}'
       */
      syndicationSourceNotice: T;
    };
  };
  universalSearch: {
    productgrid: {
      /**
       * @default 'Add From Anywhere'
       */
      sectionHed: T;

      /**
       * @default 'Explore top items from other shops.'
       */
      sectionDek: T;

      productTile: {
        /**
         * @default 'View Product'
         */
        viewProductCta: T;

        /**
         * @default 'Create Registry'
         */
        createRegistryCta: T;
      };
    };
    pdpModal: {
      formFields: {
        price: {
          /**
           * @default 'Price'
           */
          label: T;

          /**
           * @default 'Missing a price'
           */
          error: T;
        };
        title: {
          /**
           * @default 'Title'
           */
          label: T;

          /**
           * @default 'Missing a title'
           */
          error: T;
        };
        quantity: {
          /**
           * @default 'Quantity'
           */
          label: T;
        };
        note: {
          /**
           * @default 'Note'
           */
          label: T;

          /**
           * @default 'Optional: Tell folks what this gift means to you, what you'll do with it, or why you want it — have fun with it.'
           */
          placeholder: T;
        };
      };

      /**
       * @default 'View on {{shopName}}'
       */
      viewShopOnLink: T;

      /**
       * @default 'Cancel'
       */
      cancelCta: T;

      /**
       * @default 'Add to Registry'
       */
      addCta: T;
    };

    /**
     * @default 'This product cannot be loaded at the moment. Please try again later.'
     */
    getProductByIdError: T;

    /**
     * @default 'This product cannot be added to the registry at the moment. Please try again later.'
     */
    addProductToRegistryError: T;
  };
  brands: {
    /**
     * @default 'Our Brands'
     */
    ourBrands: T;

    /**
     * @default 'Shop by'
     */
    shopBy: T;

    /**
     * @default 'Filter'
     */
    filter: T;

    /**
     * @default 'Clear All'
     */
    clearAll: T;

    /**
     * @default 'Confirm'
     */
    confirm: T;
  };
  recentlyAddedRegistryItemUpdateDrawer: {
    /**
     * @default 'Added to your registry!'
     */
    drawerTitle: T;

    /**
     * @default 'You can also mark this gift as:'
     */
    updateSectionHeader: T;

    /**
     * @default 'Your change has been saved.'
     */
    updateConfirmationMessage: T;

    markAsMostWantedToggle: {
      /**
       * @default 'Our Most Wanted'
       */
      label: T;

      /**
       * @default 'Let your guests know your must-have gifts!'
       */
      tooltipMessage: T;
    };

    /**
     * @default 'Mark Gift as Most Wanted'
     */
    updateCtaMessage: T;

    /**
     * @default 'Most Wanted'
     */
    mostWanted: T;

    /**
     * @default 'Remove from Most Wanted'
     */
    removeFromMostWanted: T;
  };
  salesPrice: {
    /**
     * @default 'Sale'
     */
    message: T;
  };
  doYouHaveRegistry: {
    /**
     * @default 'Quick Question'
     */
    eyebrow: T;

    /**
     * @default 'Do you already have
a registry somewhere else?'
*/
    question: T;

    /**
     * @default 'Yes'
     */
    yes: T;

    /**
     * @default 'No'
     */
    no: T;
  };
};

export type CompanyPoliciesTranslations<T extends LeafNodeValue = TranslateFunction> = {
  shippingPolicy: {
    seo: {
      /**
       * @default 'With Joy Shipping Policy'
       */
      title: T;

      /**
       * @default 'Joy Shipping policy. Most items will ship via Standard Ground Shipping and arrive within 5-10 business days from when the order was placed. Estimated shipping times are counted in business days, excluding weekends and holidays, after the order has shipped.'
       */
      description: T;
    };
    hero: {
      /**
       * @default 'Shipping Info'
       */
      title: T;

      /**
       * @default 'Last Updated: December 12, 2023'
       */
      date: T;

      /**
       * @default 'Don’t you just love it when something special is on its way? Here’s what to expect when it comes to shipping with Joy…'
       */
      subtitle: T;
    };

    paragraphs: [
      {
        /**
         * @default '<strong>Items Added from the Joy Shop:</strong> Most items will ship via Standard Ground Shipping and arrive within 5-10 business days from when the order was placed. Estimated shipping times are counted in business days, excluding weekends and holidays, after the order has shipped.'
         */
        text: T;
      },
      {
        /**
         * @default 'Expedited Shipping is not always available. If applicable, you’ll see which items are eligible during checkout.'
         */
        text: T;
      },
      {
        /**
         * @default 'For gifts added from the Joy shop, you can enjoy free shipping on all orders over $45. For gifts added from external registries, the shipping terms will typically align with the policies of the retailer itself. In this case, the shipping fees, delivery times, and any additional details will be determined by the external retailer’s shipping policies. Any shipping and handling fees will be listed during checkout.'
         */
        text: T;
      },
      {
        /**
         * @default 'For Joy physical and eligible items, subtotals equal to or more than $45 will ship for free.'
         */
        text: T;
      },
      {
        /**
         * @default '<strong>These items include</strong>: Physical Joy Shop products and Joy Gift Wrap eCards.'
         */
        text: T;
      },
      {
        /**
         * @default 'Items that <strong>are not eligible</strong> or may not include free shipping include: Gift cards or eGift cards, Cash gifts or cash contributions, Experiences or other email vouchers, Gifts added from external registries.'
         */
        text: T;
      },
      {
        /**
         * @default 'Shipping is only available in the contiguous U.S. and items cannot be shipped to a PO Box or APO/FPO/DPO. Additional surcharges may apply depending on the item and location and will be shown at checkout when applicable. Please note, shipping fees are non-refundable.'
         */
        text: T;
      },
      {
        /**
         * @default '<strong>Items Added from External Registries or Retailers:</strong> The shipping terms, including fees, delivery times, and details for these items will typically align with the policies of the retailer itself. Any shipping and handling fees will be listed during checkout.'
         */
        text: T;
      }
    ];
  };
  returnPolicy: {
    seo: {
      /**
       * @default 'With Joy Return Policy'
       */
      title: T;

      /**
       * @default 'Experience the Joy return policy, allowing for returns on most items within a generous 90-day window. Discover more about our policy.'
       */
      description: T;
    };
    hero: {
      /**
       * @default 'Returns & Exchanges'
       */
      title: T;

      /**
       * @default 'Last Updated: September 12, 2024'
       */
      date: T;

      /**
       * @default 'We always strive to deliver Joy, but in case you’d like to return or exchange an item, here are the details.'
       */
      subtitle: T;
    };

    sections: [
      {
        /**
         * @default 'Registry Purchases'
         */
        title: T;

        subsections: [
          {
            /**
             * @default 'Most items can be returned within 90 days. Don’t worry! If you’re returning a gift that was purchased for you, the gift giver will not be notified.'
             */
            title: T;

            listItems: [
              {
                /**
                 * @default 'You must request a return or exchange no more than 90 days after your event OR no more than 90 days after purchase, whichever date is later. There are no exceptions.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'In order for eligible item(s) to be returned, proof of purchase is required and returned item(s) must be in the same condition received, unopened, unused, and in the original packaging.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'You’ll be issued a refund in the form of a merchandise credit in the amount of the full value originally paid, less any shipping and restocking fees if applicable.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'If the item was purchased through a Third Party’s website, for example, Amazon.com or Target.com, that store’s return policy applies. Any return, refund, or exchange should be handled directly by the retailer from where the item was purchased.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Please note: Joy retains the right to review and/or deny any return request on a case-by-case basis.'
                 */
                text: T;
              }
            ];

            listIndentedItems: [];

            listNoBulletItems: [];
          }
        ];
      },
      {
        /**
         * @default 'Printed Cards and Paper'
         */
        title: T;

        subsections: [
          {
            /**
             * @default 'We want you to be 100% happy with your prints. That’s why each order has a 24 hour window to make any last minute changes, be it a typo, updated shipping address, or other minor change. Simply reach out to our customer support team at printsupport@withjoy.com with your change or cancellation request.'
             */
            title: T;

            listItems: [];

            listIndentedItems: [];

            listNoBulletItems: [];
          },
          {
            /**
             * @default 'Given that each paper order is custom printed to your specifications, all sales are final after the 24-hour grace period.'
             */
            title: T;

            listItems: [];

            listIndentedItems: [];

            listNoBulletItems: [];
          },
          {
            /**
             * @default 'If an error was detected after the 24-hour grace period, you may reach out to our customer support team at printsupport@withjoy.com, and our agents will review your request on a case-by-case basis.'
             */
            title: T;

            listItems: [];

            listIndentedItems: [];

            listNoBulletItems: [];
          },
          {
            /**
             * @default 'In the rare event that a printing issue affected your order, please reach out to our customer support team at printsupport@withjoy.com with the following information:'
             */
            title: T;

            listItems: [
              {
                /**
                 * @default 'Name'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Order number'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Brief description of the issue'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Photo showing the issue and how many items were impacted'
                 */
                text: T;
              }
            ];

            listIndentedItems: [];

            listNoBulletItems: [];
          },
          {
            /**
             * @default 'If we determine that there is a quality issue with our printing process, we will refund your order or reprint free of charge.'
             */
            title: T;

            listItems: [];

            listIndentedItems: [];

            listNoBulletItems: [];
          }
        ];
      },
      {
        /**
         * @default 'All Other Purchases'
         */
        title: T;

        subsections: [
          {
            /**
             * @default 'Most items can be returned within 30 days.'
             */
            title: T;

            listItems: [
              {
                /**
                 * @default 'An order may be canceled provided the item hasn’t shipped yet.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'If the item has already shipped, you must request a refund or exchange no more than 30 days after purchase. There are no exceptions.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'If the item was purchased through a Third Party’s website, for example, Amazon.com or Target.com, that store’s return policy applies. Any return, refund, or exchange should be handled directly by the retailer from where the item was purchased.'
                 */
                text: T;
              }
            ];

            listIndentedItems: [];

            listNoBulletItems: [];
          }
        ];
      },
      {
        /**
         * @default 'Refund Policy'
         */
        title: T;

        subsections: [
          {
            /**
             * @default 'In order for the eligible item(s) to be refunded, you must ensure that:'
             */
            title: T;

            listItems: [
              {
                /**
                 * @default 'Returned item(s) must be in the same condition received, unopened, unused, and in the original packaging.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Proof of purchase is provided.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Please note: Joy retains the right to review and/or deny any return request on a case-by-case basis.'
                 */
                text: T;
              }
            ];

            listIndentedItems: [];

            listNoBulletItems: [];
          },
          {
            /**
             * @default 'Proof of purchase is required to issue an appropriate exchange, credit, or refund of an eligible item(s), as well as any taxes due to be refunded in accordance with applicable state law. For your convenience, any of the following is accepted as proof of your purchase:'
             */
            title: T;

            listItems: [];

            listIndentedItems: [
              {
                /**
                 * @default 'Gift Receipt'
                 */
                text: T;
              },
              {
                /**
                 * @default 'eReceipt'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Original Packing Slip'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Gift Packing Slip'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Order Number'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Gift Registry Number'
                 */
                text: T;
              }
            ];

            listNoBulletItems: [];
          },
          {
            /**
             * @default 'There are a few items that can’t be returned:'
             */
            title: T;

            listItems: [];

            listIndentedItems: [
              {
                /**
                 * @default 'Opened and assembled furniture items'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Gift cards and eGift cards'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Redeemed experiences'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Personalized items or custom orders'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Shipping fees, if applicable, are non-refundable'
                 */
                text: T;
              }
            ];

            listNoBulletItems: [];
          },
          {
            /**
             * @default 'Restocking fees may apply to certain items:'
             */
            title: T;

            listItems: [
              {
                /**
                 * @default 'Any restocking fee will be deducted from your refund total once the return has been processed.'
                 */
                text: T;
              }
            ];

            listIndentedItems: [];

            listNoBulletItems: [];
          }
        ];
      },
      {
        /**
         * @default 'Experiences'
         */
        title: T;

        subsections: [
          {
            /**
             * @default 'Joy partners with third-party providers to offer our registrants a wide variety of experiences and event ticket options.'
             */
            title: T;

            listItems: [
              {
                /**
                 * @default 'Unredeemed experiences can be returned for a merchandise credit but are not eligible for a refund.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Third-party providers offer site-specific return policies. Gifts purchased through a third party are subject to those policies, if applicable.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Spur offers cash back refunds up to 30 days after the purchase on un-redeemed experiences.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'After 30 days, customers can exchange experiences for credit to their e-wallet.  These funds never expire and can be used towards other Spur experiences as well as tickets to sporting events and concerts. Experience vouchers never expire. However, some experience providers may change their offerings over time. If the experience is no longer offered, the total amount of the voucher will be credited to the user’s e-wallet. Spur’s full policy details can be viewed here.  <a href='https://spurexperiences.com/terms-conditions' target='blank'>https://spurexperiences.com/terms-conditions</a>'
                 */
                text: T;
              }
            ];

            listIndentedItems: [];

            listNoBulletItems: [];
          }
        ];
      },
      {
        /**
         * @default 'Third Party and External Items'
         */
        title: T;

        subsections: [
          {
            /**
             * @default 'Joy’s registry platform allows registrants to add items from other stores so they can manage their registry all in one place. If you’d like to return or exchange an item purchased through a third party, please be aware that:'
             */
            title: T;

            listItems: [
              {
                /**
                 * @default 'Items purchased from other websites are subject to the return policy of the store from which they were purchased.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'Any customer service inquiry, return, refund, or exchange for these items should be handled directly by the retailer from where the item was purchased.'
                 */
                text: T;
              }
            ];

            listIndentedItems: [];

            listNoBulletItems: [];
          }
        ];
      },
      {
        /**
         * @default 'Delivery Issues'
         */
        title: T;

        subsections: [
          {
            /**
             * @default 'If an item is damaged upon delivery:'
             */
            title: T;

            listItems: [
              {
                /**
                 * @default 'Contact customer service within 30 days of delivery.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'A photo of the damaged item and any packaging in the order must be provided in order to process any requests.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'A replacement or merchandise credit will be issued.'
                 */
                text: T;
              }
            ];

            listIndentedItems: [];

            listNoBulletItems: [];
          },
          {
            /**
             * @default 'If an item is defective:'
             */
            title: T;

            listItems: [
              {
                /**
                 * @default 'Contact customer service within 90 days of delivery and provide a photo of any visible defects and any information pertaining to the issue, regular care and use, and any possible troubleshooting of the issue.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'For all other defective items, please refer to the manufacturer’s warranty.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'If a replacement is not desired, the value of the item will be issued as a merchandise credit.'
                 */
                text: T;
              }
            ];

            listIndentedItems: [];

            listNoBulletItems: [];
          },
          {
            /**
             * @default 'If an item is lost or missing in transit:'
             */
            title: T;

            listItems: [
              {
                /**
                 * @default 'Contact customer service within 14 days of scheduled delivery.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'After 14 days, your order may not be able to be replaced.'
                 */
                text: T;
              }
            ];

            listIndentedItems: [];

            listNoBulletItems: [];
          },
          {
            /**
             * @default 'For back ordered items, including items that were purchased and are no longer available or items delayed from the manufacturer:'
             */
            title: T;

            listItems: [
              {
                /**
                 * @default 'You may cancel the order prior to the item being shipped.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'A merchandise credit will be issued for the full value paid less any fees, if applicable.'
                 */
                text: T;
              },
              {
                /**
                 * @default 'A replacement item of equal or greater value may be offered in certain circumstances.'
                 */
                text: T;
              }
            ];

            listIndentedItems: [];

            listNoBulletItems: [];
          },
          {
            /**
             * @default 'Questions?'
             */
            title: T;

            listItems: [
              {
                /**
                 * @default 'If you’re unable to find the answer to your question here, please contact Joy customer support at <a href='mailto:support@withjoy.com'>support@withjoy.com</a> and we’d be happy to help!'
                 */
                text: T;
              }
            ];

            listIndentedItems: [];

            listNoBulletItems: [];
          }
        ];
      }
    ];
  };
};

export type ContactCollectorTranslations<T extends LeafNodeValue = TranslateFunction> = {
  admin: {
    /**
     * @default 'Can you share your current mailing address? {{names}} would love to send you something special! 💌'
     */
    messagePlaceholder: T;

    /**
     * @default 'Hey! Mind sharing your current mailing address? 📮 {{names}} are excited to send you a little something special!'
     */
    messagePlaceholder0: T;

    /**
     * @default 'Hey! 👋 {{names}} are gathering addresses to send you something special! Could you share yours?'
     */
    messagePlaceholder1: T;

    /**
     * @default 'Hi! Could you share your mailing address? {{names}} have a special surprise to send your way!'
     */
    messagePlaceholder2: T;

    /**
     * @default 'You’re invited! But first, we need your mailing address. Click the link to share with {{names}}.'
     */
    messagePlaceholder3: T;

    /**
     * @default 'Hey! {{names}} are collecting addresses to mail you a special invitation. Can you provide yours? 📬'
     */
    messagePlaceholder4: T;

    /**
     * @default 'Can you provide your mailing address? {{names}} want to send something your way!'
     */
    messagePlaceholder5: T;

    /**
     * @default 'Hey! {{names}} are getting ready to send you something special. Can you share your address with them?'
     */
    messagePlaceholder6: T;

    /**
     * @default '{{names}} are collecting addresses for their event. Please click the link to share.'
     */
    messagePlaceholder7: T;

    /**
     * @default 'Quickly get everyone’s info in your Guest List.'
     */
    createTitle: T;

    /**
     * @default 'Customize how it looks to your heart’s delight.'
     */
    createSubTitle: T;

    /**
     * @default 'Edit Design'
     */
    editDesign: T;

    /**
     * @default 'Design Your Collector'
     */
    design: T;

    /**
     * @default 'Customize how it looks to your heart’s delight.'
     */
    customize: T;

    /**
     * @default 'Get Started'
     */
    getStarted: T;

    /**
     * @default 'Text Message Preview'
     */
    textMessagePreview: T;

    /**
     * @default 'How does it work?'
     */
    instructionsTitle: T;

    /**
     * @default 'Share via text or email.'
     */
    instructionsFirst: T;

    /**
     * @default 'Your guests fill out a form.'
     */
    instructionsSecond: T;

    /**
     * @default 'Your guest list updates!'
     */
    instructionsThird: T;

    /**
     * @default 'Set a Visual'
     */
    visual: T;

    /**
     * @default 'Photo source'
     */
    photo: T;

    /**
     * @default 'Write a Brief Note'
     */
    note: T;

    /**
     * @default 'Your Message'
     */
    message: T;

    /**
     * @default 'Link to Event Website'
     */
    linkWebsite: T;

    /**
     * @default 'Display Event Date'
     */
    displayEventDate: T;

    /**
     * @default 'Event Date'
     */
    eventDate: T;

    /**
     * @default 'Edit Date'
     */
    editDate: T;

    /**
     * @default 'Your Event Date is currently set to private. If you’d like it displayed here, please update your preferences in'
     */
    privateDate: T;

    /**
     * @default ', and refresh this page.'
     */
    refresh: T;

    /**
     * @default 'Create Magic Link'
     */
    createMagicLink: T;

    /**
     * @default 'Update Magic Link'
     */
    updateMagicLink: T;

    /**
     * @default 'There was an error creating the Magic Link'
     */
    magicLinkError: T;

    /**
     * @default 'There was an error clearing the Contact List'
     */
    clearContactListError: T;

    /**
     * @default 'Settings'
     */
    settings: T;

    /**
     * @default 'Your Contact Collector'
     */
    previewTitle: T;

    /**
     * @default 'Send this link to your guests. They’ll fill out their details and we’ll update your guest list.'
     */
    previewSubTitle: T;

    /**
     * @default 'Your Magic Link'
     */
    yourMagicLink: T;

    /**
     * @default 'Enable Contact Collector'
     */
    enableContactCollector: T;

    /**
     * @default 'Your Contact Collector is disabled. The link won’t be available until you activate it.'
     */
    disabledContactCollectorHelper: T;

    /**
     * @default 'There was an error updating the Contact Collector'
     */
    enableContactCollectorError: T;

    /**
     * @default 'Copy Link'
     */
    copyLink: T;

    /**
     * @default 'Clear Contact List'
     */
    clearContactList: T;

    /**
     * @default 'I understand that by clicking Clear Contact List, I will no longer be able to recover the contacts.'
     */
    clearContactListDialog: T;

    /**
     * @default 'Modify Design'
     */
    modifyDesign: T;

    /**
     * @default 'Preview as Guest'
     */
    previewAsGuest: T;

    /**
     * @default 'Recent Updates'
     */
    recentUpdates: T;

    /**
     * @default 'Edit Guest List'
     */
    editGuestList: T;

    /**
     * @default 'Start sharing!'
     */
    startSharing: T;

    /**
     * @default 'Your updated contacts will appear here.'
     */
    recentUpdatesPlaceholder: T;

    /**
     * @default 'Select Animation'
     */
    selectAnimation: T;

    /**
     * @default 'Select Image'
     */
    selectImage: T;

    /**
     * @default 'Hmm… we’re having some trouble.'
     */
    errorPageTitle: T;

    /**
     * @default 'This page may have changed or is temporarily unavailable.'
     */
    errorPageSubTitle: T;

    /**
     * @default 'There was an error fetching the Contacts Updates.'
     */
    recentUpdatesError: T;

    /**
     * @default 'Contact Collector'
     */
    title: T;

    /**
     * @default 'Your details will only be shared with {{names}}.'
     */
    landingPageMessage: T;

    /**
     * @default 'Provide Details'
     */
    provideDetails: T;

    /**
     * @default 'Visit Event Website'
     */
    visitEventWebsite: T;

    /**
     * @default 'The Couple'
     */
    theCouple: T;

    /**
     * @default 'Please Add Your Info'
     */
    addYourInfo: T;

    /**
     * @default 'Contact Info'
     */
    contactInfo: T;

    /**
     * @default 'First Name'
     */
    firstName: T;

    /**
     * @default 'Last Name'
     */
    lastName: T;

    /**
     * @default 'Mobile Phone'
     */
    phoneNumber: T;

    /**
     * @default 'Email'
     */
    email: T;

    /**
     * @default 'Mailing Address'
     */
    mailingAddress: T;

    /**
     * @default 'Address Line 1'
     */
    address1: T;

    /**
     * @default 'Apartment, Suite, etc. (Optional)'
     */
    address2: T;

    /**
     * @default 'Zip/Postal Code'
     */
    postalCode: T;

    /**
     * @default 'City'
     */
    city: T;

    /**
     * @default 'State/Province/Territory'
     */
    region: T;

    /**
     * @default 'Country/Region'
     */
    country: T;

    /**
     * @default 'Save Details'
     */
    saveDetails: T;

    /**
     * @default 'Required'
     */
    required: T;

    /**
     * @default 'Invalid Email'
     */
    invalidEmail: T;

    /**
     * @default 'Invalid Phone Number'
     */
    invalidPhoneNumber: T;

    /**
     * @default 'Does this look right?'
     */
    looksRight: T;

    /**
     * @default 'Modify Details'
     */
    modifyDetails: T;

    /**
     * @default 'Looks Good'
     */
    looksGood: T;

    /**
     * @default 'There was an error creating the contact'
     */
    contactError: T;

    /**
     * @default 'You’re all set, {{name}}!'
     */
    allSet: T;

    /**
     * @default 'We’ve sent your details to {{names}}!'
     */
    successMessage: T;

    /**
     * @default 'Address Suggestion'
     */
    addressSuggestion: T;

    /**
     * @default 'It is possible the following modified address will ensure a more successful delivery.'
     */
    addressSuggestionText1: T;

    /**
     * @default 'Please select the address you’d like to use.'
     */
    addressSuggestionText2: T;

    /**
     * @default 'Original Address'
     */
    originalAddress: T;

    /**
     * @default 'Suggested Address'
     */
    suggestedAddress: T;

    /**
     * @default 'Confirm Address'
     */
    confirmAddress: T;

    /**
     * @default 'Back'
     */
    back: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'We couldn’t validate your address.'
     */
    addressValidationError: T;

    /**
     * @default 'Terms'
     */
    terms: T;

    /**
     * @default 'Privacy Policy'
     */
    privacyPolicy: T;

    /**
     * @default 'By clicking “Save Details”, you agree to Joy’s'
     */
    termsAndConditions1: T;

    /**
     * @default 'and'
     */
    termsAndConditions2: T;

    /**
     * @default 'and consent to receive messages for this event via SMS. Message and data rates apply.'
     */
    termsAndConditions3: T;

    /**
     * @default 'By entering your phone number, you consent to receive messages for this event via SMS. Message and data rates may apply. Reply STOP to opt out proceeding, you confirm that you have read and agree to Joy’s'
     */
    termsAndConditions4: T;

    /**
     * @default 'Help'
     */
    help: T;

    /**
     * @default '✨ Link copied! ✨'
     */
    linkCopied: T;

    csvUpload: {
      /**
       * @default 'Upload Spreadsheet'
       */
      title: T;

      /**
       * @default '🎉 Success! Upload Completed!'
       */
      successTitle: T;

      /**
       * @default '😑 Something went wrong'
       */
      errorTitle: T;

      /**
       * @default 'contacts'
       */
      contacts: T;

      /**
       * @default 'valid'
       */
      valid: T;

      /**
       * @default 'invalid'
       */
      invalid: T;

      /**
       * @default 'Cancel'
       */
      cancelCta: T;

      /**
       * @default 'Import Valid Contacts'
       */
      importCta: T;

      /**
       * @default 'Upload Spreadsheet'
       */
      uploadCta: T;

      /**
       * @default 'Upload Your Spreadsheet'
       */
      uploadYourCta: T;

      /**
       * @default 'Already have a list of contacts?'
       */
      haveAList: T;

      /**
       * @default 'Get a head start by uploading a spreadsheet of your contacts.'
       */
      getAHead: T;

      /**
       * @default 'Seems like something went wrong. Please try to submit your spreadsheet again.'
       */
      error: T;

      /**
       * @default 'Please'
       */
      please: T;

      /**
       * @default 'Please make sure that all the required fields are filled in as per spreadsheet'
       */
      pleaseLong: T;

      /**
       * @default 'template'
       */
      template: T;

      /**
       * @default 'download the template'
       */
      download: T;

      /**
       * @default 'and follow the instructions on'
       */
      follow: T;

      /**
       * @default 'this page.'
       */
      thisPage: T;

      /**
       * @default 'Important:'
       */
      important: T;

      /**
       * @default 'Uploading a spreadsheet will create a new contact in your Guest List for each row. If you already have contacts in your guest list, we recommend editing them directly in your recipient selector or'
       */
      importantText: T;

      /**
       * @default 'Guest List'
       */
      guestList: T;

      /**
       * @default 'to avoid duplicates.'
       */
      avoid: T;

      /**
       * @default 'Download Template'
       */
      downloadCta: T;

      /**
       * @default 'Upload File'
       */
      uploadFileCta: T;

      /**
       * @default 'Upload New File'
       */
      uploadNewFileCta: T;

      /**
       * @default 'We’ve successfully imported {{quantity}} contacts from the spreadsheet.'
       */
      validContacts: T;

      /**
       * @default 'View Contacts'
       */
      viewContactsCta: T;

      /**
       * @default 'The spreadsheet couldn’t be uploaded due to incorrect formatting.'
       */
      fileFormatError: T;

      /**
       * @default 'The spreadsheet couldn’t have been uploaded due to the required column missing.'
       */
      fileRequiredColumnsError: T;
    };
  };
};

export type CreateBabyRegistryTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Create your Joy Event'
   */
  title: T;

  /**
   * @default 'Start creating your registry today and celebrate this special milestone with Joy!'
   */
  description: T;

  /**
   * @default 'Congratulations! You’re in for an amazing journey.'
   */
  heroText: T;

  /**
   * @default 'Let’s get your registry started.<helpChangeDetails/>'
   */
  helpText: T;

  /**
   * @default 'You can change these details later.'
   */
  helpChangeDetails: T;

  createEventForm: {
    /**
     * @default 'Your First Name'
     */
    ownerFirstName: T;

    /**
     * @default 'Your Last Name'
     */
    ownerLastName: T;

    /**
     * @default 'Pat'
     */
    ownerFirstNamePlaceholder: T;

    /**
     * @default 'Smith'
     */
    ownerLastNamePlaceholder: T;

    /**
     * @default 'Baby’s Estimated Arrival Date'
     */
    babyArrivalDate: T;

    /**
     * @default 'Not sure yet'
     */
    skipDateDefinition: T;

    /**
     * @default 'Create Registry'
     */
    createEventCta: T;

    errors: {
      /**
       * @default 'First name is required.'
       */
      ownerFirstNameRequired: T;

      /**
       * @default 'Last name is required.'
       */
      ownerLastNameRequired: T;

      /**
       * @default 'Baby’s estimated arrival date is required.'
       */
      registryDateRequired: T;
    };
  };
};

export type CreateEventTranslations<T extends LeafNodeValue = TranslateFunction> = {
  createAccount: {
    general: {
      /**
       * @default ''
       */
      devAlertMessage: T;
    };
    wedding: {
      /**
       * @default 'This is a test environment for the Joy engineering team. Are you sure you want to create an event here? Click cancel to be redirected to our main site.'
       */
      devAlertMessage: T;
    };
    babyRegistry: {
      /**
       * @default 'This is a test environment for the Joy engineering team. Are you sure you want to create a baby registry here? Click cancel to be redirected to our main site.'
       */
      devAlertMessage: T;

      /**
       * @default 'Celebrate life’s joyful moments.'
       */
      heroText: T;

      /**
       * @default 'Create one account for all your Joy events. Sign up or'
       */
      helpText: T;
    };
    errors: {
      /**
       * @default 'Please enter an email'
       */
      emailRequired: T;

      /**
       * @default 'Please enter a valid email address'
       */
      emailValid: T;

      /**
       * @default 'Please enter a password'
       */
      passwordRequired: T;
    };

    /**
     * @default 'log in.'
     */
    signIn: T;

    /**
     * @default 'Sign in with Facebook'
     */
    signInWithFacebook: T;

    /**
     * @default 'Sign in with Google'
     */
    signInWithGoogle: T;

    /**
     * @default 'Email'
     */
    email: T;

    /**
     * @default 'Password'
     */
    password: T;

    /**
     * @default 'Create Account'
     */
    createAccountCta: T;

    /**
     * @default 'privacy policy'
     */
    privacyPolicy: T;

    /**
     * @default 'terms of service'
     */
    termsOfService: T;

    /**
     * @default 'CA Privacy Notice.'
     */
    privacyNotice: T;

    /**
     * @default 'By creating an account you agree to Joy's <PrivacyPolicy/> and <TermsOfService/>. <PrivacyNotice/>'
     */
    termsAndPrivacy: T;
  };
  socialAccount: {
    /**
     * @default 'To create a baby registry, sign in with email and password'
     */
    createEventTitle: T;

    /**
     * @default 'You're currently signed in with {{provider}}, and creating baby registry with {{provider}} is no longer supported.'
     */
    currentAuthDisclaimer: T;

    /**
     * @default 'The good news is you can still create a baby registry by signing in with email and password.'
     */
    goodNews: T;

    /**
     * @default 'Create registry with email and password'
     */
    createEventCta: T;
  };
  searchBanner: {
    /**
     * @default 'Looking for an event or registry?'
     */
    text: T;
  };
  notYou: {
    /**
     * @default 'Not {{email}}?'
     */
    changeAccount: T;

    /**
     * @default 'Sign Out'
     */
    signOut: T;
  };
  tyingTheKnot: {
    /**
     * @default 'We've thought of everything, so you don't have to.'
     */
    loadingText: T;

    /**
     * @default 'Help Center'
     */
    helpCenter: T;

    /**
     * @default 'Oops! Something’s amiss. Disable Adblocker or VPN and try again. If the issue continues, visit our <HelpCenter/>'
     */
    errorText: T;
  };
};

export type CreateRegistryTranslations<T extends LeafNodeValue = TranslateFunction> = {
  pasteLink: {
    /**
     * @default 'Add a registry from another store'
     */
    title: T;

    /**
     * @default 'Put all your registries in one place for your guests.'
     */
    subtitle: T;

    /**
     * @default 'Registry URL'
     */
    inputlabel: T;

    /**
     * @default 'amazon.com/my-registry'
     */
    inputPlaceholder: T;

    /**
     * @default 'How does it work?'
     */
    learnMore: T;

    /**
     * @default 'Add Registry'
     */
    buttonText: T;
  };
  registryStoreSection: {
    default: {
      /**
       * @default 'Don’t have a registry yet? Quickly get started.'
       */
      title: T;

      /**
       * @default 'Find and add popular stores and charities recommended by other Joy recipient'
       */
      subtitle: T;
    };
    mobile: {
      /**
       * @default 'Pick your stores'
       */
      title: T;

      /**
       * @default 'Find and add popular stores and charities recommended by other Joy recipient'
       */
      subtitle: T;
    };

    /**
     * @default 'Show more'
     */
    showMore: T;

    /**
     * @default 'Show fewer'
     */
    showFewer: T;

    /**
     * @default 'The only cash fund registry with zero fees. Whether saving for a house, honeymoon, or other purchase, create a cash fund and personalize your cash gift requests to make your dreams a reality.'
     */
    cashFundDescription: T;

    /**
     * @default 'Create Cash Fund'
     */
    cashFundTitle: T;
  };
  storeDialog: {
    /**
     * @default 'Create New Registry'
     */
    createNewRegistry: T;

    /**
     * @default 'Add Existing Registry'
     */
    addExistingRegistry: T;

    /**
     * @default 'Congratulations on creating your registry! Paste in your registry URL below to add it to your wedding website.'
     */
    congrats: T;
  };
  validation: {
    /**
     * @default 'Invalid URL'
     */
    invalidDomain: T;

    /**
     * @default 'Required'
     */
    required: T;
  };
  addRegistryDialog: {
    /**
     * @default 'Link Registry'
     */
    title: T;

    /**
     * @default 'Edit Registry'
     */
    updateTitle: T;

    /**
     * @default 'Update Registry'
     */
    actionUpdateText: T;

    /**
     * @default 'Save'
     */
    actionText: T;

    /**
     * @default 'Cancel'
     */
    cancelActionText: T;

    /**
     * @default 'Registry Link'
     */
    urlInputLabel: T;

    /**
     * @default 'Registry Title'
     */
    nameInputLabel: T;

    /**
     * @default 'Link'
     */
    urlInputPlaceholder: T;

    /**
     * @default 'Title'
     */
    nameInputPlaceholder: T;

    /**
     * @default 'Registry Image'
     */
    registryImageLabel: T;

    /**
     * @default 'Delete Registry'
     */
    deleteActionText: T;

    /**
     * @default 'Test Registry Link'
     */
    testLink: T;

    /**
     * @default 'New'
     */
    transferEyebrow: T;

    /**
     * @default 'Simplify Your Registry with Joy'
     */
    transferTitle: T;

    /**
     * @default 'This Registry is currently {{status}}, but you can now copy it directly into Joy, making it easier to manage and track in one place.'
     */
    transferSubtitle: T;

    /**
     * @default 'Transfer Items to Joy'
     */
    transferButton: T;
  };
  addGift: {
    default: {
      /**
       * @default 'Add gifts from any store'
       */
      title: T;

      /**
       * @default 'Paste a link to add anything from any site to your Joy registry.'
       */
      subtitle: T;

      /**
       * @default 'Add Gift'
       */
      buttonText: T;
    };
    mobile: {
      /**
       * @default 'Add a gift from any store'
       */
      title: T;

      /**
       * @default 'Find something you want from another store? Copy and paste the link to add that gift to your Joy registry.'
       */
      subtitle: T;

      /**
       * @default 'Add Gift'
       */
      buttonText: T;
    };
  };
  linkRegistry: {
    /**
     * @default 'Already have a registry?'
     */
    title: T;

    /**
     * @default 'Put all your registries in one place for your guests’ convenience. '
     */
    subtitle: T;

    /**
     * @default 'Find and Add'
     */
    findAndAdd: T;

    /**
     * @default 'Ex. amazon.com/registry/userid'
     */
    inputPlaceholder: T;

    /**
     * @default ' Learn more.'
     */
    learnMore: T;

    /**
     * @default 'Link Registry'
     */
    linkRegistry: T;

    /**
     * @default 'Add Registry'
     */
    addRegistry: T;
  };
  welcomeHeader: {
    /**
     * @default 'Welcome to Your All-In-One Registry'
     */
    title: T;

    valueProps: {
      connectRegistries: {
        /**
         * @default 'Connect Registries'
         */
        title: T;

        /**
         * @default 'Everything in one place.'
         */
        subtitle: T;
      };
      shopJoy: {
        /**
         * @default 'Shop on Joy'
         */
        title: T;

        /**
         * @default 'Find the perfect gift.'
         */
        subtitle: T;
      };
      shopTheWeb: {
        /**
         * @default 'Shop the Web'
         */
        title: T;

        /**
         * @default 'Add from anywhere.'
         */
        subtitle: T;
      };
    };
  };
  productCards: {
    shopNow: {
      /**
       * @default 'Shop and build your happily ever after.'
       */
      title: T;

      /**
       * @default 'From kitchens to gardens.'
       */
      subtitle: T;

      /**
       * @default 'Shop Now'
       */
      buttonText: T;
    };
    exploreCashFunds: {
      /**
       * @default 'Zero-fee cash funds are here.'
       */
      title: T;

      /**
       * @default 'Jump start your dreams.'
       */
      subtitle: T;

      /**
       * @default 'Create Cash Fund'
       */
      buttonText: T;
    };
    getBrowserBtn: {
      /**
       * @default 'Add anything as you browse the web.'
       */
      title: T;

      /**
       * @default 'One click to add.'
       */
      subtitle: T;

      /**
       * @default 'Get the Quick Add Button'
       */
      buttonText: T;
    };
    downloadApp: {
      /**
       * @default 'Get the app and add gifts on the go.'
       */
      title: T;

      /**
       * @default 'Browse or search, whenever, wherever.'
       */
      subtitle: T;

      /**
       * @default 'Download the App'
       */
      buttonText: T;
    };
    browsingSotres: {
      /**
       * @default 'Start shopping from your favorite stores.'
       */
      title: T;

      /**
       * @default 'Just browse and tap to add gifts.'
       */
      subtitle: T;

      /**
       * @default 'Find and Add Gifts'
       */
      buttonText: T;
    };
  };
  experiments: {
    installBrowserButton: {
      native: {
        /**
         * @default 'Find and Add'
         */
        buttonText: T;
      };
      mobile: {
        /**
         * @default 'Open Joy App'
         */
        buttonText: T;
      };
      default: {
        /**
         * @default 'Get the Quick Add Button'
         */
        buttonText: T;
      };
    };
  };
  promoFeed: {
    firstItem: {
      /**
       * @default 'Add your first gift'
       */
      hed: T;

      /**
       * @default 'Amazing products, cash funds, and charities... oh my!'
       */
      dek: T;
    };

    introBlock: [
      {
        /**
         * @default 'Let's get shopping'
         */
        hed: T;

        /**
         * @default 'Check back daily for inspiration'
         */
        dek: T;
      },
      {
        /**
         * @default 'Get your shop on'
         */
        hed: T;

        /**
         * @default 'We're whippin' up more ideas on the daily'
         */
        dek: T;
      },
      {
        /**
         * @default 'New ideas and inspo'
         */
        hed: T;

        /**
         * @default 'Window shopping is what's up'
         */
        dek: T;
      },
      {
        /**
         * @default 'Dream big'
         */
        hed: T;

        /**
         * @default 'We've got some fresh ideas for you'
         */
        dek: T;
      },
      {
        /**
         * @default 'Get into ship shop page'
         */
        hed: T;

        /**
         * @default 'Your registry's gotta look good'
         */
        dek: T;
      }
    ];

    outroBlock: [
      {
        /**
         * @default 'Did you know?'
         */
        hed: T;

        /**
         * @default 'This is like fantasy football, but for shopping, and you're always the winner.'
         */
        dek: T;
      },
      {
        /**
         * @default 'The first retail registry was built in 1924'
         */
        hed: T;

        /**
         * @default 'You can thank Macy's for bringing it mainstream.'
         */
        dek: T;
      },
      {
        /**
         * @default '"On the reg" has a whole new meaning'
         */
        hed: T;

        /**
         * @default 'It's where all the cool products want to be.'
         */
        dek: T;
      }
    ];

    /**
     * @default 'Add to Registry'
     */
    addToRegistry: T;

    /**
     * @default 'Item Added'
     */
    addedToRegistry: T;

    /**
     * @default 'Shop by Department'
     */
    departmentNavTitle: T;
  };
  invitePartner: {
    /**
     * @default 'Shop together.'
     */
    title: T;

    /**
     * @default 'Invite your person.'
     */
    titleSecondLine: T;

    /**
     * @default 'Divide and conquer to make quick work of building out your perfect registry.'
     */
    subtitle: T;

    /**
     * @default 'Invite Your Person'
     */
    buttonText: T;
  };
  transferRegistry: {
    /**
     * @default 'Don’t forget to make your old registry private'
     */
    publicReminderTitle: T;

    /**
     * @default 'The registry you transferred to Joy is still publicly visible, which can result in duplicate gifts. We recommend hiding the following registry:'
     */
    publicReminderSubtitle: T;

    /**
     * @default 'Continue adding gifts from your favorite stores using the'
     */
    publicReminderDesc: T;

    /**
     * @default 'Joy Browser Button'
     */
    publicReminderCTA: T;

    /**
     * @default ' or the '
     */
    publicReminderCTACont: T;

    /**
     * @default 'Joy App'
     */
    publicReminderCTAApp: T;

    /**
     * @default 'Got It'
     */
    publicReminderButton: T;

    /**
     * @default 'Set your {{storeName}} registry private to avoid duplicate gifts.'
     */
    bannerTitle: T;

    /**
     * @default 'Your registry was last seen as public at {{lastCheckDate}}.'
     */
    bannerSubtitle: T;

    /**
     * @default 'Fix Privacy'
     */
    bannerButton: T;

    /**
     * @default 'We only check this once a day, so if it doesn't seem accurate please check back tomorrow.'
     */
    bannerTooltip: T;

    /**
     * @default 'Learn How'
     */
    learnHow: T;
  };
};

export type CreateWeddingTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Create your Joy Event'
   */
  title: T;

  /**
   * @default 'Craft your dream wedding with Joy and become part of a community of over one million  couples who effortlessly planned their special day with joy and ease!'
   */
  description: T;

  createAccount: {
    scenarios: {
      default: {
        /**
         * @default 'Hi! Let’s get started.'
         */
        heroText: T;

        /**
         * @default 'Already have an account?'
         */
        helpText: T;
      };
      'save-the-date': {
        /**
         * @default 'Smarter Save the Dates, coming right up.'
         */
        heroText: T;

        /**
         * @default 'Already have an account?'
         */
        helpText: T;
      };
      invitation: {
        /**
         * @default 'Smarter invitations, coming right up.'
         */
        heroText: T;

        /**
         * @default 'Already have an account?'
         */
        helpText: T;
      };
      'wedding-website': {
        /**
         * @default 'Let’s get ready to get planning'
         */
        heroText: T;

        /**
         * @default 'Already have an account?'
         */
        helpText: T;
      };
      'guest-list': {
        /**
         * @default 'Start building your smarter guest list.'
         */
        heroText: T;

        /**
         * @default 'Already have an account?'
         */
        helpText: T;
      };
      registry: {
        /**
         * @default 'Start building your all-in-one registry.'
         */
        heroText: T;

        /**
         * @default 'Already have an account?'
         */
        helpText: T;
      };
      app: {
        /**
         * @default 'The smarter app for you and your guests.'
         */
        heroText: T;

        /**
         * @default 'Already have an account?'
         */
        helpText: T;
      };
      'imagine-your-card': {
        /**
         * @default 'Sign up for free to finish creating your card'
         */
        heroText: T;

        /**
         * @default 'Already have an account?'
         */
        helpText: T;
      };
    };
    errors: {
      /**
       * @default 'Please enter an email'
       */
      emailRequired: T;

      /**
       * @default 'Please enter a valid email'
       */
      emailValid: T;

      /**
       * @default 'Please enter a password'
       */
      passwordRequired: T;
    };

    /**
     * @default 'Sign In.'
     */
    signIn: T;

    /**
     * @default 'Sign in with Facebook'
     */
    signInWithFacebook: T;

    /**
     * @default 'Sign in with Google'
     */
    signInWithGoogle: T;

    /**
     * @default 'Email'
     */
    email: T;

    /**
     * @default 'Password'
     */
    password: T;

    /**
     * @default 'Create Account'
     */
    createAccountCta: T;

    /**
     * @default 'privacy policy'
     */
    privacyPolicy: T;

    /**
     * @default 'terms of service'
     */
    termsOfService: T;

    /**
     * @default 'CA Privacy Notice.'
     */
    privacyNotice: T;

    /**
     * @default 'By creating an account you agree to Joy's <PrivacyPolicy/> and <TermsOfService/>. <PrivacyNotice/>'
     */
    termsAndPrivacy: T;

    /**
     * @default 'Attending as a guest?'
     */
    findCoupleText: T;

    /**
     * @default 'Find a Couple'
     */
    findCoupleLink: T;

    /**
     * @default 'Join over one million happy couples who planned their wedding with Joy.'
     */
    sidebarText: T;
  };
  signIn: {
    /**
     * @default 'Log into Joy. 🎉'
     */
    heroText: T;

    /**
     * @default 'Don't have an account?'
     */
    helpText: T;

    /**
     * @default 'Create Account.'
     */
    createAccount: T;

    /**
     * @default 'Email'
     */
    email: T;

    /**
     * @default 'Password'
     */
    password: T;

    /**
     * @default 'Login'
     */
    login: T;

    /**
     * @default 'Forgot password?'
     */
    forgotCredentials: T;

    /**
     * @default 'Join over one million happy couples'
     */
    socialProofBanner: T;

    errors: {
      /**
       * @default 'Please enter an email'
       */
      emailRequired: T;

      /**
       * @default 'Please enter a valid email'
       */
      emailValid: T;

      /**
       * @default 'Please enter a password'
       */
      passwordRequired: T;
    };
  };
  forgotPassword: {
    /**
     * @default 'Forgot your password?'
     */
    heroText: T;

    /**
     * @default 'Enter the email you used to signup for Joy and we’ll send you an email to reset it.'
     */
    helpText: T;

    /**
     * @default 'Enter your email'
     */
    email: T;

    /**
     * @default 'Reset Password'
     */
    resetPassword: T;

    /**
     * @default 'Already have an account?'
     */
    accountExists: T;

    /**
     * @default 'Log in'
     */
    login: T;

    /**
     * @default 'New to Joy?'
     */
    createAccountCta: T;

    /**
     * @default 'Create An Account'
     */
    createAccount: T;
  };
  weddingForm: {
    scenarios: {
      default: {
        /**
         * @default 'Let’s get ready to get planning'
         */
        heroText: T;

        /**
         * @default 'We'll need a few details first.'
         */
        helpText: T;
      };
      defaultAbTest: {
        /**
         * @default 'Nice to meet you, {{ownerFirstName}} & {{partnerFirstName}}!'
         */
        heroText: T;

        /**
         * @default 'Let's talk details.'
         */
        helpText: T;
      };
      'save-the-date': {
        /**
         * @default 'Smarter Save the Dates, coming right up.'
         */
        heroText: T;

        /**
         * @default 'We'll need a few details first.'
         */
        helpText: T;
      };
      invitation: {
        /**
         * @default 'Smarter invitations, coming right up.'
         */
        heroText: T;

        /**
         * @default 'We'll need a few details first.'
         */
        helpText: T;
      };
      'wedding-website': {
        /**
         * @default 'Let’s get ready to get planning'
         */
        heroText: T;

        /**
         * @default 'We'll need a few details first.'
         */
        helpText: T;
      };
      'guest-list': {
        /**
         * @default 'Start building your smarter guest list.'
         */
        heroText: T;

        /**
         * @default 'We'll need a few details first.'
         */
        helpText: T;
      };
      registry: {
        /**
         * @default 'Start building your all-in-one registry.'
         */
        heroText: T;

        /**
         * @default 'We'll need a few details first.'
         */
        helpText: T;
      };
      app: {
        /**
         * @default 'The smarter app for you and your guests.'
         */
        heroText: T;

        /**
         * @default 'We'll need a few details first.'
         */
        helpText: T;
      };
    };

    /**
     * @default 'First, a quick introduction.'
     */
    coupleFormHeroText: T;

    /**
     * @default 'Tell us your names.'
     */
    coupleFormHelpText: T;

    /**
     * @default 'Your First Name'
     */
    ownerFirstName: T;

    /**
     * @default 'Your Partner's First Name'
     */
    partnerFirstName: T;

    /**
     * @default 'How many guests are coming?'
     */
    guestsNumberLabel: T;

    /**
     * @default 'Are you hiring a wedding planner?'
     */
    weddingPlannerLabel: T;

    /**
     * @default 'Jordan'
     */
    ownerFirstNamePlaceholder: T;

    /**
     * @default 'Cameron'
     */
    partnerFirstNamePlaceholder: T;

    /**
     * @default 'Provide an estimate'
     */
    guestsNumberPlaceholder: T;

    /**
     * @default 'Let us know'
     */
    weddingPlannerPlaceholder: T;

    /**
     * @default 'Yes, we're using a wedding planner.'
     */
    weddingPlannerOptionYes: T;

    /**
     * @default 'No, we're doing our own planning.'
     */
    weddingPlannerOptionNo: T;

    /**
     * @default 'We're not sure yet.'
     */
    weddingPlannerOptionUnknown: T;

    /**
     * @default 'Start typing to see options'
     */
    noOptions: T;

    /**
     * @default 'Wedding Date'
     */
    weddingDate: T;

    /**
     * @default 'We haven't picked a date (yet)'
     */
    skipDateDefinition: T;

    /**
     * @default 'Location'
     */
    location: T;

    /**
     * @default 'Next'
     */
    createWeddingCta: T;

    /**
     * @default 'Not {{email}}? <SignOut/>'
     */
    changeAccount: T;

    /**
     * @default 'Sign Out'
     */
    signOut: T;

    errors: {
      /**
       * @default 'We need your first name.'
       */
      firstNameRequired: T;

      /**
       * @default 'Who are you getting hitched with?'
       */
      partnerNameRequired: T;

      /**
       * @default 'Pick a place, any place. You can always change it.'
       */
      locationRequired: T;

      /**
       * @default 'Please re-enter the date in MM/DD/YYYY format'
       */
      invalidDate: T;
    };
  };
  questionnaire: {
    /**
     * @default 'How did you hear about Joy?'
     */
    sourceQuestion: T;

    /**
     * @default 'Check all that apply'
     */
    checkAll: T;

    /**
     * @default 'Submit'
     */
    submit: T;

    /**
     * @default 'Skip'
     */
    skip: T;

    questions: {
      /**
       * @default 'Friend or family member'
       */
      friendOrFamilyMember: T;

      /**
       * @default 'Attended a wedding that used Joy'
       */
      weddingThatUsedJoy: T;

      /**
       * @default 'Online Search'
       */
      search: T;

      /**
       * @default 'News / Media outlet'
       */
      news: T;

      /**
       * @default 'Blog'
       */
      blog: T;

      /**
       * @default 'Social Media'
       */
      socialMedia: T;

      /**
       * @default 'Influencer'
       */
      influencer: T;

      /**
       * @default 'Podcast'
       */
      podcast: T;

      /**
       * @default 'Advertisement'
       */
      ad: T;

      /**
       * @default 'Email'
       */
      email: T;

      /**
       * @default 'Other'
       */
      other: T;
    };
  };
  tyingTheKnot: {
    /**
     * @default 'We've thought of everything, so you don't have to.'
     */
    loadingText: T;

    /**
     * @default 'Help Center'
     */
    helpCenter: T;

    /**
     * @default 'Oops! Something’s amiss. Disable Adblocker or VPN and try again. If the issue continues, visit our <HelpCenter/>'
     */
    errorText: T;

    /**
     * @default 'You’re rocking event creation! To keep things smooth for everyone, kindly space out your event creations and try again in about an hour. If the issue continues, visit our <HelpCenter/>'
     */
    rateLimitErrorText: T;
  };
  weddingAlreadyExists: {
    /**
     * @default 'Looks like you already made'
     */
    alreadyMade: T;

    /**
     * @default 'Right now we support only one website per account.'
     */
    onlyOneWebsiteSupported: T;

    /**
     * @default 'If you would like to create a second site, please first logout of your account and sign up with a different email.'
     */
    createSecondSite: T;

    /**
     * @default 'Proceed to Website'
     */
    proceedWebsite: T;

    /**
     * @default 'Logout and Continue'
     */
    logout: T;
  };
  socialAccount: {
    /**
     * @default 'To create an event, sign in with email and password'
     */
    createEventTitle: T;

    /**
     * @default 'You're currently signed in with {{provider}}, and creating events with {{provider}} is no longer supported.'
     */
    currentAuthDisclaimer: T;

    /**
     * @default 'The good news is you can still create an event by signing in with email and password.'
     */
    goodNews: T;

    /**
     * @default 'Create event with email and password'
     */
    createEventCta: T;
  };
  survey: {
    /**
     * @default 'Lastly, what brings you here?'
     */
    title: T;

    /**
     * @default 'Select all that interest you.'
     */
    subtitle: T;

    /**
     * @default 'Wedding Website'
     */
    website: T;

    /**
     * @default 'Registry'
     */
    registry: T;

    /**
     * @default 'Invitations & Save the Dates'
     */
    invitations: T;

    /**
     * @default 'Guest List & RSVP'
     */
    guestList: T;

    cta: {
      /**
       * @default 'Let's go!'
       */
      proceed: T;

      /**
       * @default 'Skip'
       */
      skip: T;
    };
  };
};

export type CurationsRegistryTranslations<T extends LeafNodeValue = TranslateFunction> = {
  curations: {
    /**
     * @default 'Curations'
     */
    departmentTitle: T;

    /**
     * @default 'Home'
     */
    defaultDepartmentTitle: T;
  };
  sections: {
    /**
     * @default 'Explore more collections'
     */
    exploreOthersTitle: T;

    /**
     * @default 'Add All'
     */
    addAll: T;

    /**
     * @default 'Added'
     */
    addedAll: T;

    /**
     * @default 'Added to Registry'
     */
    addedAllToRegistry: T;

    /**
     * @default 'Add All to Registry'
     */
    addAllToRegistry: T;
  };
};

export type CustomDomainTranslations<T extends LeafNodeValue = TranslateFunction> = {
  domainSearch: {
    /**
     * @default 'Personalize Your URL'
     */
    personalizeURL: T;

    /**
     * @default 'may be available'
     */
    domainSuggestion: T;

    /**
     * @default 'Custom domains are an optional service provided by GoDaddy, not Joy. Domain masking is not supported.'
     */
    poweredByText: T;

    /**
     * @default 'Learn more'
     */
    learnMoreText: T;
  };
  addDomainDialog: {
    /**
     * @default 'How would you like to add a custom domain?'
     */
    dialogTitle: T;

    /**
     * @default 'Find the Perfect Domain'
     */
    buttonText: T;

    /**
     * @default 'I already have a domain'
     */
    linkText: T;
  };
  connectDomainDialog: {
    /**
     * @default 'Did you just buy a new domain?'
     */
    dialogTitle: T;

    /**
     * @default 'We’ll walk you through how to connect your domain to your Joy website.'
     */
    dialogSubtitle: T;

    /**
     * @default 'Yes, Connect My Domain'
     */
    buttonText: T;

    /**
     * @default 'No, but maybe later'
     */
    laterText: T;

    /**
     * @default 'No, I’m not interested'
     */
    notInterestedText: T;

    dialogActions: [
      {
        /**
         * @default 'Search Again'
         */
        title: T;

        /**
         * @default 'Find the domain you’re looking for.'
         */
        description: T;

        /**
         * @default 'Search Domains'
         */
        actionText: T;
      },
      {
        /**
         * @default 'Have More Questions?'
         */
        title: T;

        /**
         * @default 'Our FAQ section may have the answers.'
         */
        description: T;

        /**
         * @default 'Explore FAQs'
         */
        actionText: T;
      },
      {
        /**
         * @default 'We’re Here For You'
         */
        title: T;

        /**
         * @default 'Our team is just a chat bubble away.'
         */
        description: T;

        /**
         * @default 'Talk to Us'
         */
        actionText: T;
      }
    ];
  };
  findDomainDialog: {
    /**
     * @default 'may be available...'
     */
    dialogTitlePart: T;

    /**
     * @default 'Personalize your site and make it easier to share with a custom domain.'
     */
    dialogSubtitle: T;

    /**
     * @default 'Find the Perfect Domain'
     */
    buttonText: T;

    /**
     * @default 'Settings'
     */
    settingsText: T;
  };
  inputDomainDialog: {
    /**
     * @default 'What is your custom domain?'
     */
    dialogTitle: T;

    /**
     * @default 'Having trouble? '
     */
    dialogHelpText: T;

    /**
     * @default 'We’re here to help'
     */
    dialogHelpTextLink: T;

    /**
     * @default 'Test URL'
     */
    testButtonText: T;

    /**
     * @default 'Save'
     */
    saveButtonText: T;

    /**
     * @default 'Domain cannot be blank'
     */
    blankDomainError: T;

    /**
     * @default 'Not a valid domain'
     */
    notValidDomainError: T;
  };
  removeDomainDialog: {
    /**
     * @default 'Are you sure you want to remove your custom domain?'
     */
    dialogTitle: T;

    /**
     * @default 'Removing your custom domain removes it from your eCards, but it will not disconnect it with your provider.'
     */
    descriptionText: T;

    /**
     * @default 'Need help disconnecting your domain?'
     */
    linkText: T;

    /**
     * @default 'Remove Domain'
     */
    buttonText: T;

    /**
     * @default 'Cancel'
     */
    cancelText: T;
  };
  soundsGoodDialog: {
    /**
     * @default 'Sounds Good!'
     */
    dialogTitle: T;

    /**
     * @default 'You can come back to search for a custom domain anytime. '
     */
    dialogSubtitle: T;
  };
};

export type DatesTranslations<T extends LeafNodeValue = TranslateFunction> = {
  translatedDates: {
    /**
     * @default 'Today'
     */
    today: T;

    weekdayNames: [
      /**
       * @default 'Sunday'
       */
      T,
      /**
       * @default 'Monday'
       */
      T,
      /**
       * @default 'Tuesday'
       */
      T,
      /**
       * @default 'Wednesday'
       */
      T,
      /**
       * @default 'Thursday'
       */
      T,
      /**
       * @default 'Friday'
       */
      T,
      /**
       * @default 'Saturday'
       */
      T
    ];

    monthNames: [
      /**
       * @default 'January'
       */
      T,
      /**
       * @default 'February'
       */
      T,
      /**
       * @default 'March'
       */
      T,
      /**
       * @default 'April'
       */
      T,
      /**
       * @default 'May'
       */
      T,
      /**
       * @default 'June'
       */
      T,
      /**
       * @default 'July'
       */
      T,
      /**
       * @default 'August'
       */
      T,
      /**
       * @default 'September'
       */
      T,
      /**
       * @default 'October'
       */
      T,
      /**
       * @default 'November'
       */
      T,
      /**
       * @default 'December'
       */
      T
    ];
  };
  time: {
    minutesFromNow: {
      /**
       * @default '{{count}} minute ago'
       * @param {number} count - The number of ocurrencies.
       * - singular: {{count}} minute ago
       * - plural: {{count}} minutes ago
       */
      minute: T;
    };
    hoursFromNow: {
      /**
       * @default '{{count}} hour ago'
       * @param {number} count - The number of ocurrencies.
       * - singular: {{count}} hour ago
       * - plural: {{count}} hours ago
       */
      hour: T;
    };
  };
};

export type DiscountedRatesTranslations<T extends LeafNodeValue = TranslateFunction> = {
  informativeDialog: {
    /**
     * @default 'You will lose access to our exclusive pricing if you update the URL for this hotel.'
     */
    title: T;

    /**
     * @default 'We provide up to 30% discounts through our hotel partners.'
     */
    message: T;

    /**
     * @default 'Keep discount for my guests'
     */
    btnKeep: T;

    /**
     * @default 'Remove this discount'
     */
    btnRemove: T;
  };
  general: {
    /**
     * @default 'This property has discounted rates applied.'
     */
    message: T;

    /**
     * @default 'View Rates'
     */
    linkMessage: T;
  };
};

export type EmailPreferencesTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Joy - Email Preferences'
   */
  title: T;

  content: {
    /**
     * @default 'Update your email preferences.'
     */
    heading: T;

    /**
     * @default 'How much Joy do you want in your life? Select the email communications you’d like to receive.'
     */
    body: T;

    /**
     * @default 'Managing preferences for:'
     */
    managingPreferences: T;

    /**
     * @default 'Announcements'
     */
    prefAnnouncements: T;

    /**
     * @default 'Get the scoop on amazing new products, helpful features, and more.'
     */
    prefAnnouncementsBody: T;

    /**
     * @default 'Advice & Inspiration'
     */
    prefAdvice: T;

    /**
     * @default 'Stay organized with guides, checklists, registry progress reports, and articles.'
     */
    prefAdviceBody: T;

    /**
     * @default 'Registry Ideas'
     */
    prefRegistry: T;

    /**
     * @default 'Explore features and learn insider tips for creating your ultimate gift list.'
     */
    prefRegistryBody: T;

    /**
     * @default 'Unsubscribe'
     */
    unsubscribe: T;

    /**
     * @default 'If you no longer wish to receive marketing emails from Joy, please check this option.'
     */
    unsubscribeBody: T;

    /**
     * @default 'Save Changes'
     */
    saveChanges: T;

    /**
     * @default 'Updated Email Preferences!'
     */
    successMessage: T;
  };
  errors: {
    email: {
      /**
       * @default 'Missing Email ID'
       */
      required: T;

      /**
       * @default 'Invalid Email ID'
       */
      validate: T;
    };
  };
};

export type EmailsAndEcardsTranslations<T extends LeafNodeValue = TranslateFunction> = {
  emailEditor: {
    /**
     * @default 'Edit Email'
     */
    header: T;

    /**
     * @default 'Email Subject'
     */
    emailSubjectLabel: T;

    /**
     * @default 'Don’t forget about the wedding rehearsal!'
     */
    emailSubjectPlaceholder: T;

    /**
     * @default 'Email Subject is required'
     */
    emailSubjectErrorMessage: T;

    /**
     * @default 'Reply-to Email'
     */
    replyToLabel: T;

    /**
     * @default 'Reply-to Email is required'
     */
    replyToErrorMessage: T;

    /**
     * @default 'Photo'
     */
    photoLabel: T;

    photoOptions: [
      /**
       * @default 'Welcome photo'
       */
      T,
      /**
       * @default 'Custom photo'
       */
      T
    ];

    /**
     * @default 'Drag & drop a new image here or '
     */
    photoDragAndDropText: T;

    /**
     * @default 'choose an image...'
     */
    photoChooseImageText: T;

    /**
     * @default 'Headline'
     */
    headlineLabel: T;

    /**
     * @default 'You have an event display name {{headline}} that looks like a link. For security reasons we do not allow links to be posted in emails. Please change the event display name to proceed.'
     */
    headlineError: T;

    /**
     * @default 'Subheadline'
     */
    subheadlineLabel: T;

    /**
     * @default 'We’re getting married!'
     */
    subheadlinePlaceholder: T;

    /**
     * @default 'Salutation'
     */
    salutationLabel: T;

    /**
     * @default 'Hello'
     */
    salutationPlaceholder: T;

    /**
     * @default 'Include guest name(s)'
     */
    includeGuestNamesLabel: T;

    /**
     * @default 'Message'
     */
    messageLabel: T;

    /**
     * @default 'Message to our guest'
     */
    messagePlaceholder: T;

    /**
     * @default 'Link'
     */
    linkLabel: T;

    /**
     * @default 'Button Text'
     */
    buttonTextLabel: T;

    /**
     * @default 'View Card'
     */
    buttonTextPlaceholder: T;

    /**
     * @default 'Button Text is required'
     */
    buttonTextError: T;

    /**
     * @default 'Date hidden'
     */
    dateHidden: T;

    generalMessageEmailOptions: [
      {
        /**
         * @default 'Display link to your website'
         */
        hed: T;

        /**
         * @default 'Guests can visit your website for additional wedding details.'
         */
        dek: T;
      },
      {
        /**
         * @default 'Display event password'
         */
        hed: T;

        /**
         * @default 'Guests may need your event password to access some content.'
         */
        dek: T;
      },
      {
        /**
         * @default 'Display links to install Joy app'
         */
        hed: T;

        /**
         * @default 'Guests can view and share photos while staying up-to-date on the go.'
         */
        dek: T;
      }
    ];

    ecardEmailOptions: [
      {
        /**
         * @default 'Include event date'
         */
        hed: T;
      },
      {
        /**
         * @default 'Include “Add to Calendar” link'
         */
        hed: T;

        /**
         * @default 'So people can add it to their calendar '
         */
        dek: T;
      }
    ];

    /**
     * @default 'Save'
     */
    doneButtonText: T;

    /**
     * @default 'Your email has been updated.'
     */
    doneTooltipText: T;

    /**
     * @default 'Cancel'
     */
    cancelButtonText: T;

    /**
     * @default 'Password is required when you include a link to download the Joy app.'
     */
    passwordToggleTooltip: T;

    /**
     * @default 'Sorry! To keep our emails secure, email addresses and URLs are not allowed in the message of the email.'
     */
    invalidMessageIsEmailOrUrlError: T;

    /**
     * @default 'Sorry! To keep our emails secure, email addresses and URLs are not allowed in the subject of the email.'
     */
    invalidSubjectIsEmailOrUrlError: T;

    /**
     * @default 'Sorry! To keep our emails secure, email addresses and URLs are not allowed in the headline of the email.'
     */
    invalidHeadlineIsEmailOrUrlError: T;

    /**
     * @default 'Sorry! To keep our emails secure, email addresses and URLs are not allowed in the subheadline of the email.'
     */
    invalidSubheadlineIsEmailOrUrlError: T;

    /**
     * @default 'Sorry! To keep our emails secure, email addresses and URLs are not allowed in the salutation of the email.'
     */
    invalidSalutationIsEmailOrUrlError: T;

    /**
     * @default 'Sorry! To keep our emails secure, email addresses and URLs are not allowed in the button text of the email.'
     */
    invalidButtonTextIsEmailOrUrlError: T;

    /**
     * @default 'Add Photos'
     */
    addPhotos: T;
  };
  emailEditorPhotoSaveTheDate: {
    /**
     * @default 'Edit Email'
     */
    header: T;

    /**
     * @default 'Subject'
     */
    subjectLabel: T;

    /**
     * @default 'Email Subject'
     */
    emailSubjectLabel: T;

    /**
     * @default 'Reply-to Email'
     */
    replyToLabel: T;

    /**
     * @default 'Reply-to Email is required'
     */
    replyToErrorMessage: T;

    /**
     * @default 'Photo Source'
     */
    photoSourceLabel: T;

    /**
     * @default 'Headline'
     */
    headlineLabel: T;

    /**
     * @default 'Subheadline'
     */
    subheadlineLabel: T;

    /**
     * @default 'Salutation'
     */
    salutationLabel: T;

    /**
     * @default 'Dear'
     */
    salutationPlaceholder: T;

    /**
     * @default 'Use guest name(s)'
     */
    useGuestNamesLabel: T;

    /**
     * @default 'Message'
     */
    messageLabel: T;

    /**
     * @default 'Message Body'
     */
    messagePlaceholder: T;

    /**
     * @default 'Button Text'
     */
    buttonTextLabel: T;

    /**
     * @default 'View Photo Save the Date'
     */
    buttonTextPlaceholder: T;

    /**
     * @default 'Save'
     */
    doneButtonText: T;

    /**
     * @default 'Cancel'
     */
    cancelButtonText: T;

    /**
     * @default 'Email Information'
     */
    emailInformationLabel: T;
  };
  confirmAndSend: {
    /**
     * @default 'Almost done!'
     */
    header: T;

    /**
     * @default 'Review the final details and treat yourself afterwards. You’ve earned it.'
     */
    body: T;

    /**
     * @default 'Email Personalized Cards'
     */
    emailHeader: T;

    /**
     * @default 'Each email will be addressed to the guest selected, and tracking of delivered and opened messages will be visible in your guest list.'
     */
    emailBody: T;

    /**
     * @default 'Share with a Link'
     */
    shareTitle: T;

    /**
     * @default 'Easily share a direct link to your cards with anyone.'
     */
    shareBody: T;

    /**
     * @default 'Copy Link'
     */
    shareAction: T;

    shareableDialog: {
      /**
       * @default 'About Shareable Card Links'
       */
      title: T;

      /**
       * @default 'Unlike cards sent via email, cards shared via link will not be addressed to a specific guest or tracked on your guest list.'
       */
      specific: T;

      /**
       * @default 'Card contents will be visible to anyone with the link, regardless of your event privacy settings.'
       */
      visible: T;

      /**
       * @default 'This includes your event date, location, and website password (if you have included it on the card).'
       */
      includes: T;

      /**
       * @default 'Contents of your website, including schedule visibility and RSVP, will continue to be protected by your existing privacy settings.'
       */
      privacy: T;

      /**
       * @default 'Cancel'
       */
      cancel: T;

      /**
       * @default 'I Understand'
       */
      copy: T;
    };
    confirmAndSendActions: {
      addGuests: {
        hed: {
          /**
           * @default 'Add recipients'
           */
          guest_0: T;

          /**
           * @default 'Add recipients'
           * @param {number} count - The number of ocurrencies.
           * - singular: Add recipients
           * - plural: Add or remove recipients
           */
          guest: T;
        };

        /**
         * @default 'Each guest will receive a unique invitation.'
         */
        dek: T;

        buttonText: {
          /**
           * @default 'Select Guests'
           */
          guest_0: T;

          /**
           * @default 'Select Guests'
           * @param {number} count - The number of ocurrencies.
           * - singular: Select Guests
           * - plural: {{count}} Guests Selected…
           */
          guest: T;
        };

        /**
         * @default 'Modify Recipients'
         */
        buttonTextHover: T;
      };
      testEmail: {
        /**
         * @default 'Do an optional final check'
         */
        hed: T;

        /**
         * @default 'Never hurts to make sure everything is just right.'
         */
        dek: T;

        /**
         * @default 'Send Test Email'
         */
        buttonText: T;
      };
      send: {
        /**
         * @default 'Send and track'
         */
        hed: T;

        /**
         * @default 'Keep an eye on replies and poke the slow pokes.'
         */
        dek: T;

        /**
         * @default 'Send Now'
         */
        buttonText: T;
      };
      verifyEmail: {
        /**
         * @default 'First, verify your email address'
         */
        hed: T;

        /**
         * @default 'Only verified accounts can send cards.'
         */
        dek: T;

        /**
         * @default 'Verify Email Address'
         */
        buttonText: T;
      };
      copyLink: {
        /**
         * @default 'Shareable link'
         */
        hed: T;

        /**
         * @default 'A direct link to your eCard that is not addressed to a specific guest. Tracking not available.'
         */
        dek: T;

        /**
         * @default 'Copy Link'
         */
        buttonText: T;
      };
    };
    emailSentSuccessMessage: {
      /**
       * @default 'Your email is on the way!'
       * @param {number} count - The number of ocurrencies.
       * - singular: Your email is on the way!
       * - plural: Your emails are on the way!
       */
      guest: T;
    };

    /**
     * @default 'Sorry, something may have gone wrong. Please allow 1 hour for the last email status to update. If blank, please send again.'
     */
    emailSentErrorMessage: T;

    /**
     * @default 'Please, select guests first'
     */
    noGuestsSelectedErrorMessage: T;

    confirmationScreen: {
      /**
       * @default 'Your message has been sent'
       */
      hed: T;

      dek: {
        /**
         * @default 'Your message has been sent to {{count}} guest. Track which guests have received and opened your card in '
         * @param {number} count - The number of ocurrencies.
         * - singular: Your message has been sent to {{count}} guest. Track which guests have received and opened your card in
         * - plural: Your message has been sent to {{count}} guests. Track which guests have received and opened your card in
         */
        dek: T;
      };

      /**
       * @default 'Guest List'
       */
      linkText: T;

      /**
       * @default 'View Your Guest List'
       */
      buttonText: T;

      /**
       * @default 'Add Paper Cards'
       */
      printTitle: T;

      /**
       * @default 'Some guests prefer a physical card. Create a matching (or different) paper card for them!'
       */
      printDesc: T;

      /**
       * @default 'Create Paper Card'
       */
      printAction: T;
    };
    confirmationScreenThankYou: {
      /**
       * @default 'Your thank you note has been sent'
       */
      hed: T;

      /**
       * @default 'An email has been sent to {{ name }}.'
       */
      dek_name: T;

      /**
       * @default 'An email has been sent to {{ number }} People.'
       */
      dek_number: T;

      /**
       * @default 'Thank Another Guest'
       */
      buttonText: T;
    };
    sendTestEmailDialog: {
      /**
       * @default 'Send Test Email'
       */
      header: T;

      /**
       * @default 'Name'
       */
      testNameLabel: T;

      /**
       * @default 'Email'
       */
      testEmailLabel: T;

      /**
       * @default 'First Name'
       */
      testNamePlaceholder: T;

      /**
       * @default 'your@email.com'
       */
      testEmailPlaceholder: T;

      /**
       * @default 'Name is required'
       */
      testNameErrorMessage: T;

      /**
       * @default 'Email is required'
       */
      testEmailErrorMessage: T;

      /**
       * @default 'Send'
       */
      sendButtonText: T;

      /**
       * @default 'Cancel'
       */
      cancelButtonText: T;
    };
  };
  ecardEditor: {
    ecardEditPanel: {
      /**
       * @default 'Edit Card'
       */
      header: T;

      /**
       * @default 'Save'
       */
      doneButtonText: T;

      /**
       * @default 'Cancel'
       */
      cancelButtonText: T;

      /**
       * @default 'Your card has been updated.'
       */
      doneTooltipText: T;
    };
    ecardEditorSwitch: {
      /**
       * @default 'Content'
       */
      content: T;

      /**
       * @default 'Design'
       */
      design: T;
    };
    ecardDesignEditor: {
      /**
       * @default 'Upload Your Own Card Design'
       */
      uploadOwn: T;

      /**
       * @default 'If you have a card designed or if you want to design your own card, be sure it includes names, date, and location and '
       */
      uploadDesc: T;

      /**
       * @default 'check out our design guidelines.'
       */
      uploadDescHighlight: T;

      /**
       * @default 'Drag & drop your image here or '
       */
      dragAndDrop: T;

      /**
       * @default 'choose an image...'
       */
      chooseImage: T;

      /**
       * @default 'Your Card Design'
       */
      yourCard: T;

      /**
       * @default 'Be sure your image includes names, date, and location, and that you have rights to use this image.'
       */
      yourCardDesc: T;

      /**
       * @default 'Check out our design guidelines.'
       */
      yourCardDescHighlight: T;
    };
    ecardContentEditor: {
      /**
       * @default 'Title'
       */
      titleLabel: T;

      /**
       * @default 'Subtitle'
       */
      subtitleLabel: T;

      /**
       * @default 'Date Format'
       */
      dateFormatLabel: T;

      /**
       * @default 'Date'
       */
      dateLabel: T;

      /**
       * @default '1 May, 2020'
       */
      datePlaceholder: T;

      /**
       * @default 'Edit Date'
       */
      editDate: T;

      /**
       * @default 'Date hidden'
       */
      dateHidden: T;

      /**
       * @default 'Location hidden'
       */
      locationHidden: T;

      /**
       * @default 'Location'
       */
      locationLabel: T;

      /**
       * @default 'Los Angeles, CA, USA'
       */
      locationPlaceholder: T;

      /**
       * @default 'Edit Location'
       */
      editLocation: T;

      /**
       * @default 'Message'
       */
      messageLabel: T;

      linkOptions: [
        {
          /**
           * @default 'Display link to your website'
           */
          hed: T;

          /**
           * @default 'Guests can visit your website for additional wedding details.'
           */
          dek: T;
        },
        {
          /**
           * @default 'Image links to event website'
           */
          hed: T;

          /**
           * @default 'This link personalizes your website, schedule, and RSVP for each guest.'
           */
          dek: T;
        },
        {
          /**
           * @default 'Links Unlock Guest-Only Content'
           */
          hed: T;

          /**
           * @default 'Website pages that require a password will be auto-unlocked.'
           */
          dek: T;
        }
      ];

      /**
       * @default 'Additional Information'
       */
      additionalInformation: T;

      /**
       * @default 'Choose up to two additional links to display'
       */
      chooseLinkTitle: T;

      /**
       * @default 'Additional Message'
       */
      additionalMessageLabel: T;

      /**
       * @default 'We’re doing it! We’re getting married. And we’d be delighted if you could attend. Our website has all the important details—most importantly the RSVP.'
       */
      additionalMessagePlaceholder: T;

      /**
       * @default 'Website Options'
       */
      websiteOptions: T;

      /**
       * @default 'Your URL'
       */
      yourURL: T;

      /**
       * @default 'Change URL'
       */
      editURL: T;

      /**
       * @default ' may be available!'
       */
      URLAvailable: T;

      /**
       * @default 'You’re Using a Custom Design'
       */
      usingCustomDesign: T;

      /**
       * @default 'All your event details are included in your image.'
       */
      usingCustomDesignDesc1: T;

      /**
       * @default 'You can revert back to using themes.'
       */
      usingCustomDesignDesc2: T;

      /**
       * @default 'Revert to Joy Theme'
       */
      revertToJoyTheme: T;
    };
  };
  ecardEditorPhotoSaveTheDate: {
    ecardEditPanel: {
      /**
       * @default 'Edit Card'
       */
      header: T;

      /**
       * @default 'Save'
       */
      doneButtonText: T;

      /**
       * @default 'Cancel'
       */
      cancelButtonText: T;
    };
    ecardEditorSwitch: {
      /**
       * @default 'Content'
       */
      content: T;

      /**
       * @default 'Design'
       */
      design: T;
    };
    ecardDesignEditor: {
      /**
       * @default 'Photo Source'
       */
      photoSourceLabel: T;

      /**
       * @default 'Adjust Photo Position'
       */
      adjustPhotoLabel: T;

      /**
       * @default 'Templates'
       */
      templatesLabel: T;

      /**
       * @default 'Reset Position'
       */
      resetPosition: T;
    };
    ecardPhotoAdjustmentEditor: {
      /**
       * @default 'Zoom & drag photo to adjust position'
       */
      title: T;
    };
    ecardContentEditor: {
      /**
       * @default 'Name Format'
       */
      nameFormatLabel: T;

      /**
       * @default 'Edit Names'
       */
      editNameFormatLabel: T;

      /**
       * @default 'Date Format'
       */
      dateFormatLabel: T;

      /**
       * @default 'Edit Date'
       */
      editDateFormatLabel: T;

      /**
       * @default 'Location Format'
       */
      locationFormatLabel: T;

      /**
       * @default 'Edit Location'
       */
      editLocationFormatLabel: T;

      /**
       * @default 'Note'
       */
      inviteLabel: T;

      /**
       * @default 'Invitation to Follow'
       */
      invitePlaceholder: T;

      /**
       * @default 'Additional Info'
       */
      additionalInformationTitle: T;

      /**
       * @default 'Additional links to display'
       */
      additionalLinksLabel: T;

      /**
       * @default 'Optional Message'
       */
      optionalMessageLabel: T;

      /**
       * @default 'Website Options'
       */
      websiteOptionsTitle: T;

      displayEventLink: {
        /**
         * @default 'Display link to your website'
         */
        title: T;

        /**
         * @default 'Guests can visit your website for additional wedding details.'
         */
        subtitle: T;
      };
      unlockGuestOnlyContent: {
        /**
         * @default 'Unlock Guest-Only Content'
         */
        title: T;

        /**
         * @default 'Website pages that require a password will be auto-unlocked with this link.'
         */
        subtitle: T;
      };

      /**
       * @default 'Your URL'
       */
      yourURLLabel: T;

      /**
       * @default 'Edit URL'
       */
      editYourURLLabel: T;
    };
    premiumUpsell: {
      banner: {
        /**
         * @default 'Switch to Premium Card Designer'
         */
        title: T;

        /**
         * @default 'Try premium editor to change fonts, move text around, upload your own image, and more.'
         */
        description: T;

        /**
         * @default 'Try Premium'
         */
        cta: T;
      };
      dialog: {
        /**
         * @default 'Try Premium Card and Customize Your Way!'
         */
        title: T;

        /**
         * @default 'Edit all your details inline on the card.'
         */
        valuePropEdit: T;

        /**
         * @default 'Customize your card exactly how you like it, with hundreds of font styles, photo, and layout options.'
         */
        valuePropCustomize: T;

        /**
         * @default 'Choose silhouette of your card and wallpaper color.'
         */
        valuePropSilhoutte: T;

        /**
         * @default 'Try Premium'
         */
        cta: T;

        /**
         * @default 'Back to Free'
         */
        cancel: T;
      };
    };
  };
  ecardDefaults: {
    /**
     * @default '✨ Link copied. Share away! ✨'
     */
    copiedLinkTooltip: T;

    changethedate: {
      ecardContent: {
        /**
         * @default 'Change the Date'
         */
        title: T;

        /**
         * @default '{{ eventDisplayName }}'
         */
        subtitle: T;

        /**
         * @default 'We've had to change the date for our big day but we can't wait to celebrate with you soon. Please check our wedding website for updates!'
         */
        message: T;

        /**
         * @default ' '
         */
        additionalMessage: T;
      };
      emailContent: {
        /**
         * @default 'We're changing the date!'
         */
        subject: T;

        /**
         * @default '{{ eventDisplayName }}'
         */
        headline: T;

        /**
         * @default 'We're changing the date!'
         */
        subheadline: T;

        /**
         * @default 'Hello'
         */
        salutation: T;

        /**
         * @default 'We've had to change the date for our big day but we can't wait to celebrate with you soon. Please check our wedding website for updates!'
         */
        message: T;

        /**
         * @default 'View Card'
         */
        buttonText: T;
      };
      ecardLanding: {
        /**
         * @default 'Send Change the Dates'
         */
        title: T;
      };
    };
    savethedate: {
      ecardContent: {
        /**
         * @default 'Save the Date'
         */
        title: T;

        /**
         * @default '{{ eventDisplayName }}'
         */
        subtitle: T;

        /**
         * @default 'Invitation to follow. Please visit our website for more details and photos.'
         */
        message: T;

        /**
         * @default ' '
         */
        additionalMessage: T;
      };
      emailContent: {
        /**
         * @default 'Save the Date!'
         */
        subject: T;

        /**
         * @default '{{ eventDisplayName }}'
         */
        headline: T;

        /**
         * @default 'We're getting married!'
         */
        subheadline: T;

        /**
         * @default 'Hello'
         */
        salutation: T;

        /**
         * @default 'We've set a date for our big day and we can't wait to share it with you.'
         */
        message: T;

        /**
         * @default 'Open Save The Date'
         */
        buttonText: T;
      };
      ecardLanding: {
        /**
         * @default 'Send Save the Dates'
         */
        title: T;
      };
    };
    invitation: {
      ecardContent: {
        /**
         * @default '{{ eventDisplayName }}'
         */
        title: T;

        /**
         * @default ' '
         */
        subtitle: T;

        /**
         * @default 'We are so excited and can't wait to see you.'
         */
        message: T;

        /**
     * @default 'We’re doing it! We’re getting married. And we’d be delighted if you could attend.
 Our website has all the important details—most importantly the RSVP! We want to know things like whether or
 not you’re bringing a +1, your dietary preferences, and if you’re bringing your dancing shoes.

 We also provide recommendations for our favorite local activities, where to stay, and what to wear.
 We’d love if you’d download the app and start sharing photos too! It’ll be a collection of memorable moments,
before, during, and after our special day'
*/
        additionalMessage: T;
      };
      emailContent: {
        /**
         * @default 'We're getting married!'
         */
        subject: T;

        /**
         * @default '{{ eventDisplayName }}'
         */
        headline: T;

        /**
         * @default 'We're getting married!'
         */
        subheadline: T;

        /**
         * @default 'Hello'
         */
        salutation: T;

        /**
     * @default 'We’re making it official, and you’re cordially invited! We have a wedding
 website and app with all the details—from travel and lodging to the day-of schedule and what to wear. Take
 a look to RSVP and find more information. We hope you can join us! '
*/
        message: T;

        /**
         * @default 'View Invitation'
         */
        buttonText: T;
      };
      ecardLanding: {
        /**
         * @default 'Send Invitations'
         */
        title: T;
      };
    };
    custom: {
      ecardContent: {
        /**
         * @default 'Party Time'
         */
        title: T;

        /**
         * @default 'Will you join us?'
         */
        subtitle: T;

        /**
         * @default 'We are so excited and can't wait to see you.'
         */
        message: T;

        /**
         * @default ' '
         */
        additionalMessage: T;
      };
      emailContent: {
        /**
         * @default 'Party Time!'
         */
        subject: T;

        /**
         * @default 'Party Time!'
         */
        headline: T;

        /**
         * @default 'We're having a party!'
         */
        subheadline: T;

        /**
         * @default 'Hello'
         */
        salutation: T;

        /**
         * @default 'We're having a party and we can't wait to see you.'
         */
        message: T;

        /**
         * @default 'Open Card'
         */
        buttonText: T;
      };
      ecardLanding: {
        /**
         * @default 'Send Custom eCards'
         */
        title: T;
      };
    };
    savethedatephoto: {
      ecardContent: {
        /**
         * @default 'Save the Date'
         */
        title: T;

        /**
         * @default 'Invitation to follow'
         */
        subtitle: T;

        /**
         * @default 'Please visit our website for more details and photos.'
         */
        message: T;

        /**
         * @default ' '
         */
        additionalMessage: T;
      };
      emailContent: {
        /**
         * @default 'Save the Date!'
         */
        subject: T;

        /**
         * @default '{{ eventDisplayName }}'
         */
        headline: T;

        /**
         * @default 'We're getting married!'
         */
        subheadline: T;

        /**
         * @default 'Hello'
         */
        salutation: T;

        /**
         * @default 'We've set a date for our big day and we can't wait to share it with you.'
         */
        message: T;

        /**
         * @default 'Open Save The Date'
         */
        buttonText: T;
      };
      ecardLanding: {
        /**
         * @default 'Send Save the Dates'
         */
        title: T;
      };
    };
  };
  ecardNavBar: {
    /**
     * @default 'Edit Card'
     */
    design: T;

    /**
     * @default 'Edit Email'
     */
    email: T;

    /**
     * @default 'Confirm & Send'
     */
    confirm: T;
  };
  unsavedChangesDialog: {
    /**
     * @default 'Cancel'
     */
    cancelButtonText: T;

    /**
     * @default 'Discard'
     */
    discardButtonText: T;

    /**
     * @default 'Unsaved Changes'
     */
    unsavedChangesWarningHed: T;

    /**
     * @default 'Are you sure you want to stop editing your {{ warningMessageContent }}? Doing so will discard all unsaved changes.'
     */
    unsavedChangesWarningDek: T;
  };
  genericEcard: {
    /**
     * @default 'Edit eCard'
     */
    editEcard: T;

    /**
     * @default 'To:'
     */
    toPerson: T;

    /**
     * @default 'Not'
     */
    notPerson: T;

    /**
     * @default 'Not you?'
     */
    notYou: T;

    /**
     * @default '[First Name(s)]'
     */
    toPersonNames: T;
  };
  ecardLanding: {
    ecardLandingLeftPane: {
      /**
       * @default 'Make the big announcement in a big way'
       */
      titleLanding: T;

      /**
       * @default 'Send Beautiful Digital Cards'
       */
      title: T;

      /**
       * @default 'Match your wedding website or pick from beautiful designs for every taste.'
       */
      desc: T;

      /**
       * @default 'Create Digital Card'
       */
      customize: T;

      /**
       * @default 'Track Your eCard Delivery'
       */
      trackTitle: T;

      /**
       * @default 'Already sent? Track delivery in guest list'
       */
      trackLandingTitle: T;

      /**
       * @default 'Track which guests have received and opened your Digital Card in the “Email Status” column of your guest list.'
       */
      trackDesc: T;

      /**
       * @default 'Track in Guest List'
       */
      trackButtonText: T;

      /**
       * @default 'Matching Stationery is Available'
       */
      printsTitle: T;

      /**
       * @default 'Matching stationery is available for your current art card design'
       */
      printsLandingTitle: T;

      /**
       * @default 'Your website theme has matching stationery. Order {{ ecardTypeDisplayName }} prints that match your wedding website.'
       */
      printsDesc: T;

      /**
       * @default 'Order Save the Date prints that match your wedding website.'
       */
      printsLandingDesc: T;

      /**
       * @default 'US and international shipping available.'
       */
      paperlustDesc: T;

      /**
       * @default 'Shipping only available in the United States.'
       */
      paperCultureDesc: T;

      /**
       * @default 'View and Order Prints'
       */
      printsButtonText: T;

      /**
       * @default 'Our partner Paper Culture can only send cards to a US address. They do, however, ship to all United States addresses, including military and APO/FPO addresses, and US Territories.'
       */
      printsPaperCultureTooltipText: T;

      /**
       * @default 'Paperlust has express worldwide shipping to all countries including the USA, Canada, New Zealand, the UK and Europe. Please note that you are responsible for any local duties, tariffs, taxes or fees payable on delivery.'
       */
      printsPaperlustTooltipText: T;

      /**
       * @default 'Explore Our Print Partners'
       */
      exploreTitle: T;

      /**
       * @default 'Explore our print partners'
       */
      exploreLandingTitle: T;

      /**
       * @default 'International Shipping'
       */
      internationalShipping: T;

      /**
       * @default 'U.S.-Only Shipping'
       */
      USOnlyShipping: T;

      /**
       * @default 'Please use the desktop browser. Don’t worry, your guests will be able to see and experience the eCard on their phones.'
       */
      mobileAlertTextSubTitle: T;

      /**
       * @default 'Photo Digital Cards'
       */
      landingPhotoTitle: T;

      /**
       * @default 'Add your own photos and choose from ten stunning custom-designed Photo Digital Cards to announce your big day.'
       */
      landingPhotoSubTitle: T;

      /**
       * @default 'Customize Photo Digital Card'
       */
      landingPhotoButtonTitle: T;

      /**
       * @default 'Art Digital Cards'
       */
      landingArtTitle: T;

      /**
       * @default 'Select from a variety of beautiful custom-designed Art Digital Cards to make your big announcement stand out.'
       */
      landingArtSubTitle: T;

      /**
       * @default 'Customize Art Digital Card'
       */
      landingArtButtonTitle: T;

      /**
       * @default 'Add Paper Cards'
       */
      landingPrintTitle: T;

      /**
       * @default 'Some guests prefer a physical card. Create a matching (or different) paper card for them.'
       */
      landingPrintSubTitle: T;

      /**
       * @default 'Customize Paper Card'
       */
      landingPrintButtonTitle: T;

      /**
       * @default 'New'
       */
      chipNew: T;
    };
    ecardLandingRightPane: {
      /**
       * @default 'eCard'
       */
      ecard: T;

      /**
       * @default 'Print'
       */
      print: T;

      /**
       * @default 'Explore Prints'
       */
      explorePrints: T;
    };
  };
  ecardGuest: {
    /**
     * @default 'A special message from {{event}}'
     */
    title: T;

    error: {
      /**
       * @default 'Hmm… we can’t find that.'
       */
      title: T;

      /**
       * @default 'This eCard may have been changed or is temporarily unavailable.'
       */
      subtitle: T;

      /**
       * @default 'Find a Couple'
       */
      linkText: T;
    };
    toolTip: {
      /**
       * @default 'Guests are addressed by first name.'
       */
      firstPart: T;

      /**
       * @default 'If the recipient is in a party, all first names in the party will be included. e.g. “Sam, Sue, & Fred“, or “Suzy & Guest“'
       */
      secondPart: T;
    };
  };
  ecardFooter: {
    /**
     * @default 'Plan your forever, better.'
     */
    label: T;

    /**
     * @default 'Learn More'
     */
    learnMore: T;

    /**
     * @default 'Privacy Policy'
     */
    privacyPolicy: T;

    /**
     * @default 'Terms and Conditions'
     */
    termsAndConditions: T;
  };
};

export type ErrorsTranslations<T extends LeafNodeValue = TranslateFunction> = {
  errorSplash: {
    /**
     * @default 'Hmm… we're having trouble with that.'
     */
    title: T;

    /**
     * @default 'This page can’t be loaded at the moment. Please try again later.'
     */
    subtitle: T;
  };
  contributionError: {
    /**
     * @default 'Hmm… we can’t find that.'
     */
    title: T;

    /**
     * @default 'The item may have been changed or removed from the couple’s registry.'
     */
    subtitle: T;

    /**
     * @default 'Go to Registry'
     */
    linkText: T;
  };
  registryHidden: {
    /**
     * @default 'Hmm… we can’t find that.'
     */
    title: T;

    /**
     * @default 'It seems that the couple may have hidden their registry.'
     */
    subtitle: T;
  };
};

export type EventCountdownTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default '{{dateTime}} ago'
   */
  eventDateWithAgoText: T;
};

export type EventMenuTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Edit Event'
   */
  editEvent: T;

  /**
   * @default 'MENU'
   */
  menu: T;
};

export type EventSiteTranslations<T extends LeafNodeValue = TranslateFunction> = {
  eventRedirect: {
    eventNotFoundError: {
      /**
       * @default 'Hmm... we're having trouble with that.'
       */
      hed: T;

      /**
       * @default 'This event can no longer be found. We wish we knew more.'
       */
      dek: T;
    };
    graphqlError: {
      /**
       * @default 'Hmm... we're having trouble with that.'
       */
      hed: T;

      /**
       * @default 'This page can't be loaded at the moment. Please try again later.'
       */
      dek: T;
    };
  };
};

export type EventSiteAppsTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Get the app'
   */
  title: T;

  /**
   * @default 'Download Joy and join our app using our event handle {{website}}. View and share photos and stay up-to-date with details on the go.'
   */
  message: T;
};

export type ExampleConfigUsageTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Event type config - {{eventType}}'
   */
  eventTypeInfo: T;

  /**
   * @default 'Example switching content based on event type'
   */
  exampleContent: T;

  /**
   * @default 'Example passing args to API calls based on event type'
   */
  exampleAPIArgs: T;

  eventTypeStrings: {
    general: {
      /**
       * @default 'This is from the general event type ✅'
       */
      exampleStringFromEventType: T;
    };
    wedding: {
      /**
       * @default 'This is from the wedding event type 💒'
       */
      exampleStringFromEventType: T;
    };
    babyRegistry: {
      /**
       * @default 'This is from the babyRegistry event type 👶'
       */
      exampleStringFromEventType: T;
    };
  };
};

export type FocusPointTranslations<T extends LeafNodeValue = TranslateFunction> = {
  editPhoto: {
    /**
     * @default 'Edit Photo'
     */
    actionEditText: T;
  };
  focusPointDialog: {
    /**
     * @default 'Select a Focus Point'
     */
    title: T;

    /**
     * @default 'Your focus point ensures your image displays well on all devices.'
     */
    subtitle: T;

    /**
     * @default 'Change Photo...'
     */
    changePhotoText: T;

    /**
     * @default 'Save Focus Point'
     */
    applyButtonText: T;
  };
};

export type GeneralFormValidationTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Invalid Email'
   */
  invalidEmail: T;

  /**
   * @default 'Required'
   */
  required: T;
};

export type GuestKitTranslations<T extends LeafNodeValue = TranslateFunction> = {
  components: {
    virtualEventBlock: {
      /**
       * @default 'at'
       */
      at: T;

      /**
       * @default 'Join Livestream'
       */
      joinCta: T;

      /**
       * @default 'Join With Zoom'
       */
      joinCtaZoom: T;

      /**
       * @default 'Now Live'
       */
      eventLive: T;

      /**
       * @default 'The event has not yet started.'
       */
      eventUpcoming: T;

      /**
       * @default 'Starts in {{startsInMin}} minutes.'
       */
      eventStartsSoon: T;

      /**
       * @default 'This event has passed.'
       */
      eventPassed: T;

      /**
       * @default 'Time to be announced'
       */
      eventTba: T;

      /**
       * @default 'Your Local Time ({{offset}})'
       */
      localTimezoneOffset: T;

      /**
       * @default 'Streaming by'
       */
      streamingProvider: T;

      /**
       * @default 'Having trouble with the app?'
       */
      troubleWithApp: T;

      /**
       * @default 'Watch in a browser'
       */
      watchInBrowser: T;
    };
  };
  widgetRegistry: {
    /**
     * @default 'Visit {{name}} registry'
     */
    visitNamedRegistry: T;

    /**
     * @default 'Visit registry'
     */
    visitUnnamedRegistry: T;

    /**
     * @default 'Browse Our Registry'
     */
    buttonText: T;
  };
  common: {
    /**
     * @default '{{start}} to {{end}}'
     */
    timeframe: T;

    /**
     * @default '{{start}} to {{end}} ({{timezone}})'
     */
    timeframeTz: T;

    /**
     * @default '{{time}} ({{timezone}})'
     */
    timeTz: T;

    /**
     * @default 'Add to Calendar'
     */
    addToCalendar: T;

    /**
     * @default 'Add to Cal'
     */
    addToCal: T;

    /**
     * @default 'Discount Code'
     */
    discountCodeLabel: T;

    /**
     * @default 'Dress Code'
     */
    dressCode: T;

    /**
     * @default 'Directions'
     */
    getDirections: T;

    /**
     * @default 'menu'
     */
    menu: T;

    /**
     * @default 'For more details'
     */
    moreDetails: T;

    /**
     * @default 'To Be Announced'
     */
    toBeAnnouncedHeader: T;

    /**
     * @default 'Details for this event will be shared at a later date.'
     */
    toBeAnnouncedDescription: T;

    /**
     * @default 'Text Copied!'
     */
    textCopiedNotificationLabel: T;
  };
};

export type GuestRegistryTranslations<T extends LeafNodeValue = TranslateFunction> = {
  pageTitle: {
    /**
     * @default '🎁 Did you purchase it?'
     */
    confirmPaymentTitle: T;

    /**
     * @default 'Registry'
     */
    registryPage: T;

    /**
     * @default 'Already Gifted'
     */
    alreadyGifted: T;
  };
  coupleHeader: {
    /**
     * @default 'Manage Registry'
     */
    dashboardBtn: T;

    /**
     * @default 'Continue reading…'
     */
    readMore: T;

    /**
     * @default 'Collapse note…'
     */
    readLess: T;

    /**
     * @default '…'
     */
    readMoreText: T;

    /**
     * @default 'Change Photo'
     */
    photoButton: T;

    /**
     * @default 'Add Photo'
     */
    addPhotoButton: T;

    /**
     * @default 'Edit Note'
     */
    noteButton: T;

    /**
     * @default 'Add Note'
     */
    addNoteButton: T;

    /**
     * @default 'Menu'
     */
    menu: T;

    /**
     * @default 'Edit Event'
     */
    editEvent: T;

    /**
     * @default 'Logout'
     */
    logout: T;

    /**
     * @default 'More…'
     */
    more: T;

    defaultName: {
      /**
       * @default 'Baby Registry'
       */
      babyRegistry: T;

      /**
       * @default 'Jack & Jill's Registry'
       */
      wedding: T;

      /**
       * @default 'Registry'
       */
      general: T;
    };
  };
  productTile: {
    /**
     * @default 'Purchased'
     */
    purchased: T;

    /**
     * @default 'Reserved'
     */
    reserved: T;

    /**
     * @default '{{amount}} Remaining'
     */
    remaining: T;

    /**
     * @default '{{amount}} Requested'
     */
    requested: T;

    /**
     * @default 'Trending'
     */
    labelTrending: T;

    /**
     * @default 'Purchased by you'
     */
    purchasedByYou: T;

    /**
     * @default 'Reserved by you'
     */
    reservedByYou: T;

    /**
     * @default '<StillNeeded/> more needed'
     */
    needed: T;

    /**
     * @default 'Contribute what you wish'
     */
    contributionAnyAmountText: T;

    /**
     * @default 'Goal Reached!'
     */
    goalReachedText: T;

    /**
     * @default '{{goal}} Goal Reached!'
     */
    goalReachedWithPriceText: T;

    /**
     * @default 'Charity'
     */
    charity: T;

    /**
     * @default 'Cash Fund'
     */
    cashFund: T;

    /**
     * @default 'Funded'
     */
    funded: T;

    /**
     * @default 'Our Most Wanted'
     */
    ourMostWanted: T;
  };
  emptyFilter: {
    /**
     * @default 'Nothing to see here (yet).'
     */
    title: T;

    /**
     * @default 'There are no current items that appear in this category.'
     */
    subtitle: T;
  };
  productDialog: {
    /**
     * @default 'Registered on'
     */
    availableToPurchaseOn: T;

    /**
     * @default 'Purchase on Retailer'
     */
    clickToBuy: T;

    /**
     * @default 'Retailer'
     */
    retailer: T;

    /**
     * @default 'How do I mark this gift as purchased?'
     */
    markAsPurchased: T;

    /**
     * @default 'This gift is from a {{storeName}} registry'
     */
    markAsPurchasedTitle: T;

    /**
     * @default 'Mark your item as purchased on that registry. We check and update the purchase status daily.'
     */
    markAsPurchasedSubtitle: T;
  };
  purchaseDialog: {
    /**
     * @default 'How many are you purchasing?'
     */
    howManyRequest: T;

    /**
     * @default '<StillNeeded/> more needed'
     */
    needed: T;

    /**
     * @default 'Your Name'
     */
    fieldNameLabel: T;

    /**
     * @default 'Email'
     */
    fieldEmailLabel: T;

    /**
     * @default 'First and last name'
     */
    fieldNamePlaceholder: T;

    /**
     * @default 'Your email address'
     */
    fieldEmailPlaceholder: T;

    /**
     * @default 'Gift Note'
     */
    fieldNoteLabel: T;

    /**
     * @default 'Add a note (optional)'
     */
    fieldNotePlaceholder: T;

    /**
     * @default 'Reserve & Purchase'
     */
    formButtonReservePurchase: T;

    /**
     * @default 'Buy Now'
     */
    formButtonBuyNow: T;

    /**
     * @default 'Add to Cart'
     */
    formButtonAddToCart: T;

    /**
     * @default 'Purchase Now'
     */
    formButtonPurchaseNow: T;

    /**
     * @default 'I've Already Purchased This'
     */
    formButtonAlreadyPurchase: T;

    /**
     * @default 'Mark as Purchased'
     */
    formButtonMarkAsPurchased: T;

    /**
     * @default 'Hide description'
     */
    hideDescription: T;

    /**
     * @default 'Read More'
     */
    readMore: T;

    /**
     * @default 'Out of Stock'
     */
    outOfStock: T;

    /**
     * @default 'This gift is not available for purchase right now. Browse <link/>'
     */
    isNotPurchasableText: T;

    /**
     * @default 'Description'
     */
    descriptionLabel: T;

    /**
     * @default 'Added from {{storeName}}'
     */
    detailsLinkText: T;

    /**
     * @default 'Note'
     */
    noteLabel: T;

    /**
     * @default ' other gifts'
     */
    otherGifts: T;

    /**
     * @default 'Have Questions?'
     */
    questionLinkText: T;

    /**
     * @default 'Have questions? We’re here to help.'
     */
    questionV2LinkText: T;

    /**
     * @default 'This item will be fulfilled by Joy'
     */
    fulfilledByJoy: T;

    /**
     * @default 'This item will be fulfilled by {{storeName}}'
     */
    fulfilledByExternal: T;

    /**
     * @default 'Add to Cart & Reserve'
     */
    formButtonAddCartReserve: T;

    /**
     * @default 'Please enter your name'
     */
    requiredNameWarning: T;

    /**
     * @default 'Please enter your email'
     */
    requiredEmailWarning: T;

    /**
     * @default 'Please enter a valid email'
     */
    validEmailWarning: T;

    /**
     * @default 'We could not process your order at this time.'
     */
    purchaseErrorMsg: T;

    /**
     * @default 'Change Email'
     */
    changeEmail: T;

    /**
     * @default 'Quantity'
     */
    quantityTitle: T;

    /**
     * @default 'Subtotal'
     */
    subtotalTitle: T;

    /**
     * @default '{{quantity}} x {{price}}'
     */
    quantityTimesPrice: T;

    /**
     * @default 'Total {{total}}'
     */
    totalPriceTitleText: T;

    /**
     * @default 'Gift'
     */
    giftTitle: T;

    /**
     * @default '🎁 Item added to cart 🎁'
     */
    itemAddedSuccess: T;

    /**
     * @default 'Free shipping fulfilled by Joy'
     */
    freeShippingByJoy: T;

    /**
     * @default 'Fulfilled by Joy'
     */
    fulfilledByJoyShort: T;

    /**
     * @default 'Free Shipping'
     */
    freeShipping: T;

    /**
     * @default 'For orders over $45'
     */
    freeShippingDescription: T;

    /**
     * @default 'Every Joy Registry Deserves'
     */
    everyJoyRegistryDeserves: T;

    /**
     * @default 'Price Match Promise'
     */
    priceMatchPromise: T;

    /**
     * @default 'We'll match any retailer'
     */
    priceMatchPromiseDescription: T;

    /**
     * @default 'Easy Returns'
     */
    easyReturns: T;

    /**
     * @default 'Free online returns'
     */
    easyReturnsDescription: T;

    /**
     * @default 'Your Details'
     */
    backToDetails: T;

    /**
     * @default 'Where's it going?'
     */
    backToMissingCoupleAddress: T;

    /**
     * @default 'Add optional gift note'
     */
    giftNoteTitle: T;

    /**
     * @default 'Gift Details'
     */
    backToGiftDetails: T;

    /**
     * @default 'Greeting Card'
     */
    backToGreetingCard: T;

    /**
     * @default 'Upgrade My Gift Note'
     */
    upgradeGiftNote: T;

    /**
     * @default 'Next'
     */
    next: T;

    /**
     * @default 'Subject to third party shipping.'
     */
    shippingInfo: T;

    /**
     * @default 'Learn more'
     */
    learnMore: T;

    missingCoupleAddress: {
      /**
       * @default 'Where's it going?'
       */
      title: T;

      /**
       * @default 'Missing Recipient's Address'
       */
      warningTitle: T;

      /**
       * @default 'Please contact the recipient to complete this purchase.'
       */
      warningBody: T;

      /**
       * @default 'Ship to'
       */
      shipTo: T;

      /**
       * @default 'Another U.S. Address'
       */
      anotherAddressTitle: T;

      /**
       * @default 'Joy only ships to U.S. addresses'
       */
      anotherAddressTooltip: T;

      /**
       * @default 'We'll tell the recipient it was purchased.'
       */
      anotherAddressBody: T;

      /**
       * @default 'Add Shipping Address'
       */
      button: T;
    };
    otherAddress: {
      /**
       * @default 'Shipping Address'
       */
      title: T;

      /**
       * @default 'Name'
       */
      nameLabel: T;

      /**
       * @default 'Only one recipient name is recommended'
       */
      namePlaceholder: T;

      /**
       * @default 'Jamie Lee'
       */
      nameGhost: T;

      /**
       * @default 'Address Line 1'
       */
      addressLine1Label: T;

      /**
       * @default 'Only Non-P.O. Box addresses in the United States'
       */
      addressLine1Placeholder: T;

      /**
       * @default 'Address Line 2'
       */
      addressLine2Label: T;

      /**
       * @default 'City'
       */
      cityLabel: T;

      /**
       * @default 'State'
       */
      stateLabel: T;

      /**
       * @default 'Zip/Postal Code'
       */
      postalCode: T;

      /**
       * @default '*Required fields'
       */
      requiredFields: T;

      /**
       * @default 'We cannot ship to P.O. boxes'
       */
      poBoxWarningCaption: T;

      /**
       * @default 'Review Details'
       */
      button: T;
    };
    groupGifting: {
      /**
       * @default 'Buy Entire Gift'
       */
      buyEntireGift: T;

      /**
       * @default 'Contribute'
       */
      contribute: T;

      /**
       * @default 'Contribute {{amount}} to Gift'
       */
      contributeToGiftBtn: T;

      /**
       * @default 'Contribute to Gift'
       */
      contributeToGiftBtnMobile: T;

      /**
       * @default 'Contribute Remaining Amount'
       */
      contributeRemainingAmount: T;

      /**
       * @default 'Contribute a Partial Amount'
       */
      contributePartialAmount: T;

      /**
       * @default '{{amount}} Remaining'
       */
      amountRemaining: T;
    };
  };
  confirmationPage: {
    /**
     * @default 'Quantity'
     */
    quantity: T;

    /**
     * @default 'Subtotal'
     */
    subtotal: T;

    /**
     * @default 'Shipping and Handling'
     */
    shipping: T;

    /**
     * @default 'Est Taxes'
     */
    estimatedTaxes: T;

    /**
     * @default 'eCard Fee'
     */
    eCardFee: T;

    /**
     * @default 'total'
     */
    total: T;

    /**
     * @default 'Thank you for your order!'
     */
    giftPurchased: T;

    /**
     * @default 'Your gift will ship directly to them in <addressText/>'
     */
    giftPurchasedSubtitle: T;

    /**
     * @default 'Order #{{orderId}}'
     */
    giftPurchasedOrderNumber: T;

    /**
     * @default 'We'll send you an email confirmation for your order shortly. {{host}} will also receive an email notifying them of your gift.'
     */
    giftPurchasedSendEmail: T;

    /**
     * @default 'We'll send you an email confirmation for your order shortly.'
     */
    giftPurchasedEmail: T;

    /**
     * @default '{{host}} will also receive an email notifying them of your gift.'
     */
    giftPurchasedCouple: T;

    /**
     * @default 'Your gift will be delivered electronically to them.'
     */
    giftPurchasedSubtitleState1Paragraph3: T;

    /**
     * @default 'Your item will ship directly to them in {{address}}'
     */
    giftPurchasedSubtitleState2Paragraph3: T;

    /**
     * @default 'Paid via {{card}} {{cardLastDigits}}'
     */
    paidNote: T;

    /**
     * @default 'Back to Registry'
     */
    backToRegistryBtn: T;

    /**
     * @default 'Go to Wedding Website'
     */
    goToWeddingWebsiteBtn: T;

    /**
     * @default 'Have you purchased your gift?'
     */
    confirmQuestion: T;

    /**
     * @default 'Yes, I've Purchased This'
     */
    confirmButtonPage: T;

    /**
     * @default 'Yes'
     */
    confirmButton: T;

    /**
     * @default 'Please confirm your gift was purchased, and we'll let the recipient know.'
     */
    confirmSubtitle: T;

    /**
     * @default 'No'
     */
    noButton: T;

    /**
     * @default 'Something wrong?'
     */
    somethingWrongTitle: T;

    /**
     * @default 'Go back'
     */
    somethingWrongAction: T;

    /**
     * @default 'Changed your mind?'
     */
    changedMindTitle: T;

    /**
     * @default 'Cancel Reservation'
     */
    changedMindAction: T;

    /**
     * @default 'Have Questions?'
     */
    haveQuestions: T;

    /**
     * @default 'Just to be safe, please double check that you've sent it to the right address.'
     */
    justToBeSafe: T;

    /**
     * @default 'Like what you see?'
     */
    likeWhatYouSee: T;

    /**
     * @default 'Treat yourself'
     */
    treatYourself: T;

    /**
     * @default 'We won't tell anyone if you want to buy it for yourself — it'll be our little secret.'
     */
    weWontTellAnyone: T;

    /**
     * @default 'Buy Now'
     */
    buyNowBtn: T;

    /**
     * @default 'Next Steps'
     */
    nextSteps: T;

    /**
     * @default 'Book your place to stay  🛏️'
     */
    bookYourPlaceToStay: T;

    /**
     * @default 'Rooms can book up quickly and discounted rates can expire.'
     */
    roomsBookUpQuickly: T;

    /**
     * @default 'Visit event website  👩‍💻'
     */
    visitEventWebsite: T;

    /**
     * @default 'For important travel, schedule and details for this event'
     */
    detailsForTheEvent: T;

    /**
     * @default 'Continue shopping  🎁'
     */
    continueShopping: T;
  };
  confirmationPurchaseModal: {
    /**
     * @default 'Ready to head to {{retailer}}?'
     */
    title: T;

    /**
     * @default 'After purchasing, we will ask you to confirm that you've completed your purchase.'
     */
    afterPurchasing: T;

    /**
     * @default 'We don't have a shipping address for the recipient. Please ask them where they would like to receive their gifts.'
     */
    dontHaveAddress: T;

    /**
     * @default 'Continue to {{retailer}}'
     */
    button: T;

    /**
     * @default '✨ Address copied ✨'
     */
    addressCopied: T;

    /**
     * @default 'Cancel'
     */
    cancelButton: T;

    /**
     * @default 'Shipping Address'
     */
    couplesAddressTitle: T;

    /**
     * @default 'Joy may earn an affiliate commission on purchases.'
     */
    legal: T;

    /**
     * @default 'Before you leave, you'll need the recipient's address.'
     */
    addressNote: T;

    /**
     * @default 'When you're done, come back here to mark the gift as purchased on the registry.'
     */
    purchaseNote: T;

    /**
     * @default 'Got It'
     */
    buttonGotIt: T;

    /**
     * @default 'Shipping Address'
     */
    shippingAddress: T;
  };
  intentToPurchaseModal: {
    /**
     * @default 'Do you still intend to purchase this gift?'
     */
    title: T;

    /**
     * @default 'Yes, just not right now'
     */
    ctaYes: T;

    /**
     * @default 'No, cancel my reservation'
     */
    ctaNo: T;

    /**
     * @default 'How “Mark as Purchased” works:'
     */
    titleHowTo: T;

    /**
     * @default 'Purchase the item from any store'
     */
    howTo1: T;

    /**
     * @default 'Return to Joy with your order number and mark the item as purchased'
     */
    howTo2: T;

    /**
     * @default 'How sending a cash gift works:'
     */
    titleHowToDonation: T;

    /**
     * @default 'Send your gift via {{platform}}'
     */
    howTo1Donation: T;

    /**
     * @default 'Return to Joy and mark the item as purchased'
     */
    howTo2Donation: T;
  };
  createOrderAndPurchaseModal: {
    /**
     * @default 'Great! Now add your purchase details.'
     */
    title: T;

    /**
     * @default 'How many did you purchase?'
     */
    qtyPurchased: T;

    /**
     * @default '{{qty}} needed'
     */
    qtyNeeded: T;

    /**
     * @default 'Your Name'
     */
    nameLabel: T;

    /**
     * @default 'First and last name'
     */
    namePlaceholder: T;

    /**
     * @default 'Email'
     */
    emailLabel: T;

    /**
     * @default 'Your email address'
     */
    emailPlaceholder: T;

    /**
     * @default 'Done'
     */
    doneButton: T;

    /**
     * @default 'Didn't purchase this gift?'
     */
    cancelTitle: T;

    /**
     * @default 'Cancel Reservation'
     */
    cancelButton: T;
  };
  purchaseGift: {
    /**
     * @default 'Your gift is reserved. Please complete your purchase.'
     */
    reservedTitle: T;

    /**
     * @default 'Didn't mean to reserve this item?'
     */
    reservedGift: T;

    /**
     * @default 'Complete your order on {{retailer}} and make sure you mark it as a gift to include a gift receipt.'
     */
    purchaseDescription: T;

    /**
     * @default 'Your gift is reserved. Please complete your purchase.'
     */
    customPurchaseTitle: T;

    /**
     * @default 'Complete your order and make sure you mark it as a gift to include a gift receipt.'
     */
    customPurchaseDescription: T;

    /**
     * @default 'Complete your order on Amazon and make sure you mark it as a gift to include a gift receipt.'
     */
    customPurchaseDescriptionAmazon: T;

    /**
     * @default 'Address is not filled out automatically.'
     */
    purchaseTip: T;

    /**
     * @default 'Shipping Address'
     */
    shippingAddress: T;

    /**
     * @default '✨ Address copied ✨'
     */
    copyToClipboard: T;

    /**
     * @default 'Back to Registry'
     */
    backToRegistry: T;

    /**
     * @default 'Complete Purchase'
     */
    completeButton: T;

    /**
     * @default 'Buy on {{storeText}}'
     */
    buyOn: T;

    /**
     * @default 'Thanks for buying this gift!'
     */
    thanksTitle: T;

    /**
     * @default 'Please confirm that you've bought this item so it can be marked as purchased.'
     */
    thanksDescription: T;

    /**
     * @default 'All Done'
     */
    thanksButton: T;

    /**
     * @default 'Copy'
     */
    copyAddress: T;

    /**
     * @default 'Cancel Reservation'
     */
    cancelReservation: T;

    /**
     * @default 'Undo'
     */
    undo: T;

    /**
     * @default ' Didn't complete purchase?'
     */
    didntCompletePurchase: T;

    /**
     * @default 'The recipient hasn't indicated a shipping address. Please ask them about where they would like to receive their gifts.'
     */
    missingShippingAddress: T;

    /**
     * @default 'Retailer'
     */
    retailer: T;

    /**
     * @default 'Address doesn't transfer automatically.'
     */
    addressWarning: T;
  };
  contributeToFund: {
    /**
     * @default 'Via {{platform}}'
     */
    viaPlatform: T;

    /**
     * @default 'Now, let's send it.'
     */
    contributeTitle: T;

    /**
     * @default 'Need help?'
     */
    needHelp: T;

    /**
     * @default 'All Done'
     */
    allDone: T;

    /**
     * @default '{{amount}} for {{fundTitle}}'
     */
    contributeDetails: T;

    /**
     * @default '{{handle}} on {{platform}}'
     */
    contributeDetailsPayment: T;

    /**
     * @default 'Send Gift on {{donationPlatform}}'
     */
    contributeButtonSend: T;

    /**
     * @default 'Go to Donation Site'
     */
    contributeButtonTextCharity: T;

    /**
     * @default 'Suggested Note'
     */
    contributeSuggestedNoteLabel: T;

    /**
     * @default 'Copy'
     */
    contributeCopyText: T;

    /**
     * @default 'Copied'
     */
    contributeCopiedText: T;

    /**
     * @default 'For your {{fundTitle}} on Joy ❤️'
     */
    contributeSuggestedNoteString: T;

    /**
     * @default 'Have you sent your gift?'
     */
    contributeConfirmationHeader: T;

    /**
     * @default 'Please confirm your gift was sent, and we'll let the recipient know.'
     */
    contributeConfirmationBody: T;

    /**
     * @default 'Yes, I've Sent My Gift'
     */
    contributeConfirmationButtonText: T;

    /**
     * @default 'Changed your mind?'
     */
    contributeCancelQuestion: T;

    /**
     * @default 'Cancel Reservation'
     */
    contributeCancelText: T;

    /**
     * @default 'Thanks for giving to this gift!'
     */
    contributeConfirmationTitle: T;

    /**
     * @default 'Thanks for buying this gift!'
     */
    contributeConfirmationTitleProduct: T;

    /**
     * @default 'This gift has been marked as purchased and is unavailable for purchase by other gift givers.'
     */
    contributeConfirmationDescription: T;

    /**
     * @default 'Back to Registry'
     */
    contributeBackToRegistryText: T;

    /**
     * @default 'We've reserved your gift'
     */
    reservedCashOrCheckDonationTitle: T;

    /**
     * @default 'Bring your gift to the wedding or mail it to them at this address. We'll let them know that you plan to give them this gift.'
     */
    reservedCashOrCheckDonationInstructions: T;
  };
  undoPurchase: {
    /**
     * @default 'Would you like to cancel your reservation?'
     */
    undoPurchaseTitle: T;

    /**
     * @default 'The recipient will no longer see this gift as reserved, and the item will be available again for purchase.'
     */
    undoPurchaseBody: T;

    /**
     * @default 'Cancel Reservation'
     */
    undoPurchaseButtonText: T;

    /**
     * @default 'Nevermind'
     */
    undoPurchaseCancelText: T;

    /**
     * @default 'Reservation canceled'
     */
    undoPurchaseConfirmationTitle: T;

    /**
     * @default 'Back to Registry'
     */
    undoPurchaseBackText: T;

    /**
     * @default 'thanks for buying'
     */
    thanksForBuying: T;

    /**
     * @default 'contribute'
     */
    contribute: T;

    /**
     * @default 'purchase gift'
     */
    purchaseGift: T;
  };
  giveGiftDialog: {
    /**
     * @default 'Gift Amount'
     */
    fieldAmountLabel: T;

    /**
     * @default 'Your Name'
     */
    fieldNameLabel: T;

    /**
     * @default 'Email'
     */
    fieldEmailLabel: T;

    /**
     * @default 'Gift Note'
     */
    fieldNoteLabel: T;

    /**
     * @default 'First and last name'
     */
    fieldNamePlaceholder: T;

    /**
     * @default 'Your email address'
     */
    fieldEmailPlaceholder: T;

    /**
     * @default 'Add a note (optional)'
     */
    fieldNotePlaceholder: T;

    /**
     * @default 'Reserve and Give'
     */
    formButtonReserveGive: T;

    /**
     * @default 'Provide your name so the recipient knows who to thank.'
     */
    nameEmptyError: T;

    /**
     * @default 'Provide your email so the recipient knows who to thank.'
     */
    emailEmptyError: T;

    /**
     * @default 'Not a valid email.'
     */
    emailNotValidError: T;

    /**
     * @default 'How does this work?'
     */
    explanationLink: T;

    /**
     * @default 'Also registered at'
     */
    alsoRegisteredAt: T;

    /**
     * @default 'Please enter a gift amount.'
     */
    amountEmptyError: T;

    /**
     * @default 'Payment Method'
     */
    paymentMethodLabel: T;

    /**
     * @default 'Select...'
     */
    paymentMethodSelectPlaceholder: T;

    /**
     * @default 'Select a payment method'
     */
    paymentMethodError: T;

    /**
     * @default 'Cash/Check'
     */
    cashOrCheckGuestSelectLabel: T;

    /**
     * @default 'Add to Cart & Reserve'
     */
    formButtonAddCartReserve: T;

    /**
     * @default 'Update Reservation'
     */
    formButtonUpdateDonation: T;
  };
  registryHidden: {
    /**
     * @default 'This registry isn't ready.'
     */
    title: T;

    /**
     * @default 'You'll be redirected to the event website in a moment. Otherwise, '
     */
    subtitle: T;

    /**
     * @default 'click here to be redirected now.'
     */
    link: T;
  };
  retailerRedirect: {
    /**
     * @default 'We're taking you to {{storeName}}...'
     */
    title: T;

    /**
     * @default 'Don't forget!'
     */
    markGiftTitle: T;

    /**
     * @default 'After purchasing, go back to your previous tab to mark your gift as purchased. ✅ 🎁'
     */
    markGiftDescription: T;

    /**
     * @default 'This will help the host know who to thank.'
     */
    coupleMessage: T;

    /**
     * @default 'Click here if you haven't been redirected'
     */
    link: T;
  };
  browserWindow: {
    /**
     * @default '🎁  Did you purchase it?'
     */
    joyBrowserTabTitle: T;
  };
  registryProtected: {
    /**
     * @default 'This event is protected'
     */
    title: T;

    /**
     * @default 'Please enter the password'
     */
    subtitle: T;

    /**
     * @default 'Password'
     */
    inputPlaceholder: T;

    /**
     * @default 'Unlock Registry'
     */
    actionButton: T;

    /**
     * @default 'Sign In'
     */
    signInButton: T;

    /**
     * @default 'Contact Host'
     */
    contactButton: T;

    /**
     * @default 'Incorrect Password. Please try again.'
     */
    incorrectPass: T;
  };
  contactTheHost: {
    /**
     * @default 'Request Password'
     */
    title: T;

    /**
     * @default 'Tell the host who you are, so they can email you back with their event password.'
     */
    subtitle: T;

    /**
     * @default 'Your Full Name'
     */
    namePlaceholder: T;

    /**
     * @default 'Your Email'
     */
    emailPlaceholder: T;

    /**
     * @default 'Send Request'
     */
    actionButton: T;

    /**
     * @default 'We've sent the host an email'
     */
    successTitle: T;

    /**
     * @default 'In the meantime, we'll redirect you to their website. '
     */
    sucessSubtitle: T;

    /**
     * @default 'Click here to be redirected now.'
     */
    successLink: T;

    /**
     * @default 'Invalid Email. Please try again.'
     */
    emailInputError: T;
  };
  confirmationSection: {
    /**
     * @default 'Cancel Reservation'
     */
    cancelText: T;

    /**
     * @default 'Change Payment Method'
     */
    changeGiftingMethod: T;

    /**
     * @default 'Changed your mind?'
     */
    cancelQuestion: T;

    /**
     * @default 'Have Questions?'
     */
    haveQuestions: T;

    /**
     * @default 'Your gift is reserved.'
     */
    itsReserved: T;
  };
  alreadyReserveSection: {
    /**
     * @default 'It looks like you've already reserved this gift with that email address.'
     */
    title: T;

    /**
     * @default 'We've sent you an email with a link to your reservation so you can complete your purchase or cancel your reservation.'
     */
    subTitle: T;

    /**
     * @default 'Check your  “{{email}}” email inbox.'
     */
    checkEmail: T;

    /**
     * @default 'Change Email'
     */
    changeEmail: T;
  };
  reserveItem: {
    /**
     * @default 'Did you reserve {{itemName}}?'
     */
    title: T;

    /**
     * @default 'Enter the email address you used to reserve this gift and we'll send you a link to your reservation.'
     */
    subTitle: T;

    /**
     * @default 'Email address'
     */
    inputLabel: T;

    /**
     * @default 'Find Reservation'
     */
    buttonLabel: T;

    /**
     * @default 'Cancel'
     */
    cancelText: T;

    /**
     * @default 'We've sent a link to your reservation! Check your email at {{email}}.'
     */
    successMessage: T;

    /**
     * @default 'This gift was not reserved with this email.'
     */
    errorMessage: T;

    /**
     * @default 'Did you reserve a gift for {{itemName}}?'
     */
    cashFundTitle: T;
  };
  registryFooter: {
    /**
     * @default 'About'
     */
    about: T;

    /**
     * @default 'Help'
     */
    help: T;
  };
  trackingSection: {
    /**
     * @default 'Update Tracking'
     */
    updateCta: T;

    /**
     * @default 'Add Tracking Info'
     */
    addTrackingCta: T;

    /**
     * @default 'Add Tracking Info for recipient'
     */
    addTrackingInfoCta: T;

    /**
     * @default 'Help the recipient keep track of this gift by providing your Order and Tracking Numbers.'
     */
    addTrackingTooltip: T;

    /**
     * @default 'Order Number (Required)'
     */
    orderNumberLabel: T;

    /**
     * @default 'Please enter an order number'
     */
    orderNumberRequiredError: T;

    /**
     * @default 'Tracking Number'
     */
    trackingNumberLabel: T;

    /**
     * @default 'Test Link'
     */
    testLink: T;

    /**
     * @default 'Save'
     */
    saveCta: T;

    /**
     * @default 'Save Tracking Info'
     */
    saveTrakingInfoCta: T;

    /**
     * @default 'Cancel'
     */
    cancelCta: T;
  };
  changeEmailSection: {
    /**
     * @default 'Are you sure you want to change your email?'
     */
    title: T;

    /**
     * @default 'Continuing with a new email will clear your shopping cart and past purchases, and start a new one.'
     */
    subTitle: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Change Email'
     */
    changeEmail: T;

    /**
     * @default 'Email'
     */
    email: T;
  };
  checkoutDialog: {
    /**
     * @default '{{amount}} Contribution'
     */
    contributionAmount: T;

    /**
     * @default 'Read more'
     */
    readMoreButton: T;

    /**
     * @default 'Read less'
     */
    readLessButton: T;

    giftMethodBadge: {
      /**
       * @default 'Cash / Check'
       */
      cashCheckLabel: T;

      /**
       * @default 'Charity'
       */
      charityLabel: T;
    };
    donationProgressBar: {
      /**
       * @default 'funded of {{amount}} goal'
       */
      fundedLabelWithoutGift: T;

      /**
       * @default 'funded of {{amount}} goal (with your gift)'
       */
      fundedLabelWithGift: T;
    };
    shippingAddress: {
      /**
       * @default 'The recipient hasn't indicated a shipping address. Please ask them where they would like to receive their gifts.'
       */
      noShippingAddress: T;

      /**
       * @default 'Mailing Address'
       */
      shippingAddressTitle: T;

      /**
       * @default 'Copy'
       */
      copy: T;

      /**
       * @default 'Address copied'
       */
      copied: T;
    };
    stateBackLabels: {
      /**
       * @default 'Gift'
       */
      gifterDetails: T;

      /**
       * @default 'Payment Method'
       */
      giftWrap: T;

      /**
       * @default 'Gift Wrap'
       */
      giftWrapNote: T;

      /**
       * @default 'Gift Wrap'
       */
      externalCheckoutInstructionsGiftWrapIdle: T;

      /**
       * @default 'Your Details'
       */
      paymentMethod: T;

      /**
       * @default 'Payment Method'
       */
      paperCheckoutInstructions: T;

      /**
       * @default 'Gift Wrap'
       */
      paperCheckoutInstructionsGiftWrapIdle: T;

      /**
       * @default 'Address'
       */
      paperCheckoutConfirmAndNotify: T;

      /**
       * @default 'Payment Method'
       */
      externalCheckoutInstructions: T;

      /**
       * @default 'Your Details'
       */
      externalCheckoutInstructionsAffiliate: T;

      /**
       * @default 'Your Details'
       */
      externalCheckoutInstructionsCharity: T;

      /**
       * @default 'Instructions'
       */
      externalCheckoutCopyAddress: T;

      /**
       * @default 'Instructions'
       */
      externalCheckoutReview: T;

      /**
       * @default 'Address'
       */
      externalCheckoutReviewAffiliate: T;

      /**
       * @default 'Confirm Gift'
       */
      cancelGiftIntent: T;
    };
    screens: {
      externalCheckoutInstructions: {
        /**
         * @default 'Great! Here's how it works:'
         */
        title: T;

        steps: {
          step1: {
            venmo: {
              /**
               * @default '<strong>On mobile</strong>, we'll take you to the Venmo app to send your gift.'
               */
              mobile: T;

              /**
               * @default '<strong>On desktop</strong>, we'll take you to the Venmo website to send your gift.'
               */
              desktop: T;
            };

            /**
             * @default 'You'll be taken to the recipient's PayPal page to send your gift.'
             */
            paypal: T;

            /**
             * @default 'You'll be taken to the recipient's Cash App page to send your gift.'
             */
            cashapp: T;

            /**
             * @default 'You'll be taken to the charity website.'
             */
            charity: T;

            /**
             * @default '<strong>You'll be taken to {{retailer}}</strong> and your gift will be added to the cart in this registry.'
             */
            affiliate: T;
          };
          step2: {
            /**
             * @default 'Venmo account: <strong>{{couplesAccount}}</strong>'
             */
            venmo: T;

            /**
             * @default 'PayPal.me account is: <strong>{{couplesAccount}}</strong>'
             */
            paypal: T;

            /**
             * @default 'Open Cash App on your phone and <strong>tap the QR Scanner</strong> on the top left corner of the home screen.'
             */
            cashapp: T;

            /**
             * @default 'Amount you're donating: <br><strong>{{amount}}</strong>'
             */
            charity: T;

            /**
             * @default 'Purchase your gift at <strong>{{retailer}}.</strong>'
             */
            affiliate: T;
          };
          step3: {
            /**
             * @default 'Amount you're sending: <strong>{{amount}}</strong>'
             */
            venmo: T;

            /**
             * @default 'Amount you're sending: <strong>{{amount}}</strong>'
             */
            paypal: T;

            /**
             * @default 'Hold your camera over the QR code generated on your desktop to scan it.'
             */
            cashapp: T;

            /**
             * @default 'Give your donation.'
             */
            charity: T;

            /**
             * @default '<strong>Come back to this tab</strong> to confirm your purchase and we'll notify the recipient.'
             */
            affiliate: T;
          };
        };
        stepsGiftWrap: {
          step1: {
            /**
             * @default 'Pay for your personalized eCard.'
             */
            venmo: T;

            /**
             * @default 'Pay for your personalized eCard.'
             */
            paypal: T;

            /**
             * @default 'Pay for your personalized eCard.'
             */
            cashapp: T;

            /**
             * @default 'Pay for your personalized eCard.'
             */
            affiliate: T;
          };
          step2: {
            /**
             * @default 'You'll be taken to the Venmo app to send your gift.'
             */
            venmo: T;

            /**
             * @default 'You'll be taken to the couple's PayPal page to send your gift.'
             */
            paypal: T;

            /**
             * @default 'We'll take you to the Cash App to send your gift.'
             */
            cashapp: T;

            /**
             * @default '<strong>You'll then be taken to {{retailer}}</strong> to purchase your gift.'
             */
            affiliate: T;
          };
          step3: {
            /**
             * @default 'Venmo account: @{{couplesAccount}}<br>Amount you're sending: {{amount}}'
             */
            venmo: T;

            /**
             * @default 'Paypal.me account: @{{couplesAccount}}<br>Amount you're sending: {{amount}}'
             */
            paypal: T;

            /**
             * @default 'Cash App account: ${{couplesAccount}}<br>Amount you're sending: {{amount}}'
             */
            cashapp: T;

            /**
             * @default '<strong>After purchase, come back to this tab</strong> to confirm your purchase.'
             */
            affiliate: T;
          };
        };

        /**
         * @default 'When you're done: '
         */
        alertTitle: T;

        /**
         * @default 'Come back to this tab to confirm your gift and we'll notify the recipient.'
         */
        alertText: T;

        /**
         * @default 'While gifts are in your cart, they won't be available for other gifts givers to purchase.'
         */
        alertTextAffiliate: T;

        /**
         * @default 'Copy {{account}} Account'
         */
        copyButton: T;

        /**
         * @default 'Got it'
         */
        gotItButton: T;
      };
      externalCheckoutReview: {
        /**
     * @default 'Almost done!
You're headed to {{platform}}...'
*/
        title: T;

        /**
         * @default 'Let's go to {{platform}}'
         */
        goToButton: T;

        /**
         * @default 'Cancel Gift'
         */
        cancelButton: T;

        /**
         * @default 'Go to Charity'
         */
        goToCharityButton: T;
      };
      giftAmount: {
        /**
         * @default 'Give {{amount}} Now'
         */
        giveNow: T;

        /**
         * @default 'Give Now'
         */
        giveNowWithAmount: T;

        /**
         * @default 'A gift amount is required'
         */
        amountEmptyError: T;
      };
      gifterDetails: {
        /**
         * @default 'Add your details'
         */
        title: T;

        /**
         * @default 'Share your details to mark this gift as purchased'
         */
        titleDeferred: T;

        /**
         * @default 'Please share your details to help {{host}} track your gift.'
         */
        description: T;

        /**
         * @default 'Add Details'
         */
        cta: T;

        /**
         * @default 'Email'
         */
        fieldEmailLabel: T;

        /**
         * @default 'Your email address'
         */
        fieldEmailPlaceholder: T;

        /**
         * @default 'Sharing your email is required'
         */
        fieldEmailMissing: T;

        /**
         * @default 'Name'
         */
        fieldNameLabel: T;

        /**
         * @default 'First and last name'
         */
        fieldNamePlaceholder: T;

        /**
         * @default 'Sharing your name is required'
         */
        fieldNameMissing: T;

        /**
         * @default 'Gift Note'
         */
        fieldNoteLabel: T;

        /**
         * @default 'Add an optional note'
         */
        fieldNotePlaceholder: T;

        /**
         * @default 'Come back to this tab to confirm your gift and we'll notify the recipient.'
         */
        warningText: T;
      };
      paymentMethod: {
        /**
         * @default 'How would you like to give?'
         */
        title: T;

        /**
         * @default '{{host}} would like to receive their gifts using one of the methods below:'
         */
        description: T;

        /**
         * @default 'Select a method below to contribute to {{fundName}}'
         */
        descriptionDonation: T;

        /**
         * @default 'Select Payment Method'
         */
        cta: T;

        /**
         * @default 'Cash / Check'
         */
        cashCheck: T;

        /**
         * @default 'Credit Card'
         */
        creditCard: T;
      };
      paperCheckoutInstructions: {
        /**
         * @default 'You'll need the recipient's address to mail your gift:'
         */
        title: T;

        /**
         * @default 'Got it'
         */
        cta: T;
      };
      paperCheckoutInstructionsGiftWrap: {
        /**
         * @default 'Great! Here's how it works:'
         */
        title: T;

        /**
         * @default 'You'll need the couple's address if you're mailing your gift:'
         */
        subtitle: T;

        stepsGiftWrap: {
          /**
           * @default 'Pay for your personalized eCard.'
           */
          step1: T;

          /**
           * @default 'Confirm your gift details and we'll send your eCard.'
           */
          step2: T;

          /**
           * @default 'Send your check gift by mail or bring cash or check with you to the event.'
           */
          step3: T;
        };

        /**
         * @default 'Got it'
         */
        cta: T;
      };
      paperCheckoutConfirmAndNotify: {
        /**
         * @default 'Great!'
         */
        title: T;

        /**
         * @default 'Once you confirm below, we'll let the recipient know you're contributing to this gift.'
         */
        description: T;

        /**
         * @default 'Confirm & Notify recipient'
         */
        cta: T;

        /**
         * @default 'Cancel Gift'
         */
        cancelButton: T;
      };
      externalCheckoutConfirmAndNotify: {
        /**
         * @default 'Did you send your gift with {{platform}}?'
         */
        title: T;

        /**
         * @default 'Did you give to the charity in the recipient's name?'
         */
        titleCharity: T;

        /**
         * @default 'Have you purchased your gift?'
         */
        titleAffiliate: T;

        /**
         * @default 'When you confirm your gift, we'll let the recipient know it's headed their way and mark your gift as purchased.'
         */
        subtitle: T;

        /**
         * @default 'Yes, I did'
         */
        affirmativeButton: T;

        /**
         * @default 'No, I didn't'
         */
        negativeButton: T;

        /**
         * @default 'Not sure what to do?'
         */
        helpText: T;

        /**
         * @default 'Get help'
         */
        helpButton: T;
      };
      cancelGiftIntent: {
        /**
         * @default 'Do you still intend to send this gift?'
         */
        title: T;

        /**
         * @default 'Keep this gift in your cart and send it later, or cancel your reservation.'
         */
        subtitle: T;

        /**
         * @default 'Yes, I'll send it later'
         */
        affirmativeButton: T;

        /**
         * @default 'No, Cancel Gift'
         */
        negativeButton: T;
      };
      cancelGiftWarning: {
        /**
         * @default 'Are you sure you want to cancel this gift?'
         */
        title: T;

        /**
         * @default 'The recipient will no longer see this gift as reserved and it will be available again for others to purchase.'
         */
        subtitle: T;

        /**
         * @default 'Cancel Gift'
         */
        affirmativeButton: T;

        /**
         * @default 'Nevermind'
         */
        negativeButton: T;
      };
      confirmedPurchase: {
        /**
         * @default 'Thank You!'
         */
        title: T;

        /**
         * @default 'We've just let {{host}} know that you sent this gift with {{platform}}.'
         */
        subtitle: T;

        /**
         * @default 'We've just let {{host}} know that you plan to give this gift.'
         */
        subtitleForCashCheckMethod: T;

        /**
         * @default 'All Done'
         */
        affirmativeButton: T;
      };
      cancelGiftConfirmation: {
        /**
         * @default 'Your gift has been cancelled'
         */
        title: T;

        /**
         * @default 'This gift was removed from your shopping cart and is now available for others to buy.'
         */
        subtitle: T;

        /**
         * @default 'Done'
         */
        buttonLabel: T;
      };
      externalCheckoutCopyAddress: {
        /**
         * @default 'You'll need the recipient address to mail your gift:'
         */
        title: T;

        /**
         * @default 'Mailing Address'
         */
        coupleMailingAddress: T;

        /**
         * @default 'Copy'
         */
        copyButton: T;

        /**
         * @default 'Got it'
         */
        cta: T;

        /**
         * @default 'The recipient hasn't indicated a shipping address. Please ask them about where they would like to receive their gifts.'
         */
        missingShippingAddress: T;

        /**
         * @default 'Copied to clipboard'
         */
        copyToClipboard: T;
      };
      creditCardCheckoutError: {
        /**
         * @default 'Oops! An error has ocurred.'
         */
        title: T;

        /**
         * @default 'Please try again later.  If you continue to receive this error, try contacting the owner of this event, or contact Joy support at support@withjoy.com.'
         */
        description: T;

        /**
         * @default 'Close'
         */
        close: T;

        /**
         * @default 'Oops, that didn't work.'
         */
        checkoutErrorTitle: T;

        /**
         * @default 'We're sorry, but there was an error while processing your order. Please try again later or contact our support team at support@withjoy.com for assistance. Thank you for your patience.'
         */
        checkoutErrorText: T;

        /**
         * @default 'Back'
         */
        checkoutErrorAction: T;

        /**
         * @default 'We're sorry, but there seems to be an issue with your address. Please double-check the address in your account settings to ensure everything is correct. If you need further assistance, feel free to reach out to our support team at support@withjoy.com'
         */
        badAddress: T;

        /**
         * @default 'It looks like your transaction is still being verified. Check back soon and thank you for your patience.'
         */
        registryError: T;

        /**
         * @default 'We're sorry, but the item you're looking for is currently out of stock. We're working to replenish our inventory, so please check back later or explore similar options.'
         */
        oos: T;
      };
    };
  };
  sortByOptions: {
    /**
     * @default 'Sort by'
     */
    sortBy: T;

    /**
     * @default 'Featured'
     */
    featured: T;

    /**
     * @default 'Price: Low to High'
     */
    priceLowToHigh: T;

    /**
     * @default 'Price: High to Low'
     */
    priceHighToLow: T;
  };
  orderTrackingDialog: {
    /**
     * @default 'Great! Now add your purchase details.'
     */
    orderTrackingDialogTitle: T;

    /**
     * @default 'Help {{host}} keep track of this gift by providing your Order Number'
     */
    orderTrackingDialogSubTitle: T;

    /**
     * @default 'Enter Order Number'
     */
    orderNumberPlaceholder: T;

    /**
     * @default 'Add Order Number'
     */
    submit: T;

    /**
     * @default 'Add Later'
     */
    addLater: T;

    /**
     * @default 'Please enter an Order Number'
     */
    requiredMessage: T;

    /**
     * @default 'Not sure where to find it? Check your confirmation email from {{retailer}}.'
     */
    orderTrackingDialogDetail: T;

    /**
     * @default 'Didn't purchase this gift?'
     */
    didntPurchase: T;

    /**
     * @default 'Cancel Reservation'
     */
    cancelReservation: T;

    /**
     * @default '✨ Thank you, Order Number submitted ✨'
     */
    successMessage: T;

    /**
     * @default 'Something went wrong, try again later'
     */
    errorMessage: T;

    /**
     * @default '🚫 Your gift reservation has been canceled. 🚫'
     */
    removeSuccess: T;

    /**
     * @default '🎉 This item has been marked as purchased. 🎉'
     */
    purchaseSuccess: T;
  };
  photoMenu: {
    /**
     * @default 'Update Photo'
     */
    updatePhoto: T;

    /**
     * @default 'Delete Photo'
     */
    deletePhoto: T;

    /**
     * @default 'An error occurred uploading your image. Please try again'
     */
    error: T;

    /**
     * @default 'Your image has been updated'
     */
    success: T;
  };
  checkoutGiftCard: {
    /**
     * @default 'Elevate your gift with a personalized eCard'
     */
    title: T;

    /**
     * @default 'Wrap your gift note in a beautiful design.'
     */
    subtitle: T;

    /**
     * @default 'Select Design'
     */
    selectDesign: T;

    /**
     * @default 'Edit Message'
     */
    editMessage: T;

    /**
     * @default 'Unwrap My Gift'
     */
    unwrap: T;

    /**
     * @default 'Add Greeting Card'
     */
    addGreetingCard: T;

    /**
     * @default 'No Thanks'
     */
    noThanks: T;

    /**
     * @default 'Sent with'
     */
    sentWith: T;

    /**
     * @default 'Collapse theme list'
     */
    collapseThemeList: T;

    /**
     * @default 'Expand theme list'
     */
    expandThemeList: T;

    otherGifts: {
      /**
       * @default '... and {{count}} other gift!'
       */
      single: T;

      /**
       * @default '... and {{count}} other gifts!'
       */
      plural: T;
    };

    /**
     * @default 'Next'
     */
    next: T;
  };
  host: {
    /**
     * @default 'the recipient'
     */
    defaultValue: T;
  };
  expressCheckout: {
    shippingAddress: {
      /**
       * @default 'Shipping Method'
       */
      shippingMethod: T;

      /**
       * @default 'Free Shipping'
       */
      freeShipping: T;

      /**
       * @default 'Standard Shipping'
       */
      standardShipping: T;

      /**
       * @default 'Ship to'
       */
      shipTo: T;

      /**
       * @default '🎁'
       */
      giftEmoji: T;

      /**
       * @default 'Joy only ships to U.S. addresses'
       */
      onlyUSAddress: T;

      /**
       * @default 'Ship to me, I’ll give it to them.'
       */
      shipToMe: T;

      /**
       * @default 'You will add a shipping address during payment.'
       */
      shippingAddressDuringPayment: T;
    };

    /**
     * @default 'We’ve written you a nice first draft gift note, please edit it before purchase, or use as-is.'
     */
    giftWrapUneditedMessageWarning: T;

    errorDialog: {
      /**
       * @default 'An error occurred, please try again'
       */
      header: T;

      /**
       * @default 'Error code'
       */
      code: T;

      /**
       * @default 'An unexpected error occurred with your purchase, please check your information and try again. If the problem persists, please '
       */
      defaultMessageBeginning: T;

      /**
       * @default 'contact our support team'
       */
      defaultMessageContactSupport: T;

      /**
       * @default 'Okay'
       */
      okay: T;
    };
  };
};

export type GuestSiteCustomTranslations<T extends LeafNodeValue = TranslateFunction> = {
  emptyState: {
    /**
     * @default 'Stay Tuned!'
     */
    title: T;

    /**
     * @default 'We’re working on something exciting for this space—check back soon.'
     */
    content: T;
  };
  accommodations: {
    /**
     * @default 'Venue'
     */
    venue: T;

    /**
     * @default 'Book before {{date}}'
     */
    beforeDate: T;

    /**
     * @default 'From ${{strikeoutPricePerNight}}'
     */
    fromPrice: T;

    /**
     * @default '{{miles}} miles away'
     */
    milesAway: T;

    /**
     * @default '{{miles}} miles from the venue'
     */
    milesFromVenue: T;

    /**
     * @default '{{interestedGuestCount}} guests interested'
     */
    guestsInterested: T;

    /**
     * @default 'We reserved {{rooms}} rooms'
     */
    reservedRooms: T;

    /**
     * @default 'Per night'
     */
    perNight: T;

    /**
     * @default 'Book Before'
     */
    bookBefore: T;

    /**
     * @default 'Group Rate'
     */
    groupRate: T;

    /**
     * @default 'Our Favorite Hotel'
     */
    favoriteHotel: T;

    /**
     * @default 'Hotel Room Block'
     */
    hotelRoomBlock: T;

    /**
     * @default 'Room Block'
     */
    roomBlockEyebrow: T;

    /**
     * @default 'Risk-free cancellation.'
     */
    riskFreeCancellation: T;

    /**
     * @default 'Select Rooms'
     */
    selectCTARoomBlock: T;

    /**
     * @default 'Select Hotel'
     */
    selectCTAHotel: T;

    /**
     * @default 'Select Property'
     */
    selectCTACustom: T;

    /**
     * @default 'Night'
     */
    night: T;

    /**
     * @default 'More'
     */
    more: T;

    /**
     * @default 'Less'
     */
    less: T;

    /**
     * @default 'guests are interested'
     */
    guestCount: T;

    /**
     * @default 'View More'
     */
    viewMore: T;

    leadGenDialog: {
      /**
       * @default 'Unlock your guest accommodations page'
       */
      title: T;

      /**
       * @default 'Add courtesy room blocks to your site with our free hotel concierge service. We offer discounted hotel rates with zero deposit requirements.'
       */
      content: T;

      /**
       * @default 'Get Started'
       */
      cta: T;
    };

    /**
     * @default 'Explore On Map'
     */
    exploreOnMap: T;

    /**
     * @default 'Exclusive block rate for our guests!'
     */
    exclusiveBlockRate: T;

    /**
     * @default 'Starting from'
     */
    roomsStartingFrom: T;

    /**
     * @default '{{price}} / night'
     */
    pricePerNight: T;

    /**
     * @default 'Exclusive Rate'
     */
    exclusiveRate: T;

    /**
     * @default '{{name}}’s exclusive guest rates are available only on Joy'
     */
    exclusiveRateInfo: T;
  };
  bookingAssistant: {
    /**
     * @default 'Explore Other Options'
     */
    cta: T;
  };
};

export type GuestSiteTravelTranslations<T extends LeafNodeValue = TranslateFunction> = {
  booking: {
    /**
     * @default 'Suggested Stay:'
     */
    suggestedStayDatesTitle: T;

    /**
     * @default 'Search On...'
     */
    bookingSubmitButtonTitle: T;

    /**
     * @default 'Guests'
     */
    guestOptionPlural: T;

    /**
     * @default 'Guest'
     */
    guestOptionSingle: T;

    /**
     * @default 'Check in'
     */
    checkIn: T;

    /**
     * @default 'Check out'
     */
    checkOut: T;
  };
  hertzWidget: {
    /**
     * @default 'For Our Guests: Up to 25% off'
     */
    offerTitle: T;

    /**
     * @default 'Search Rental Cars'
     */
    ctaLabel: T;

    /**
     * @default 'Provided by'
     */
    providedBy: T;
  };
  accommodationLink: {
    /**
     * @default 'For more information on where to stay'
     */
    linkText: T;

    /**
     * @default 'More'
     */
    readMore: T;

    /**
     * @default 'Less'
     */
    readLess: T;
  };
};

export type GuestSiteWelcomeTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'View Details'
   */
  viewDetails: T;
};

export type GuestUnlockTranslations<T extends LeafNodeValue = TranslateFunction> = {
  unlockForm: {
    /**
     * @default 'Let’s find your invitation...'
     */
    title: T;

    /**
     * @default 'Enter the password you received from your host.'
     */
    subtitle: T;

    /**
     * @default 'Password'
     */
    passwordLabel: T;

    /**
     * @default 'e.g. abc123'
     */
    passwordPlaceholder: T;

    /**
     * @default 'Unlock'
     */
    submitButtonText: T;

    /**
     * @default 'Ask the Host'
     */
    contactButtonText: T;

    /**
     * @default 'Sign In'
     */
    signInButtonText: T;

    /**
     * @default 'Incorrect password. Please check that you typed the password correctly or try copying and pasting.'
     */
    incorrectPassText: T;

    /**
     * @default 'Password is required'
     */
    required: T;
  };
  findInviteForm: {
    /**
     * @default 'Let’s find your invitation'
     */
    title: T;

    /**
     * @default 'Enter your name to get your event schedule.'
     */
    subtitle: T;

    /**
     * @default 'First name'
     */
    firstNameLabel: T;

    /**
     * @default 'Last name'
     */
    lastNameLabel: T;

    /**
     * @default 'e.g. Sarah'
     */
    firstNamePlaceholder: T;

    /**
     * @default 'e.g. Smith'
     */
    lastNamePlaceholder: T;

    /**
     * @default 'Find Invitation'
     */
    submitButtonText: T;

    /**
     * @default 'Searching...'
     */
    loadingButtonText: T;

    /**
     * @default '{{firstName}} {{lastName}} is not on the guest list. Try an alternative spelling or nickname. You can still view the public schedule.'
     */
    notFoundErrorText: T;

    /**
     * @default 'All fields are required'
     */
    required: T;
  };
  verifyEmailForm: {
    /**
     * @default 'Enter your email'
     */
    title: T;

    /**
     * @default 'We found multiple guests with the name {{firstName}} {{lastName}}. Please provide your email to confirm your identity.'
     */
    subtitle: T;

    /**
     * @default 'Email (Only visible to the host)'
     */
    emailLabel: T;

    /**
     * @default 'Find Invitation'
     */
    submitButtonText: T;

    /**
     * @default 'Searching...'
     */
    loadingButtonText: T;

    /**
     * @default 'We couldn’t find {{firstName}} {{lastName}} with that email on the guest list. Please try another email address.'
     */
    notFoundErrorText: T;

    /**
     * @default 'Email is required'
     */
    required: T;

    /**
     * @default 'e.g. sarahsmith@example.com'
     */
    inputPlaceholder: T;
  };
  findInviteSuccessForm: {
    /**
     * @default 'Welcome {{firstName}}!'
     */
    title: T;

    /**
     * @default 'You’ve unlocked guest-only details.'
     */
    subtitle: T;

    /**
     * @default 'Explore the Event'
     */
    submitButtonText: T;
  };
  askHostForm: {
    /**
     * @default 'Ask the host'
     */
    title: T;

    /**
     * @default 'Tell the host who you are, so they can email you back with their event password.'
     */
    subtitle: T;

    /**
     * @default 'Your Full Name'
     */
    fullNameLabel: T;

    /**
     * @default 'Your Email'
     */
    emailLabel: T;

    /**
     * @default 'e.g. Sarah Smith'
     */
    namePlaceholder: T;

    /**
     * @default 'e.g. sarahsmith@example.com'
     */
    emailPlaceholder: T;

    /**
     * @default 'Send Request'
     */
    submitButtonText: T;

    /**
     * @default 'Sending...'
     */
    loadingButtonText: T;

    /**
     * @default 'All fields are required'
     */
    required: T;

    /**
     * @default 'Please enter a valid email'
     */
    validEmailWarning: T;
  };
  askHostSuccessForm: {
    /**
     * @default 'Your message has been sent'
     */
    title: T;

    /**
     * @default 'We’ve sent the host an email letting them know you’ve requested their event password.'
     */
    subtitle: T;

    /**
     * @default 'Return to Site'
     */
    submitButtonText: T;
  };
};

export type HeaderHelpMenuTranslations<T extends LeafNodeValue = TranslateFunction> = {
  menuItems: {
    /**
     * @default 'Leave a Suggestion'
     */
    suggestion: T;

    /**
     * @default 'Contact Support'
     */
    support: T;

    /**
     * @default 'Help Guides'
     */
    help: T;
  };
};

export type HotelBlockTranslations<T extends LeafNodeValue = TranslateFunction> = {
  leadGenerationForm: {
    /**
     * @default 'Destination'
     */
    destination: T;

    /**
     * @default 'Check in'
     */
    checkIn: T;

    /**
     * @default 'Check out'
     */
    checkOut: T;

    /**
     * @default 'Rooms Per Night'
     */
    roomsPerNight: T;

    /**
     * @default 'Full Name'
     */
    fullName: T;

    /**
     * @default 'Email Address'
     */
    email: T;

    /**
     * @default 'Who should we contact?'
     */
    sendQuotes: T;

    /**
     * @default 'Continue'
     */
    cont: T;

    /**
     * @default 'Your full name will help us know who to contact.'
     */
    fullNameError: T;

    /**
     * @default 'Please share your email address.'
     */
    emailError: T;

    /**
     * @default 'Please select the number of rooms per night.'
     */
    numberRoomsError: T;

    /**
     * @default 'Search for a city or venue for your event.'
     */
    locationError: T;

    /**
     * @default 'Add a check in date.'
     */
    checkInDateError: T;

    /**
     * @default 'Add a check out date.'
     */
    checkOutDateError: T;

    /**
     * @default 'Check in date must be before check out date'
     */
    checkInDateRangeError: T;

    /**
     * @default 'Check out date must be after check in date'
     */
    checkOutDateRangeError: T;

    /**
     * @default 'Search for a city or venue'
     */
    locationPlaceholder: T;

    /**
     * @default 'Add a date'
     */
    datePlaceholder: T;

    /**
     * @default 'Choose your best estimate'
     */
    numberRoomsPlaceholder: T;

    /**
     * @default 'Almost Done!'
     */
    almostDone: T;

    /**
     * @default 'Get the best hotel rates by answering a few more optional questions below.'
     */
    additionalInfoTitle: T;

    /**
     * @default 'Any additional details we should know?'
     */
    additionalInfoSubtext: T;

    /**
     * @default 'You can tell us about hotel brand preferences, any special needs, or even specific neighborhoods.'
     */
    notePlaceholder: T;

    /**
     * @default 'How would you like to be contacted?'
     */
    howContacted: T;

    /**
     * @default 'Your phone number is kept confidential and not shared with other parties. SMS data rates apply.'
     */
    confidential: T;

    /**
     * @default 'Phone Number'
     */
    phoneLabel: T;

    /**
     * @default 'Interested in scheduling a call with our concierge team?'
     */
    scheduleCall: T;

    /**
     * @default 'Select a date and time'
     */
    scheduleCallCTA: T;

    /**
     * @default 'Concierge Call Confirmed'
     */
    callConfirmed: T;

    /**
     * @default 'Finish'
     */
    finishCTA: T;

    /**
     * @default 'All Set!'
     */
    allSet: T;

    /**
     * @default 'A member of our concierge team will contact you.'
     */
    allSetText: T;

    /**
     * @default 'An error occurred while submitting your request. Please try again.'
     */
    submitError: T;

    /**
     * @default 'Please share your cell phone number.'
     */
    smsRequiredError: T;

    /**
     * @default 'Please select a convenient time slot.'
     */
    timeSlotRequiredError: T;
  };
  smsGenerationForm: {
    /**
     * @default 'Success!'
     */
    successTitle: T;

    /**
     * @default 'A member of our concierge team will text you within 48 hours.'
     */
    successDescription: T;

    /**
     * @default 'Unlock group hotel rates delivered right to your phone.'
     */
    title: T;

    /**
     * @default 'Our concierge team will text you within 48 hours.'
     */
    description: T;

    /**
     * @default 'Phone Number'
     */
    inputPhoneLabel: T;

    /**
     * @default 'Text Me'
     */
    buttonConfirm: T;

    /**
     * @default 'Your phone number is kept confidential and not shared with other parties.'
     */
    buttonConfirmLabel: T;

    /**
     * @default 'Or share your hotel preferences now and get started.'
     */
    labelForm: T;

    /**
     * @default 'Get Started Now'
     */
    buttonForm: T;

    /**
     * @default 'Please share your cell phone number.'
     */
    inputRequired: T;
  };
  hotelBookingSubmissionSuccess: {
    /**
     * @default 'Success!'
     */
    successTitle: T;

    /**
     * @default 'A member of our concierge team will get back to you.'
     */
    successDescription: T;
  };
  hotelSelection: {
    informativeDialog: {
      /**
       * @default 'What happens next?'
       */
      title: T;

      /**
       * @default 'Select hotels near your venue and let our concierge team do the work of negotiating discounted rates.'
       */
      item1: T;

      /**
       * @default 'Review your personalized hotel group rates with your personal concierge and select your favorite hotel for your guests.'
       */
      item2: T;

      /**
       * @default 'View Hotels'
       */
      btn: T;
    };
    addManuallyHotelDialog: {
      /**
       * @default 'Manually add hotel'
       */
      title: T;

      /**
       * @default 'Hotel'
       */
      inputHotelTitle: T;

      /**
       * @default 'Find Hotel'
       */
      inputHotelPlaceholder: T;

      /**
       * @default 'URL'
       */
      inputHotelLocationTitle: T;

      /**
       * @default 'company.com'
       */
      inputHotelLocationPlaceholder: T;

      /**
       * @default 'Add Hotel'
       */
      btn: T;
    };
    confirmationDialog: {
      /**
       * @default 'Are you sure you want to continue?'
       */
      title: T;

      /**
       * @default 'You can add up to five hotels to your group rate request.'
       */
      subtitle: T;

      /**
       * @default 'Confirm Hotels'
       */
      btn: T;
    };
    list: {
      search: {
        /**
         * @default 'Venue, City or Hotel'
         */
        location: T;

        /**
         * @default 'Near'
         */
        near: T;

        /**
         * @default '{{roomBlocks}} Room Blocks'
         */
        roomBlocks: T;

        /**
         * @default 'Destination or Hotel'
         */
        locationV2: T;

        /**
         * @default 'Check in'
         */
        checkIn: T;

        /**
         * @default 'Check out'
         */
        checkOut: T;

        /**
         * @default 'Search for a city or venue for your event.'
         */
        locationError: T;

        /**
         * @default 'Add a check in date.'
         */
        checkInDateError: T;

        /**
         * @default 'Add a check out date.'
         */
        checkOutDateError: T;

        /**
         * @default 'Check in date must be before check out date'
         */
        checkInDateRangeError: T;

        /**
         * @default 'Check out date must be after check in date'
         */
        checkOutDateRangeError: T;

        /**
         * @default 'San Fransisco City hall'
         */
        locationPlaceholder: T;

        /**
         * @default 'Date'
         */
        datePlaceholder: T;

        /**
         * @default 'Search'
         */
        searchCTA: T;
      };
      hotelList: {
        /**
         * @default 'Select Your Hotels'
         */
        selectHotels: T;

        /**
         * @default 'Show More'
         */
        showMore: T;

        /**
         * @default 'Top Picks'
         */
        filterPopularity: T;

        /**
         * @default 'Distance'
         */
        filterDistance: T;
      };
      hotelItem: {
        /**
         * @default 'Add Hotel'
         */
        addHotelTxt: T;

        /**
         * @default 'miles away'
         */
        distance: T;

        /**
         * @default 'Remove'
         */
        removeHotelTxt: T;

        /**
         * @default 'Interested Property'
         */
        interestedLabel: T;

        /**
         * @default 'Couple Favorite'
         */
        preferredHotelTooltipTitle: T;

        /**
         * @default 'Get a room block with one of Joy's partner hotels and get the best group rates fast. We hand picked these properties based on reviews from other Joy couples, competitive rates, and a great fit for larger groups.'
         */
        preferredHotelTooltipDescription: T;

        /**
         * @default 'Couple Favorite'
         */
        preferredHotelMobileViewLabel: T;
      };
      hotelCart: {
        /**
         * @default 'Confirm Hotels'
         */
        btnConfirm: T;

        /**
         * @default 'Your Hotels'
         */
        yourHotels: T;

        /**
         * @default '{{hotelsCount}} of {{maxHotels}}'
         */
        hotelsCountLabel: T;

        /**
         * @default 'Add your first hotel!'
         */
        noHotels: T;

        /**
         * @default 'You can add a maximum of 5 hotels.'
         */
        onlyFive: T;
      };
      addManuallyBanner: {
        /**
         * @default 'Manually Add Hotel'
         */
        addHotelManually: T;

        /**
         * @default 'Not finding what you’re looking for?'
         */
        label: T;
      };
      noResults: {
        /**
         * @default 'Sorry, no results found.'
         */
        label: T;

        /**
         * @default 'Update your search area to see hotel options.'
         */
        eyeBrow: T;
      };
      noItem: {
        /**
         * @default 'Not finding what you’re looking for?'
         */
        label: T;

        /**
         * @default 'Manually Add Hotel'
         */
        manualAddCTA: T;

        /**
         * @default 'Contact Concierge Team'
         */
        buttonCTA: T;

        /**
         * @default 'Chat'
         */
        intercomButtonCTA: T;
      };
      addManuallyDialog: {
        /**
         * @default 'Manually add hotel'
         */
        title: T;

        /**
         * @default 'Search for hotels'
         */
        label: T;

        /**
         * @default 'Hotel Name'
         */
        placeholder: T;

        /**
         * @default 'Add Hotel'
         */
        addHotelCTA: T;

        /**
         * @default 'Remove Hotel'
         */
        removeHotelCTA: T;
      };
    };
  };
  hotelConfirmation: {
    /**
     * @default 'Confirm and Request Rates'
     */
    title: T;

    /**
     * @default 'Review and request rates'
     */
    titleV2: T;

    /**
     * @default 'Request Hotel Rates'
     */
    buttonCTA: T;

    /**
     * @default 'City or Venue'
     */
    location: T;

    /**
     * @default 'Hotel Search Area'
     */
    hotelSearchArea: T;

    personalDetailSection: {
      /**
       * @default 'Personal Details'
       */
      header: T;

      /**
       * @default 'Partner One'
       */
      partnerOneHeader: T;

      /**
       * @default 'Partner Two'
       */
      partnerTwoHeader: T;

      /**
       * @default 'First Name'
       */
      firstNamePlaceholder: T;

      /**
       * @default 'Last Name'
       */
      lastNamePlaceholder: T;
    };

    /**
     * @default 'Event Name'
     */
    requestName: T;

    /**
     * @default 'your@email.com'
     */
    emailPlaceholder: T;

    /**
     * @default 'Dates'
     */
    dates: T;

    /**
     * @default 'Add a date'
     */
    datePlaceholder: T;

    /**
     * @default 'Check in'
     */
    checkIn: T;

    /**
     * @default 'Check out'
     */
    checkOut: T;

    /**
     * @default 'We don’t have a venue (yet)'
     */
    venueNotSelectedCheckBoxText: T;

    /**
     * @default 'Venue Name'
     */
    venueNamePlaceholder: T;

    /**
     * @default 'Address'
     */
    venueAddressPlaceholder: T;

    /**
     * @default 'Venue Address'
     */
    venueAddressTitle: T;

    /**
     * @default 'Please enter a venue address to help us find your properties.'
     */
    venueAddressFieldError: T;

    /**
     * @default 'Please enter a location to help us find your properties.'
     */
    hotelSearchAreaFieldError: T;

    /**
     * @default 'Please share your venue address to help us locate the most relevant properties for your guests.'
     */
    venueAddressTooltipText: T;

    /**
     * @default 'Search for a city or venue for your event.'
     */
    locationError: T;

    /**
     * @default 'Add a check in date.'
     */
    checkInDateError: T;

    /**
     * @default 'Add a check out date.'
     */
    checkOutDateError: T;

    /**
     * @default 'Check in date must be before check out date'
     */
    checkInDateRangeError: T;

    /**
     * @default 'Check out date must be after check in date'
     */
    checkOutDateRangeError: T;

    /**
     * @default 'Search for a city or venue'
     */
    locationPlaceholder: T;

    /**
     * @default 'Additional Notes'
     */
    additionalNotes: T;

    /**
     * @default 'You can tell us about hotel brand preferences, any special needs, or even specific neighborhoods you like.'
     */
    additionalNotesText: T;

    /**
     * @default 'Your Contact Details'
     */
    contact: T;

    /**
     * @default 'Contact Preference'
     */
    contactPreference: T;

    /**
     * @default 'Please enter a valid phone number.'
     */
    invalidPhoneNumberError: T;

    /**
     * @default 'How would you like us to contact you?'
     */
    contactYou: T;

    /**
     * @default 'How would you like to receive your rates?'
     */
    howWouldYouLikeToRecieveRates: T;

    /**
     * @default 'Please select a convenient time slot.'
     */
    contactError: T;

    /**
     * @default 'Please provdie a phone number.'
     */
    contactPhoneError: T;

    /**
     * @default 'Phone Number'
     */
    phoneNumber: T;

    /**
     * @default 'Please select the number of rooms per night.'
     */
    numberRoomsError: T;

    /**
     * @default 'Choose your best estimate'
     */
    numberRoomsPlaceholder: T;

    /**
     * @default 'Mininum is 10'
     */
    minNumberRoomsError: T;

    /**
     * @default 'Maximum is 999'
     */
    maxNumberRoomsError: T;

    /**
     * @default 'Must be a number'
     */
    mustBeNumberRoomsError: T;

    /**
     * @default 'More than 50'
     */
    moreThanFifty: T;

    /**
     * @default 'Estimated Rooms Per Night'
     */
    roomsPerNight: T;

    /**
     * @default 'You can change this later.'
     */
    roomsPerNightSubtitle: T;

    /**
     * @default 'Schedule a call'
     */
    scheduleCall: T;

    /**
     * @default 'Text Message'
     */
    textMessage: T;

    /**
     * @default 'Concierge Call Confirmed'
     */
    callConfirmed: T;

    /**
     * @default 'Phone Number'
     */
    phoneLabel: T;

    /**
     * @default 'Choose a slot that suits you.'
     */
    timeSlotLabel: T;

    /**
     * @default 'Select a date and time'
     */
    scheduleCallCTA: T;

    /**
     * @default 'Please add a name for your event.'
     */
    eventNameError: T;

    /**
     * @default 'Please add a first name for partner one.'
     */
    partnerOneFirstNameError: T;

    /**
     * @default 'Please add a last name for partner one.'
     */
    partnerOneLastNameError: T;

    /**
     * @default 'Please add a first name for partner two.'
     */
    partnerTwoFirstNameError: T;

    /**
     * @default 'Please add a last name for partner two.'
     */
    partnerTwoLastNameError: T;

    /**
     * @default 'Your phone number is kept confidential and not shared with other parties. SMS data rates apply.'
     */
    confidential: T;

    /**
     * @default 'Your Hotels'
     */
    yourHotels: T;

    /**
     * @default 'Edit'
     */
    changeCTA: T;

    /**
     * @default 'An error occurred while submitting your request. Please try again.'
     */
    submitError: T;

    /**
     * @default 'An error occurred while submitting your request. Please refresh the page and try again'
     */
    authError: T;

    /**
     * @default 'Please share your cell phone number.'
     */
    smsRequiredError: T;

    /**
     * @default 'Please select a convenient time slot.'
     */
    timeSlotRequiredError: T;

    /**
     * @default 'Remove'
     */
    remove: T;

    /**
     * @default 'We suggest considering both out-of-town and in-town guests when calculating the number of rooms needed per night.'
     */
    tooltipText: T;

    /**
     * @default 'Please share a location to help us locate the most relevant properties for your guests.'
     */
    hotelSearchAreaTooltipText: T;

    /**
     * @default 'You need at least one accommodation to submit your rate request.'
     */
    minimumShortlistError: T;

    /**
     * @default 'Email'
     */
    contactViaEmail: T;

    /**
     * @default 'Email Address'
     */
    emailAddress: T;

    /**
     * @default 'Please share your email address.'
     */
    emailError: T;

    /**
     * @default 'Name'
     */
    fullName: T;

    /**
     * @default 'John Smith'
     */
    fullNamePlaceholder: T;

    /**
     * @default 'Your full name will help us know who to contact.'
     */
    fullNameError: T;

    informativeBox: {
      /**
       * @default 'You're just a step away from our free hotel concierge service!'
       */
      title: T;

      /**
       * @default 'Joy concierge does the work so you don’t have to.'
       */
      item1: T;

      /**
       * @default 'Exclusively negotiated discounts up to 30% off.'
       */
      item2: T;

      /**
       * @default 'Hotel perks and upgrades.'
       */
      item3: T;
    };
  };
  hotelAdmin: {
    /**
     * @default 'Room Blocks'
     */
    menu: T;

    /**
     * @default 'Request Submitted!'
     */
    title: T;

    /**
     * @default 'Our concierge team is working with our hotel partners to secure the best group rates possible. We'll contact you via your preferred channel once we have all the details. 🌟'
     */
    subTitle: T;

    /**
     * @default 'Event Name'
     */
    eventName: T;

    /**
     * @default 'City or Venue'
     */
    location: T;

    /**
     * @default 'Hotel Dates'
     */
    hotelDates: T;

    /**
     * @default 'Rooms Requested'
     */
    roomsRequested: T;

    /**
     * @default 'Additional Details'
     */
    additionalDetails: T;

    /**
     * @default 'Need Help? Contact Our Team'
     */
    helpText: T;

    /**
     * @default 'Email Us'
     */
    emailUsCta: T;

    /**
     * @default 'Chat Now'
     */
    chatNowCta: T;
  };
  hotelRoomBlockFunnel: {
    /**
     * @default 'Room Blocks'
     */
    menu: T;

    /**
     * @default 'Email'
     */
    email: T;

    /**
     * @default 'Event Name'
     */
    eventName: T;

    /**
     * @default 'City or Venue'
     */
    location: T;

    /**
     * @default 'Hotel Dates'
     */
    hotelDates: T;

    /**
     * @default 'Chat with us'
     */
    headerChat: T;

    /**
     * @default 'Chat'
     */
    headerChatMobile: T;

    /**
     * @default 'Rooms Requested'
     */
    roomsRequested: T;

    /**
     * @default 'Additional Details'
     */
    additionalDetails: T;

    /**
     * @default 'We’re working on getting you the best deals.'
     */
    subTitleV2: T;

    /**
     * @default 'Rates are coming in slower than expected but rest assure they’re coming.'
     */
    slowProgressInfoMessage: T;

    /**
     * @default 'Need Changes Made?'
     */
    conciergeMemberDetailsCardHeader: T;

    /**
     * @default '🙌 At your service!'
     */
    atYourService: T;

    /**
     * @default 'Joy concierge team is here to assist you'
     */
    conciergeMemberNotAvailableCardText: T;

    /**
     * @default 'Chat with Concierge'
     */
    chatWithConcierge: T;

    heading: {
      /**
       * @default 'Your search has begun!'
       */
      workingOnRequest: T;

      /**
       * @default 'We’re reaching out to your hotels.'
       */
      gettingTheBestRates: T;

      /**
       * @default 'Success! We got great rates!'
       */
      ratesReadyForReview: T;

      /**
       * @default 'Your hotel are almost ready.'
       */
      finalizingHotelDeals: T;
    };
    info: {
      /**
       * @default 'We’re assigning your hotel requests to one of our concierge to help you throughout this process. Leave it up to us!'
       */
      workingOnRequest: T;

      /**
       * @default 'We’re working on getting you the best deals. The typical request takes 6 days. We’ll contact you when your rates are ready.'
       */
      gettingTheBestRates: T;

      /**
       * @default 'We’ve received hotel offers and they’re ready for you to review.'
       */
      ratesReadyForReview: T;

      /**
       * @default 'We’re crossing t’s and dotting i’s to make sure your deals are locked in. Your guests will soon have the best deals.'
       */
      finalizingHotelDeals: T;
    };
    progressBarStates: {
      /**
       * @default 'Working on'
       */
      workingOnRequestPart1: T;

      /**
       * @default 'request'
       */
      workingOnRequestPart2: T;

      /**
       * @default 'Getting the'
       */
      gettingTheBestRatesPart1: T;

      /**
       * @default 'Best Rates'
       */
      gettingTheBestRatesPart2: T;

      /**
       * @default 'Rates Ready'
       */
      ratesReadyForReviewPart1: T;

      /**
       * @default 'for Review'
       */
      ratesReadyForReviewPart2: T;

      /**
       * @default 'Finalizing'
       */
      finalizingHotelDetailsPart1: T;

      /**
       * @default 'Hotels Deals'
       */
      finalizingHotelDetailspart2: T;

      /**
       * @default 'Time to'
       */
      timeToPartyParty1: T;

      /**
       * @default 'Party!'
       */
      timeToPartyParty2: T;
    };
  };
  headerSection: {
    /**
     * @default 'Hotels'
     */
    headerTitle: T;

    /**
     * @default 'Help'
     */
    help: T;
  };
  hotelBlockV4: {
    /**
     * @default 'Back'
     */
    back: T;

    /**
     * @default 'Hotels'
     */
    headerTitle: T;

    /**
     * @default 'Chat with us'
     */
    headerChat: T;

    /**
     * @default 'Chat'
     */
    headerChatMobile: T;

    /**
     * @default 'Request discounted rates for hotels near your venue.'
     */
    headerSubtitle: T;

    /**
     * @default 'Find Your Perfect Wedding Stay'
     */
    header: T;

    /**
     * @default 'We unlock exclusive rates by bringing together the bargaining power of all Joy couples.'
     */
    headerSubtitleV2: T;

    /**
     * @default 'Potential Hotels Near Your Destination'
     */
    title: T;

    /**
     * @default 'Load More Hotels'
     */
    btnLoadMore: T;

    /**
     * @default 'Introducing Group Reservations with Joy'
     */
    valuePropHeaderEyeBrow: T;

    /**
     * @default 'Take care of your guests with great rates and no fees.'
     */
    valuePropHeaderTitle: T;

    /**
     * @default 'Your personal Joy concierge agent will help you get the best room block rates for you and your guests.'
     */
    valuePropHeaderSubtitle: T;

    /**
     * @default 'Save up to 30% Off Room Rates'
     */
    valuePropOneTitle: T;

    /**
     * @default 'Zero Deposit Requirements'
     */
    valuePropTwoTitle: T;

    /**
     * @default 'Dedicated Concierge'
     */
    valuePropThreeTitle: T;

    /**
     * @default 'Good to Know'
     */
    remindMeHeaderEyeBrow: T;

    /**
     * @default '86% of Joy couples share hotels with their guests.'
     */
    remindMeHeaderTitle: T;

    /**
     * @default 'When do you wish to begin selecting your hotels?'
     */
    remindMeTitle: T;

    /**
     * @default 'We’ll help you get started with your hotel search on time.'
     */
    remindMeSubtitle: T;

    /**
     * @default 'Remind me in 2 weeks.'
     */
    remindMeOpt1: T;

    /**
     * @default 'Remind me in 4 weeks'
     */
    remindMeOpt2: T;

    /**
     * @default 'Remind me in 8 weeks'
     */
    remindMeOpt3: T;

    /**
     * @default 'Set a Reminder'
     */
    remindMeBtn: T;

    /**
     * @default 'An error occurred while submitting your request. Please try again.'
     */
    submitError: T;

    /**
     * @default 'An error occurred while submitting your request. Please refresh the page and try again.'
     */
    authError: T;

    /**
     * @default 'Thank You!'
     */
    reminderThankYouTitle: T;

    /**
     * @default 'your email address'
     */
    reminderThankYouEmailText: T;

    /**
     * @default 'We’ll send you an email reminder at <Email/> in {{reminderSelected}}'
     */
    reminderThankYouMessage: T;
  };
  hotelShortlistingFlowSelection: {
    /**
     * @default 'Hotels'
     */
    menu: T;

    /**
     * @default 'Chat with us'
     */
    headerChat: T;

    /**
     * @default 'Chat'
     */
    headerChatMobile: T;

    /**
     * @default 'Tailor Your Hotel Choices'
     */
    title: T;

    /**
     * @default 'You can request discounted rates through our free concierge service or simply add hotels without discounts'
     */
    subTitle: T;

    /**
     * @default 'Request discounted rates through our free concierge service or directly add the hotels to your website.'
     */
    subTitleV2: T;

    /**
     * @default 'Most Popular'
     */
    popularCardTitleLabel: T;

    /**
     * @default 'Back'
     */
    titleBack: T;

    /**
     * @default 'An error occurred while adding your hotels to your website.'
     */
    hotelAdditioinErrorMessage: T;

    withoutDiscountCard: {
      /**
       * @default 'Hotels Only'
       */
      title: T;

      /**
       * @default 'Your selected hotels will be automatically added to your website.'
       */
      subTitle: T;

      /**
       * @default 'Add Hotels Only'
       */
      cta: T;
    };
    withDiscountCard: {
      /**
       * @default 'Hotel Room Blocks'
       */
      title: T;

      /**
       * @default 'Concierge will secure exclusive block rates at no cost. Hotels will be added to your website.'
       */
      subTitle: T;

      /**
       * @default 'Add Hotels With Room Blocks'
       */
      cta: T;

      /**
       * @default 'What is a room block'
       */
      footerCtaText: T;

      /**
       * @default 'A room block is a reservation of a grouping of rooms (typically 10 or more) that are usually at the same hotel.'
       */
      footerCtaHoverText: T;
    };
    cardPoints: {
      makeItEasyPoint: {
        /**
         * @default 'Make it easy'
         */
        title: T;

        /**
         * @default 'Share hotel options with guests on your website.'
         */
        description: T;
      };
      riskFreePoint: {
        /**
         * @default 'Risk-Free'
         */
        title: T;

        /**
         * @default 'No financial commitment required.'
         */
        description: T;
      };
      perksAndDiscountsPoint: {
        /**
         * @default 'Discounts & Perks'
         */
        title: T;

        /**
         * @default 'Get up to 30% off market rates and enjoy hotel upgrades.'
         */
        description: T;
      };
      personalizedConciergePoint: {
        /**
         * @default 'Free Concierge'
         */
        title: T;

        /**
         * @default 'We negotiate exclusive group booking rates so you don’t have to.'
         */
        description: T;
      };
    };
  };
  hotelShortlistingFlowReviewRequestAccommodations: {
    /**
     * @default 'Back'
     */
    titleBack: T;

    /**
     * @default 'We’ve added these hotels to your accommodations page!'
     */
    infoTextAccommodations: T;

    /**
     * @default 'Edit Accommodations Page'
     */
    editAccommodationsPageCTAText: T;

    /**
     * @default 'View Accommodations Page'
     */
    viewAccommodationsPageCTAText: T;
  };
  roomBlockManager: {
    /**
     * @default 'Your Room Blocks'
     */
    title: T;

    /**
     * @default 'Your room blocks are ready'
     */
    titleV2: T;

    /**
     * @default 'Share your booking link with guests. Help them get the best rates before they expire.'
     */
    subTitle: T;

    /**
     * @default 'Share your booking link with guests to ensure they get the best rates before they expire.'
     */
    subTitleV2: T;

    /**
     * @default 'Rate expires {{date}}'
     */
    rateExpires: T;

    /**
     * @default '{{rooms}} Rooms'
     */
    rooms: T;

    /**
     * @default 'night'
     */
    night: T;

    /**
     * @default 'Share Booking Link'
     */
    shareBookingLink: T;

    /**
     * @default 'Copy Hotel Booking Link'
     */
    copyBookingLink: T;

    /**
     * @default 'Need help or have questions?'
     */
    helpText: T;

    /**
     * @default 'Chat With Us'
     */
    chatWithUs: T;

    /**
     * @default '✨ Link Copied ✨'
     */
    copied: T;

    /**
     * @default '{{miles}} miles from the venue'
     */
    milesFromVenue: T;

    errorState: {
      /**
       * @default 'Something went wrong. We're unable to display your reserved room blocks right now.'
       */
      title: T;

      /**
       * @default 'You can still copy your hotels page link below and share with your guests.'
       */
      subTitle: T;

      /**
       * @default 'Copy Link'
       */
      copyLink: T;
    };

    /**
     * @default 'Room Block Cancelled'
     */
    roomBlockCancelled: T;

    contactCollector: {
      /**
       * @default 'Smart Tip'
       */
      smartTip: T;

      /**
       * @default 'Gather contacts and share your room block.'
       */
      title: T;

      /**
       * @default 'Easily gather guest info and share your room block with everyone on your list.'
       */
      text: T;

      /**
       * @default 'Gather Contacts'
       */
      cta: T;
    };
    progressBar: {
      roomsBooked: {
        /**
         * @default '{{numberRoomsGuestBooked}} Room Booked'
         */
        singular: T;

        /**
         * @default '{{numberRoomsGuestBooked}} Rooms Booked'
         */
        plural: T;
      };

      /**
       * @default '{{totalRoomsBooked}} Total'
       */
      totalRoomsBooked: T;
    };
  };
  roomBlockManagerIncentives: {
    /**
     * @default 'Unlock Joy perks when your guests book'
     */
    title: T;

    /**
     * @default 'Speak with your Joy concierge to unlock perks as you reach your booking milestones.'
     */
    subTitle: T;

    /**
     * @default 'Level 1: 40% Nights Filled  '
     */
    titleTierOne: T;

    /**
     * @default 'Claim one of the Joy perks below when your guests book 40% of your room block.'
     */
    subtitleTierOne: T;

    /**
     * @default 'Discounts on printed invitations and save the dates.'
     */
    subtitleTierOneNoRegistry: T;

    /**
     * @default '40% Discount on Printed Cards'
     */
    discountPrint: T;

    /**
     * @default '40% Off Printed Cards'
     */
    discountPrintV2: T;

    /**
     * @default '$50 Registry Gift Card'
     */
    registryGiftCard: T;

    /**
     * @default 'Learn More'
     */
    cta: T;

    /**
     * @default 'Level 2: 60% Nights Filled'
     */
    titleTierTwo: T;

    /**
     * @default 'Earn points towards your next reservation at participating US properties.'
     */
    subtitleTierTwo: T;

    /**
     * @default 'Available for room blocks at Marriott, Hilton, IHG, or Wyndham properties in the United States.'
     */
    tierTwoTooltip: T;

    /**
     * @default 'More Info'
     */
    moreInfo: T;

    /**
     * @default '15,000 Hotel Points'
     */
    hotelPoints: T;

    /**
     * @default 'Let your guests know about your room block:'
     */
    titleNextSteps: T;

    /**
     * @default 'Share your room block with your guests:'
     */
    titleNextStepsV2: T;

    /**
     * @default 'Add a note in your invitation or save the date'
     */
    nextStepOne: T;

    /**
     * @default 'Send a message to your guests'
     */
    nextStepTwo: T;

    /**
     * @default 'Add an announcement note on your website'
     */
    nextStepThree: T;

    /**
     * @default 'Nights Filled:'
     */
    titleV2: T;

    /**
     * @default 'Milestones:'
     */
    milestones: T;

    /**
     * @default 'Perk 1'
     */
    perkOne: T;

    /**
     * @default 'Perk 2'
     */
    perkTwo: T;

    /**
     * @default 'Learn more about Joy room block perks'
     */
    subtitleV2: T;

    /**
     * @default 'Room Block Perks'
     */
    subtitleV2Mobile: T;

    /**
     * @default 'Room Block Perks'
     */
    subtitleNoUtilData: T;

    /**
     * @default 'Perk 1: 40% Nights Filled'
     */
    titlePerkOne: T;

    /**
     * @default 'Send thank you notes and invitations to your guests.'
     */
    subtitlePerkOne: T;

    /**
     * @default 'Perk 2: 60% Nights Filled'
     */
    titlePerkTwo: T;

    /**
     * @default 'Claim one of the Joy perks below.'
     */
    subtitlePerkTwo: T;

    /**
     * @default 'Claim Perk'
     */
    claimPerkCta: T;

    printPerkDialog: {
      /**
       * @default 'You’ve unlocked {{percentageDiscount}}% off all printed cards from Joy.'
       */
      title: T;

      /**
       * @default 'We’re unable to retrieve your promo code at this time.'
       */
      titleError: T;

      /**
       * @default 'Excludes concierge premium prints.'
       */
      subtitle: T;

      /**
       * @default 'Please contact our concierge team if your continue to experience this issue.'
       */
      subtitleError: T;

      /**
       * @default 'Use this promo code at checkout:'
       */
      promoCodeHeader: T;

      /**
       * @default 'View Printed Cards'
       */
      cta: T;

      /**
       * @default 'Contact Concierge'
       */
      ctaError: T;

      /**
       * @default 'Cancel'
       */
      cancel: T;
    };
    registryPerkDialog: {
      /**
       * @default 'You’ve unlocked ${{dollarDiscount}} off a registry gift from Joy.'
       */
      title: T;

      /**
       * @default 'We’re unable to retrieve your promo code at this time.'
       */
      titleError: T;

      /**
       * @default 'Promo code is valid on orders of $75 or more, after promotions and discounts are applied. Excludes gift cards, cash funds, and gifts added to a Joy Registry from external stores and websites.'
       */
      subtitle: T;

      /**
       * @default 'Please contact our concierge team if your continue to experience this issue.'
       */
      subtitleError: T;

      /**
       * @default 'Use this promo code at checkout:'
       */
      promoCodeHeader: T;

      /**
       * @default 'View Registry'
       */
      cta: T;

      /**
       * @default 'Contact Concierge'
       */
      ctaError: T;

      /**
       * @default 'Cancel'
       */
      cancel: T;
    };
  };
  simplifiedLeadGenerationFlow: {
    landingPage: {
      /**
       * @default 'Get the list of the top hotels Joy couples have chosen near your venue.'
       */
      subTitle: T;

      /**
       * @default ' Learn how we make this list.'
       */
      learnMoreCtaText: T;

      infoList: [
        /**
         * @default 'Hotels which Joy couples pick for their wedding guests'
         */
        T,
        /**
         * @default 'Hotels that offer the deepest group discounts for weddings — sometimes up to 45% off'
         */
        T,
        /**
         * @default 'Hotels that gives most perks to Joy couples and their guests'
         */
        T
      ];

      /**
       * @default 'Enter your venue to get your personalized list.'
       */
      locationSectionTitle: T;

      /**
       * @default 'Venue or location'
       */
      locationPlaceholder: T;

      /**
       * @default 'Please enter a location to help us find your properties.'
       */
      locationError: T;

      /**
       * @default 'Get It'
       */
      locationCtaText: T;

      learnMoreDialog: {
        /**
         * @default 'How does it work?'
         */
        title: T;

        /**
         * @default 'We’ve helped millions of couples around the world plan their weddings, and through that experience, we’ve gathered valuable insights into the best hotels for wedding guests. Here’s how we curate your personalized list:'
         */
        subTitle: T;

        listItems: [
          {
            /**
             * @default 'Anonymized Data Collection'
             */
            title: T;

            /**
             * @default 'We analyze anonymized data from all the weddings we’ve assisted with to identify the most popular hotels among couples who’ve used our platform.'
             */
            description: T;
          },
          {
            /**
             * @default 'Top Choices by Couples'
             */
            title: T;

            /**
             * @default 'We look at the hotels that Joy couples have consistently chosen for their wedding guests. These hotels have proven to be reliable, comfortable, and conveniently located near popular venues.'
             */
            description: T;
          },
          {
            /**
             * @default 'Best Group Discounts'
             */
            title: T;

            /**
             * @default 'We identify hotels that offer the deepest discounts for wedding room blocks. Some of our hotel partners offer savings of up to 45% off regular rates, ensuring your guests get great value.'
             */
            description: T;
          },
          {
            /**
             * @default 'Perks and Benefits'
             */
            title: T;

            /**
             * @default 'We also consider the perks that hotels have provided to our couples and their guests, such as complimentary breakfasts, late check-outs, or free parking.'
             */
            description: T;
          },
          {
            /**
             * @default 'Personalized Recommendations'
             */
            title: T;

            /**
             * @default 'By sharing your venue, we tailor the list to include hotels that are close by and have been popular among other Joy couples getting married in the same area.'
             */
            description: T;
          }
        ];

        /**
         * @default 'Our goal is to make sure you and your guests have an amazing stay while saving time and money on accommodations. We look forward to helping you!'
         */
        footerInfo: T;
      };
    };
    phoneNumberCollectionPage: {
      /**
       * @default 'Where shall we send your list?'
       */
      title: T;

      /**
       * @default 'We’ll send you a text with the most popular hotels near your venue in 3-5 business days.'
       */
      subTitle: T;

      /**
       * @default 'Phone Number'
       */
      phoneLabel: T;

      /**
       * @default 'We understand how frustrating marketing texts can be. Rest assured, we never share or sell your phone number to third parties.'
       */
      phoneNumberInfo: T;

      /**
       * @default 'SMS data rates may apply.'
       */
      dataRatesInfo: T;

      /**
       * @default 'Get The List'
       */
      submitPhoneNumberCtaText: T;

      /**
       * @default 'Please share your cell phone number.'
       */
      smsRequiredError: T;

      /**
       * @default 'Please enter a valid phone number.'
       */
      invalidPhoneNumberError: T;
    };
    confirmationPage: {
      /**
       * @default 'Thank you!'
       */
      titlePart1: T;

      /**
       * @default 'Our team is on it.'
       */
      titlePart2: T;

      /**
       * @default 'Look out for a text with your personalized recommendations in the coming days. Happy planning!'
       */
      note: T;

      /**
       * @default 'Back to Dashboard'
       */
      backToDashboardCtaText: T;
    };
  };
};

export type HotelReminderTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Send Hotels Reminder'
   */
  title: T;

  createReminder: {
    /**
     * @default 'Remind your guests to book their stay.'
     */
    title: T;

    /**
     * @default 'Send your guests a quick reminder about booking their accommodations, so they can celebrate without a worry in the world.'
     */
    description: T;

    /**
     * @default 'From'
     */
    fromLabel: T;

    /**
     * @default 'Your Message'
     */
    messageLabel: T;

    /**
     * @default 'Accommodations Link'
     */
    linkLabel: T;

    /**
     * @default 'Link is not editable to prevent abuse and misuse of the platform.'
     */
    linkLabelHint: T;

    tabs: {
      /**
       * @default 'Text Preview'
       */
      text: T;

      /**
       * @default 'Email Preview'
       */
      email: T;
    };
    howItWorks: {
      /**
       * @default 'How does it work?'
       */
      title: T;

      /**
       * @default 'Write your message. Pick guests to remind. Reminder sent!'
       */
      description: T;

      steps: {
        /**
         * @default 'Write your message.'
         */
        writeMessage: T;

        /**
         * @default 'Pick guests to remind.'
         */
        pickGuests: T;

        /**
         * @default 'Reminder sent!'
         */
        reminderSent: T;
      };
    };
  };
  confirmAndSend: {
    /**
     * @default 'Almost done!'
     */
    header: T;

    /**
     * @default 'Review the final details and treat yourself afterwards. You’ve earned it.'
     */
    body: T;

    confirmAndSendActions: {
      addGuests: {
        hed: {
          /**
           * @default 'Add recipients'
           */
          guest_0: T;

          /**
           * @default 'Add recipients'
           * @param {number} count - The number of ocurrencies.
           * - singular: Add recipients
           * - plural: Add or remove recipients
           */
          guest: T;
        };

        /**
         * @default 'Each guest will receive the SMS reminder, or email in case their phone number is missing.'
         */
        dekSMS: T;

        /**
         * @default 'Each guest will receive the email reminder.'
         */
        dekEmail: T;

        buttonText: {
          /**
           * @default 'Select Guests'
           */
          guest_0: T;

          /**
           * @default 'Select Guests'
           * @param {number} count - The number of ocurrencies.
           * - singular: Select Guests
           * - plural: {{count}} Guests Selected…
           */
          guest: T;
        };

        /**
         * @default 'Modify Recipients'
         */
        buttonTextHover: T;
      };
      testSMS: {
        /**
         * @default 'Do an optional final check'
         */
        hed: T;

        /**
         * @default 'Never hurts to make sure everything is just right.'
         */
        dek: T;

        /**
         * @default 'Send Test SMS'
         */
        buttonTextSMS: T;

        /**
         * @default 'Send Test Email'
         */
        buttonTextEmail: T;
      };
      send: {
        /**
         * @default 'Send Reminder'
         */
        hed: T;

        /**
         * @default 'Send reminder to all guests you picked.'
         */
        dek: T;

        /**
         * @default 'Send Now'
         */
        buttonText: T;
      };
    };
    SMSSentSuccessMessage: {
      /**
       * @default 'Your reminder is on the way!'
       * @param {number} count - The number of ocurrencies.
       * - singular: Your reminder is on the way!
       * - plural: Your reminders are on the way!
       */
      guest: T;
    };

    /**
     * @default 'Sorry, something may have gone wrong'
     */
    SMSSentErrorMessage: T;

    /**
     * @default 'Please, select guests first'
     */
    noGuestsSelectedErrorMessage: T;

    /**
     * @default 'Phone Number is not valid'
     */
    testNumberInvalidMessage: T;

    confirmationScreen: {
      /**
       * @default 'Your reminder to book has been sent to {{count}} people.'
       */
      hed: T;

      /**
       * @default 'Patience is a virtue, but these great rates won’t wait!'
       */
      dek: T;

      /**
       * @default 'View Your Guests List'
       */
      buttonText: T;
    };
    sendTestSMSDialog: {
      /**
       * @default 'Send Test SMS'
       */
      header: T;

      /**
       * @default 'Name'
       */
      testNameLabel: T;

      /**
       * @default 'Phone number'
       */
      testNumberLabel: T;

      /**
       * @default 'First Name'
       */
      testNamePlaceholder: T;

      /**
       * @default '+1-555-555-5555'
       */
      testNumberPlaceholder: T;

      /**
       * @default 'Name is required'
       */
      testNameErrorMessage: T;

      /**
       * @default 'Phone Number is required'
       */
      testNumberErrorMessage: T;

      /**
       * @default 'Should be a valid phone number'
       */
      testNumberInvalidErrorMessage: T;

      /**
       * @default 'Send'
       */
      sendButtonText: T;

      /**
       * @default 'Cancel'
       */
      cancelButtonText: T;
    };
    sendTestEmailDialog: {
      /**
       * @default 'Send Test Email'
       */
      header: T;

      /**
       * @default 'Name'
       */
      testNameLabel: T;

      /**
       * @default 'Email'
       */
      testEmailLabel: T;

      /**
       * @default 'First Name'
       */
      testNamePlaceholder: T;

      /**
       * @default 'hello@gmail.com'
       */
      testEmailPlaceholder: T;

      /**
       * @default 'Name is required'
       */
      testNameErrorMessage: T;

      /**
       * @default 'Email is required'
       */
      testEmailErrorMessage: T;

      /**
       * @default 'Send'
       */
      sendButtonText: T;

      /**
       * @default 'Cancel'
       */
      cancelButtonText: T;
    };
  };
  peoplePicker: {
    /**
     * @default 'SMS'
     */
    emailAndValidPhoneMessage: T;

    /**
     * @default 'No SMS or email'
     */
    noEmailAndPhoneError: T;

    /**
     * @default 'email (no mobile)'
     */
    emailButNoPhoneError: T;

    /**
     * @default 'email (invalid mobile)'
     */
    emailAndInvalidPhoneError: T;
  };
};

export type IdeabookTranslations<T extends LeafNodeValue = TranslateFunction> = {
  ideas: {
    /**
     * @default 'Ideas'
     */
    pageTitle: T;

    /**
     * @default 'Ideabooks'
     */
    ideabookSectionTitle: T;
  };
  ideabook: {};
};

export type IdeasTranslations<T extends LeafNodeValue = TranslateFunction> = {
  addIdeabooks: {
    /**
     * @default 'Create'
     */
    create: T;

    /**
     * @default 'Description'
     */
    description: T;

    /**
     * @default 'Create Ideabook'
     */
    formTitle: T;

    /**
     * @default 'Name'
     */
    name: T;
  };
  editIdeabook: {
    /**
     * @default 'Upload Photo'
     */
    addCollectionItemButtonTitle: T;

    /**
     * @default 'Saving'
     */
    savingCollection: T;

    /**
     * @default 'Ideabook items could not be saved'
     */
    generalError: T;
  };
};

export type ImpermissibleTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Hmm… we're having trouble with that.'
   */
  headerText: T;

  /**
   * @default 'If you already have an account, we don't recognize these credentials, please <Action/>'
   */
  alreadyHaveAccountSuggestion: T;

  /**
   * @default 'try another sign in method.'
   */
  alreadyHaveAccountAction: T;

  /**
   * @default 'Creating a new account? We're phasing out Facebook and Gmail sign up. Please <Action/>'
   */
  newAccountSuggestion: T;

  /**
   * @default 'sign up with your email.'
   */
  newAccountAction: T;
};

export type JoykitTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Back'
   */
  back: T;

  /**
   * @default 'Frequently Asked Questions'
   */
  frequentlyAskedQuestions: T;

  drawer: {
    /**
     * @default 'Drawer'
     */
    rootLabel: T;

    /**
     * @default 'Close'
     */
    closeButtonLabel: T;
  };
  locationInput: {
    /**
     * @default 'Start typing to see options'
     */
    noOptionsMessage: T;
  };
  emailVerificationFailed: {
    /**
     * @default 'We're having trouble verifying your email address.'
     */
    title: T;

    /**
     * @default 'The attempt to verify your email didn't quite work. Please try again.'
     */
    description: T;

    /**
     * @default 'Resend Verification Email'
     */
    ctaLabel: T;
  };
  deleteDialog: {
    /**
     * @default 'Are you sure you want to delete this item?'
     */
    title: T;

    /**
     * @default 'This cannot be undone.'
     */
    message: T;

    /**
     * @default 'Cancel'
     */
    cancelCta: T;

    /**
     * @default 'Delete'
     */
    deleteCta: T;
  };
  inviteAdmin: {
    /**
     * @default 'Add Collaborator'
     */
    addCollaborator: T;

    /**
     * @default 'Verify your email address to invite collaborators'
     */
    verifyYourEmail: T;

    /**
     * @default 'Owner'
     */
    owner: T;

    /**
     * @default 'Collaborators'
     */
    collaborators: T;

    /**
     * @default 'Pending Invites'
     */
    pendingInvites: T;

    /**
     * @default 'You'
     */
    you: T;

    /**
     * @default 'Transfer Ownership'
     */
    transferOwnership: T;

    /**
     * @default 'Resend Invite'
     */
    resendInvite: T;

    /**
     * @default 'Revoke Invite'
     */
    revokeInvite: T;

    /**
     * @default 'Done'
     */
    done: T;

    /**
     * @default 'First name is required'
     */
    firstNameRequired: T;

    /**
     * @default 'Last name is required'
     */
    lastNameRequired: T;

    /**
     * @default 'Email is required'
     */
    emailRequired: T;

    /**
     * @default 'Invalid email format'
     */
    invalidEmail: T;

    /**
     * @default 'Add Collaborator'
     */
    addAdminTitle: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Send Invite'
     */
    sendInvite: T;

    /**
     * @default 'First name'
     */
    firstNameAriaLabel: T;

    /**
     * @default 'First Name'
     */
    firstNamePlaceholder: T;

    /**
     * @default 'Last name'
     */
    lastNameAriaLabel: T;

    /**
     * @default 'Last Name'
     */
    lastNamePlaceholder: T;

    /**
     * @default 'Email'
     */
    emailAriaLabel: T;

    /**
     * @default 'name@example.com'
     */
    emailPlaceholder: T;

    /**
     * @default 'Email'
     */
    email: T;

    /**
     * @default 'Revoke Invitation?'
     */
    revokeAdminInvitationTitle: T;

    /**
     * @default 'Are you sure you want to revoke <fullname/> admin privileges?'
     */
    revokeAdminMessage: T;

    /**
     * @default 'Revoke'
     */
    revoke: T;

    /**
     * @default 'Transfer'
     */
    transfer: T;

    /**
     * @default 'Transfer Ownership?'
     */
    transferOwnershipTitle: T;

    /**
     * @default 'Are you sure you want to transfer the ownership of this Joy event to <fullname/>?'
     */
    transferOwnershipMessage: T;

    /**
     * @default 'You will no longer be the primary account owner and your account will be downgraded to an admin account for this event.'
     */
    transferOwnershipConfirmation: T;

    /**
     * @default 'Enter '{{confirmationWord}}' to Confirm'
     */
    transferOwnershipInputLabel: T;

    /**
     * @default 'Email verification is required.'
     */
    emailVerificationRequired: T;

    /**
     * @default 'Verify Email'
     */
    verifyEmail: T;

    /**
     * @default 'Transfer Account'
     */
    transferAccount: T;
  };
  deleteEvent: {
    /**
     * @default 'Are you sure you want to delete this event?'
     */
    title: T;

    /**
     * @default 'Permanently deleting your Joy event includes all event details, your website, admin dashboard, photos, and guests.'
     */
    message1: T;

    /**
     * @default 'If you would like to continue, please type ‘DELETE’ in the field below to permanently delete your event and all its associated information from Joy.'
     */
    message2: T;

    /**
     * @default 'This is an IRREVERSIBLE change.'
     */
    message3: T;

    /**
     * @default 'Enter ‘DELETE’ to Confirm'
     */
    inputTitle: T;

    /**
     * @default 'DELETE'
     */
    delete: T;

    /**
     * @default 'Delete My Joy Event'
     */
    deleteMyJoyEvent: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'We were unable to delete the event.'
     */
    deleteEventFailureMessage: T;
  };
  focusPointDialog: {
    /**
     * @default 'Edit Page Photo'
     */
    title: T;

    /**
     * @default 'Focus point ensures your image displays well on all devices.'
     */
    note: T;

    /**
     * @default 'Delete'
     */
    delete: T;

    /**
     * @default 'Change'
     */
    change: T;

    /**
     * @default 'Save'
     */
    save: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Photo settings updated successfully.'
     */
    photoSettingsUpdateSuccess: T;

    /**
     * @default 'Failed to update photo settings.'
     */
    photoSettingsUpdateFailure: T;

    /**
     * @default 'Photo has been deleted'
     */
    photoDeleteSuccess: T;

    /**
     * @default 'Failed to delete photo.'
     */
    photoDeleteError: T;

    /**
     * @default 'Replace Photo'
     */
    replacePhoto: T;

    /**
     * @default 'Photo Layout'
     */
    photoLayout: T;

    /**
     * @default 'Full Width Photo'
     */
    fullWidthPhoto: T;

    /**
     * @default 'Centered Photo'
     */
    centeredPhoto: T;

    /**
     * @default 'Pick the part of the image that you'd like to show on your site.'
     */
    fullWidthCropNote: T;
  };
  inlineEditor: {
    /**
     * @default 'Content'
     */
    content: T;

    /**
     * @default 'Font Styles'
     */
    fontStyles: T;

    /**
     * @default 'Font Size'
     */
    fontSize: T;

    /**
     * @default 'Alignment'
     */
    alignement: T;

    /**
     * @default 'Photo'
     */
    photo: T;

    /**
     * @default 'Colors'
     */
    colors: T;

    /**
     * @default 'This page is public.'
     */
    pagePublic: T;

    /**
     * @default 'This page is password protected.'
     */
    pageProtected: T;

    /**
     * @default 'This page is hidden.'
     */
    pageHidden: T;

    /**
     * @default 'Edit Photo'
     */
    editPhoto: T;

    /**
     * @default 'Add Page Photo'
     */
    addPagePhoto: T;

    /**
     * @default 'Edit Color'
     */
    editColor: T;

    /**
     * @default 'Save'
     */
    save: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Edit Content'
     */
    editContent: T;

    /**
     * @default 'Event info has been saved'
     */
    eventInfoUpdateSuccess: T;

    /**
     * @default 'Error updating event info. Please try again later.'
     */
    eventInfoUpdateError: T;

    /**
     * @default 'Display Name'
     */
    displayName: T;

    /**
     * @default 'Event Date'
     */
    eventDate: T;

    /**
     * @default 'Please re-enter the date in MM/DD/YYYY format'
     */
    invalidDate: T;

    /**
     * @default 'Greetings'
     */
    greetings: T;

    /**
     * @default 'Text should be {{max}} characters or less.'
     */
    maxCharacters: T;

    /**
     * @default 'Event Location'
     */
    eventLocation: T;

    /**
     * @default 'Buttons'
     */
    buttons: T;

    /**
     * @default 'Navigation'
     */
    navigation: T;

    /**
     * @default 'Powered by Google'
     */
    poweredBy: T;

    /**
     * @default 'Theme'
     */
    theme: T;

    /**
     * @default 'Page Settings'
     */
    pageSettings: T;

    /**
     * @default 'Graphic Accent'
     */
    graphicAccent: T;
  };
  colorPalettePicker: {
    /**
     * @default 'Reset to default'
     */
    resetToDefault: T;
  };
  photoMenu: {
    /**
     * @default 'Update Photo'
     */
    update: T;

    /**
     * @default 'Reset to Default'
     */
    reset: T;

    /**
     * @default 'Delete Photo'
     */
    delete: T;
  };
  editEventUrl: {
    /**
     * @default 'Event URL'
     */
    eventUrl: T;

    /**
     * @default 'Joy Event URL'
     */
    joyEventURL: T;

    /**
     * @default 'Please Note:'
     */
    pleaseNote: T;

    /**
     * @default 'Your old Joy URL will no longer work - this means you'll need to let all your guests know the new address so they can still access your website. Please note that if you have a custom domain, you will also need to update your forwarding URL to point to this new Joy URL.'
     */
    eventUrlNote: T;

    /**
     * @default 'Save'
     */
    save: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Event URL updated'
     */
    eventUrlUpdateSuccess: T;

    /**
     * @default 'Error updating event URL. Please try again later.'
     */
    eventUrlUpdateError: T;
  };
  smartAppBanner: {
    /**
     * @default 'Continue in the App'
     */
    title: T;

    /**
     * @default 'Join event: {{eventHandle}}'
     */
    description: T;

    /**
     * @default 'View'
     */
    view: T;
  };
  photoPicker: {
    /**
     * @default 'Choose Your Photo'
     */
    chooseYourPhoto: T;

    /**
     * @default 'All your photos will appear here.'
     */
    allYourPhotosWillAppearHere: T;

    /**
     * @default 'Upload Photos'
     */
    uploadPhotos: T;

    /**
     * @default 'Save Photo'
     */
    savePhoto: T;

    /**
     * @default 'Uploads'
     */
    uploads: T;

    /**
     * @default 'All Photos'
     */
    allPhotos: T;

    /**
     * @default 'An error occurred while saving your photo. Please try again.'
     */
    savePhotoError: T;
  };
};

export type LayoutEngineTranslations<T extends LeafNodeValue = TranslateFunction> = {
  components: {
    footer: {
      /**
       * @default 'Help'
       */
      help: T;

      /**
       * @default 'About'
       */
      about: T;
    };
    announcement: {
      /**
       * @default 'Close'
       */
      close: T;

      /**
       * @default 'Continue'
       */
      continue: T;
    };
    eventCta: {
      /**
       * @default 'Unlock Guest Details'
       */
      unlockButtonText: T;

      /**
       * @default 'Unlock Your Schedule'
       */
      findInviteButtonText: T;

      /**
       * @default 'Don’t Forget to RSVP'
       */
      rsvpButtonText: T;

      /**
       * @default 'Hello {{guestName}}.'
       */
      personalizedText: T;

      /**
       * @default 'Not You?'
       */
      buttonNotYouText: T;
    };
  };
};

export type MarcomTranslations<T extends LeafNodeValue = TranslateFunction> = {
  topLevelLinks: {
    /**
     * @default 'Joy Wedding'
     */
    joyWedding: T;

    /**
     * @default 'Joy Baby'
     */
    joyBaby: T;

    /**
     * @default 'Find an Event'
     */
    findACouple: T;

    /**
     * @default 'Log In'
     */
    login: T;

    /**
     * @default 'Get Started'
     */
    getStarted: T;

    /**
     * @default 'Shipping'
     */
    shipping: T;

    /**
     * @default 'Returns'
     */
    returns: T;

    /**
     * @default 'Newsroom'
     */
    newsroom: T;

    /**
     * @default 'Careers'
     */
    careers: T;

    /**
     * @default 'About Joy'
     */
    aboutJoy: T;

    /**
     * @default 'Contact Us'
     */
    contactUs: T;

    /**
     * @default 'Terms'
     */
    terms: T;

    /**
     * @default 'Privacy'
     */
    privacy: T;

    /**
     * @default 'Checklist'
     */
    giftBasketWidget: T;

    /**
     * @default 'California Privacy Notice'
     */
    californiaPrivacyNotice: T;

    /**
     * @default 'Do not share my personal information.'
     */
    doNotShareInformation: T;
  };
  linkGroupHeaders: {
    /**
     * @default 'Plan & Invite'
     */
    planInvite: T;

    /**
     * @default 'Registry'
     */
    registry: T;

    /**
     * @default 'Expert Advice'
     */
    expertAdvice: T;
  };
  planInvite: {
    /**
     * @default 'Wedding Website'
     */
    weddingWebsite: T;

    /**
     * @default 'Guest List'
     */
    guestList: T;

    /**
     * @default 'Save the Dates'
     */
    saveTheDates: T;

    /**
     * @default 'Invitations'
     */
    invitations: T;

    /**
     * @default 'Smart RSVP'
     */
    smartRsvp: T;

    /**
     * @default 'Hotel Room Blocks'
     */
    hotelRoomBlocks: T;

    /**
     * @default 'Mobile App'
     */
    mobileApp: T;
  };
  registry: {
    /**
     * @default 'Wedding Registry'
     */
    weddingRegistry: T;

    /**
     * @default 'The Registry Store'
     */
    shopWedding: T;

    /**
     * @default 'Zero-Fee Cash Funds'
     */
    zeroFeeCashFunds: T;

    /**
     * @default 'Honeymoon Funds'
     */
    honeymoonFunds: T;

    /**
     * @default 'Baby Registry'
     */
    babyRegistry: T;

    /**
     * @default 'Shop Baby Gifts'
     */
    shopBabyGift: T;
  };
  expertAdvice: {
    /**
     * @default 'Wedding Guides & Ideas'
     */
    weddingGuidesIdeas: T;

    /**
     * @default 'Wedding Guides'
     */
    weddingGuidesIdeasMobile: T;

    /**
     * @default 'Baby & Parenting Guides'
     */
    babyParentingGuides: T;

    /**
     * @default 'Baby Guides'
     */
    babyParentingGuidesMobile: T;

    /**
     * @default 'FAQs'
     */
    faq: T;

    /**
     * @default 'Help & Support'
     */
    support: T;

    blogs: {
      /**
       * @default 'Wedding Planning'
       */
      weddingPlanning: T;

      /**
       * @default 'Budget'
       */
      budget: T;

      /**
       * @default 'Tips & Tricks'
       */
      tipsTricks: T;

      /**
       * @default 'Venues'
       */
      venues: T;

      /**
       * @default 'Photography'
       */
      photography: T;

      /**
       * @default 'Food & Drink'
       */
      foodDrink: T;

      /**
       * @default 'Wedding Guests'
       */
      weddingGuests: T;

      /**
       * @default 'Registry & Gift Ideas'
       */
      registryGiftIdeas: T;

      /**
       * @default 'Real Weddings'
       */
      realWeddings: T;

      /**
       * @default 'Proposal Ideas'
       */
      proposalIdeas: T;

      /**
       * @default 'Honeymoons'
       */
      honeymoons: T;

      /**
       * @default 'Pregnancy'
       */
      pregnancy: T;

      /**
       * @default 'Planning'
       */
      planning: T;

      /**
       * @default 'Parties'
       */
      parties: T;

      /**
       * @default 'Parenting'
       */
      parenting: T;

      /**
       * @default 'View All Categories'
       */
      viewAllCategories: T;
    };
  };
  identityWidget: {
    /**
     * @default 'Dashboard'
     */
    toDashboard: T;

    /**
     * @default 'Registry'
     */
    toRegistry: T;

    /**
     * @default 'Settings'
     */
    toSettings: T;

    /**
     * @default 'Manage Account'
     */
    toManageAcct: T;

    /**
     * @default 'Invite Collaborator'
     */
    toInvite: T;

    /**
     * @default 'Help'
     */
    toHelp: T;

    /**
     * @default 'Sign Out'
     */
    signOut: T;

    /**
     * @default 'Add Photos'
     */
    addPhotos: T;

    /**
     * @default 'Switch Event'
     */
    switchEvent: T;

    /**
     * @default 'Select Event'
     */
    selectEvent: T;

    /**
     * @default 'No Event Selected'
     */
    noEventSelected: T;
  };
  footerNav: {
    /**
     * @default 'New'
     */
    new: T;

    /**
     * @default 'Back'
     */
    back: T;

    /**
     * @default '© {{year}} Joy. All rights reserved.'
     */
    copyright: T;
  };
  footerMenu: {
    primary: {
      /**
       * @default 'Wedding Website'
       */
      weddingWebsite: T;

      /**
       * @default 'Guest List'
       */
      guestList: T;

      /**
       * @default 'Save the Dates'
       */
      saveTheDates: T;

      /**
       * @default 'Invitations'
       */
      invitations: T;

      /**
       * @default 'Smart RSVP'
       */
      smartRsvp: T;

      /**
       * @default 'Registry'
       */
      registry: T;

      /**
       * @default 'Hotel Room Blocks'
       */
      hotelRoomBlocks: T;

      /**
       * @default 'Mobile App'
       */
      mobileApp: T;

      /**
       * @default 'Baby Registry'
       */
      babyRegistry: T;

      /**
       * @default 'Shop Baby Gifts'
       */
      shopBabyGifts: T;

      /**
       * @default 'Zero-Fee Cash Funds'
       */
      zeroFeeCashFunds: T;
    };
    secondary: {
      /**
       * @default 'Design Templates'
       */
      designTemplates: T;

      /**
       * @default 'Ideas & Inspiration'
       */
      ideasInspiration: T;

      /**
       * @default 'Common Questions'
       */
      commonQuestions: T;

      /**
       * @default 'Help & Support'
       */
      helpSupport: T;

      /**
       * @default 'Pricing'
       */
      pricing: T;

      /**
       * @default 'Baby & Parenting Guides'
       */
      babyParentingGuides: T;

      /**
       * @default 'FAQs'
       */
      faqs: T;
    };
  };
};

export type PageNotFoundTranslations<T extends LeafNodeValue = TranslateFunction> = {
  pageNotFound: {
    /**
     * @default 'Error on Aisle 404'
     */
    textEyebrow: T;

    /**
     * @default 'Let’s get this cleaned up.'
     */
    textTitle: T;

    /**
     * @default 'That link doesn’t seem to exist.'
     */
    textInfoOne: T;

    /**
     * @default 'Double check it or try one of these options:'
     */
    textInfoTwo: T;

    /**
     * @default 'Find an Event'
     */
    findBtn: T;

    /**
     * @default 'Start a Registry'
     */
    registryBtn: T;

    /**
     * @default 'Explore the Joy Shop'
     */
    shopBtn: T;

    /**
     * @default 'Get Started with Joy'
     */
    getStartedBtn: T;

    /**
     * @default 'Need Help?'
     */
    helpBtn: T;

    /**
     * @default 'Home'
     */
    homeBtn: T;
  };
};

export type PageSettingsDialogTranslations<T extends LeafNodeValue = TranslateFunction> = {
  pageSettingsDialog: {
    /**
     * @default '{{pageDisplayName}} Settings'
     */
    title: T;

    /**
     * @default 'Hidden'
     */
    hidden: T;

    /**
     * @default 'Public'
     */
    public: T;

    /**
     * @default 'Password Protected'
     */
    passwordProtected: T;

    /**
     * @default 'Save'
     */
    saveButton: T;

    /**
     * @default 'Cancel'
     */
    cancelButton: T;

    /**
     * @default 'Password copied.'
     */
    passwordCopiedToast: T;

    /**
     * @default 'Event Password'
     */
    eventPassword: T;

    /**
     * @default 'Edit'
     */
    edit: T;

    /**
     * @default 'Please Note:'
     */
    passwordNoteTitle: T;

    /**
     * @default 'If you change your password, guests will no longer be able to use your current password to unlock your event.'
     */
    passwordNoteMessage: T;

    /**
     * @default 'An error occurred while updating your {{resource}} Visibility. Please try again.'
     */
    changeVisibilityFailed: T;

    /**
     * @default 'Your {{resource}} is now {{visibility}}.'
     */
    changeVisibilitySuccessful: T;

    /**
     * @default 'An error occurred while updating your {{resource}} Password. Please try again.'
     */
    changePasswordFailed: T;

    /**
     * @default 'Your Event Password has been updated.'
     */
    changePasswordSuccessful: T;

    /**
     * @default 'Your password must be atleast 3 characters and only contain letters, numbers and '!', '&', '+'.'
     */
    passwordError: T;

    /**
     * @default 'Registry'
     */
    registry: T;

    /**
     * @default 'Copy'
     */
    copy: T;

    /**
     * @default 'Title'
     */
    titleLabel: T;

    /**
     * @default 'Visibility'
     */
    visibility: T;

    /**
     * @default 'Require password for guest uploads'
     */
    requirePasswordForGuestPhotos: T;

    /**
     * @default 'Note: This will password-protect your entire event for guests using the Joy app.'
     */
    requirePasswordForGuestPhotosNote: T;

    /**
     * @default 'All Pages'
     */
    allPages: T;

    /**
     * @default 'Override your current page visibility settings and set them all to one option.'
     */
    allPagesNote: T;

    /**
     * @default 'Edit Page Photo'
     */
    editPagePhoto: T;

    /**
     * @default 'Copy Event Password'
     */
    copyEventPassword: T;

    /**
     * @default 'Please add a page title.'
     */
    pageTitleRequiredError: T;

    /**
     * @default 'Page title must be less than {{maxLength}} characters long.'
     */
    pageTitleMaxLengthError: T;

    /**
     * @default 'Delete Page'
     */
    deletePageCta: T;

    /**
     * @default 'Page Path'
     */
    pagePath: T;

    /**
     * @default 'Be sure to share the new URL with your guests if you've already shared a link to this page.'
     */
    pagePathNote: T;

    /**
     * @default '{{maxLength}} Max Chars'
     */
    pagePathMax: T;

    /**
     * @default 'This page path is available'
     */
    pagePathAvailable: T;

    /**
     * @default 'This page path is not available.'
     */
    pagePathNotAvailable: T;

    /**
     * @default 'Oops! Something went wrong. 
 Please reload the page and try again.'
*/
    pagePathError: T;

    /**
     * @default 'Coming Soon'
     */
    comingSoon: T;

    /**
     * @default 'Are you sure?'
     */
    areYouSure: T;

    /**
     * @default 'Inspired by couples’ feedback, we’ve found that keeping this page on your website encourages guests to book early and confidently plan their attendance, knowing their stays are secured.'
     */
    disableAccommodationNote: T;

    /**
     * @default 'Lowest rates possible'
     */
    lowestRatesPossible: T;

    /**
     * @default 'The best rates available through group discounts that is only through your website.'
     */
    lowestRatesPossibleNote: T;

    /**
     * @default 'Help guest decide on attendance'
     */
    helpOnAttendance: T;

    /**
     * @default 'We’ve seen it help out-of-town guests RSVP when they know where they will be staying.'
     */
    helpOnAttendanceNote: T;

    /**
     * @default 'Know where guests are staying'
     */
    whereGuestsAreStaying: T;

    /**
     * @default 'Plan your event better and know where guests will be staying.'
     */
    whereGuestsAreStayingNote: T;

    /**
     * @default 'Keep Page'
     */
    keepPageCta: T;

    /**
     * @default 'Continue'
     */
    continueCta: T;
  };
};

export type PlanningAccommodationsTranslations<T extends LeafNodeValue = TranslateFunction> = {
  topBar: {
    /**
     * @default 'Hotels'
     */
    headerTitle: T;

    /**
     * @default 'Page Settings'
     */
    pageSettings: T;

    /**
     * @default 'Share'
     */
    share: T;

    /**
     * @default 'Preview'
     */
    preview: T;

    /**
     * @default 'Help'
     */
    help: T;

    visibility: {
      /**
       * @default 'Hidden'
       */
      hidden: T;

      /**
       * @default 'Password Protected'
       */
      protected: T;

      /**
       * @default 'Public'
       */
      visible: T;
    };

    /**
     * @default '✨ Link copied! Share it with your guests!'
     */
    linkCopied: T;

    /**
     * @default 'Your page settings has been updated.'
     */
    updateSettingsSuccessful: T;

    /**
     * @default 'An error occurred while updating your page settings. Please try again.'
     */
    updateSettingsFailed: T;

    /**
     * @default 'Explore Hotels'
     */
    exploreHotels: T;
  };
  tabs: {
    /**
     * @default 'Overview'
     */
    overview: T;

    /**
     * @default 'Room Blocks'
     */
    roomBlocks: T;

    /**
     * @default 'Browse'
     */
    browse: T;

    /**
     * @default 'Share'
     */
    share: T;

    /**
     * @default 'Menu'
     */
    menu: T;
  };
  overview: {
    /**
     * @default 'Back'
     */
    back: T;

    /**
     * @default 'Your Accommodations'
     */
    yourAccommodations: T;

    zeroState: {
      /**
       * @default 'Introducing Joy Hotels'
       */
      caption: T;

      /**
       * @default 'Discover and share the best hotels with amazing discounts.'
       */
      title: T;

      /**
       * @default 'Amazing deals at top hotels worldwide, shared by other Joy couples. Lock in room blocks for even bigger savings, and easily add hotels to your site so guests can book hassle-free.'
       */
      body: T;

      /**
       * @default 'Learn more about Joy Hotels'
       */
      learnMoreLink: T;

      /**
       * @default 'Popular Hotels near {{nearCity}}'
       */
      popularHotels: T;

      /**
       * @default 'Your Destination'
       */
      yourDestination: T;

      /**
       * @default 'Explore More'
       */
      exploreMore: T;
    };
    noneZeroState: {
      /**
       * @default 'Your Room Blocks'
       */
      caption: T;

      /**
       * @default 'Manage Room blocks'
       */
      title: T;

      requestRoomBlock: {
        /**
         * @default 'Group Reservations with Joy'
         */
        caption: T;

        /**
         * @default 'Request Room Block Rates'
         */
        title: T;

        /**
         * @default 'Your Hotels'
         */
        yourHotels: T;

        /**
         * @default 'Request Room Rates'
         */
        requestRoomRates: T;

        /**
         * @default 'View Your Request'
         */
        viewRequests: T;

        /**
         * @default 'Text Revisions Mode'
         */
        tooltipTitle: T;

        /**
         * @default 'It allows you to fix typographical errors. You can edit all text fields on the card and move elements around. At this point, you cannot change the card specifications.'
         */
        tooltipContent: T;

        /**
         * @default 'Rates are coming in slower than expected but rest assure they’re coming.'
         */
        slowProgressInfoMessage: T;

        /**
         * @default 'An error occurred while submitting your request. Please try again.'
         */
        leadSubmitError: T;

        heading: {
          /**
           * @default 'Your search has begun!'
           */
          workingOnRequest: T;

          /**
           * @default 'We’re reaching out to your hotels.'
           */
          gettingTheBestRates: T;

          /**
           * @default 'Success! We got great rates!'
           */
          ratesReadyForReview: T;

          /**
           * @default 'Your hotel are almost ready.'
           */
          finalizingHotelDeals: T;
        };
        info: {
          /**
           * @default 'We’re assigning your hotel requests to one of our concierge to help you throughout this process. Leave it up to us!'
           */
          workingOnRequest: T;

          /**
           * @default 'We’re working on getting you the best deals. The typical request takes 6 days. We’ll contact you when your rates are ready.'
           */
          gettingTheBestRates: T;

          /**
           * @default 'We’ve received hotel offers and they’re ready for you to review.'
           */
          ratesReadyForReview: T;

          /**
           * @default 'We’re crossing t’s and dotting i’s to make sure your deals are locked in. Your guests will soon have the best deals.'
           */
          finalizingHotelDeals: T;
        };
        progressBarStates: {
          /**
           * @default 'Working on'
           */
          workingOnRequestPart1: T;

          /**
           * @default 'request'
           */
          workingOnRequestPart2: T;

          /**
           * @default 'Getting the'
           */
          gettingTheBestRatesPart1: T;

          /**
           * @default 'Best Rates'
           */
          gettingTheBestRatesPart2: T;

          /**
           * @default 'Rates Ready'
           */
          ratesReadyForReviewPart1: T;

          /**
           * @default 'for Review'
           */
          ratesReadyForReviewPart2: T;

          /**
           * @default 'Finalizing'
           */
          finalizingHotelDetailsPart1: T;

          /**
           * @default 'Hotels Deals'
           */
          finalizingHotelDetailspart2: T;

          /**
           * @default 'Time to'
           */
          timeToPartyParty1: T;

          /**
           * @default 'Party!'
           */
          timeToPartyParty2: T;
        };
      };
      myRoomBlocks: {
        /**
         * @default 'My Room Blocks'
         */
        title: T;

        /**
         * @default 'Text Revisions Mode'
         */
        tooltipTitle: T;

        /**
         * @default 'It allows you to fix typographical errors. You can edit all text fields on the card and move elements around. At this point, you cannot change the card specifications.'
         */
        tooltipContent: T;
      };
      yourRoomBlock: {
        /**
         * @default 'Group Reservations with Joy'
         */
        caption: T;

        /**
         * @default 'Your Room Blocks'
         */
        title: T;

        /**
         * @default 'View Your Reservation'
         */
        viewYourReservation: T;

        /**
         * @default 'Nights Filled:'
         */
        titleV2: T;
      };
      requestGroupRates: {
        /**
         * @default 'Introducing Group Reservations with Joy'
         */
        caption: T;

        /**
         * @default 'Take care of your guests with great rates and no fees.'
         */
        title: T;

        /**
         * @default 'Your personal Joy concierge agent will help you get the best room block rates for you and your guests.'
         */
        body: T;

        /**
         * @default 'Save up to 30% Off Room Rates'
         */
        saveRoomRates: T;

        /**
         * @default 'Zero Deposit Requirements'
         */
        zeroDeposit: T;

        /**
         * @default 'Dedicated Concierge'
         */
        dedicatedConcierge: T;

        /**
         * @default 'Maybe Later'
         */
        maybeLater: T;

        /**
         * @default 'Request Your Group Rates'
         */
        requestYourGroupRates: T;
      };
    };
  };
  firstPartyOverview: {
    heroSection: {
      /**
       * @default 'Introducing'
       */
      caption: T;

      /**
       * @default 'Instant Room Blocks'
       */
      title: T;

      /**
       * @default 'Now you can reserve room blocks instantly from the top hotels near your venue. Together with Joy, each hotel includes exclusive upgrades and the lowest prices to make your event memorable. Share them with guests so they can book hassle-free.'
       */
      body: T;

      /**
       * @default 'Watch the Video'
       */
      watchVideo: T;

      /**
       * @default 'Venue or Destination'
       */
      inputTitle: T;

      /**
       * @default 'Check Availability'
       */
      inputCta: T;
    };
    hotelTileInfo: {
      /**
       * @default 'Joy Certified Celebration Partner Hotels are trusted favorites used by other Joy couples getting married near you and carefully vetted to make sure your event is seamless and stress-free.'
       */
      joyCertifiedCelebration: T;

      /**
       * @default 'Every Room blocks includes exclusive upgrades and perks most asked for by couples and guests. From suite upgrades to early check-in and late checkout to luxury touches like spa treatments and free drinks, it’s more then just a place for guests to stay.'
       */
      everyRoomBlocks: T;

      /**
       * @default 'Discover nearby hotels with guaranteed room blocks for you and your guests without the need to play phone tag with hotel representatives.'
       */
      discoverNearbyHotels: T;

      /**
       * @default 'Joy leverages its collective power from millions of weddings to secure unmatched deals, offering you the lowest possible rates. We did the talking so you don’t have too.'
       */
      joyLeverages: T;

      /**
       * @default 'Lock-in limited rooms with blocks reserved just for Joy events. Calling hotels to get group rates takes an average or 5 days and countless hours for each hotel.'
       */
      lockInLimited: T;
    };
    joyCertification: {
      /**
       * @default 'Joy Certified Celebration Partners'
       */
      title: T;

      /**
       * @default 'Given only to the top hotels and thoughtfully curated, Joy Celebration Hotels highlights hotels known for exceptional service, personalized experiences, and wedding-ready amenities, making them the go-to choice for hosts, guests, and planners. '
       */
      body: T;

      founder: {
        /**
         * @default 'Vishal Joshi'
         */
        title: T;

        /**
         * @default 'Co-Founder & CEO'
         */
        subTitle: T;
      };
      features: {
        /**
         * @default 'What it takes'
         */
        title: T;

        features: [
          {
            /**
             * @default 'A Couple’s Favorite'
             */
            title: T;

            /**
             * @default 'Each hotel is highly rated and favorite among Joy couples getting married nearby.'
             */
            description: T;
          },
          {
            /**
             * @default 'Instant Room Block Reservations'
             */
            title: T;

            /**
             * @default 'Inventory reserved just for Joy couples. Reserve instantly with no deposit required.'
             */
            description: T;
          },
          {
            /**
             * @default 'Exclusive Perks and Upgrades'
             */
            title: T;

            /**
             * @default 'Exclusive and most requested perks for hosts and guests for an all inclusive experience.'
             */
            description: T;
          },
          {
            /**
             * @default 'Lowest Available Rates'
             */
            title: T;

            /**
             * @default 'Zero-hassle and with the lowest transparent room rates without requiring traditional back and forth negotiations. '
             */
            description: T;
          },
          {
            /**
             * @default 'History of Exceptional Service'
             */
            title: T;

            /**
             * @default 'Personalized and exceptional history of service for large group events.'
             */
            description: T;
          },
          {
            /**
             * @default 'Designed for Events'
             */
            title: T;

            /**
             * @default 'Facilities that caters to large events and celebrations. Such as accessible common areas for guests and beautiful event settings.'
             */
            description: T;
          }
        ];
      };
      valueProps: {
        /**
         * @default 'What’s included'
         */
        title: T;

        valueProps: [
          {
            /**
             * @default 'For Hosts'
             */
            tag: T;

            /**
             * @default 'Suite Upgrade'
             */
            caption: T;

            /**
             * @default 'Free upgrade from a standard room to bridal suite or equivalent for the host of the event.'
             */
            title: T;
          },
          {
            /**
             * @default 'Everyone'
             */
            tag: T;

            /**
             * @default 'Check-In & Check-Out'
             */
            caption: T;

            /**
             * @default 'Early check-in and late check-out for you and your guests.'
             */
            title: T;
          },
          {
            /**
             * @default 'Guests'
             */
            tag: T;

            /**
             * @default 'Welcome Bags'
             */
            caption: T;

            /**
             * @default 'Free delivery of host provided welcome bags in every room.'
             */
            title: T;
          },
          {
            /**
             * @default 'Guests'
             */
            tag: T;

            /**
             * @default 'Spa Discount'
             */
            caption: T;

            /**
             * @default 'Up to 20% on spa services like massage facials, and treatments.'
             */
            title: T;
          },
          {
            /**
             * @default 'Guests'
             */
            tag: T;

            /**
             * @default 'Welcome Drink'
             */
            caption: T;

            /**
             * @default 'One complimentary drink per guest, ideally provided on behalf of the couple and Joy.'
             */
            title: T;
          },
          {
            /**
             * @default 'Everyone'
             */
            tag: T;

            /**
             * @default 'Food & Beverage Discount'
             */
            caption: T;

            /**
             * @default '15% discount on food and beverage services, saving up to $150 on meals, drinks, and catering.'
             */
            title: T;
          },
          {
            /**
             * @default 'Everyone'
             */
            tag: T;

            /**
             * @default 'Free Parking'
             */
            caption: T;

            /**
             * @default 'Complimentary for you and your guests, saving $50 per vehicle per day.'
             */
            title: T;
          },
          {
            /**
             * @default 'Everyone'
             */
            tag: T;

            /**
             * @default 'Champagne Welcome'
             */
            caption: T;

            /**
             * @default 'Complimentary champagne or wine, along with a personalized welcome note from hotel management.'
             */
            title: T;
          },
          {
            /**
             * @default 'Guests'
             */
            tag: T;

            /**
             * @default 'Group Pricing Extended to Shoulder Days'
             */
            caption: T;

            /**
             * @default 'Discounted group pricing on shoulder days (surrounding event days) giving you rate flexibility for your guests'
             */
            title: T;
          },
          {
            /**
             * @default 'Everyone'
             */
            tag: T;

            /**
             * @default 'Points for All Reservations'
             */
            caption: T;

            /**
             * @default 'Earn points on all reservations made through Joy, which can be redeemed for future savings.'
             */
            title: T;
          }
        ];
      };
    };
    faqs: {
      questions: [
        {
          /**
           * @default 'How does Joy's zero fee cash gifting work?'
           */
          Q: T;

          /**
           * @default 'We've integrated with your favorite cash-sharing services such as Venmo, PayPal, and Cash App, so that guests can easily and conveniently send funds to couples, without being charged a credit card processing fee or any other fee.'
           */
          A: T;
        },
        {
          /**
           * @default 'What should be on my wedding registry?'
           */
          Q: T;

          /**
           * @default 'It all depends on what you need or want for your wedding or bridal registry. For instance, if you and your partner recently moved in together or bought a new house, your wish list may include a stand mixer, dinnerware, or kitchen gadgets. If you prefer cash gifts to have control over what to buy, you can set up a cash registry and link it to a goal such as a romantic honeymoon or a downpayment on your dream house. Whatever your preferences, be sure to briefly explain your reasoning in a note or on your wedding website.'
           */
          A: T;
        },
        {
          /**
           * @default 'When should we start our registry?'
           */
          Q: T;

          /**
           * @default 'It’s best to start your registry any time between the time you start wedding planning and about 4 months before your wedding day. You’ll likely want to set up your registry before sending out wedding invitations, as guests are likely to want to know by that time.'
           */
          A: T;
        },
        {
          /**
           * @default 'How many gifts should we register for?'
           */
          Q: T;

          /**
           * @default 'When creating your wedding registry, you’ll want to give guests plenty of options, so the best bet is to register for more gifts than you have guests. For example, in a wedding with 90 guests you would want to have at least 100 registry items. You can build registries with multiple brands like Amazon, Bed Bath & Beyond, Crate & Barrel, and Williams-Sonoma, and then sync them all to your Joy registry for an easier guest shopping experience.'
           */
          A: T;
        },
        {
          /**
           * @default 'How can I explain to my guests that we are using a cash registry?'
           */
          Q: T;

          /**
           * @default 'For your family and friends who are more used to gift registries, it’s helpful to include a note explaining what their cash gift will go towards and expressing your gratitude for any amount they are able to give.'
           */
          A: T;
        },
        {
          /**
           * @default 'Is it rude to ask for money instead of wedding gifts?'
           */
          Q: T;

          /**
           * @default 'Not at all! In fact, in many places outside of the US, it’s common (and sometimes expected) to give money in lieu of traditional gifts. In recent years, cash gifting has become more and more popular in the US, as a way of elevating experiences instead of accumulating things, thanks in part to the emergence of cash registry platforms such as Joy and Honeyfund. Check out our article <a class="faq-link" href="https://withjoy.com/blog/cash-wedding-registries-guide/" target="blank">Cash Wedding Registries: An Introductory Guide</a> for best practices on how to navigate the cash ask on your wedding website.'
           */
          A: T;
        },
        {
          /**
           * @default 'How many types of wedding registries should I have?'
           */
          Q: T;

          /**
           * @default 'It’s always good to give your loved ones plenty of gift ideas. Some wedding guests may be uncomfortable with the idea of giving money, so it’s best to include the option of a wedding gift registry as well. '
           */
          A: T;
        },
        {
          /**
           * @default 'Can I send thank-you notes through Joy?'
           */
          Q: T;

          /**
           * @default 'Yes! Joy allows you to keep track of who has given a gift and send guests personalized messages.'
           */
          A: T;
        },
        {
          /**
           * @default 'What does Joy cost?'
           */
          Q: T;

          /**
           * @default 'Joy wedding websites, planning tools, registry, and online RSVP are free! Paper wedding invitations come in a range of price and style options, and all include free back-of-card printing and free recipient and return address printing. On a tight budget? Mix and match Joy’s digital and paper cards to save money on paper and postage. Hundreds of digital wedding invitation designs are available to send for free.'
           */
          A: T;
        },
        {
          /**
           * @default 'Do you have a support team we can reach out to if we have questions?'
           */
          Q: T;

          /**
           * @default 'Absolutely! Our amazing support team works hard around the clock, every day of the week, including weekends, to make sure that your questions are answered. You can send us an email at support@withjoy.com.'
           */
          A: T;
        }
      ];
    };
  };
  browse: {
    /**
     * @default 'Venue'
     */
    venue: T;

    /**
     * @default 'Wedding Venue'
     */
    weddingVenue: T;

    /**
     * @default 'Show More'
     */
    showMore: T;

    /**
     * @default 'Add Custom Link'
     */
    addCustomLink: T;

    /**
     * @default 'Can't find your hotel? Add your own listing.'
     */
    cantFindHotel: T;

    /**
     * @default 'Our Top Picks'
     */
    filterPopularity: T;

    /**
     * @default 'Distance'
     */
    filterDistance: T;

    /**
     * @default 'Show Map'
     */
    showMap: T;

    /**
     * @default 'Show List'
     */
    showList: T;

    /**
     * @default 'An error occurred while updating hotel order. Please try again.'
     */
    changeBlockOrderFailed: T;

    hotelItem: {
      /**
       * @default 'Add to Page'
       */
      addHotelTxt: T;

      /**
       * @default 'miles away'
       */
      distance: T;

      /**
       * @default 'Remove from Page'
       */
      removeHotelTxt: T;

      /**
       * @default 'Request Group Rates'
       */
      requestGroupRates: T;

      /**
       * @default 'View Your Request'
       */
      viewYourRequest: T;

      /**
       * @default 'Interested Property'
       */
      interestedLabel: T;

      /**
       * @default 'Need a room block?'
       */
      preferredHotelTooltipTitle: T;

      /**
       * @default 'Get a room block with one of Joy's partner hotels and get the best group rates fast. We hand picked these properties based on reviews from other Joy couples, competitive rates, and a great fit for larger groups. '
       */
      preferredHotelTooltipDescription: T;

      /**
       * @default 'Get Started'
       */
      getStarted: T;

      /**
       * @default 'Room Block Requested'
       */
      roomBlockRequested: T;

      /**
       * @default 'Our Room Block'
       */
      ourRoomBlock: T;

      /**
       * @default 'Couple Favorite'
       */
      preferredHotelMobileViewLabel: T;

      /**
       * @default 'View Hotel'
       */
      viewHotel: T;

      /**
       * @default 'Group Rate'
       */
      groupRate: T;

      ratingCategory: {
        /**
         * @default 'Excellent'
         */
        excellent: T;

        /**
         * @default 'Very Good'
         */
        veryGood: T;

        /**
         * @default 'Good'
         */
        good: T;

        /**
         * @default 'Fair'
         */
        fair: T;

        /**
         * @default 'Poor'
         */
        poor: T;

        /**
         * @default 'Terrible'
         */
        terrible: T;
      };
    };
    noResults: {
      /**
       * @default 'Sorry, no results found.'
       */
      label: T;

      /**
       * @default 'Update your search area to see hotel options.'
       */
      eyeBrow: T;
    };
    manualItem: {
      /**
       * @default 'Not finding what you’re looking for?'
       */
      label: T;

      /**
       * @default 'Manually Add Hotel'
       */
      manualAddCTA: T;

      /**
       * @default 'Contact Concierge Team'
       */
      buttonCTA: T;

      /**
       * @default 'Chat'
       */
      intercomButtonCTA: T;
    };
    informativeDialog: {
      /**
       * @default 'What happens next?'
       */
      title: T;

      /**
       * @default 'Select hotels near your venue and let our concierge team do the work of negotiating discounted rates.'
       */
      item1: T;

      /**
       * @default 'Review your personalized hotel group rates with your personal concierge and select your favorite hotel for your guests.'
       */
      item2: T;

      /**
       * @default 'View Hotels'
       */
      btn: T;

      ourPicks: {
        /**
         * @default 'Why add hotels to your website?  ✨ '
         */
        title: T;

        picks: [
          {
            /**
             * @default 'Save Guests Money'
             */
            title: T;

            /**
             * @default 'Because we help millions of guests, we are able to get cheaper group rates than Expedia—up to 40% off nightly rates.'
             */
            subTitle: T;
          },
          {
            /**
             * @default 'Something for Everyone'
             */
            title: T;

            /**
             * @default 'A range of price points that include hotels with the best event friendly amenities.'
             */
            subTitle: T;
          },
          {
            /**
             * @default 'Stay Close to the Venue'
             */
            title: T;

            /**
             * @default 'Balancing travel time and distance, we’ve picked the most popular and conveniently located hotels near your event.'
             */
            subTitle: T;
          }
        ];
      };
    };
    addManuallyDialog: {
      /**
       * @default 'Manually add hotel'
       */
      title: T;

      /**
       * @default 'Search for hotels'
       */
      label: T;

      /**
       * @default 'Hotel Name'
       */
      placeholder: T;

      /**
       * @default 'Add Hotel'
       */
      addHotelCTA: T;

      /**
       * @default 'Remove Hotel'
       */
      removeHotelCTA: T;
    };

    /**
     * @default 'An error occurred while saving your accommodations place. Please try again.'
     */
    addAccommodationsPlaceFailed: T;

    /**
     * @default 'An error occurred while deleting your hotel. Please try again.'
     */
    deleteAccommodationsPlaceFailed: T;

    /**
     * @default '✨ Hotel added to page'
     */
    addedToPageSuccess: T;

    /**
     * @default '✨ Hotel removed from page'
     */
    removedFromPageSuccess: T;
  };
  firstPartyBrowse: {
    /**
     * @default 'Venue or Destination'
     */
    locationLabel: T;

    /**
     * @default 'View Hotel'
     */
    viewHotel: T;

    /**
     * @default 'Add Hotel'
     */
    addHotelTxt: T;

    /**
     * @default 'Remove Hotel'
     */
    removeHotelTxt: T;

    hotelCard: {
      /**
       * @default '️⚡️ Likely to Sell Out'
       */
      likelyToSellOut: T;

      /**
       * @default '{{miles}} miles from venue'
       */
      milesFromVenue: T;

      /**
       * @default '12 Exclusive Upgrades'
       */
      exclusiveUpgrades: T;

      /**
       * @default 'Upgrade from standard to suite'
       */
      propsUpgrade: T;

      /**
       * @default 'Early Check-in and Late Check-out'
       */
      propsEarlyCheckInOut: T;

      /**
       * @default 'Free welcome drink on check-in'
       */
      propsFreeWelcomeDrink: T;

      /**
       * @default 'Group Rate'
       */
      groupRate: T;

      /**
       * @default 'Per Night'
       */
      perNight: T;

      /**
       * @default '{{percent}}% Off'
       */
      off: T;

      /**
       * @default 'Compared to'
       */
      comparedTo: T;

      /**
       * @default 'See Room Block Offer 🎉'
       */
      seeRoomBlockOffer: T;

      /**
       * @default 'Text Revisions Mode'
       */
      tooltipTitle: T;

      /**
       * @default 'It allows you to fix typographical errors. You can edit all text fields on the card and move elements around. At this point, you cannot change the card specifications.'
       */
      tooltipContent: T;
    };
  };
  share: {
    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Save Guest Note'
     */
    saveGuestNote: T;

    /**
     * @default 'Edit Guest Note'
     */
    editGuestNote: T;

    /**
     * @default '✨ Couple note has been updated.'
     */
    coupleNoteUpdateSuccess: T;

    /**
     * @default 'An error occurred while updating couple note. Please try again.'
     */
    coupleNoteUpdateFailed: T;

    /**
     * @default 'Add Hotels'
     */
    addHotels: T;

    /**
     * @default 'Add Hotel'
     */
    addHotelTxt: T;

    /**
     * @default 'Remove Hotel'
     */
    removeHotelTxt: T;

    /**
     * @default 'Remove Custom Property'
     */
    removeCustomPropertyTxt: T;

    /**
     * @default 'Remove Room Block'
     */
    removeRoomBlockTxt: T;

    /**
     * @default 'Request Group Rates'
     */
    requestGroupRates: T;

    /**
     * @default 'Requested Group Rates'
     */
    requestedGroupRates: T;

    /**
     * @default 'Reserved Room Blocks'
     */
    reservedRoomBlocks: T;

    /**
     * @default 'View Your Request'
     */
    viewYourRequest: T;

    /**
     * @default 'Select Hotel'
     */
    selectHotel: T;

    /**
     * @default 'View Hotel'
     */
    viewHotel: T;

    /**
     * @default 'No Hotel Room Blocks Reserved.'
     */
    noRoomBlocks: T;

    /**
     * @default 'Find Hotels Room Blocks'
     */
    findRoomBlocks: T;

    /**
     * @default 'Find More Hotels Room Blocks'
     */
    findMoreRoomBlocks: T;

    /**
     * @default 'No Hotels Added.'
     */
    noHotels: T;

    /**
     * @default 'You can’t drag an item 
 above your preferred hotel.'
*/
    changeOrderInfo: T;

    /**
     * @default 'An error occurred while updating hotel order. Please try again.'
     */
    changeBlockOrderFailed: T;

    /**
     * @default 'Your Hotel order has been updated.'
     */
    changeBlockOrderSuccess: T;

    cards: {
      hotel: {
        /**
         * @default 'Add, edit or rearrange your hotels'
         */
        title: T;

        /**
         * @default 'Text Revisions Mode'
         */
        tooltipTitle: T;

        /**
         * @default 'It allows you to fix typographical errors. You can edit all text fields on the card and move elements around. At this point, you cannot change the card specifications.'
         */
        tooltipContent: T;
      };
      coupleNote: {
        /**
         * @default 'Note To Guests'
         */
        title: T;

        /**
         * @default 'Text Revisions Mode'
         */
        tooltipTitle: T;

        /**
         * @default 'It allows you to fix typographical errors. You can edit all text fields on the card and move elements around. At this point, you cannot change the card specifications.'
         */
        tooltipContent: T;
      };
      roomBlocks: {
        /**
         * @default 'Hotel Room Blocks'
         */
        title: T;

        /**
         * @default 'Text Revisions Mode'
         */
        tooltipTitle: T;

        /**
         * @default 'It allows you to fix typographical errors. You can edit all text fields on the card and move elements around. At this point, you cannot change the card specifications.'
         */
        tooltipContent: T;
      };
      addHotel: {
        /**
         * @default 'Hotels & Accommodations'
         */
        title: T;

        /**
         * @default 'Text Revisions Mode'
         */
        tooltipTitle: T;

        /**
         * @default 'It allows you to fix typographical errors. You can edit all text fields on the card and move elements around. At this point, you cannot change the card specifications.'
         */
        tooltipContent: T;
      };
    };
    delete: {
      /**
       * @default 'Are you sure you want to delete this item?'
       */
      title: T;

      /**
       * @default 'This cannot be undone.'
       */
      message: T;

      /**
       * @default 'Cancel'
       */
      cancelCta: T;

      /**
       * @default 'Success! Accommodation have been deleted.'
       */
      deleteAccommodationSuccess: T;

      /**
       * @default 'An error occurred while deleting your accommodation. Please try again.'
       */
      deleteAccommodationFailed: T;

      travelMapVenue: {
        /**
         * @default 'Delete Venue'
         */
        deleteCta: T;
      };
      travelMapHotels: {
        /**
         * @default 'Delete Hotel'
         */
        deleteCta: T;
      };
      travelMapAccommodationsHotel: {
        /**
         * @default 'Delete Accommodation'
         */
        deleteCta: T;
      };
      travelMapAccommodationsCustom: {
        /**
         * @default 'Delete Custom Property'
         */
        deleteCta: T;
      };
      travelMapAccommodationsRoomblock: {
        /**
         * @default 'Delete Room Block'
         */
        deleteCta: T;
      };
    };
  };
  roomBlocks: {
    /**
     * @default 'Request room blocks'
     */
    title: T;

    /**
     * @default 'Amazing deals at top hotels worldwide, shared by other Joy couples. Lock in room blocks for even bigger savings, and easily add hotels to your site so guests can book hassle-free.'
     */
    body: T;

    /**
     * @default 'Learn more about Room Blocks'
     */
    learnMoreLink: T;

    /**
     * @default 'Selected Hotels'
     */
    selectedHotels: T;

    /**
     * @default 'Request Group Rates'
     */
    requestGroupRates: T;

    /**
     * @default 'Select More Hotels'
     */
    selectMoreHotels: T;
  };
  hotelPdpDialog: {
    /**
     * @default '{{miles}} miles away'
     */
    milesAway: T;

    /**
     * @default 'Night'
     */
    night: T;

    /**
     * @default 'Exclusive block rate for our guests!'
     */
    exclusiveBlockRate: T;
  };
  firstPartyExclusiveUpgradesDialog: {
    /**
     * @default 'Exclusive Upgrades'
     */
    title: T;

    /**
     * @default 'This Joy Certified Hotel includes the following exclusive upgrades for your event.'
     */
    body: T;

    upgrades: [
      {
        /**
         * @default 'Suite Room Upgrade'
         */
        title: T;

        /**
         * @default 'Free upgrade from a standard room to bridal suite or equivalent for the host of the event.'
         */
        body: T;
      },
      {
        /**
         * @default 'Early Check-In and Late Check-Out'
         */
        title: T;

        /**
         * @default 'Early check-in at 1pm instead of 3pm and late check-out changed from 11am to 1pm for you and all your guests.'
         */
        body: T;
      },
      {
        /**
         * @default 'Champagne Welcome'
         */
        title: T;

        /**
         * @default 'Complimentary champagne or wine, along with a personalized welcome note from hotel management.'
         */
        body: T;
      },
      {
        /**
         * @default 'Spa Discounts'
         */
        title: T;

        /**
         * @default 'Discount of 20% on spa services, saving up to $200 on massages, facials, and treatments.'
         */
        body: T;
      },
      {
        /**
         * @default 'Welcome Drink'
         */
        title: T;

        /**
         * @default 'One complimentary drink per guest, ideally provided on behalf of the couple and Joy.'
         */
        body: T;
      },
      {
        /**
         * @default 'Food & Beverage Discount'
         */
        title: T;

        /**
         * @default '15% discount on food and beverage services, saving up to $150 on meals, drinks, and catering.'
         */
        body: T;
      },
      {
        /**
         * @default 'Free Parking'
         */
        title: T;

        /**
         * @default 'Complimentary for you and your guests, saving $50 per vehicle per day.'
         */
        body: T;
      },
      {
        /**
         * @default 'Welcome Bags'
         */
        title: T;

        /**
         * @default 'Free delivery of couple-provided welcome bags to guest rooms, a thoughtful and convenient service.'
         */
        body: T;
      },
      {
        /**
         * @default 'Group Pricing Extended to Shoulder Days'
         */
        title: T;

        /**
         * @default 'Discounted group pricing on shoulder days (surrounding event days) giving you rate flexibility for your guests'
         */
        body: T;
      },
      {
        /**
         * @default 'Points for All Reservations'
         */
        title: T;

        /**
         * @default 'Earn points on all reservations made through Joy, which can be redeemed for future savings.'
         */
        body: T;
      }
    ];
  };
  firstPartyHotelDialog: {
    /**
     * @default 'Venue'
     */
    venue: T;

    /**
     * @default 'See all {{count}} reviews'
     */
    seeAllReviews: T;

    ratingCategory: {
      /**
       * @default 'Excellent'
       */
      excellent: T;

      /**
       * @default 'Very Good'
       */
      veryGood: T;

      /**
       * @default 'Good'
       */
      good: T;

      /**
       * @default 'Fair'
       */
      fair: T;

      /**
       * @default 'Poor'
       */
      poor: T;

      /**
       * @default 'Terrible'
       */
      terrible: T;
    };

    /**
     * @default 'A couple favorite with exclusive room block pricing & upgrades'
     */
    favoriteNote: T;

    /**
     * @default 'Included Upgrades'
     */
    includedUpgrades: T;

    /**
     * @default 'Title'
     */
    upgradesTooltipTitle: T;

    /**
     * @default 'Content'
     */
    upgradesTooltipBody: T;

    /**
     * @default 'See all {{count}} upgrades'
     */
    seeAllUpgrades: T;

    upgrades: [
      {
        /**
         * @default 'Room Upgrade'
         */
        title: T;

        /**
         * @default 'Guaranteed suite or equivalent upgrade for the host.'
         */
        body: T;
      },
      {
        /**
         * @default 'Check In & Out'
         */
        title: T;

        /**
         * @default 'Early check-in and late check-out for you and your guests.'
         */
        body: T;
      },
      {
        /**
         * @default 'Welcome Bags'
         */
        title: T;

        /**
         * @default 'Free delivery of host welcome bags in every room.'
         */
        body: T;
      }
    ];

    /**
     * @default 'Why it’s Our Pick'
     */
    whyItsOurPick: T;

    /**
     * @default 'Title'
     */
    ourPickTooltipTitle: T;

    /**
     * @default 'Content'
     */
    ourPickTooltipBody: T;

    /**
     * @default 'Couple’s Favorite'
     */
    couplesFavorite: T;

    /**
     * @default 'Highly rated and favorite among Joy couples.'
     */
    couplesFavoriteBody: T;

    /**
     * @default 'Instant Room Block Reservation'
     */
    instantReservation: T;

    /**
     * @default 'Reserve instantly with no deposit required.'
     */
    instantReservationBody: T;

    /**
     * @default '{{drive}} min drive or {{walk}} min walk from your venue'
     */
    easilyAccessible: T;

    /**
     * @default 'Hotel is easily accessible from your venue.'
     */
    easilyAccessibleBody: T;

    /**
     * @default 'Read More'
     */
    readMore: T;

    /**
     * @default 'Show Less'
     */
    showLess: T;

    /**
     * @default '{{miles}} miles from venue'
     */
    milesFromVenue: T;

    /**
     * @default '{{walk}} min walk from venue'
     */
    walkFromVenue: T;

    /**
     * @default '{{drive}} min drive from venue'
     */
    driveFromVenue: T;

    /**
     * @default '{{min}} min from {{location}}'
     */
    fromLocation: T;

    /**
     * @default 'Front Desk'
     */
    frontDesk: T;
  };
  instantRoomBlockForm: {
    /**
     * @default 'Reserve Your Room Block'
     */
    title: T;

    /**
     * @default 'Your Room Blocks'
     */
    titleView: T;

    /**
     * @default 'Event Date'
     */
    eventDate: T;

    /**
     * @default 'Guest Count'
     */
    guestCount: T;

    /**
     * @default 'Add Guests'
     */
    addGuests: T;

    /**
     * @default ' Guests'
     */
    guests: T;

    /**
     * @default 'Title'
     */
    guestCountTooltipTitle: T;

    /**
     * @default 'Content'
     */
    guestCountTooltipBody: T;

    /**
     * @default 'Rooms Per Night'
     */
    roomsPerNight: T;

    /**
     * @default 'Title'
     */
    roomsPerNightTooltipTitle: T;

    /**
     * @default 'Content'
     */
    roomsPerNightTooltipBody: T;

    /**
     * @default 'Only '
     */
    only: T;

    /**
     * @default '% Off'
     */
    off: T;

    /**
     * @default 'Night'
     */
    night: T;

    /**
     * @default ' and '
     */
    and: T;

    /**
     * @default ' more '
     */
    more: T;

    /**
     * @default 'Reserve Room Blocks'
     */
    reserveRoomBlocks: T;

    /**
     * @default 'Reserve now & confirm later. We’ll hold your room block for 48 hr.'
     */
    note: T;

    /**
     * @default 'Block Left'
     */
    blockLeft: T;

    /**
     * @default '⚡️ Likely to Sell Out'
     */
    likelyToSellOut: T;

    /**
     * @default 'Check in'
     */
    checkIn: T;

    /**
     * @default 'Check out'
     */
    checkOut: T;

    /**
     * @default 'Add a date'
     */
    addADatePlaceholder: T;

    /**
     * @default '10'
     */
    numberOfRoomsPlaceholder: T;

    /**
     * @default 'Please add a check in date.'
     */
    checkInDateError: T;

    /**
     * @default 'Please add a check out date.'
     */
    checkOutDateError: T;

    /**
     * @default 'Check in date must be before check out date'
     */
    checkInDateRangeError: T;

    /**
     * @default 'Check out date must be after check in date'
     */
    checkOutDateRangeError: T;

    /**
     * @default 'Please enter the number of rooms.'
     */
    numberOfRoomsError: T;

    /**
     * @default 'Please enter a valid number of rooms.'
     */
    numberOfRoomsTypeError: T;

    /**
     * @default 'Please add the number of guests.'
     */
    numberOfGuestError: T;

    /**
     * @default 'Please add a event date.'
     */
    eventDateError: T;

    /**
     * @default 'Minimum is 10'
     */
    minNumberError: T;

    /**
     * @default 'Maximum is 999'
     */
    maxNumberError: T;

    /**
     * @default 'Must be a number'
     */
    mustBeNumberError: T;

    /**
     * @default 'Group Pricing'
     */
    groupPricing: T;
  };
};

export type PostOfficeTranslations<T extends LeafNodeValue = TranslateFunction> = {
  header: {
    /**
     * @default 'Send General Message'
     */
    generic: T;

    /**
     * @default 'Send Reminder'
     */
    reminder: T;

    /**
     * @default 'Send Thank You'
     */
    thankyou: T;
  };
  emailEditor: {
    /**
     * @default 'Edit Email'
     */
    header: T;

    /**
     * @default 'Email Subject'
     */
    emailSubjectLabel: T;

    /**
     * @default 'Don’t forget about the wedding rehearsal!'
     */
    emailSubjectPlaceholder: T;

    /**
     * @default 'Email Subject is required'
     */
    emailSubjectErrorMessage: T;

    /**
     * @default 'Reply-to Email'
     */
    replyToLabel: T;

    /**
     * @default 'Reply-to Email is required'
     */
    replyToErrorMessage: T;

    /**
     * @default 'Message'
     */
    messageLabel: T;

    /**
     * @default 'Message to our guest'
     */
    messagePlaceholder: T;

    emailEditorOptions: [
      {
        /**
         * @default 'Include link to download Joy app'
         */
        hed: T;

        /**
         * @default 'Guests can view & share photos and stay up-to-date on the go.'
         */
        dek: T;
      },
      {
        /**
         * @default 'Include link to your website'
         */
        hed: T;

        /**
         * @default 'Guests can visit your website for wedding details.'
         */
        dek: T;
      },
      {
        /**
         * @default 'Include event password'
         */
        hed: T;

        /**
         * @default 'Guests need an event password to gain access to your website and app.'
         */
        dek: T;
      }
    ];

    /**
     * @default 'Save'
     */
    doneButtonText: T;

    /**
     * @default 'Cancel'
     */
    cancelButtonText: T;

    /**
     * @default 'Discard'
     */
    discardButtonText: T;

    /**
     * @default 'Unsaved Changes'
     */
    undavedChangesWarningHed: T;

    /**
     * @default 'Are you sure you want to stop editing your digital message? Doing so will discard all unsaved changes.'
     */
    undavedChangesWarningDek: T;

    /**
     * @default 'Password is required when you include link to download the Joy app.'
     */
    passwordToggleTooltip: T;

    /**
     * @default 'Sorry. Something went wrong. Try again later.'
     */
    saveDraftError: T;

    /**
     * @default 'Salutation'
     */
    salutationLabel: T;

    /**
     * @default 'Hello'
     */
    salutationPlaceholder: T;

    /**
     * @default 'Include guest name(s)'
     */
    includeGuestNamesLabel: T;

    /**
     * @default 'Generating first draft...'
     */
    generatingDraft: T;
  };
  bottomSheet: {
    sendAndTrack: {
      /**
       * @default 'Send and Track'
       */
      title: T;

      /**
       * @default 'Keep an eye on replies and poke the slow pokes.'
       */
      description: T;

      /**
       * @default 'Send to 1 Person'
       */
      sendToSingular: T;

      /**
       * @default 'Send to {{count}} People'
       */
      sendTo: T;

      /**
       * @default 'Send to {{name}}'
       */
      sendToName: T;

      /**
       * @default 'Send to (No Guests Selected)'
       */
      sendToDisabled: T;
    };
    confirm: {
      /**
       * @default 'Your message has been sent'
       */
      title: T;

      /**
       * @default 'Your thank you note has been sent'
       */
      titleThankYou: T;

      /**
       * @default 'Your email has been sent to {{count}} guests. Track which guests have received and opened your card in Guest List.'
       */
      description: T;

      /**
       * @default 'An email has been sent to {{ name }}.'
       */
      descriptionThankYouName: T;

      /**
       * @default 'An email has been sent to {{ count }} People.'
       */
      descriptionThankYouCount: T;

      /**
       * @default 'Back to Dashboard'
       */
      button: T;

      /**
       * @default 'Thank Another Guest'
       */
      buttonThankYou: T;
    };
    designEmail: {
      /**
       * @default 'Design Email'
       */
      title: T;

      /**
       * @default 'Preview and edit your message to your guests.'
       */
      description: T;

      /**
       * @default 'Edit Email'
       */
      editEmail: T;
    };
    designEcard: {
      /**
       * @default 'Design Card'
       */
      title: T;

      /**
       * @default 'Upload your design and edit your message content.'
       */
      description: T;

      /**
       * @default 'Edit Card'
       */
      editEmail: T;
    };
    optionalTestEmail: {
      /**
       * @default 'Optional Test Email'
       */
      title: T;

      /**
       * @default 'Never hurts to make sure everything is just right.'
       */
      description: T;

      /**
       * @default 'Send Test'
       */
      sendTest: T;
    };
    selectRecipients: {
      /**
       * @default 'Select Recipients'
       */
      title: T;

      /**
       * @default 'No guests selected yet.'
       */
      noGuestsDescription: T;

      /**
       * @default '{{count}} guests selected.'
       */
      guestsDescription: T;

      /**
       * @default '1 guest selected.'
       */
      guestsDescriptionSingular: T;

      /**
       * @default 'Edit Guests'
       */
      editGuests: T;

      /**
       * @default 'Add Guests'
       */
      addGuests: T;

      /**
       * @default 'Share With a Link'
       */
      shareLink: T;

      /**
       * @default 'Easily share a direct link to your eCards with anyone.'
       */
      shareLinkDescription: T;

      /**
       * @default 'Copy Link'
       */
      shareLinkCTA: T;

      /**
       * @default 'Delivery Method'
       */
      deliveryMethod: T;

      /**
       * @default 'How would you like to share your eCard?'
       */
      deliveryMethodDescription: T;

      /**
       * @default 'Send by Email'
       */
      deliveryMethodEmailCTA: T;

      /**
       * @default 'Share with a Link'
       */
      deliveryMethodLinkCTA: T;
    };
  };
};

export type ProductSelectionDialogTranslations<T extends LeafNodeValue = TranslateFunction> = {
  text: {
    /**
     * @default 'What would you like to use?'
     */
    title: T;

    /**
     * @default 'Select all that interest you. You can always change your mind later.'
     */
    subtitle: T;

    /**
     * @default 'Website'
     */
    website: T;

    /**
     * @default 'Registry'
     */
    registry: T;

    /**
     * @default 'Cards & Messaging'
     */
    invitations: T;

    /**
     * @default 'Guest List & RSVP'
     */
    guestList: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Save'
     */
    save: T;

    /**
     * @default 'Your interests were updated successfully'
     */
    success: T;

    /**
     * @default 'An error occurred while updating your interests'
     */
    error: T;
  };
};

export type PublishReminderTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Close'
   */
  close: T;

  /**
   * @default 'Not seeing your design changes? Check that you’ve applied changes in the '
   */
  message: T;

  /**
   * @default 'website designer'
   */
  linkText: T;
};

export type RegistryChecklistTranslations<T extends LeafNodeValue = TranslateFunction> = {
  progress: {
    /**
     * @default 'Checklist Progress'
     */
    title: T;

    /**
     * @default 'Keep going, you’re doing great! 👍'
     */
    subtitle: T;
  };
  zeroState: {
    /**
     * @default 'Need Some Ideas?'
     */
    title: T;

    /**
     * @default 'Use our checklist as a starting point to build your registry.'
     */
    subtitle: T;

    /**
     * @default 'Try Checklist'
     */
    primaryButton: T;

    /**
     * @default 'No Thanks'
     */
    secondaryButton: T;
  };
};

export type RegistryOnboardingSurveyTranslations<T extends LeafNodeValue = TranslateFunction> = {
  actions: {
    /**
     * @default '{{questionIndex}} of {{count}}'
     */
    xOfY: T;

    /**
     * @default 'Not sure yet'
     */
    stillDeciding: T;

    /**
     * @default 'Yes'
     */
    yes: T;

    /**
     * @default 'No'
     */
    no: T;

    /**
     * @default 'Next'
     */
    next: T;

    /**
     * @default 'Back'
     */
    back: T;

    /**
     * @default 'Skip survey'
     */
    skip: T;

    /**
     * @default 'Show me later'
     */
    showLater: T;

    /**
     * @default 'Don’t show me again'
     */
    neverShowAgain: T;

    /**
     * @default 'All Done'
     */
    allDone: T;

    /**
     * @default 'That's it. We'll use this to help you find gifts you love... Starting now!'
     */
    completed: T;
  };
  questions: {
    question1: {
      /**
       * @default 'Let's get started! What are you planning to register for?'
       */
      title: T;

      /**
       * @default 'Select all that interest you.'
       */
      subtitle: T;

      types: {
        /**
         * @default 'Gifts'
         */
        gifts: T;

        /**
         * @default 'Cash Funds'
         */
        'cash-funds': T;

        /**
         * @default 'Experiences'
         */
        experiences: T;
      };
      feedback: {
        /**
         * @default 'Nice! There’s so much *joy* in unboxing your gifts. 😄'
         */
        gifts: T;

        /**
         * @default 'Nice! You’ve come to the right place for zero-fee cash funds.'
         */
        'cash-funds': T;

        /**
         * @default 'Rock on! What better way to celebrate than to experience something fun together.'
         */
        experiences: T;

        /**
         * @default 'Nice! We’ve seen that couples that register for everything get more gifts. 😉'
         */
        multiple: T;

        /**
         * @default 'No worries, we’ll help you shop all the best newlywed essentials on Joy.'
         */
        'still-deciding': T;
      };
    };
    question2: {
      /**
       * @default 'Are you registering at any other store? It’s okay if you are!'
       */
      title: T;

      /**
       * @default 'Joy lets you connect all your registries in one place.'
       */
      subtitle: T;

      feedback: {
        /**
         * @default 'Awesome! You can easily link other registries and add gifts from any store to your Joy registry, allowing guests to shop for everything in one place.'
         */
        yes: T;

        /**
         * @default 'Sweet! You’ll find all the gifts you want at Joy, and your guests will love shopping for everything in one place.'
         */
        no: T;

        /**
         * @default 'No problem, Joy’s registry is a great place to start.'
         */
        'still-deciding': T;
      };
    };
    question3: {
      /**
       * @default 'What are some things you and your partner enjoy doing together?'
       */
      title: T;

      /**
       * @default 'Select all that interest you.'
       */
      subtitle: T;

      types: {
        /**
         * @default 'Travel & experiences'
         */
        'travel-and-experiences': T;

        /**
         * @default 'Cooking together'
         */
        'cooking-together': T;

        /**
         * @default 'Outdoor adventures'
         */
        'outdoor-adventures': T;

        /**
         * @default 'Decorating our place'
         */
        'decorating-our-place': T;

        /**
         * @default 'Baking'
         */
        baking: T;

        /**
         * @default 'Happy hour'
         */
        'happy-hour': T;

        /**
         * @default 'Sleeping in'
         */
        'sleeping-in': T;

        /**
         * @default 'Date night'
         */
        'date-night': T;

        /**
         * @default 'Outdoor entertaining'
         */
        'outdoor-entertaining': T;

        /**
         * @default 'Coffee & tea'
         */
        'coffee-and-tea': T;

        /**
         * @default 'Playing with pets'
         */
        'playing-with-pets': T;

        /**
         * @default 'Health and wellness'
         */
        'health-and-wellness': T;
      };
      feedback: {
        /**
         * @default 'That's great, we'll show you all the unique gifts for what you like to do together.'
         */
        ok: T;
      };
    };
    question4: {
      /**
       * @default 'How many guests are you planning to invite to your wedding?'
       */
      title: T;

      /**
       * @default 'It's okay if you don't know exactly, you can always update this number later.'
       */
      subtitle: T;

      feedback: {
        /**
         * @default 'Sounds like a party! We'll help you figure out how many gifts to add so you have enough for your guests.'
         */
        ok: T;

        /**
         * @default 'No worries, you can update this number when you have a better idea.'
         */
        'still-deciding': T;
      };
    };
    question5: {
      /**
       * @default 'Are you having a wedding shower?'
       */
      title: T;

      /**
       * @default 'You can change this at any time.'
       */
      subtitle: T;

      feedback: {
        /**
         * @default 'Nice, saving it all for the big day!'
         */
        no: T;
      };
    };
    question6: {
      /**
       * @default 'How many guests will you invite to your wedding shower?'
       */
      title: T;

      /**
       * @default 'Guests often look for shower gifts on your wedding registry so we recommend adding more to help them find gifts you’ll love.'
       */
      subtitle: T;

      feedback: {
        /**
         * @default 'Great! If your shower is being organized by a friend or relative, be sure to share with them a link to your registry!'
         */
        ok: T;

        /**
         * @default 'Okay, you can update this number later.'
         */
        'still-deciding': T;
      };
    };
  };
  newRegistryOnboardingCards: {
    'onbrd-reg-add-shipping-address': {
      /**
       * @default 'Where should we ship your gifts?'
       */
      title: T;

      /**
       * @default 'Add a shipping address'
       */
      titleV2: T;
    };
    'onbrd-reg-set-currency': {
      /**
       * @default 'Set registry currency'
       */
      title: T;

      /**
       * @default 'Set registry currency'
       */
      titleV2: T;
    };
    'reg-shop': {
      /**
       * @default 'Start shopping'
       */
      title: T;

      /**
       * @default 'Enjoy a 20% Registry Completion Discount'
       */
      titleV2: T;
    };
    'reg-browse-cash-funds': {
      /**
       * @default 'Explore zero-fee cash funds'
       */
      title: T;

      /**
       * @default 'Explore zero-fee cash funds'
       */
      titleV2: T;
    };
    'reg-connect-registries': {
      /**
       * @default 'Connect a registry from another store'
       */
      title: T;

      /**
       * @default 'Connect a registry from another store'
       */
      titleV2: T;
    };
    'onbrd-reg-install-browser-button': {
      /**
       * @default 'Install the Quick Add Button to shop anywhere'
       */
      title: T;

      /**
       * @default 'Install the Joy Quick Add Button to shop anywhere'
       */
      titleV2: T;
    };
    'onbrd-reg-add-external-gift': {
      /**
       * @default 'Add a gift from another store'
       */
      title: T;

      /**
       * @default 'Add a gift from another store'
       */
      titleV2: T;
    };
    'onboarding-invite-admin': {
      /**
       * @default 'Invite your partner'
       */
      title: T;

      /**
       * @default 'Invite your partner'
       */
      titleV2: T;
    };
    'onbrd-reg-download-app': {
      /**
       * @default 'Download the App and add gifts on the go'
       */
      title: T;

      /**
       * @default 'Download the mobile app and add gifts on the go'
       */
      titleV2: T;
    };
    'onbrd-reg-add-registry-photo': {
      /**
       * @default 'Select a photo for your registry'
       */
      title: T;

      /**
       * @default 'Select a registry photo'
       */
      titleV2: T;
    };
    'onbrd-reg-write-page-greeting': {
      /**
       * @default 'Write a page greeting'
       */
      title: T;

      /**
       * @default 'Write a page greeting'
       */
      titleV2: T;
    };
    'onbrd-reg-share-registry': {
      /**
       * @default 'Share your registry'
       */
      title: T;

      /**
       * @default 'Share your registry'
       */
      titleV2: T;
    };
  };
  newRegistryOnboardingMeta: {
    /**
     * @default 'Get Started'
     */
    getStarted: T;

    /**
     * @default '{{completed}} / {{count}} done'
     */
    completedCards: T;
  };
};

export type RegistryOnboardingSurveyV2Translations<T extends LeafNodeValue = TranslateFunction> = {
  ctaBlocks: {
    /**
     * @default 'Sounds good'
     */
    primaryCtaSoundsGood: T;

    /**
     * @default 'Skip Survey'
     */
    secondaryCTASkipSurvey: T;

    /**
     * @default 'Turn On Recommendations'
     */
    primaryCtaRecommend: T;

    /**
     * @default 'Turn on Cash Funds '
     */
    primaryCtaCashFunds: T;

    /**
     * @default 'All Done'
     */
    allDone: T;
  };
  intro: {
    /**
     * @default 'Recommendations'
     */
    eyebrowText: T;

    /**
     * @default 'Let’s Personalize Your Joy Registry Experience'
     */
    hed: T;

    /**
     * @default 'Let's personalize your shopping experience based on your interests. We'll make gift recommendations based on couples like you.'
     */
    dek: T;
  };
  collectGiftCatalogInterest: {
    /**
     * @default 'Browse the Best Gifts'
     */
    hed: T;

    /**
     * @default 'Enjoy shopping from over 10,000 best wedding gifts selected by our team and delivered right to your doorstep with free shipping.'
     */
    dek: T;

    /**
     * @default 'Not Interested in Physical Gifts'
     */
    secondaryCta: T;
  };
  collectCashFundCatalogInterest: {
    /**
     * @default 'Zero-Fee Cash Funds'
     */
    hed: T;

    /**
     * @default 'No fees. No, really. From your honeymoon to your first home, make your dreams come true without paying pesky fees when you use your Paypal, Venmo, or CashApp account.'
     */
    dek: T;

    /**
     * @default 'Not Interested in Cash Funds'
     */
    secondaryCta: T;
  };
  collectExperiencesCatalogInterest: {
    /**
     * @default 'Unforgettable Experiences'
     */
    hed: T;

    /**
     * @default 'Discover our Experience gifts and crowdfund memories that will last a lifetime. Learn to surf in Costa Rica, explore the open ocean on a private cruise, or hike across Yosemite National Park.'
     */
    dek: T;

    /**
     * @default 'Not Interested in Experiences'
     */
    secondaryCta: T;
  };
  saveLater: {
    /**
     * @default 'Register Now, Save Later.'
     */
    hed: T;

    /**
     * @default 'Register now and save later with 20% off all remaining registry items.'
     */
    dek: T;

    /**
     * @default 'Love It'
     */
    primaryCta: T;

    /**
     * @default 'Not Interested in Saving Later'
     */
    secondaryCta: T;
  };
  browserButton: {
    /**
     * @default 'Your Universal Registry'
     */
    hed: T;

    /**
     * @default 'Shop on Joy or your favorite stores, connect another registry, and give guests one place to more easily shop from your wedding website.'
     */
    dek: T;

    /**
     * @default 'Not Interested'
     */
    secondaryCta: T;
  };
  giftsPersonalization: {
    /**
     * @default 'What are you interested in?'
     */
    hed: T;

    /**
     * @default 'We’ll help you find the best gifts from each category.'
     */
    dek: T;

    /**
     * @default 'Select Interests'
     */
    primaryCta: T;

    /**
     * @default 'Please choose your interests first.'
     */
    primaryCtaError: T;

    /**
     * @default 'Maybe Later'
     */
    secondaryCta: T;

    types: {
      /**
       * @default 'Travel & Experiences'
       */
      'travel-and-experiences': T;

      /**
       * @default 'Cooking Together'
       */
      'cooking-together': T;

      /**
       * @default 'Outdoor Adventures'
       */
      'outdoor-adventures': T;

      /**
       * @default 'Decorating Our Place'
       */
      'decorating-our-place': T;

      /**
       * @default 'Baking'
       */
      baking: T;

      /**
       * @default 'Happy Hour'
       */
      'happy-hour': T;

      /**
       * @default 'Sleeping In'
       */
      'sleeping-in': T;

      /**
       * @default 'Date Night'
       */
      'date-night': T;

      /**
       * @default 'Outdoor Entertaining'
       */
      'outdoor-entertaining': T;

      /**
       * @default 'Coffee & Tea'
       */
      'coffee-and-tea': T;

      /**
       * @default 'Spa Worthy Essentials'
       */
      'spa-worthy-essentials': T;

      /**
       * @default 'Cleaning & Organization'
       */
      'cleaning-and-organization': T;
    };
  };
  giftRecommendations: {
    /**
     * @default 'That’s it. We’ll use this to help you find gifts you love...starting now!'
     */
    hed: T;

    /**
     * @default 'The item was added to your registry!'
     */
    notificationText: T;
  };
  socialProofOnboarding: {
    perks: {
      /**
       * @default 'Your exclusive perks.'
       */
      title: T;

      /**
       * @default 'All Joy couples get access to helpful tools and money-saving benefits.'
       */
      subtitle: T;

      /**
       * @default 'Shop your favorite brands'
       */
      shopFavBrands: T;

      /**
       * @default 'Add gifts from any store'
       */
      addGiftsAnyStore: T;

      /**
       * @default 'Connect any registry'
       */
      connectAnyRegistry: T;

      /**
       * @default 'Zero-fee cash funds'
       */
      zeroFeeCashFund: T;

      /**
       * @default 'Cash options with Joy Wallet'
       */
      CashOptions: T;

      /**
       * @default 'Price Match Guarantee'
       */
      priceMatch: T;

      /**
       * @default 'Group Gifting'
       */
      groupGifting: T;

      /**
       * @default 'Free Shipping'
       */
      freeShipping: T;

      /**
       * @default 'Ship When You Want'
       */
      shipWhenYouWant: T;

      /**
       * @default 'To the Door Tracking'
       */
      doorTracking: T;

      /**
       * @default 'Mobile App'
       */
      mobileApp: T;

      /**
       * @default 'Quick Add Button'
       */
      quickAddBtn: T;

      /**
       * @default 'Live Concierge Help'
       */
      liveHelp: T;

      /**
       * @default '20% Completion Discount'
       */
      discount: T;

      /**
       * @default 'Automagic Thank You Notes'
       */
      thankYouNotes: T;
    };
    universalRegistry: {
      /**
       * @default 'Your universal registry with <br />anything from anywhere.'
       */
      title: T;

      /**
       * @default 'We've helped over a million couples — now we're excited to make your registry experience a breeze and your guests happy.'
       */
      subtitle: T;

      /**
       * @default 'Create one registry. Share one link.'
       */
      bulletOne: T;

      /**
       * @default 'Shop your favorite stores and add gifts easily.'
       */
      bulletTwo: T;

      /**
       * @default 'Get personal assistance whenever you or your guests need it.'
       */
      bulletThree: T;

      /**
       * @default 'View All Perks'
       */
      viewAllPerks: T;

      /**
       * @default 'Complete your registry effortlessly with Checklist.'
       */
      completeRegistry: T;

      /**
       * @default 'Let’s do this!'
       */
      letsDoThis: T;
    };
    shopPopularBrands: {
      /**
       * @default 'Shop popular brands.'
       */
      title: T;
    };
    anyStore: {
      /**
       * @default 'Add anything from any store, literally.'
       */
      title: T;

      /**
       * @default 'You only need one registry with Joy. Add items from any store with our Quick Add button. Already have a registry? Connect it to Joy. You'll have one registry to manage and guests will have one place to shop.'
       */
      subtitle: T;
    };
    usingRegistry: {
      /**
       * @default 'Using your registry is a piece of cake.'
       */
      title: T;

      /**
       * @default 'Get a detailed overview of your registry and recommendations to add options for guests, plus track gifts to your doorstep.'
       */
      subtitle: T;
    };
    reviewOne: {
      /**
       * @default 'I think my favorite feature is the 'Registry Advisor' that suggests how many products to have in all the various price ranges! It is super helpful to have it broken down like that!'
       */
      content: T;

      /**
       * @default '— Molly & Wyatt'
       */
      reviewer: T;
    };
    singleRegistry: {
      /**
       * @default 'Don’t get locked into a single registry.'
       */
      title: T;

      /**
       * @default 'Add any registry to Joy for ultimate flexibility. Seamlessly combine all your registries in one place, making it easy to manage and share with your guests. No more limitations—just pure convenience.'
       */
      subtitle: T;
    };
    reviewTwo: {
      /**
       * @default 'Joy was easy to connect registry items from Amazon, Macy’s and other online and store vendors. The website tracks everything for you. We had one broken registry item and returned without any issues. I was frankly surprised at how quickly they responded to the return request. I love having one site that tracks everything. 5 Stars.'
       */
      content: T;

      /**
       * @default '— Paul & Febbie'
       */
      reviewer: T;
    };
    universalSearch: {
      /**
       * @default 'Universal Search'
       */
      title: T;

      /**
       * @default 'If it’s on Google, it’s on Joy.'
       */
      subtitle: T;
    };
    reviewThree: {
      /**
       * @default 'We used the registry and it was fantastic. We particularly enjoyed the fund feature and how seamlessly it allowed us to import items from other registries. The easy exchange and return process also gave us peace of mind, making the whole experience smooth for us and our guests.'
       */
      content: T;

      /**
       * @default '— Guadalupe & Andy'
       */
      reviewer: T;
    };
    getHelp: {
      /**
       * @default 'Get help any time.'
       */
      title: T;

      /**
       * @default 'Joy is here for you and your guests via chat and email.'
       */
      subtitle: T;

      /**
       * @default 'Online Support 24/7'
       */
      onlineSupport: T;

      /**
       * @default 'Enjoy round-the-clock assistance online.'
       */
      roundClockAssistance: T;

      /**
       * @default 'Reach Out'
       */
      reachOut: T;

      /**
       * @default 'Live Concierge Help'
       */
      liveConcierge: T;

      /**
       * @default 'Get personalized help with a Zoom call.'
       */
      personalisedHelp: T;

      /**
       * @default 'Book Appointment'
       */
      bookAppointment: T;
    };
  };
};

export type RegistryOverviewTranslations<T extends LeafNodeValue = TranslateFunction> = {
  helpLinks: {
    /**
     * @default 'Preview'
     */
    previewRegistry: T;

    shareRegistry: {
      /**
       * @default 'Share'
       */
      title: T;

      /**
       * @default '✨ Link copied! Share it with gift givers so they can shop in one place!'
       */
      toast: T;
    };

    /**
     * @default 'Public'
     */
    registryIsPublic: T;

    /**
     * @default 'Hidden'
     */
    registryIsHidden: T;

    /**
     * @default 'Protected'
     */
    registryIsPasswordProtected: T;

    settings: {
      /**
       * @default 'Settings'
       */
      title: T;

      /**
       * @default 'Registry Settings'
       */
      navTitle: T;

      /**
       * @default 'Event Settings'
       */
      event: T;

      /**
       * @default 'Shipping Address'
       */
      shippingAddress: T;

      /**
       * @default 'Set Currency'
       */
      currencyZeroState: T;

      /**
       * @default 'Currency ({{code}} - {{symbol}})'
       */
      currency: T;

      /**
       * @default 'Edit Welcome Note'
       */
      note: T;

      /**
       * @default 'Your presence is enough of a present to us! But for those of you who are stubborn, we've put together a wish-list to help you out.'
       */
      defaultNote: T;

      /**
       * @default 'Invite Collaborator'
       */
      invite: T;

      /**
       * @default 'Connect a Registry'
       */
      connect: T;

      /**
       * @default 'Seamless checkout (On)'
       */
      seamlessCheckoutOn: T;

      /**
       * @default 'Seamless checkout (Off)'
       */
      seamlessCheckoutOff: T;
    };
  };
  tabsNavigation: {
    /**
     * @default 'Overview'
     */
    overview: T;

    /**
     * @default 'Manage'
     */
    manage: T;

    /**
     * @default 'Track'
     */
    track: T;
  };
  checklist: {
    /**
     * @default 'Registry Checklist'
     */
    title: T;

    /**
     * @default 'Use our checklist as a starting point to build your registry.'
     */
    subtitle: T;

    /**
     * @default 'Try Checklist'
     */
    button: T;
  };
  registry101: {
    /**
     * @default 'Registry 101'
     */
    title: T;

    /**
     * @default 'Need some guidance? Try one of these:'
     */
    subtitle: T;

    /**
     * @default 'Registry Walkthrough'
     */
    registryWalkthrough: T;

    /**
     * @default 'Tips & Tricks'
     */
    tipsTricks: T;

    /**
     * @default 'FAQs'
     */
    faqs: T;
  };
  registryPerks: {
    /**
     * @default 'Registry Completion Discount'
     */
    header: T;

    /**
     * @default 'Save 20% on Products'
     */
    title1: T;

    /**
     * @default 'You 100% Love.'
     */
    title2: T;

    /**
     * @default 'Use your completion discount up to six months after the big day.'
     */
    subTitle: T;

    /**
     * @default 'Explore All Benefits'
     */
    button: T;

    perksDialog: {
      /**
       * @default 'Registry Perks & Benefits'
       */
      header: T;

      /**
       * @default 'For You'
       */
      forYou: T;

      /**
       * @default 'For Your Guests'
       */
      forGuest: T;

      /**
       * @default 'Easy Returns'
       */
      easyReturns: T;

      /**
       * @default 'Free online returns let you get the gifts you want without any cost or inconvenience.'
       */
      easyReturnsSubTitle: T;

      /**
       * @default '20% Completion Discount'
       */
      registryCompletionDiscount: T;

      /**
       * @default 'Enjoy 20% off eligible gifts remaining on your registry up to 6 months after the big day.'
       */
      registryCompletionDiscountSubTitle: T;

      /**
       * @default 'Universal Registry'
       */
      universalRegistry: T;

      /**
       * @default 'Add gifts and experiences from the Joy Shop or any online store, plus connect or transfer another registry.'
       */
      universalRegistrySubTitle: T;

      /**
       * @default 'Zero-Fee Cash Funds'
       */
      zeroCashFund: T;

      /**
       * @default 'Save for your dreams faster by creating a cash fund for anything from your honeymoon to a new home.'
       */
      zeroCashFundSubTitle: T;

      /**
       * @default 'Joy Consultation'
       */
      joyConsultation: T;

      /**
       * @default 'Elevate your wedding planning with complimentary expert advice and creative ideas from the pros.'
       */
      joyConsultationSubTitle: T;

      /**
       * @default 'Gift Tracking'
       */
      giftNotifications: T;

      /**
       * @default 'See which gifts have been purchased, view your cash fund’s progress, and send free online thank you notes.'
       */
      giftNotificationsSubTitle: T;

      /**
       * @default 'Flexible, Free Shipping'
       */
      freeShipping: T;

      /**
       * @default 'Guests can choose to ship to you or to themselves so they can bring your gifts with them to your event.'
       */
      freeShippingSubTitle: T;

      /**
       * @default 'Price Match Promise'
       */
      priceMatch: T;

      /**
       * @default 'If your guests find a better price on a qualifying product from our eligible retailers, we'll match it.'
       */
      priceMatchSubTitle: T;

      /**
       * @default 'Group Gifting'
       */
      groupGifting: T;

      /**
       * @default 'A win-win for everyone, add big gifts over $350 and let your friends and family contribute what they want.'
       */
      groupGiftingSubTitle: T;

      /**
       * @default 'Fast Checkout'
       */
      fastCheckout: T;

      /**
       * @default 'Convenient payment options, like ApplePay and GooglePay, plus a streamlined checkout simplify gifting.'
       */
      fastCheckoutSubTitle: T;

      /**
       * @default 'Data Protection'
       */
      dataProtection: T;

      /**
       * @default 'Keeping personal data secure is our priority and we will never sell or compromise that information.'
       */
      dataProtectionSubTitle: T;

      /**
       * @default 'Priority Picks'
       */
      priorityPicks: T;

      /**
       * @default 'We’ll help your guests see which items are your must-haves, taking the guesswork out of picking the right gifts.'
       */
      priorityPicksSubTitle: T;

      /**
       * @default 'See Details'
       */
      seeDetails: T;
    };
  };
  virtualConsultation: {
    /**
     * @default 'Need Help?'
     */
    needHelp: T;

    /**
     * @default 'Get expert registry advice.'
     */
    title: T;

    /**
     * @default 'Build your registry like a pro with the help of expert advice.'
     */
    subTitle: T;

    /**
     * @default 'Book An Appointment'
     */
    button: T;
  };
  registryTools: {
    quickAdd: {
      /**
       * @default 'Install the Joy Quick Add Button'
       */
      title: T;

      /**
       * @default 'Add anything as you browse the web.'
       */
      subtitle: T;

      /**
       * @default 'Get the button'
       */
      button: T;
    };
    mobileApp: {
      /**
       * @default 'Shop on Your Phone'
       */
      title: T;

      /**
       * @default 'Add items from wherever, whenever.'
       */
      subtitle: T;

      /**
       * @default 'Download App'
       */
      button: T;
    };
    externalRegistries: {
      /**
       * @default 'Add Anything from Any Store'
       */
      title: T;

      /**
       * @default 'Joy lets you do you.'
       */
      subtitle: T;

      /**
       * @default 'Connect a Registry'
       */
      button: T;

      /**
       * @default 'Add a Gift'
       */
      buttonBaby: T;

      /**
       * @default 'Gifts'
       */
      gifts: T;

      /**
       * @default 'Cash Funds'
       */
      cashFunds: T;

      /**
       * @default 'Experiences'
       */
      experiences: T;

      /**
       * @default 'Charity'
       */
      charity: T;

      /**
       * @default 'Babymoons'
       */
      babymoons: T;
    };
  };
  registryToolsV2: {
    mobileApp: {
      /**
       * @default 'Add gifts on the go<lineBreak/>with the Joy App.'
       */
      title: T;

      /**
       * @default 'Get the App'
       */
      button: T;
    };
    universalRegistry: {
      /**
       * @default ' Already registered?<lineBreak/>Make it easier for your guests.'
       */
      title: T;

      /**
       * @default 'Connect a Registry'
       */
      button: T;
    };
  };
  departmentShowcase: {
    /**
     * @default 'Explore Departments'
     */
    title: T;
  };
  faqs: {
    /**
     * @default 'Frequently Asked Questions'
     */
    title: T;

    /**
     * @default 'Our most commonly asked questions are here, and you can reach our support team <0>here</0>.'
     */
    subtitle: T;

    questions: [
      {
        /**
         * @default '1'
         */
        id: T;

        /**
         * @default 'Why do we need a registry?'
         */
        Q: T;

        /**
         * @default 'With your big day around the corner, we’re sure your friends and family are excited to shower you with gifts. Chances are they’ll buy something for you even if you don’t have a registry and those gifts may not exactly be what you need or want. Having a wedding registry ensures you'll receive stuff, experiences, or even cash fund gifts that you truly need and want. So, it's a win-win for you and your guests–you’ll get what you love, and your guests feel awesome knowing they've given you something you'll cherish.'
         */
        A: T;
      },
      {
        /**
         * @default '2'
         */
        id: T;

        /**
         * @default 'Why should we register with Joy?'
         */
        Q: T;

        /**
         * @default 'The Joy wedding registry is a truly all-in-one registry that has been designed to make the registering process simple and fun for you and your guests. We've stocked our online <0>Joy Shop</0> with tons of products from top-name brands, <1>zero-fee cash funds</1> to make your dreams come true, and even the option to give back with charitable donations. We have gift cards, <2>honeymoon funds</2>, and <3>experiences</3> to make your future even more memorable, too.<br><br>And here's the icing on the cake: Joy's a universal registry wizard. You can register for items from any online store, and even transfer or connect existing registries, giving you and your guests one super-convenient place to shop for absolutely everything you desire.<br><br>We know weddings are expensive and time-consuming, so we’re always rolling out more features and perks to save you money and time. We promise to match prices, ship items free, offer convenient group gifting, make returns a breeze, and even give you a 20% Post-Wedding Completion Discount for most items left on your registry. It's like a wedding gift that keeps on giving! So why register with Joy? Because we're here to make your journey as stress-free and seamless as possible.'
         */
        A: T;
      },
      {
        /**
         * @default '3'
         */
        id: T;

        /**
         * @default 'When should we start our registry?'
         */
        Q: T;

        /**
         * @default 'Creating your wedding registry about 8-9 months before your wedding date is a fantastic idea because it gives you time to thoughtfully select items and gives guests time to browse and choose the perfect gifts. You definitely want to have your wedding registry finalized before sending out any shower or wedding invitations. When you start early, it takes away any last-minute rushing and gives everyone the chance to find the most meaningful and thoughtful presents for you and your future spouse.'
         */
        A: T;
      },
      {
        /**
         * @default '4'
         */
        id: T;

        /**
         * @default 'What should we add to our wedding registry?'
         */
        Q: T;

        /**
         * @default 'What you add to your wedding registry really comes down to your lifestyle and preferences. It's your opportunity to curate a list of items that will truly enhance your married life together. A couple who is moving into a new space for the first time together will likely have a different registry than a couple who is planning to travel the world. To strike a good balance, consider a mix of physical gifts like kitchen gadgets, home essentials, and comfy linens, which can make everyday life more enjoyable. Including options like cash funds for future plans or even charitable donations adds a meaningful touch to your registry. But don't stop there! Registering for experiences is a way for you to create beautiful memories that will last a lifetime. So, go ahead and make it a reflection of your needs and wants, knowing that your loved ones are excited to support your journey in whichever way they can. It's all about making your wedding registry uniquely yours.<br><br>Still not sure what to add? We recommend starting with our Registry Checklist, which you can find at the top of your registry page. We’ll guide you through the must-have items and tell you how many of each you might need. Check out the checklist.'
         */
        A: T;
      },
      {
        /**
         * @default '5'
         */
        id: T;

        /**
         * @default 'How many gifts should we register for?'
         */
        Q: T;

        /**
         * @default 'Ah, the age-old question, right? How many gifts should you put on that wedding registry? The thing is, there's no one-size-fits-all answer here. It really boils down to your unique situation. But here's a helpful rule of thumb: aim to have more items on your registry than you have guests. This gives folks a range of options to choose from. To make it easier, we’ve created your personal Registry Calculator, found within the “Overview” and “Gifts” sections of your registry. Tell us how many guests you’re inviting and we’ll give you a recommendation of how many gifts to register for. You’ll also be able to see how many gifts you have on your registry per price range.<br><br>Include a mix of essentials and fun stuff, all at different price points. That way, your guests can pick something that suits their budget and your new life together. And, don’t stress about over-registering. You can use your 20% Post-Wedding Discount on most items remaining on your registry after the big day. Learn more about how your discount works <0>here</0>.'
         */
        A: T;
      },
      {
        /**
         * @default '6'
         */
        id: T;

        /**
         * @default 'What is the Joy Shop and how are the products selected?'
         */
        Q: T;

        /**
         * @default 'While the Joy registry is truly universal and you can add any item from any online store when you download the Joy Browser Button, there are over 10,000 products from top brands, niche brands, and artisans around the world right in the <0>Joy Shop</0>.<br><br>Our product selection process is driven by a passionate commitment to deliver an exceptional catalog that feels hand-selected, intentional, and truly special. We take great pride in offering a diverse range of products chosen with a commitment to quality and longevity. The Joy Shop is thoughtfully curated to cater to every couples’ and guest's budget, ensuring there's something special for everyone to choose from. We also pay close attention to what our Joy couples love and listen to your suggestions. Your input helps us refine our product selection and introduce new items that cater to your evolving preferences and needs.'
         */
        A: T;
      },
      {
        /**
         * @default '7'
         */
        id: T;

        /**
         * @default 'Is it rude to ask for money instead of wedding gifts?'
         */
        Q: T;

        /**
         * @default 'Not at all! In fact, in many places outside of the US, giving money in lieu of traditional gifts is quite common and sometimes expected. Cash gifting has become increasingly popular in recent years as it allows couples to focus on experiences rather than material possessions. Joy has made it easier than ever to create cash registries, where you can politely express your preference for cash gifts. With Joy, you can create a general cash fund for your honeymoon or house downpayment, or customize cash funds for specific experiences like airfare, future date nights, or whatever you dream up.<br><br>Just remember to express gratitude for any type of gift you receive. You can send free, online thank you notes right from your Gift Tracker, quickly and easily. We’ll even help you write them.'
         */
        A: T;
      },
      {
        /**
         * @default '8'
         */
        id: T;

        /**
         * @default 'How do Joy's zero fee-cash funds work?'
         */
        Q: T;

        /**
         * @default 'Just like any product, you can register for a zero-fee cash fund right from the <0>Joy Shop</0>. Select one or create your own, make any edits to the amount, and add to your registry. Using Venmo, PayPal, or CashApp, your loved ones can sprinkle a little monetary magic your way without those pesky credit card fees. Whether you're dreaming of a honeymoon getaway, saving for that first home, or looking to fly high with airfare funds, the sky's the limit when it comes to crafting the perfect cash gifts for your newlywed journey.<br><br>P.S. Don’t stress if your guests want to pay in a more traditional way. They’ll also have the option to contribute via cash or check.'
         */
        A: T;
      },
      {
        /**
         * @default '9'
         */
        id: T;

        /**
         * @default 'How can we explain to our guests that we are using a cash registry?'
         */
        Q: T;

        /**
         * @default 'For loved ones who are more accustomed to traditional gift registries, it's a lovely idea to leave them a little note. You can write a note right in your Joy registry to let them know how much you appreciate their generosity, and share what your cash gift will be used for–whether it's your dream honeymoon, saving for a cozy home, or any other exciting adventure you're planning. It's all about making your guests feel connected to your journey and showing them that every contribution, big or small, means the world to you.'
         */
        A: T;
      },
      {
        /**
         * @default '10'
         */
        id: T;

        /**
         * @default 'Does Joy offer a post-wedding or completion discount?'
         */
        Q: T;

        /**
         * @default 'Absolutely! We’re here for you before, during, and after your wedding. Our 20% Post-Wedding discount can be used on eligible items that are still left on your registry after your big day. It's our way of helping you grab those items you've had your eye on at an even better price, so don’t be shy about adding everything you need and everything you love to your registry now. Use it as MANY times as you’d like, up to 6 months after your wedding date. Learn more about it here.'
         */
        A: T;
      },
      {
        /**
         * @default '11'
         */
        id: T;

        /**
         * @default 'Does Joy offer price matching?'
         */
        Q: T;

        /**
         * @default 'Yes! At Joy, we've got your back when it comes to getting the most value out of your purchases, so we’re happy to offer our Price Match Promise. If you find a better price for a qualifying product from one of our eligible retailers within 48 hours of making your order, just give us a shout. Learn more about it <0>here</0>.'
         */
        A: T;
      },
      {
        /**
         * @default '12'
         */
        id: T;

        /**
         * @default 'We already have a registry with another store. Can we link it to Joy?'
         */
        Q: T;

        /**
         * @default 'We know you’ve got your eyes on some fantastic finds from various stores. Imagine registering at a bunch of different stores and combining all of those registries into one glorious display on your wedding website. Let’s dive into how you can add external registries to your Joy Registry:<br><br><strong>1</strong>. Linking: Simply add a link to any external registry for guests’ convenience.<br><strong>2</strong>. Transfer: Move an external registry into Joy, manage items, and see gift details within Joy. Supported for specific registries.<br><strong>3</strong>. Syncing: Automatically import items from an external registry into Joy, though changes must be made on the external registry. Supported for specific registries.'
         */
        A: T;
      },
      {
        /**
         * @default '13'
         */
        id: T;

        /**
         * @default 'Does Joy cost anything to use?'
         */
        Q: T;

        /**
         * @default 'Joy's wedding websites, planning tools, registry, and online Save the Dates, invitations, and thank you notes are all available for free, so you can save on wedding costs and enjoy a stress-free planning experience.'
         */
        A: T;
      },
      {
        /**
         * @default '14'
         */
        id: T;

        /**
         * @default 'Do you have a support team we can reach out to if we have questions?'
         */
        Q: T;

        /**
         * @default 'Our awesome support squad has whipped up a fantastic collection of FAQs and nifty tips in our Help Center, all designed to make your wedding planning journey easier. This treasure trove of information is here to give you lightning-fast and super-detailed answers, so you can get the most out of your Joy experience. Visit our <0>Help Center</0>.'
         */
        A: T;
      }
    ];
  };
  highlights: {
    cashFundPromo: {
      /**
       * @default 'Cash in Your Dreams'
       */
      eyebrow: T;

      /**
     * @default 'Joy accepts the most
 Cash Fund payment options.'
*/
      title: T;

      /**
       * @default 'Create Cash Fund'
       */
      buttonLabel: T;

      /**
       * @default 'Honeymoon Fund'
       */
      progressBarLabel: T;
    };
    completionDiscountPromo: {
      /**
       * @default 'Post-Wedding Completion Discount'
       */
      eyebrow: T;

      /**
       * @default 'Celebrate all over again with 20% off registry gifts after your wedding.'
       */
      title: T;

      /**
       * @default 'Learn More'
       */
      buttonLabel: T;
    };
    curationsPromo: {
      /**
       * @default 'Expert Curations'
       */
      eyebrow: T;

      /**
       * @default 'Hot recs from industry pros to craft your registry.'
       */
      title: T;

      /**
       * @default 'Explore Curations'
       */
      buttonLabel: T;
    };
    customerSupportPromo: {
      /**
       * @default 'Customer support for you and your guests at every step.'
       */
      title: T;
    };
    delayShippingPromo: {
      /**
       * @default 'Avoid Honeymoon Deliveries'
       */
      eyebrow: T;

      /**
       * @default 'With Joy, only you decide when your gifts should ship.'
       */
      title: T;

      /**
       * @default 'Set Up Delay Shipping'
       */
      buttonLabel: T;
    };
    etsyPromo: {
      /**
       * @default 'Shop the Best of Etsy Wedding Supplies'
       */
      title: T;

      /**
       * @default 'Explore Wedding Shop'
       */
      buttonLabel: T;
    };
    groupGiftingPromo: {
      /**
       * @default 'Group Gifting'
       */
      eyebrow: T;

      /**
       * @default 'Let guests chip in together on your dreamiest gifts.'
       */
      title: T;

      progressBar: {
        /**
         * @default 'Sofa Sectional 🎉 You’re almost there!'
         */
        leftLabel: T;

        /**
         * @default '$4,000 Goal'
         */
        rightLabel: T;
      };
    };
    universalSearchPromo: {
      /**
       * @default 'Universal Search'
       */
      eyebrow: T;

      /**
     * @default 'If it’s on Google,
it’s on Joy.'
*/
      title: T;

      /**
       * @default 'Search for anything. Yes, really.'
       */
      buttonLabel: T;
    };
    weddingThankYouNotes: {
      /**
     * @default 'Thank You Notes
Made Easy'
*/
      title: T;

      /**
       * @default 'Introducing'
       */
      eyebrow: T;

      /**
       * @default 'Watch the Video'
       */
      buttonLabel: T;
    };
    weddingChecklist: {
      /**
     * @default 'Not sure where
to start?'
*/
      title: T;

      /**
       * @default 'Try Checklist'
       */
      buttonLabel: T;
    };
    weddingCashFunds: {
      /**
     * @default 'Zero-Fee
Cash Funds.'
*/
      title: T;

      /**
       * @default 'Explore Cash Funds'
       */
      buttonLabel: T;
    };
    weddingBrowserButton: {
      /**
     * @default 'Add anything from
anywhere. Like, literally.'
*/
      title: T;

      /**
       * @default 'Install Quick Add Button'
       */
      buttonLabel: T;
    };
    weddingMobileApp: {
      /**
     * @default 'Add anything from
anywhere. Like, literally.'
*/
      title: T;

      /**
       * @default 'Install Quick Add Button'
       */
      buttonLabel: T;
    };
    babyFriendsRegistry: {
      /**
       * @default 'Only with Joy'
       */
      eyebrow: T;

      /**
     * @default 'Add items from
anyone’s registry.'
*/
      title: T;

      /**
       * @default 'We’ll collect their gifts so you can pick what to add.'
       */
      text: T;

      /**
       * @default 'Scan and Add Gifts'
       */
      buttonLabel: T;
    };
    babyBrowserButton: {
      /**
     * @default 'Register for
anything. Literally.'
*/
      title: T;

      /**
       * @default 'Install the Quick Add Button'
       */
      buttonLabel: T;
    };
    babyCashFunds: {
      /**
     * @default 'Zero-Fee
Cash Funds.'
*/
      title: T;

      /**
       * @default 'Explore Cash Funds'
       */
      buttonLabel: T;
    };
    babyChecklists: {
      /**
     * @default 'Don't know
what to add?'
*/
      title: T;

      /**
       * @default 'Try Checklist'
       */
      buttonLabel: T;
    };
    babyThankYouNotes: {
      /**
     * @default 'Automagic
Thank You Notes.'
*/
      title: T;

      /**
       * @default 'Watch the Video'
       */
      buttonLabel: T;
    };
  };
  mainPromo: {
    /**
     * @default 'The registry trusted by millions.'
     */
    title: T;

    /**
     * @default 'Explore, search, or browse the shop to build your happily ever after.'
     */
    description: T;
  };
  exploreRegistry: {
    /**
     * @default 'Explore Your Registry'
     */
    headerTitle: T;

    /**
     * @default 'Check out
The Check List'
*/
    tryCheckListTitle: T;

    /**
     * @default 'Top Gifts
Under $100'
*/
    topGiftsTitle: T;

    /**
     * @default 'Add a
Cash Fund'
*/
    addCashFundTitle: T;

    /**
     * @default 'Go Dutch'
     */
    goDutchTitle: T;

    /**
     * @default 'Shop by Brand'
     */
    shopByBrandTitle: T;

    /**
     * @default 'Explore
Curations'
*/
    exploreCurationsTitle: T;
  };
  productRecommendations: {
    /**
     * @default 'No product recommendations here yet.
Select your interests from the dropdown.'
*/
    emptyState: T;

    /**
     * @default 'Show All {{count}}'
     */
    showAll: T;

    interestsDropdown: {
      /**
       * @default 'Select your interests'
       */
      empty: T;

      /**
       * @default 'Because you like <interest/>'
       */
      liked: T;

      /**
       * @default 'Add'
       */
      add: T;

      /**
       * @default 'Remove'
       */
      remove: T;
    };
  };
};

export type RetargetTravelDialogTranslations<T extends LeafNodeValue = TranslateFunction> = {
  text: {
    /**
     * @default 'Preparing for a wedding guest extravaganza?'
     */
    titleText: T;

    /**
     * @default 'Our Hotel Concierge team specializes in making wedding guest stays a breeze.'
     */
    infoText: T;

    /**
     * @default 'Enjoy exclusive group rate discounts without requiring your credit card as a guarantee for your guests.'
     */
    subText: T;

    /**
     * @default 'Learn More'
     */
    ctaText: T;
  };
};

export type RsvpReminderTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Send RSVP Reminder'
   */
  title: T;

  editMessage: {
    /**
     * @default 'From'
     */
    fromLabel: T;

    /**
     * @default 'Your message'
     */
    messageLabel: T;

    /**
     * @default 'RSVP Link'
     */
    rsvpLinkLabel: T;

    /**
     * @default 'Link is not editable to prevent abuse and misuse of the platform.'
     */
    rsvpLinkLabelHint: T;
  };
  confirmAndSend: {
    /**
     * @default 'Almost done!'
     */
    header: T;

    /**
     * @default 'Review the final details and treat yourself afterwards. You’ve earned it.'
     */
    body: T;

    confirmAndSendActions: {
      addGuests: {
        hed: {
          /**
           * @default 'Add recipients'
           */
          guest_0: T;

          /**
           * @default 'Add recipients'
           * @param {number} count - The number of ocurrencies.
           * - singular: Add recipients
           * - plural: Edit recipients
           */
          guest: T;
        };

        /**
         * @default 'We have preselected guests who have not RSVP’d, but you can edit our selection if you wish.'
         */
        dek: T;

        buttonText: {
          /**
           * @default 'Select Guests'
           */
          guest_0: T;

          /**
           * @default 'Select Guests'
           * @param {number} count - The number of ocurrencies.
           * - singular: Select Guests
           * - plural: {{count}} Guests Selected…
           */
          guest: T;
        };

        /**
         * @default 'Modify Recipients'
         */
        buttonTextHover: T;
      };
      testEmail: {
        /**
         * @default 'Do an optional final check'
         */
        hed: T;

        /**
         * @default 'Never hurts to make sure everything is just right.'
         */
        dek: T;

        /**
         * @default 'Send Test Email'
         */
        buttonText: T;
      };
      send: {
        /**
         * @default 'Send Reminder'
         */
        hed: T;

        /**
         * @default 'Send reminder to all guests you picked.'
         */
        dek: T;

        /**
         * @default 'Send Now'
         */
        buttonText: T;
      };
    };
    EmailSentSuccessMessage: {
      /**
       * @default 'Your email is on the way!'
       * @param {number} count - The number of ocurrencies.
       * - singular: Your email is on the way!
       * - plural: Your emails are on the way!
       */
      guest: T;
    };

    /**
     * @default 'Sorry, something may have gone wrong'
     */
    EmailSentErrorMessage: T;

    /**
     * @default 'Please, select guests first'
     */
    noGuestsSelectedErrorMessage: T;

    /**
     * @default 'Email is not valid'
     */
    emailInvalidMessage: T;

    confirmationScreen: {
      /**
       * @default 'Your reminder to RSVP has been sent to {{count}} people.'
       */
      hed: T;

      /**
       * @default 'Patience is a virtue, but these great rates won’t wait!'
       */
      dek: T;

      /**
       * @default 'You can track their responses by clicking the link below.'
       */
      buttonText: T;
    };
    sendTestEmailDialog: {
      /**
       * @default 'Send Test Email'
       */
      header: T;

      /**
       * @default 'Name'
       */
      testNameLabel: T;

      /**
       * @default 'Email'
       */
      emailLabel: T;

      /**
       * @default 'First Name'
       */
      testNamePlaceholder: T;

      /**
       * @default 'pat@withjoy.com'
       */
      emailPlaceholder: T;

      /**
       * @default 'Name is required'
       */
      testNameErrorMessage: T;

      /**
       * @default 'Email is required'
       */
      emailErrorMessage: T;

      /**
       * @default 'Should be a valid email address'
       */
      emailInvalidErrorMessage: T;

      /**
       * @default 'Send'
       */
      sendButtonText: T;

      /**
       * @default 'Cancel'
       */
      cancelButtonText: T;
    };
  };
};

export type SettingsTranslations<T extends LeafNodeValue = TranslateFunction> = {
  header: {
    /**
     * @default 'Event Settings'
     */
    eventSettings: T;

    /**
     * @default 'Home'
     */
    home: T;

    /**
     * @default 'Help'
     */
    help: T;
  };
  nav: {
    /**
     * @default 'General'
     */
    general: T;

    /**
     * @default 'Website'
     */
    website: T;

    /**
     * @default 'Admins'
     */
    admins: T;
  };
  inputJoyUrl: {
    /**
     * @default 'withjoy.com/'
     */
    prefix: T;

    /**
     * @default 'This URL is available'
     */
    available: T;

    /**
     * @default 'This URL is not available'
     */
    notAvailable: T;

    /**
     * @default 'Oops! Something went wrong. 
 Please reload the page and try again.'
*/
    error: T;

    /**
     * @default 'Max characters'
     */
    labelMaxCharacters: T;
  };
  website: {
    /**
     * @default 'Website'
     */
    title: T;

    /**
     * @default 'Event Password'
     */
    eventPassword: T;

    /**
     * @default 'Password'
     */
    password: T;

    /**
     * @default 'Copy Event Password'
     */
    copyPassword: T;

    /**
     * @default 'Password copied.'
     */
    passwordCopiedToast: T;

    /**
     * @default 'Edit Password'
     */
    editPassword: T;

    /**
     * @default 'Save'
     */
    save: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Please Note:'
     */
    pleaseNote: T;

    /**
     * @default 'If you changed your password, guests will no longer be able to use your current password to unlock your event.'
     */
    passwordChangeNote: T;

    /**
     * @default 'Guests will need to know your Joy event URL and event password to find and join your event on the app.'
     */
    passwordNote: T;

    /**
     * @default 'Password protect event date'
     */
    protectEventDate: T;

    /**
     * @default 'Password protect your event date so guests with password can access this information.'
     */
    protectEventDateNote: T;

    /**
     * @default 'Password protect event location'
     */
    protectEventLocation: T;

    /**
     * @default 'Password protect your event location so guests with password can access this information.'
     */
    protectEventLocationNote: T;

    /**
     * @default 'Event Settings saved'
     */
    settingsUpdateSuccess: T;

    /**
     * @default 'Error updating settings. Please try again later.'
     */
    settingsUpdateError: T;

    /**
     * @default 'Page settings saved.'
     */
    pageSettingsSaved: T;

    /**
     * @default 'Error saving page settings. Please try again later.'
     */
    pageSettingsError: T;

    /**
     * @default 'Page order saved.'
     */
    pageOrderSaved: T;

    /**
     * @default 'Error saving page order. Please try again later.'
     */
    pageOrderError: T;

    /**
     * @default 'Search Privacy'
     */
    searchPrivacy: T;

    /**
     * @default 'Allow guests to search for my event on Joy'
     */
    showOnGuestSearch: T;

    /**
     * @default 'Help guests find your website and registry if they’ve lost the link. Search results are not guaranteed.'
     */
    showOnGuestSearchNote: T;

    /**
     * @default 'Allow my event to be found on Google etc.'
     */
    showOnSearchEngine: T;

    /**
     * @default 'Search engine changes may take anywhere from a few days to weeks to take effect. Search results are not guaranteed.'
     */
    showOnSearchEngineNote: T;

    /**
     * @default 'Learn More'
     */
    learnMore: T;

    /**
     * @default 'Email verification is required.'
     */
    emailVerificationRequired: T;

    /**
     * @default 'Verify Email'
     */
    verifyEmail: T;

    /**
     * @default 'Pages'
     */
    pages: T;

    /**
     * @default 'Modify All…'
     */
    modifyAll: T;

    /**
     * @default 'Password Protections'
     */
    passwordProtections: T;

    /**
     * @default 'Edit'
     */
    edit: T;

    /**
     * @default 'None of your pages are password protected.'
     */
    nonePasswordProtected: T;

    /**
     * @default 'Some of your pages are password protected.'
     */
    somePagesProtected: T;

    /**
     * @default 'All of your pages are password protected.'
     */
    allPagesProtected: T;

    /**
     * @default 'All of your pages are hidden.'
     */
    allPagesHidden: T;

    /**
     * @default 'Announcement Banner'
     */
    announcementBanner: T;

    /**
     * @default 'Display announcement banner'
     */
    displayAnnouncementBanner: T;

    /**
     * @default 'Make sure guests don't miss an update or important detail when they arrive on your website.'
     */
    displayAnnouncementBannerNote: T;

    /**
     * @default 'Title'
     */
    bannerTitle: T;

    /**
     * @default 'Message'
     */
    message: T;

    /**
     * @default 'Preview Banner'
     */
    previewBanner: T;

    /**
     * @default 'Countdown and App Link'
     */
    countdownAndAppLink: T;

    /**
     * @default 'Display event countdown'
     */
    displayEventCountdown: T;

    /**
     * @default 'Countdown to your {{eventName}} for {{date}}. You can edit event times in your'
     */
    displayCountdownNoteWithEvent: T;

    /**
     * @default 'Countdown to {{date}}.'
     */
    displayCountdownNoteWithoutEvent: T;

    /**
     * @default 'Schedule.'
     */
    schedule: T;

    /**
     * @default 'Schedule Event'
     */
    scheduleEvent: T;

    /**
     * @default 'Display "Get the App" link'
     */
    getTheAppTitle: T;

    /**
     * @default 'Give guests a link to download the Joy app so they can upload photos and access event details on the go.'
     */
    getTheAppSubtitle: T;

    /**
     * @default 'App Photo'
     */
    appPhoto: T;

    /**
     * @default 'Update Photo'
     */
    updatePhoto: T;

    /**
     * @default 'Delete Photo'
     */
    deletePhoto: T;

    /**
     * @default 'Error uploading photo. Please try again later.'
     */
    uploadError: T;

    /**
     * @default 'Photo uploaded successfully.'
     */
    uploadSuccess: T;

    /**
     * @default 'Error deleting photo. Please try again later.'
     */
    deletePhotoError: T;

    /**
     * @default 'Photo deleted successfully.'
     */
    deletePhotoSuccess: T;

    /**
     * @default 'Add an event to your schedule to customize'
     */
    scheduleEmptyPlaceholder: T;

    /**
     * @default 'Select...'
     */
    scheduleNotSelectedPlaceholder: T;

    /**
     * @default 'We have determined that your website contains potentially dangerous content which is in breach of our terms of service. Please contact our customer support team to resolve this issue.'
     */
    unSafeEventInfoText: T;

    /**
     * @default 'Contact Us'
     */
    contactSupport: T;

    /**
     * @default 'No page photo'
     */
    noPagePhoto: T;
  };
  general: {
    /**
     * @default 'General'
     */
    title: T;

    /**
     * @default 'Joy Event URL'
     */
    joyEventURL: T;

    /**
     * @default 'Edit'
     */
    edit: T;

    /**
     * @default 'Add a Custom Domain'
     */
    addCustomDomain: T;

    /**
     * @default 'Copy Event URL'
     */
    copyEventURL: T;

    /**
     * @default 'Event URL copied.'
     */
    eventUrlCopiedToast: T;

    /**
     * @default 'Custom domain URL copied.'
     */
    customDomainCopiedToast: T;

    /**
     * @default 'Event URL'
     */
    eventUrl: T;

    /**
     * @default 'Your old Joy URL will no longer work - this means you'll need to let all your guests know the new address so they can still access your website. Please note that if you have a custom domain, you will also need to update your forwarding URL to point to this new Joy URL.'
     */
    eventUrlNote: T;

    /**
     * @default 'Event URL updated'
     */
    eventUrlUpdateSuccess: T;

    /**
     * @default 'Error updating event URL. Please try again later.'
     */
    eventUrlUpdateError: T;

    /**
     * @default 'Personal Info'
     */
    personalInfo: T;

    /**
     * @default 'Partner One'
     */
    partnerOne: T;

    /**
     * @default 'Partner Two'
     */
    partnerTwo: T;

    /**
     * @default 'Event Display Name'
     */
    eventDisplayName: T;

    /**
     * @default 'First Name'
     */
    firstName: T;

    /**
     * @default 'Last Name'
     */
    lastName: T;

    /**
     * @default 'First name is required'
     */
    firstNameError: T;

    /**
     * @default 'Event display name cannot be blank'
     */
    eventDisplayNameError: T;

    /**
     * @default 'Date'
     */
    date: T;

    /**
     * @default 'Please re-enter the date in MM/DD/YYYY format'
     */
    invalidDate: T;

    /**
     * @default 'Show date in website and app'
     */
    showDateInApp: T;

    /**
     * @default 'When you hide your date, it won’t appear on your website or app and your countdown will be disabled.'
     */
    showDateInAppInfo: T;

    /**
     * @default 'Location'
     */
    location: T;

    /**
     * @default 'Powered by Google'
     */
    poweredBy: T;

    /**
     * @default 'Time Zone'
     */
    timeZone: T;

    /**
     * @default 'Delete Event'
     */
    deleteEvent: T;

    /**
     * @default 'Learn More'
     */
    learnMore: T;

    /**
     * @default 'Custom Domain'
     */
    customDomain: T;

    /**
     * @default 'Remove Domain'
     */
    removeDomain: T;

    /**
     * @default 'Copy Custom Domain URL'
     */
    copyCustomDomainUrl: T;

    /**
     * @default 'Need Help?'
     */
    needHelp: T;

    /**
     * @default 'Custom domain updated'
     */
    customDomainUpdateSuccess: T;

    /**
     * @default 'Error updating custom domain. Please try again later.'
     */
    customDomainUpdateError: T;

    /**
     * @default 'Custom domain removed'
     */
    customDomainRemoveSuccess: T;

    /**
     * @default 'Error removing custom domain. Please try again later.'
     */
    customDomainRemoveError: T;

    findCustomDomainDialog: {
      /**
       * @default 'How would you like to add a custom domain?'
       */
      title: T;

      /**
       * @default 'Find the perfect domain'
       */
      ctaLabel1: T;

      /**
       * @default 'I already have a domain'
       */
      ctaLabel2: T;

      /**
       * @default 'Custom domains are an option service provided by GoDaddy not Joy. Domain masking is not supported.'
       */
      note: T;
    };
    addCustomDomainDialog: {
      /**
       * @default 'What’s your custom domain?'
       */
      title: T;

      /**
       * @default 'We’ll walk you through how to connect your domain to your Joy website.'
       */
      subtitle: T;

      /**
       * @default 'Save'
       */
      ctaLabel1: T;

      /**
       * @default 'Cancel'
       */
      ctaLabel2: T;

      /**
       * @default 'Custom domains are an option service provided by GoDaddy not Joy. Domain masking is not supported.'
       */
      note: T;

      /**
       * @default 'Your Domain Name'
       */
      yourDomainName: T;

      /**
       * @default 'yourdomain.com'
       */
      placeholder: T;

      /**
       * @default 'Test URL'
       */
      testUrl: T;

      /**
       * @default 'Domain name cannot be blank'
       */
      blankDomainError: T;

      /**
       * @default 'Not a valid domain'
       */
      notValidDomainError: T;

      quickAccess: [
        {
          /**
           * @default 'search_domain'
           */
          id: T;

          /**
           * @default 'Search Again'
           */
          title: T;

          /**
           * @default 'Find the domain you’re looking for.'
           */
          subtitle: T;

          /**
           * @default 'Search Domains'
           */
          ctaLabel: T;
        },
        {
          /**
           * @default 'faq'
           */
          id: T;

          /**
           * @default 'Have Questions?'
           */
          title: T;

          /**
           * @default 'Our FAQ section may have the answers.'
           */
          subtitle: T;

          /**
           * @default 'Explore FAQs'
           */
          ctaLabel: T;
        },
        {
          /**
           * @default 'chat'
           */
          id: T;

          /**
           * @default 'Have More Questions'
           */
          title: T;

          /**
           * @default 'Our team is just a chat bubble away.'
           */
          subtitle: T;

          /**
           * @default 'Chat with Us'
           */
          ctaLabel: T;
        }
      ];
    };
    removeCustomDomainDialog: {
      /**
       * @default 'Remove your custom domain?'
       */
      title: T;

      /**
       * @default 'Removing your custom domain removes it from your eCards but it will not disconnect it with your domain host.'
       */
      subtitle: T;

      /**
       * @default 'Need help?'
       */
      needHelp: T;

      /**
       * @default 'Remove Domain'
       */
      ctaLabel1: T;

      /**
       * @default 'Cancel'
       */
      ctaLabel2: T;
    };
  };
  users: {
    /**
     * @default 'Admins'
     */
    title: T;
  };
};

export type SharedComponentsTranslations<T extends LeafNodeValue = TranslateFunction> = {
  registryGiftBasket: {
    /**
     * @default 'My Registry'
     */
    registryManageRedirectCTA: T;

    /**
     * @default 'Registry Guide'
     */
    giftListProgressTitle: T;

    giftOverlay: {
      giftList: {
        /**
         * @default '{{count}} Gifts'
         */
        tabLabel: T;

        /**
         * @default 'Advisor'
         */
        tabLabelV2: T;

        /**
         * @default 'Recently Added Gifts'
         */
        headerMsg: T;

        /**
         * @default 'Plus {{count}} more gifts in your registry'
         */
        overflowMsg: T;

        /**
         * @default 'Add some gifts and we’ll help you keep track of your most recent adds here.'
         */
        zeroStateMsg: T;

        /**
         * @default 'Manage Gifts'
         */
        fabBtn: T;
      };
      giftTracker: {
        /**
         * @default '{{count}} Tracked'
         */
        tabLabel: T;

        /**
         * @default 'Recently Reserved or Purchased'
         */
        headerMsg: T;

        /**
         * @default 'Plus {{count}} more gifts tracked in your registry'
         */
        overflowMsg: T;

        /**
         * @default 'There are no gifts reserved (yet!). Once gifts are reserved or purchased, you can preview them here.'
         */
        zeroStateMsg: T;

        /**
         * @default 'Track Gifts'
         */
        fabBtn: T;
      };
      checklist: {
        /**
         * @default 'Building your Checklist…'
         */
        buildingChecklist: T;

        /**
         * @default 'Checklist'
         */
        tabLabel: T;

        /**
         * @default ''
         */
        headerMsg: T;

        /**
         * @default ''
         */
        overflowMsg: T;

        /**
         * @default ''
         */
        zeroStateMsg: T;

        /**
         * @default ''
         */
        fabBtn: T;
      };
      shoppingCart: {
        /**
         * @default 'Cart'
         */
        tabLabel: T;

        /**
         * @default ''
         */
        headerMsg: T;

        /**
         * @default ''
         */
        overflowMsg: T;

        /**
         * @default ''
         */
        zeroStateMsg: T;

        /**
         * @default ''
         */
        fabBtn: T;
      };

      /**
       * @default 'There are no gifts in your registry yet. Once you start adding gifts, you can preview recently added items here.'
       */
      giftListZeroState: T;

      /**
       * @default 'There are no gifts reserved (yet!). Once gifts are reserved or purchased, you can preview them here.'
       */
      giftTrackerZeroState: T;

      trackedGiftBadges: {
        /**
         * @default 'Reserved'
         */
        reserved: T;

        /**
         * @default 'Purchased'
         */
        purchased: T;

        /**
         * @default 'Shipped'
         */
        shipped: T;

        /**
         * @default 'Processing'
         */
        processing: T;

        /**
         * @default 'Given'
         */
        given: T;
      };
    };
    floatingWidget: {
      /**
       * @default '{{count}} Gifts'
       */
      giftCount: T;

      /**
       * @default 'Checklist'
       */
      checklist: T;
    };
  };
  giftProgress: {
    /**
     * @default 'Start adding some gifts! 🎁'
     */
    progressNotStarted: T;

    /**
     * @default 'Keep going, you're doing great! 👍'
     */
    progressLessThanHalf: T;

    /**
     * @default 'You're halfway there! 🙌'
     */
    halfwayProgress: T;

    /**
     * @default 'You're so close! 💃'
     */
    progressAlmostComplete: T;

    /**
     * @default 'Congrats, you did it! 🎉'
     */
    progressComplete: T;

    /**
     * @default 'You're super awesome! 🦄✨'
     */
    progressBeyondComplete: T;

    /**
     * @default '{{count}} gifts added!'
     */
    giftsAdded: T;
  };
  registryTabsNavigation: {
    tabs: {
      /**
       * @default 'Overview'
       */
      overview: T;

      /**
       * @default 'Add Gifts'
       */
      gifts: T;

      /**
       * @default 'Track'
       */
      trackNative: T;

      /**
       * @default 'Track Gifts'
       */
      track: T;

      /**
       * @default 'Manage'
       */
      manageNative: T;

      /**
       * @default 'Manage Registry'
       */
      manage: T;

      /**
       * @default 'Menu'
       */
      menu: T;

      /**
       * @default 'Add'
       */
      giftsNative: T;
    };
  };
  countdownGiftCount: {
    greeting: {
      /**
       * @default '{{count}} days ago ️💘'
       */
      countdownPostWedding: T;

      /**
       * @default 'Today's the big day! 🥂'
       */
      weddingDay: T;

      /**
       * @default '🎊 {{count}} day to go!'
       * @param {number} count - The number of ocurrencies.
       * - singular: 🎊 {{count}} day to go!
       * - plural: 🎊 {{count}} days to go!
       */
      countdownToWedding: T;

      /**
       * @default 'Happy {{dayOfWeek}}!'
       */
      dailyMessage: T;
    };
    counters: {
      /**
       * @default 'Gifts Available'
       */
      giftsAddedLabel: T;

      /**
       * @default 'Reserved & Purchased'
       */
      reservedAndPurchaseLabel: T;
    };
  };
  missingGifts: {
    /**
     * @default 'Looking for a gift?'
     */
    missingGiftsLabel: T;

    /**
     * @default 'To track gifts on linked or synced registries, please visit those registries directly.'
     */
    trackGiftsLabel: T;
  };
  dialog: {
    /**
     * @default 'close dialog'
     */
    closeDialog: T;
  };
  registryGiftCounts: {
    /**
     * @default 'Registry Advisor'
     */
    registryGuide: T;

    /**
     * @default 'Gift Counts by Price'
     */
    countsByPrice: T;

    /**
     * @default 'Goal: {{targetCount}}'
     */
    giftCounts: T;

    /**
     * @default '{{giftCount}} of {{targetCount}} Gift Goal'
     */
    giftCountWithTarget: T;

    /**
     * @default '{{giftCount}} gifts added!'
     */
    totalCompletionCount: T;

    /**
     * @default 'Gift count is calculated with your potential guest count. Remember to include potential guests for engagement parties and showers.'
     */
    toolTip: T;

    /**
     * @default 'Please enter a valid number.'
     */
    inputError: T;

    /**
     * @default 'Save Estimate'
     */
    save: T;

    /**
     * @default 'How many guests are you inviting?'
     */
    howManyGuests: T;

    /**
     * @default 'Not sure? Just guess for now.'
     */
    guessForNow: T;

    /**
     * @default 'Under {{price}}'
     */
    underPrice: T;

    /**
     * @default 'Over {{price}}'
     */
    overPrice: T;

    /**
     * @default 'Edit Guest Count'
     */
    ariaEditLabel: T;

    /**
     * @default 'Browse'
     */
    button: T;

    /**
     * @default 'of '
     */
    of: T;

    /**
     * @default ' Gift Goal'
     */
    giftGoal: T;

    /**
     * @default ' Gifts Added'
     */
    giftsAdded: T;
  };
  safeLink: {
    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Proceed'
     */
    proceed: T;

    /**
     * @default 'Close'
     */
    close: T;

    /**
     * @default 'You are now leaving our website'
     */
    safeLinkTitle: T;

    /**
     * @default 'Unsafe link detected'
     */
    unsafeLinkTitle: T;

    /**
     * @default 'You are about to leave Joy. We are not responsible for the content or availability of the linked site. Please review the site's privacy and security policy as they may differ from Joy's.'
     */
    safeLinkMessage: T;

    /**
     * @default 'External Link'
     */
    externalLinkLabel: T;

    /**
     * @default 'We have determined that this external link is not safe to visit. Please contact the event owner for more details.'
     */
    unsafeLinkMessage: T;

    /**
     * @default 'Thank you for visiting!'
     */
    emptyTabTitle: T;

    /**
     * @default 'You can now close this tab and continue exploring our site.'
     */
    emptyTabMessage: T;
  };
  shippingAddressDialog: {
    /**
     * @default 'Add Your Shipping Address for Registry'
     */
    reminderTitle: T;

    /**
     * @default 'Add Shipping Address'
     */
    addShippingAddressBtn: T;

    /**
     * @default 'Avoid missing packages and awkward texts, we’ll take care of the rest.'
     */
    reminderDescription: T;

    /**
     * @default 'Remind me Later'
     */
    remindMeLater: T;

    /**
     * @default 'Where should guests send your gifts?'
     */
    shippingAdressFormTitle: T;

    /**
     * @default 'Address Suggestion'
     */
    addressSuggestion: T;

    /**
     * @default 'Please select the address you’d like to use.'
     */
    addressSuggestionDescription: T;

    /**
     * @default 'Confirm'
     */
    confirmAddress: T;

    /**
     * @default 'Back'
     */
    back: T;

    /**
     * @default 'Save Address'
     */
    saveAddressBtn: T;

    /**
     * @default 'Clear Address'
     */
    clearBtn: T;

    /**
     * @default 'Name'
     */
    name: T;

    /**
     * @default 'Only one recipient name is recommended'
     */
    nameSubtext: T;

    /**
     * @default 'Phone Number'
     */
    mobilePhone: T;

    /**
     * @default 'By entering your phone number, you agree to receive account notifications. You can opt out at any time.'
     */
    phoneDisclaimer: T;

    /**
     * @default 'Street Address'
     */
    address1: T;

    /**
     * @default 'We cannot ship to P.O. boxes'
     */
    address1Subtext: T;

    /**
     * @default 'Apartment, Suite, etc. (Optional)'
     */
    address2: T;

    /**
     * @default 'City'
     */
    city: T;

    /**
     * @default 'State/Region/Province'
     */
    state: T;

    /**
     * @default 'Zip/Postal Code'
     */
    zipCode: T;

    /**
     * @default 'Country'
     */
    country: T;

    /**
     * @default '*Required fields'
     */
    requiredText: T;

    /**
     * @default 'Suggested Address'
     */
    suggestedAddressText: T;

    /**
     * @default 'Original Address'
     */
    originalAddressText: T;

    /**
     * @default 'We could not validate your address.'
     */
    addressValidationError: T;

    syncAndTransfer: {
      /**
       * @default 'Tell Guests where to send your gifts!'
       */
      formTitle: T;

      /**
       * @default 'Please note: items sold by Joy can only be shipped within the contingent United States.'
       */
      formDescription: T;
    };
  };
  searchBar: {
    /**
     * @default 'Search brands, products, or paste a URL'
     */
    placeholder: T;
  };
  eventInfoToast: {
    /**
     * @default '✨ Event ID Copied ✨'
     */
    copiedTextToast: T;

    /**
     * @default 'The event id has been copied to your clipboard:'
     */
    copiedText: T;

    /**
     * @default 'event ID:'
     */
    eventId: T;

    /**
     * @default 'firebase event ID:'
     */
    firebaseEventId: T;

    /**
     * @default 'Go to Firebase'
     */
    goToFirebase: T;
  };
  viewMore: {
    /**
     * @default 'View More'
     */
    viewMoreTitle: T;

    /**
     * @default '{{count}} of {{total}} products'
     */
    productCountSummary: T;
  };
};

export type ShareDialogTranslations<T extends LeafNodeValue = TranslateFunction> = {
  text: {
    /**
     * @default 'Share with your guests'
     */
    dialogTitle: T;

    /**
     * @default 'Share this event'
     */
    guestDialogTitle: T;

    /**
     * @default 'Joy URL'
     */
    joyUrlText: T;

    /**
     * @default 'Event Password'
     */
    eventPasswordText: T;

    /**
     * @default 'Edit'
     */
    editLinkText: T;

    /**
     * @default 'Copy'
     */
    copy: T;

    /**
     * @default 'Open in New Tab'
     */
    buttonName: T;

    /**
     * @default '✨ {{copyText}} Copied to Clipboard ✨'
     */
    toastText: T;
  };
};

export type SharedRegistryTranslations<T extends LeafNodeValue = TranslateFunction> = {
  shoppingCart: {
    /**
     * @default 'Stripe Fee'
     */
    stripeFee: T;

    /**
     * @default 'eCard Fee'
     */
    eCardFee: T;

    /**
     * @default 'Cart'
     */
    cartTitle: T;

    /**
     * @default 'Purchase History'
     */
    purchaseHistoryTitle: T;

    /**
     * @default 'Estimated Total: '
     */
    estimatedTotal: T;

    /**
     * @default '{{count}} Gift'
     */
    cartGiftCount: T;

    /**
     * @default '{{count}} Gifts'
     */
    cartGiftCounts: T;

    /**
     * @default 'Quantity: {{quantity}} x {{price}}'
     */
    quantity: T;

    /**
     * @default 'Order #: {{orderNumber}}'
     */
    orderNumber: T;

    /**
     * @default 'Tracking #: {{trackingNumber}}'
     */
    trackingNumber: T;

    /**
     * @default 'Didn't purchase this item?'
     */
    didntPurchase: T;

    /**
     * @default 'Continue to Purchase'
     */
    continuePurchase: T;

    /**
     * @default 'Mark Purchased'
     */
    markPurchased: T;

    /**
     * @default 'Mark as Sent'
     */
    markAsSent: T;

    /**
     * @default 'Cancel Reservation'
     */
    cancelReservation: T;

    /**
     * @default 'Donate Online'
     */
    donationNow: T;

    /**
     * @default 'Send with {{type}}'
     */
    donationSendPlatform: T;

    /**
     * @default 'Send Payment'
     */
    donationSendPayment: T;

    /**
     * @default '🚫 Your gift reservation has been canceled. 🚫'
     */
    removeSuccess: T;

    /**
     * @default 'I did not mean to reserve this gift'
     */
    looking: T;

    /**
     * @default 'The item was not available'
     */
    available: T;

    /**
     * @default 'Changed my mind'
     */
    changed: T;

    /**
     * @default 'Could you tell us what happened?'
     */
    removeTitle: T;

    /**
     * @default 'Cancel Reservation'
     */
    removeItem: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'This item is currently out of stock.'
     */
    outOfStockItemText: T;

    /**
     * @default 'Please purchase a different gift.'
     */
    purchaseOtherGiftText: T;

    /**
     * @default '🎉 This item has been marked as purchased. 🎉'
     */
    purchaseSuccess: T;

    /**
     * @default '🎁 We've moved this item back to your cart. 🎁'
     */
    reservedSuccess: T;

    /**
     * @default 'Your Cart is Empty'
     */
    cartEmptyTitle: T;

    /**
     * @default 'Send {{type}}'
     */
    donationPlatform: T;

    /**
     * @default 'Donate Online'
     */
    donateOnline: T;

    /**
     * @default '{{count}} View Your Gifts'
     */
    viewGifts: T;

    /**
     * @default 'Start Checkout'
     */
    viewGift: T;

    /**
     * @default '🎁 Item added to cart 🎁'
     */
    itemAddedSuccess: T;

    /**
     * @default 'How does this work?'
     */
    helpText: T;

    /**
     * @default 'Something went wrong, try again later'
     */
    errorMessage: T;

    /**
     * @default 'View Order Details'
     */
    viewOrderDetails: T;

    /**
     * @default 'This gift is no longer available'
     */
    hideWarningTitle: T;

    /**
     * @default 'It has been removed from the registry. Please select another gift to give {{host}} instead.'
     */
    hideWarning: T;

    /**
     * @default 'Gift Message'
     */
    giftMessage: T;

    /**
     * @default 'Edit'
     */
    editGiftNote: T;

    /**
     * @default 'Leave a note for the recipient.'
     */
    fieldNotePlaceholder: T;

    /**
     * @default 'Save'
     */
    saveNote: T;

    /**
     * @default 'Add Gift Message'
     */
    addGiftNote: T;

    /**
     * @default 'Your gift note is set to go!'
     */
    messageUpdateSuccess: T;

    /**
     * @default 'Hmmm we had trouble updating your message.'
     */
    messageUpdateError: T;

    /**
     * @default 'Subtotal'
     */
    subtotal: T;

    /**
     * @default 'Total'
     */
    total: T;

    /**
     * @default 'Delivery'
     */
    delivery: T;

    /**
     * @default 'Sales tax'
     */
    salesTax: T;

    /**
     * @default 'FREE'
     */
    freeDeliveryPriceText: T;

    /**
     * @default 'Personalized eCard'
     */
    eCard: T;

    /**
     * @default 'Amount pending'
     */
    pendingSalesTaxPriceText: T;

    /**
     * @default 'Item'
     */
    item: T;

    /**
     * @default 'Items'
     */
    items: T;

    /**
     * @default 'Ordered'
     */
    ordered: T;

    /**
     * @default 'Paid via {{card}} {{cardLastDigits}}'
     */
    paidNote: T;

    /**
     * @default 'Check Out'
     */
    checkout: T;

    /**
     * @default 'No items are in your cart... yet!'
     */
    cartEmptyMessage: T;

    /**
     * @default 'Cart'
     */
    cart: T;

    /**
     * @default 'Shipping'
     */
    shipping: T;

    /**
     * @default 'Details'
     */
    details: T;

    /**
     * @default 'Payment'
     */
    payment: T;

    /**
     * @default 'Order Summary'
     */
    orderSummary: T;

    /**
     * @default 'Close Order Summary'
     */
    closeOrderSummary: T;

    /**
     * @default 'Have you completed your purchase of {{productName}}?'
     */
    completePurchaseConfirmation: T;

    /**
     * @default 'Let us know'
     */
    completePurchaseConfirmationAction: T;

    metadataAtoms: {
      /**
       * @default 'Gift Message'
       */
      giftMessage: T;

      /**
       * @default 'Order Number'
       */
      orderNumber: T;

      /**
       * @default 'Shipping to'
       */
      shippingTo: T;

      /**
       * @default 'Tracking'
       */
      tracking: T;

      /**
       * @default 'Leave a note for the recipient.'
       */
      leaveNoteForCouple: T;

      /**
       * @default 'Save Gift Message'
       */
      saveGiftNoteButton: T;

      /**
       * @default 'Cancel'
       */
      cancelButton: T;
    };
    addressAtoms: {
      /**
       * @default 'We're missing the recipient's address'
       */
      missingCoupleAddress: T;

      /**
       * @default 'Please contact the recipient.'
       */
      contactTheCouple: T;
    };

    /**
     * @default 'Edit Gift Message'
     */
    editGiftMessage: T;

    /**
     * @default 'Buy Now'
     */
    buyNow: T;

    /**
     * @default 'Give Now'
     */
    giveNow: T;

    /**
     * @default 'I Already Purchased'
     */
    alreadyPurchased: T;

    /**
     * @default 'I Already Gave'
     */
    alreadyGave: T;

    /**
     * @default 'Did you purchase this?'
     */
    isPurchased: T;

    /**
     * @default 'Purchased from Joy'
     */
    purchasedFromJoy: T;

    /**
     * @default 'Purchased Elsewhere'
     */
    purchasedElsewhere: T;

    /**
     * @default '{{shippingMethod}} to {{addressName}}'
     */
    cartFooterAddressText: T;

    /**
     * @default 'Modify'
     */
    modifyButton: T;

    /**
     * @default 'Check Out'
     */
    checkOutButton: T;

    /**
     * @default 'Continue to Details'
     */
    continueToDetailsButton: T;

    /**
     * @default 'Continue to Payment'
     */
    continueToPaymentButton: T;

    /**
     * @default 'Continue to Message'
     */
    continueToMessageButton: T;

    uneditedMessageWarning: {
      /**
       * @default 'Unedited Message'
       */
      title: T;

      /**
       * @default 'Are you sure you want to continue without editing the gift message?'
       */
      message: T;

      /**
       * @default 'Back'
       */
      back: T;

      /**
       * @default 'Yes, continue'
       */
      continue: T;
    };
    steps: {
      /**
       * @default 'Cart'
       */
      cart: T;

      /**
       * @default 'Shipping'
       */
      shipping: T;

      /**
       * @default 'Details'
       */
      details: T;

      /**
       * @default 'Message'
       */
      message: T;

      /**
       * @default 'Payment'
       */
      payment: T;
    };
    cartStep: {
      /**
       * @default 'History'
       */
      historyTitle: T;

      /**
       * @default 'Cart'
       */
      cartTitle: T;

      /**
       * @default 'Where are we shipping?'
       */
      addressTitle: T;
    };
    shippingAddressStep: {
      /**
       * @default 'Where are we shipping?'
       */
      'whereAreWeShipping?': T;

      addressForm: {
        /**
         * @default 'Name*'
         */
        nameLabel: T;

        /**
         * @default 'Only one recipient name is recommended'
         */
        nameCaption: T;

        /**
         * @default 'Email*'
         */
        emailLabel: T;

        /**
         * @default 'Address Line 1*'
         */
        addressLine1Label: T;

        /**
         * @default 'Only Non-P.O. Box addresses in the United States'
         */
        addressLine1Caption: T;

        /**
         * @default 'Address Line 2'
         */
        addressLine2Label: T;

        /**
         * @default 'City*'
         */
        cityLabel: T;

        /**
         * @default 'State*'
         */
        stateLabel: T;

        /**
         * @default 'Zip/Postal Code*'
         */
        postalCodeLabel: T;

        /**
         * @default '*Required fields'
         */
        requiredFields: T;

        /**
         * @default 'Save Shipping Address'
         */
        saveButton: T;

        /**
         * @default 'Continue to Checkout'
         */
        saveButtonBilling: T;

        /**
         * @default 'Cancel'
         */
        cancelButton: T;

        /**
         * @default 'Edit Shipping Address'
         */
        editButton: T;
      };

      /**
       * @default 'Free Shipping'
       */
      freeShipping: T;

      /**
       * @default 'Standard Shipping'
       */
      standardShipping: T;

      /**
       * @default 'Shipping'
       */
      shipping: T;

      /**
       * @default 'Shipping Method'
       */
      shippingMethod: T;

      /**
       * @default 'Ship to'
       */
      shipTo: T;

      /**
       * @default '🎁'
       */
      giftEmoji: T;

      /**
       * @default 'Another U.S. Address'
       */
      anotherAddress: T;

      /**
       * @default 'Joy only ships to U.S. addresses'
       */
      onlyUSAddress: T;

      /**
       * @default 'Ship to a different address'
       */
      shipToMe: T;

      /**
       * @default 'We'll tell the recipient about the purchase.'
       */
      weWillTellAboutPurchase: T;
    };
    detailsStep: {
      /**
       * @default 'Shipping Address'
       */
      shippingAddress: T;

      /**
       * @default 'Edit'
       */
      editShippingAddress: T;

      /**
       * @default 'Shipping Method'
       */
      shippingMethod: T;

      /**
       * @default '🎁'
       */
      giftEmoji: T;

      /**
       * @default 'Free Shipping'
       */
      freeShipping: T;

      /**
       * @default 'Standard Shipping'
       */
      standardShipping: T;

      detailsForm: {
        /**
         * @default 'Your Email'
         */
        emailLabel: T;

        /**
         * @default 'Email address'
         */
        emailPlaceholder: T;

        /**
         * @default 'Please enter a valid email'
         */
        validEmailError: T;

        /**
         * @default 'Your Name(s)'
         */
        nameLabel: T;

        /**
         * @default 'Full name or recipient name'
         */
        namePlaceholder: T;

        /**
         * @default 'Gift Message (Optional)'
         */
        messageLabel: T;

        /**
         * @default 'Add an optional note'
         */
        messagePlaceholder: T;
      };
    };
    messageStep: {
      /**
       * @default 'Include a Message (Optional)'
       */
      title: T;

      /**
       * @default 'Personalized eCard'
       */
      wrapTitle: T;

      /**
       * @default 'Wrap your gift note in a beautiful design.'
       */
      wrapDescription: T;

      /**
       * @default 'Basic Gift Note'
       */
      noteTitle: T;

      /**
       * @default 'Gift Note'
       */
      textareaLabel: T;

      /**
       * @default 'Add a note to the couple (optional)'
       */
      textAreaPlaceholder: T;
    };
    paymentStep: {
      /**
       * @default 'Please return to the registry and purchase another item.'
       */
      outOfStockErrorText: T;

      /**
       * @default 'Back to Registry'
       */
      outOfStockErrorAction: T;

      /**
       * @default 'Some items in your cart have already been purchased. Please check and try again'
       */
      notNeededErrorText: T;

      /**
       * @default 'Oops, that didn't work.'
       */
      checkoutErrorTitle: T;

      /**
       * @default 'We're sorry, but there was an error while processing your order. Please try again later or contact our support team at support@withjoy.com for assistance. Thank you for your patience.'
       */
      checkoutErrorText: T;

      /**
       * @default 'Back'
       */
      checkoutErrorAction: T;

      /**
       * @default 'We're sorry, but there seems to be an issue with your address. Please double-check the address in your account settings to ensure everything is correct. If you need further assistance, feel free to reach out to our support team at support@withjoy.com'
       */
      badAddress: T;

      /**
       * @default 'It looks like your transaction is still being verified. Check back soon and thank you for your patience.'
       */
      registryError: T;

      /**
       * @default 'We're sorry, but the item you're looking for is currently out of stock. We're working to replenish our inventory, so please check back later or explore similar options.'
       */
      oos: T;

      /**
       * @default 'We're sorry, but the item you're looking for is currently out of stock. We're working to replenish our inventory, so please check back later or explore similar options.'
       */
      unableDropship: T;
    };
  };
  shoppingCartHelp: {
    /**
     * @default 'How My Cart Works'
     */
    helpTitle: T;

    /**
     * @default 'Reserving Gifts'
     */
    reservingGifts: T;

    /**
     * @default 'When a gift is added to your cart, it is automatically reserved in your name and made unavailable for other gift givers to purchase. Reserved gifts appear at the top of your cart.'
     */
    reservingGiftsDescription: T;

    /**
     * @default 'Purchasing Gifts'
     */
    purchasingGifts: T;

    /**
     * @default 'To check out, just click Continue to Purchase and you can complete your purchase from the retailer that the recipient selected. Please note: Once you're done, be sure to come back here and mark that you have completed your purchase.'
     */
    purchasingGiftsDescription: T;

    /**
     * @default 'What does “mark as purchased” mean?'
     */
    markPurchased: T;

    /**
     * @default 'After you've purchased an item, please mark it as purchased so we can tell the recipient. This is important because it allows the recipient to thank you while also tracking still needed gifts. Purchased gifts appear at the bottom of your cart.'
     */
    markPurchasedDescription: T;

    /**
     * @default 'Where are my past purchases?'
     */
    pastPurchases: T;

    /**
     * @default 'Because Joy doesn't require gift givers to create an account, we use a cookie to track your past purchases. If you need to modify a purchased item that you don't see in your cart, just click on the item you purchased in the registry and enter the email address you used. You'll receive a special link to see your purchase history again.'
     */
    pastPurchasesDescription: T;

    /**
     * @default 'Cancelling Reservations'
     */
    cancelling: T;

    /**
     * @default 'Changed your mind? Please cancel your reservation so other gift givers can purchase the gift.'
     */
    cancellingDescription: T;

    /**
     * @default 'Still need help?'
     */
    stillHelp: T;

    /**
     * @default 'Contact Support'
     */
    contactSupport: T;

    /**
     * @default 'Return Policy'
     */
    returnPolicy: T;
  };
  pageNote: {
    /**
     * @default 'Welcome Note'
     */
    title: T;

    /**
     * @default 'Add a personal message to the top of your registry page for your guests.'
     */
    subtitle: T;

    /**
     * @default 'Note to Guests'
     */
    inputPlaceholder: T;

    /**
     * @default 'of 2000 Max Characters'
     */
    maxCharacters: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Save Note'
     */
    save: T;

    /**
     * @default 'Your presence is enough of a present to us! But for those of you who are stubborn, we've put together a wish-list to help you out, including two charities that are near and dear to our hearts.'
     */
    defaultNote: T;
  };
  giftDialog: {
    /**
     * @default 'Add Gift'
     */
    addGiftTitle: T;

    /**
     * @default 'Edit Gift'
     */
    editGiftTitle: T;

    /**
     * @default 'Link'
     */
    linkField: T;

    /**
     * @default 'Title'
     */
    titleField: T;

    /**
     * @default 'Price'
     */
    priceField: T;

    /**
     * @default 'Quantity'
     */
    qtyField: T;

    /**
     * @default 'Note to Family and Friends'
     */
    noteField: T;

    /**
     * @default 'Optional: Tell folks what this gift means to you, what you'll do with it, or why you want it—have fun with it.'
     */
    noteFieldPlaceholder: T;

    /**
     * @default 'Upload your own photo or search for photos on Unsplash.'
     */
    photoField: T;

    /**
     * @default 'Add Gift'
     */
    addGiftButton: T;

    /**
     * @default 'Cancel'
     */
    cancelButton: T;

    /**
     * @default 'Delete'
     */
    deleteButton: T;

    /**
     * @default 'Save Gift'
     */
    saveGiftButton: T;

    /**
     * @default 'Invalid Link'
     */
    linkInvalid: T;

    /**
     * @default 'Missing title'
     */
    missingTitle: T;

    /**
     * @default 'Gift Type'
     */
    giftTypeTitleTooltip: T;

    /**
     * @default 'Each gift type requires different information.'
     */
    giftTypeBodyTooltip: T;

    /**
     * @default 'Quantity must be greater than 0'
     */
    quantityMinError: T;

    /**
     * @default 'Change your registry currency'
     */
    changeCurrencyTitle: T;

    /**
     * @default 'You can change the currency for your entire registry <currencyLink/>.'
     */
    changeCurrencyBody: T;

    /**
     * @default 'here'
     */
    currencyLinkText: T;

    /**
     * @default 'Joy lets you add gifts from anywhere – just paste a link and enter your gift info.'
     */
    helpText: T;

    /**
     * @default 'Read More'
     */
    readMoreText: T;

    /**
     * @default 'The quantity cannot be less than the amount you’ve already received for this gift.'
     */
    receivedQuantityError: T;

    /**
     * @default 'View Product Details'
     */
    pdpLinkText: T;

    /**
     * @default 'MSRP'
     */
    msrpTitle: T;

    /**
     * @default 'We use the MSRP (manufacturer’s suggested retail price). Prices are subject to change based on retailer.'
     */
    msrpDescription: T;

    /**
     * @default 'Allow Group Gifting'
     */
    allowGroupGifting: T;

    /**
     * @default 'Gift givers will be able to contribute any amount.'
     */
    groupGiftingDescription: T;

    /**
     * @default 'Learn more'
     */
    learnMore: T;

    /**
     * @default 'Payment Settings'
     */
    paymentSettings: T;

    /**
     * @default 'Select how you would like guests to contribute.'
     */
    selectPaymentMethod: T;

    /**
     * @default 'By turning on group gifting, we will turn this gift into a cash fund and help you collect money towards this gift using PayPal, Venmo, or CashApp. You can use that money to purchase the gift. The gift will not automatically get bought and delivered when it is funded. Group gifting is only available for gifts over $350. '
     */
    groupGiftingTooltip: T;

    /**
     * @default 'For more details,'
     */
    moreDetails: T;

    /**
     * @default 'Group Gift'
     */
    groupGift: T;

    /**
     * @default 'Mark gift as…'
     */
    markGiftAs: T;

    /**
     * @default 'Our Most Wanted'
     */
    mostWanted: T;

    /**
     * @default 'Let your guests know your must-have gifts!'
     */
    mostWantedDescription: T;

    /**
     * @default 'Sorry we were unable to retrieve that product, please enter your gift manually.'
     */
    autocompleteError: T;

    /**
     * @default 'MSRP Price ({{currency}})'
     */
    msrpLabel: T;

    /**
     * @default 'Select Purchase Options'
     */
    selectPurchaseOption: T;

    /**
     * @default 'Direct friends and family to the store's website'
     */
    selectPurchaseExternalTitle: T;

    /**
     * @default 'We'll share your address with your friends and family so they can buy and ship the gift themselves.'
     */
    selectPurchaseExternalDescription: T;

    /**
     * @default 'Get cash for my gift'
     */
    getCashEquivalentTitle: T;

    /**
     * @default 'Get cash equivalent for this gift; recommended for optimal guest experience.'
     */
    getCashEquivalentDescription: T;

    /**
     * @default '(U.S only)'
     */
    usOnly: T;

    /**
     * @default 'Get Started'
     */
    getStarted: T;

    /**
     * @default 'Make this a group gift'
     */
    makeGroupGift: T;

    /**
     * @default 'Gift givers will be able to contribute any amount.'
     */
    makeGroupGiftDescription: T;
  };
  cashFundDialog: {
    wedding: {
      /**
       * @default 'First, let’s name your cash fund'
       */
      nameLabel: T;

      /**
       * @default 'Cash/Check'
       */
      cashOrCheckText: T;

      /**
       * @default 'Please enter a gift name.'
       */
      nameEmptyError: T;

      /**
       * @default 'Next'
       */
      nextButton: T;

      /**
       * @default 'Gift Size'
       */
      amountLabel: T;

      /**
       * @default 'Gift size required.'
       */
      amountRequiredError: T;

      /**
       * @default 'Quantity'
       */
      quantityLabel: T;

      /**
       * @default 'Minimum of 1 required.'
       */
      quantityRequiredError: T;

      /**
       * @default 'Please use whole numbers'
       */
      quantityIntegerError: T;

      /**
       * @default 'Total Goal'
       */
      totalGoalLabel: T;

      /**
       * @default 'Payment Method'
       */
      paymentMethodText: T;

      /**
       * @default 'App Payment'
       */
      appPayment: T;

      /**
       * @default 'Venmo, Cash App, or PayPal. Zero fees.'
       */
      appPaymentDescription: T;

      /**
       * @default 'Credit Card'
       */
      creditCardOption: T;

      /**
       * @default 'Accept credit card payments. 2.5% guest fee charged by Stripe.'
       */
      creditCardOptionDescription: T;

      /**
       * @default '(U.S. only)'
       */
      usOnly: T;

      /**
       * @default 'Continue'
       */
      continueText: T;

      /**
       * @default 'Credit Card'
       */
      enableJoyCreditText: T;

      /**
       * @default 'Guests Contribution Methods'
       */
      paymentSettingsText: T;

      /**
       * @default 'Cash or Check'
       */
      enableCashOrCheckText: T;

      /**
       * @default 'Allow guest to give in-person or by mail.'
       */
      enableCashOrCheckDescription: T;

      /**
       * @default 'Use your favorite payment app to avoid extra fees.'
       */
      paymentMethodDescription: T;

      /**
       * @default 'Select Provider'
       */
      paymentMethodLabel: T;

      /**
       * @default 'No payment selected'
       */
      selectPaymentEmptyError: T;

      /**
       * @default 'Invalid username'
       */
      invalidUsernameError: T;

      /**
       * @default 'Your username should not be an e-mail address'
       */
      invalidUsernameIsEmailError: T;

      /**
       * @default 'Test payment link'
       */
      testPaymentLinkText: T;

      /**
       * @default 'Test link'
       */
      testLinkText: T;

      /**
       * @default 'Gift of'
       */
      giftOfText: T;

      /**
       * @default 'Gifts of'
       */
      giftsOfText: T;

      /**
       * @default 'Total'
       */
      totalText: T;

      /**
       * @default 'Note to Family and Friends'
       */
      descriptionLabel: T;

      /**
       * @default 'Optional: Tell folks what this gift means to you, what you'll do with it, or why you want it—have fun with it.'
       */
      descriptionPlaceholder: T;

      /**
       * @default 'Add to Registry'
       */
      addtoRegistryButton: T;

      /**
       * @default 'has been added to your registry'
       */
      confirmationHeader: T;

      /**
       * @default 'Sounds like it’ll be rad!'
       */
      confirmationSubHeader: T;

      /**
       * @default 'Discard registry item?'
       */
      cancelHeader: T;

      /**
       * @default 'This gift won’t be added to your registry.'
       */
      cancelText: T;

      /**
       * @default 'Discard Item'
       */
      yesText: T;

      /**
       * @default 'Nevermind'
       */
      noText: T;

      /**
       * @default 'Get Started'
       */
      getStarted: T;

      /**
       * @default 'Max Chars'
       */
      maxCharacters: T;

      /**
       * @default 'Edit'
       */
      editText: T;

      /**
       * @default 'Save'
       */
      saveButton: T;

      /**
       * @default 'Cancel'
       */
      cancelButton: T;

      /**
       * @default 'Delete'
       */
      deleteButton: T;

      cashFundNameSuggestions: [
        /**
         * @default 'Our Newlywed Fund'
         */
        T,
        /**
         * @default '🛣 Roadtrip!'
         */
        T,
        /**
         * @default '🚙 Family Car Fund'
         */
        T,
        /**
         * @default 'Our Favorite Charity'
         */
        T,
        /**
         * @default 'Our Honeymoon Fund'
         */
        T,
        /**
         * @default '🖼 Museum Membership'
         */
        T,
        /**
         * @default 'New Backpacking Gear'
         */
        T,
        /**
         * @default 'Couples Spa Day'
         */
        T,
        /**
         * @default '🍽 Future Kitchen Fund'
         */
        T,
        /**
         * @default '🏕 Camping Adventure'
         */
        T
      ];

      /**
       * @default 'The quantity cannot be less than the amount you’ve already received for this gift.'
       */
      newQtyError: T;

      /**
       * @default 'The total goal cannot be less than the amount you’ve already received for this gift.'
       */
      newAmountError: T;

      /**
       * @default 'At least one guest has already sent a payment with your previous payment method. Don’t forget!'
       */
      newPaymentMethodWarning: T;

      /**
       * @default 'Charity Fund Name'
       */
      nameLabelCharity: T;

      /**
       * @default 'Donation Method'
       */
      donationMethodText: T;

      /**
       * @default 'Link to Charity'
       */
      donationMethodOptionOneText: T;

      /**
       * @default 'Collect Myself'
       */
      donationMethodOptionTwoText: T;

      /**
       * @default 'Charity Link'
       */
      charityLinkLabel: T;

      /**
       * @default 'ourcharity.org/donate'
       */
      charityLinkPlaceholder: T;

      /**
       * @default 'Invalid link'
       */
      charityLinkInvalidError: T;

      /**
       * @default 'Charity Link Required'
       */
      charityLinkRequiredError: T;

      /**
       * @default 'Use your favorite payment app to collect and donate later.'
       */
      charityPaymentDescription: T;

      charityFundNameSuggestions: [
        /**
         * @default 'Our Favorite Charity'
         */
        T,
        /**
         * @default '🐶 ASPCA'
         */
        T,
        /**
         * @default '✨ Make-A-Wish Foundation'
         */
        T,
        /**
         * @default 'World Wildlife Fund'
         */
        T,
        /**
         * @default 'Leukemia & Lymphoma Society'
         */
        T,
        /**
         * @default 'American Cancer Society'
         */
        T,
        /**
         * @default 'Special Olympics'
         */
        T,
        /**
         * @default 'UNICEF'
         */
        T,
        /**
         * @default 'Habitat for Humanity'
         */
        T,
        /**
         * @default 'United Way Worldwide'
         */
        T
      ];

      /**
       * @default 'Allow Gifts In'
       */
      goalStepLabel: T;

      /**
       * @default 'Any Amount'
       */
      anyAmountText: T;

      /**
       * @default 'Fixed Amount'
       */
      fixedAmountText: T;

      /**
       * @default 'Hide goal from guest'
       */
      hideGoalLabel: T;

      /**
       * @default 'No goal? Just leave it blank.'
       */
      noGoalCaption: T;

      /**
       * @default 'No Goal'
       */
      noGoalText: T;

      /**
       * @default 'Select at least one payment method'
       */
      errorValidation: T;
    };
    babyRegistry: {
      /**
       * @default 'Name'
       */
      nameLabel: T;

      /**
       * @default 'Cash/Check'
       */
      cashOrCheckText: T;

      /**
       * @default 'Please enter a gift name.'
       */
      nameEmptyError: T;

      /**
       * @default 'Next'
       */
      nextButton: T;

      /**
       * @default 'Gift Size'
       */
      amountLabel: T;

      /**
       * @default 'Gift size required.'
       */
      amountRequiredError: T;

      /**
       * @default 'Quantity'
       */
      quantityLabel: T;

      /**
       * @default 'Minimum of 1 required.'
       */
      quantityRequiredError: T;

      /**
       * @default 'Please use whole numbers'
       */
      quantityIntegerError: T;

      /**
       * @default 'Total Goal'
       */
      totalGoalLabel: T;

      /**
       * @default 'Payment Method'
       */
      paymentMethodText: T;

      /**
       * @default 'App Payment'
       */
      appPayment: T;

      /**
       * @default 'Venmo, Cash App, or PayPal. Zero fees.'
       */
      appPaymentDescription: T;

      /**
       * @default 'Credit Card'
       */
      creditCardOption: T;

      /**
       * @default 'Accept credit card payments. 2.5% guest fee.'
       */
      creditCardOptionDescription: T;

      /**
       * @default '(U.S. only)'
       */
      usOnly: T;

      /**
       * @default 'Continue'
       */
      continueText: T;

      /**
       * @default 'Credit Card'
       */
      enableJoyCreditText: T;

      /**
       * @default 'Guests Contribution Methods'
       */
      paymentSettingsText: T;

      /**
       * @default 'Cash or Check'
       */
      enableCashOrCheckText: T;

      /**
       * @default 'Allow gift givers to give in-person or by mail.'
       */
      enableCashOrCheckDescription: T;

      /**
       * @default 'Use your favorite payment app to avoid extra fees.'
       */
      paymentMethodDescription: T;

      /**
       * @default 'Select Provider'
       */
      paymentMethodLabel: T;

      /**
       * @default 'No payment selected'
       */
      selectPaymentEmptyError: T;

      /**
       * @default 'Invalid username'
       */
      invalidUsernameError: T;

      /**
       * @default 'Your username should not be an e-mail address'
       */
      invalidUsernameIsEmailError: T;

      /**
       * @default 'Test payment link'
       */
      testPaymentLinkText: T;

      /**
       * @default 'Test link'
       */
      testLinkText: T;

      /**
       * @default 'Gift of'
       */
      giftOfText: T;

      /**
       * @default 'Gifts of'
       */
      giftsOfText: T;

      /**
       * @default 'Total'
       */
      totalText: T;

      /**
       * @default 'Note to Family and Friends'
       */
      descriptionLabel: T;

      /**
       * @default 'Optional: Tell folks what this gift means to you, what you'll do with it, or why you want it—have fun with it.'
       */
      descriptionPlaceholder: T;

      /**
       * @default 'Add to Registry'
       */
      addtoRegistryButton: T;

      /**
       * @default 'has been added to your registry'
       */
      confirmationHeader: T;

      /**
       * @default 'Sounds like it’ll be rad!'
       */
      confirmationSubHeader: T;

      /**
       * @default 'Discard registry item?'
       */
      cancelHeader: T;

      /**
       * @default 'This gift won’t be added to your registry.'
       */
      cancelText: T;

      /**
       * @default 'Discard Item'
       */
      yesText: T;

      /**
       * @default 'Nevermind'
       */
      noText: T;

      /**
       * @default 'Get Started'
       */
      getStarted: T;

      /**
       * @default 'Max Chars'
       */
      maxCharacters: T;

      /**
       * @default 'Edit'
       */
      editText: T;

      /**
       * @default 'Save'
       */
      saveButton: T;

      /**
       * @default 'Cancel'
       */
      cancelButton: T;

      /**
       * @default 'Delete'
       */
      deleteButton: T;

      cashFundNameSuggestions: [
        /**
         * @default 'Diaper Fund'
         */
        T,
        /**
         * @default 'College Fund'
         */
        T,
        /**
         * @default 'Babymoon'
         */
        T,
        /**
         * @default 'Doula Fund'
         */
        T,
        /**
         * @default 'Meal Fund'
         */
        T,
        /**
         * @default 'Parental Leave Fund'
         */
        T,
        /**
         * @default 'Childcare Fund'
         */
        T,
        /**
         * @default 'Adoption Fund'
         */
        T
      ];

      /**
       * @default 'The quantity cannot be less than the amount you’ve already received for this gift.'
       */
      newQtyError: T;

      /**
       * @default 'The total goal cannot be less than the amount you’ve already received for this gift.'
       */
      newAmountError: T;

      /**
       * @default 'At least one guest has already sent a payment with your previous payment method. Don’t forget!'
       */
      newPaymentMethodWarning: T;

      /**
       * @default 'Charity Fund Name'
       */
      nameLabelCharity: T;

      /**
       * @default 'Donation Method'
       */
      donationMethodText: T;

      /**
       * @default 'Link to Charity'
       */
      donationMethodOptionOneText: T;

      /**
       * @default 'Collect Myself'
       */
      donationMethodOptionTwoText: T;

      /**
       * @default 'Charity Link'
       */
      charityLinkLabel: T;

      /**
       * @default 'ourcharity.org/donate'
       */
      charityLinkPlaceholder: T;

      /**
       * @default 'Invalid link'
       */
      charityLinkInvalidError: T;

      /**
       * @default 'Charity Link Required'
       */
      charityLinkRequiredError: T;

      /**
       * @default 'Use your favorite payment app to collect and donate later.'
       */
      charityPaymentDescription: T;

      charityFundNameSuggestions: [
        /**
         * @default 'Our Favorite Charity'
         */
        T,
        /**
         * @default '🐶 ASPCA'
         */
        T,
        /**
         * @default '✨ Make-A-Wish Foundation'
         */
        T,
        /**
         * @default 'World Wildlife Fund'
         */
        T,
        /**
         * @default 'Leukemia & Lymphoma Society'
         */
        T,
        /**
         * @default 'American Cancer Society'
         */
        T,
        /**
         * @default 'Special Olympics'
         */
        T,
        /**
         * @default 'UNICEF'
         */
        T,
        /**
         * @default 'Habitat for Humanity'
         */
        T,
        /**
         * @default 'United Way Worldwide'
         */
        T
      ];

      /**
       * @default 'Allow Gifts In'
       */
      goalStepLabel: T;

      /**
       * @default 'Any Amount'
       */
      anyAmountText: T;

      /**
       * @default 'Fixed Amount'
       */
      fixedAmountText: T;

      /**
       * @default 'Hide goal from gift givers'
       */
      hideGoalLabel: T;

      /**
       * @default 'No goal? Just leave it blank.'
       */
      noGoalCaption: T;

      /**
       * @default 'No Goal'
       */
      noGoalText: T;

      /**
       * @default 'Select at least one payment method'
       */
      errorValidation: T;
    };
  };
  creditCardSetUpDialog: {
    /**
     * @default 'Set Up Your Joy Wallet'
     */
    joyWalletTitle: T;

    /**
     * @default 'Connect Joy Wallet to your bank or debit card to unlock cash-gift options from your guests for gifts and contributions purchased with a credit card.'
     */
    joyWalletDescription: T;

    /**
     * @default 'Congrats! You can now enable cash-gift options for external items and cash funds for guests wanting to use a credit card. Purchases and contributions through Stripe will be available as soon as tomorrow. Have questions? Feel free to contact us at support@withjoy.com'
     */
    successJoyWalletDescription: T;

    /**
     * @default 'Your guests can now send you cash with a credit card! See your contributions and transfer cash to your bank or debit card using Stripe.'
     */
    description: T;

    /**
     * @default 'Set Up Credit Card for Cash Funds'
     */
    title: T;

    /**
     * @default 'Here’s what you need to get started:'
     */
    subtitle: T;

    /**
     * @default 'Email & phone number'
     */
    recipient: T;

    /**
     * @default 'Need Assistance?'
     */
    needAssistance: T;

    /**
     * @default 'Contact Us'
     */
    contactUs: T;

    /**
     * @default 'U.S. home address'
     */
    address: T;

    /**
     * @default 'Legal first and last name'
     */
    legalName: T;

    /**
     * @default 'U.S. bank or debit card'
     */
    bank: T;

    /**
     * @default 'Let’s Get Started'
     */
    letsGo: T;

    /**
     * @default 'Accept All Major Credit Payments'
     */
    footerText: T;

    /**
     * @default 'Approval is taking longer than expected'
     */
    takingLong: T;

    /**
     * @default 'Stripe needs more information'
     */
    stripeIssue: T;

    /**
     * @default 'Check Stripe Status'
     */
    checkStripeStatus: T;

    /**
     * @default 'Resolve Stripe Issue'
     */
    resolveStripeStatus: T;

    /**
     * @default 'Your account is being reviewed'
     */
    inReview: T;

    /**
     * @default 'This will only take a minute'
     */
    inReviewDuration: T;

    /**
     * @default 'You are the editor but not the owner of this event. Only an owner can set this up.'
     */
    onlyForOwners: T;

    /**
     * @default 'Add a valid U.S. shipping address to continue'
     */
    onlyForUS: T;

    /**
     * @default 'Continue'
     */
    continueText: T;

    /**
     * @default 'Stripe Connect Policy'
     */
    stripeConnect: T;

    /**
     * @default ' Stripe charges a 2.5% processing fee for each contribution and $1.70 per bank or debit transfer'
     */
    stripeDisclaimer: T;

    /**
     * @default 'Your Stripe Account has been Approved!'
     */
    stripeApprovedTitle: T;

    /**
     * @default 'Congrats! You can now add credit cards as a payment option for your new and existing cash funds. Guests can start contributing as soon as tomorrow. You still have the option of adding alternative contribution methods like cash, check, or mobile app payment.'
     */
    stripeApprovedDescription: T;

    /**
     * @default 'Next'
     */
    next: T;

    /**
     * @default 'I’ll Do This Later'
     */
    doLater: T;

    /**
     * @default 'Accept Cash Fund Credit Card Contributions'
     */
    widgetTitle: T;

    /**
     * @default 'Your guests can now send you cash with a credit card!'
     */
    widgetDescription: T;

    /**
     * @default 'Get Started'
     */
    getStarted: T;

    /**
     * @default 'Credit Card Contributions'
     */
    creditCardContributions: T;

    /**
     * @default 'Current Balance'
     */
    currentBalance: T;

    /**
     * @default 'Transfer Balance'
     */
    transferBalance: T;

    /**
     * @default 'Your available balance is updated as contributions are processed and ready to transfer. This can sometimes take 1-3 business days, but is usually instant.'
     */
    balanceTooltip: T;

    /**
     * @default 'Account'
     */
    account: T;
  };
  creditCardWidgetV2: {
    /**
     * @default 'Manage'
     */
    manage: T;

    /**
     * @default 'Transfer'
     */
    transfer: T;

    /**
     * @default 'Joy Wallet'
     */
    joyWallet: T;

    /**
     * @default 'Cash for Gifts'
     */
    cashForGiftsText: T;

    /**
     * @default 'Credit Card Cash Funds'
     */
    cashFundsText: T;

    /**
     * @default 'Total Balance'
     */
    totalBalance: T;

    /**
     * @default 'Why is this different from my available balance?'
     */
    whyText: T;

    /**
     * @default 'You can now receive cash via:'
     */
    footerText: T;

    /**
     * @default 'credit card platforms allowed'
     */
    footerImageAlt: T;

    /**
     * @default 'Available balance sometimes takes 1-3 business days to reflect recent transactions, but is usually instant.'
     */
    balanceTooltip: T;

    /**
     * @default 'Available Balance'
     */
    availableBalance: T;

    /**
     * @default 'Receive cash for your gifts and contributions'
     */
    creditCardSetupTitle: T;

    /**
     * @default 'Exchange eligible gifts for cash, allow gift givers to contribute to your cash funds using a credit card, and more when you create a Joy Wallet!'
     */
    creditCardSetupDescription: T;

    /**
     * @default 'Approval pending...'
     */
    approvalPending: T;

    /**
     * @default 'More information needed.'
     */
    needMoreInfo: T;

    /**
     * @default 'Get Started'
     */
    getStarted: T;

    /**
     * @default 'Need Assistance?'
     */
    needAssistance: T;

    /**
     * @default 'Contact Us'
     */
    contactUs: T;

    /**
     * @default 'You’ll be able to accept payments from:'
     */
    alternativeFooterText: T;
  };
  cashFundDisclaimerDialog: {
    /**
     * @default 'Before proceeding, please double-check that your payment account is correct.'
     */
    title: T;

    warning: {
      /**
       * @default 'If the account handle is incorrect, funds may be sent to the wrong person, and Joy cannot intervene in these transactions.'
       */
      main: T;

      /**
       * @default 'Venmo, Cash App, and PayPal often do not take responsibility in mistaken transactions, meaning you may be left at the mercy of the unintended recipient to return the money. This can create a messy and possibly unresolvable situation where you have to ask your guests to contact the unintended recipient to recover the funds.'
       */
      additional: T;
    };
    paymentAccount: {
      /**
       * @default 'Your Payment Account:'
       */
      label: T;

      /**
       * @default 'Please test that your handle is correct and verify it using a test deposit. You will need to send money after clicking Open “{{paymentMethod}}”'
       */
      test_warning: T;

      /**
       * @default 'Learn more about testing your account.'
       */
      learn_more: T;
    };
    agreement: {
      /**
       * @default 'To proceed, please type I AGREE. By agreeing to these terms you acknowledge that you take full responsibility for ensuring your handle is correct and understand that Joy cannot assist if funds are sent to the wrong person.'
       */
      instruction: T;

      /**
       * @default 'I understand and agree to these terms and conditions.'
       */
      label: T;

      /**
       * @default 'Please type “I AGREE”'
       */
      placeholder: T;
    };
    buttons: {
      /**
       * @default 'Accept Responsibility'
       */
      accept: T;

      /**
       * @default 'Cancel'
       */
      cancel: T;
    };
  };
  payoutDialog: {
    /**
     * @default 'Transfer to Bank'
     */
    title: T;

    /**
     * @default 'You are about to transfer your cash balance to the account or card ending in {{accountNumber}}. This transfer cannot be reversed.'
     */
    description: T;

    /**
     * @default 'Balance'
     */
    balance: T;

    /**
     * @default 'Transfer Fee'
     */
    transferFee: T;

    /**
     * @default 'Total Transfer'
     */
    totalTransfer: T;

    /**
     * @default 'Stripe charges a small fee of $1.70 per transfer to help cover Stripe processing costs. To avoid multiple fees, wait until your cash fund or event is complete, then make a single transfer.'
     */
    feeTooltip: T;

    /**
     * @default 'Yes, Transfer Balance'
     */
    confirmTransfer: T;

    /**
     * @default 'Nevermind'
     */
    nevermind: T;

    /**
     * @default 'Your Transfer is Being Proccessed'
     */
    successTitle: T;

    /**
     * @default 'Woo-hoo! We’re working on moving your cash fund balance to your bank account now. Please note that transferred funds typically arrive within 5 to 7 business days.'
     */
    successDescription: T;

    /**
     * @default 'Close'
     */
    close: T;

    /**
     * @default 'Oops! Something Went Wrong.'
     */
    errorTitle: T;

    /**
     * @default 'We’re unable to complete this transfer. Please ensure the destination account is valid and try again. If this error persists, contact Joy Customer Support.'
     */
    errorDescription: T;

    /**
     * @default 'Sorry! A minimum balance of $2 is needed to process a balance transfer.'
     */
    balanceError: T;
  };
  addressReminder: {
    /**
     * @default 'Now let’s add your shipping address'
     */
    reminderTitleLow: T;

    /**
     * @default 'You’ve picked out some great gifts!'
     */
    reminderTitleModerateLine1: T;

    /**
     * @default 'But where should we send them?'
     */
    reminderTitleModerateLine2: T;

    /**
     * @default 'Where should we send this gift?'
     */
    reminderTitleSevere: T;

    /**
     * @default 'Avoid missing packages and awkward texts!'
     */
    reminderSubtitleLowModerateLine1: T;

    /**
     * @default 'We’ll make sure gift givers know where to send your gifts.'
     */
    reminderSubtitleLowModerateLine2: T;

    /**
     * @default 'Joy requires a shipping address for events happening'
     */
    reminderSubtitleSevereLine1: T;

    /**
     * @default 'within 60 days so your gifts can happily reach you!'
     */
    reminderSubtitleSevereLine2: T;

    /**
     * @default 'Add Shipping Address'
     */
    primaryButton: T;
  };
  publicShop: {
    /**
     * @default 'The All-in-One Registry'
     */
    heroContentHeader: T;

    /**
     * @default 'Shop Here, There, Everywhere.'
     */
    heroContentTitle: T;

    /**
     * @default 'Shop on Joy'
     */
    shopTitle: T;

    /**
     * @default 'Find the perfect gift.'
     */
    shopSubtitle: T;

    /**
     * @default 'Connect Any Registry'
     */
    connectTitle: T;

    /**
     * @default 'Everything in one place.'
     */
    connectSubtitle: T;

    /**
     * @default 'Zero-Fee Cash Funds'
     */
    noFeeTitle: T;

    /**
     * @default 'Yes, really. No fees.'
     */
    noFeeSubtitle: T;

    /**
     * @default 'Shop the Web'
     */
    shopWebTitle: T;

    /**
     * @default 'Add from anywhere.'
     */
    shopWebSubtitle: T;
  };
  departmentNavBar: {
    /**
     * @default 'View {{departmentName}}'
     */
    navItemTooltip: T;
  };
  contact: {
    /**
     * @default '← Please keep this link in the email to make it easier for us to answer your questions.'
     */
    contactSupportMessage: T;
  };
  catalogSearchInput: {
    /**
     * @default 'Search'
     */
    shortPlaceholder: T;

    /**
     * @default 'Search brands, products, and more'
     */
    catalogRoutePlaceholder: T;

    /**
     * @default 'Find new gifts for your Registry'
     */
    manageRoutePlaceholder: T;

    /**
     * @default 'Back to Dashboard'
     */
    homeIconTooltip: T;
  };
  registryNavigationMenu: {
    /**
     * @default 'Event Dashboard'
     */
    eventDashboard: T;

    /**
     * @default 'Dashboard'
     */
    dashboard: T;

    /**
     * @default 'Overview'
     */
    overview: T;

    /**
     * @default 'Shop'
     */
    shop: T;

    /**
     * @default 'Manage'
     */
    manage: T;

    /**
     * @default 'Track'
     */
    track: T;

    /**
     * @default 'Account Info'
     */
    accountInfo: T;

    /**
     * @default 'Manage Events'
     */
    manageEvents: T;

    /**
     * @default 'Sign Out'
     */
    signOut: T;

    /**
     * @default 'Registry'
     */
    registry: T;

    /**
     * @default 'New'
     */
    newBadge: T;

    /**
     * @default 'Help'
     */
    help: T;

    /**
     * @default 'Event Settings'
     */
    eventSettings: T;

    /**
     * @default 'Registry Settings'
     */
    registrySettings: T;
  };
  alreadyReservedWarning: {
    /**
     * @default 'It looks like you’ve already reserved this gift with this email address.'
     */
    title: T;

    /**
     * @default 'We’ve sent you an email with a link to your reservation so you can complete your purchase or cancel your reservation.'
     */
    text1: T;

    /**
     * @default 'Check your inbox at {{email}}'
     */
    text2: T;
  };
  externalOrderTracking: {
    /**
     * @default 'Order & Tracking Information'
     */
    externalTrackingInfoDialogTitle: T;

    /**
     * @default 'Don’t have tracking details? We can send a request to your guest for you. '
     */
    externalTrackingInfoDialogDetails: T;

    /**
     * @default 'This information was provided by {{guestName}}.'
     */
    informationProvided: T;

    /**
     * @default 'Request Order and Tracking Info'
     */
    requestOrderTracking: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Save'
     */
    save: T;

    /**
     * @default 'Order Number (Required)'
     */
    orderID: T;

    /**
     * @default 'Tracking Number'
     */
    trackingNumber: T;

    /**
     * @default 'Need tracking info for your gift? We’ve  got you.'
     */
    requestTrackingInfoDialogTitle: T;

    /**
     * @default 'We’ll send a one-time email to your guest to request the Order ID, Tracking Number and Shipping Carrier for this gift. Once provided, the information will appear in your gift tracker.'
     */
    requestTrackingInfoDialogSubtitle: T;

    /**
     * @default 'Send Request'
     */
    sendRequest: T;

    /**
     * @default 'Sample Email:'
     */
    sampleEmail: T;

    /**
     * @default 'Please enter an order number'
     */
    orderNumberRequiredError: T;

    /**
     * @default 'Please enter an tracking number'
     */
    trackingRequiredError: T;

    /**
     * @default 'Please enter shipping carrier number'
     */
    shippingRequiredError: T;

    /**
     * @default 'Quantity : {{qty}}'
     */
    quantity: T;

    /**
     * @default 'Test Link'
     */
    testLink: T;

    /**
     * @default '✨ Tracking info has been added to {{orderTitle}} ✨ '
     */
    successMessage: T;
  };
  subCatHighlights: {
    /**
     * @default 'Learn More'
     */
    itemButtonLabel: T;
  };
};

export type SlapAdsRegistryTranslations<T extends LeafNodeValue = TranslateFunction> = {
  messageVariants: [
    {
      /**
       * @default 'Add gifts from anywhere. One simple Registry.'
       */
      message: T;

      /**
       * @default 'Start Your Registry'
       */
      btn: T;
    },
    {
      /**
       * @default 'Fact: Registrants who build their registry early get more gifts.'
       */
      message: T;

      /**
       * @default 'Start Your Registry'
       */
      btn: T;
    },
    {
      /**
       * @default 'Pro Tip: Add gifts to your website from anywhere.'
       */
      message: T;

      /**
       * @default 'Start Your Registry'
       */
      btn: T;
    },
    {
      /**
       * @default 'Adding your Registry to your site is easy and fun.'
       */
      message: T;

      /**
       * @default 'Start Your Registry'
       */
      btn: T;
    },
    {
      /**
       * @default 'Browse top brands right from our Registry.'
       */
      message: T;

      /**
       * @default 'Start Your Registry'
       */
      btn: T;
    }
  ];
};

export type SsrServerTranslations<T extends LeafNodeValue = TranslateFunction> = {
  metatags: {
    /**
     * @default 'The only wedding app and website you need'
     */
    title: T;
  };
};

export type StationeryTranslations<T extends LeafNodeValue = TranslateFunction> = {
  cardCustomizer: {
    uploadDesignFloatingMessage: {
      /**
       * @default 'Click or tap here to upload your design.'
       */
      title: T;

      /**
       * @default 'How it works?'
       */
      cta: T;
    };
    qrCodeLinkModal: {
      /**
       * @default 'QR Code Link'
       */
      websiteLinkTitle: T;

      /**
       * @default 'Edit Link'
       */
      websiteLinkTitleDigital: T;

      /**
       * @default 'Edit Your Website Details'
       */
      handleAndPasswordTitle: T;

      websiteLink: {
        /**
         * @default 'Add QR code to your card, so guests can easily access your wedding website and see all the details.'
         */
        description: T;

        /**
         * @default 'Add a link to your card, so guests can easily access your wedding website and see all the details.'
         */
        linkDescription: T;

        /**
         * @default 'Your Website'
         */
        yourWebsiteTitle: T;

        /**
         * @default 'Edit'
         */
        edit: T;

        /**
         * @default 'Edit URL and Password'
         */
        editURLAndPassword: T;

        /**
         * @default 'Uh oh! Your website link seems a bit long, you can edit it to make it shorter and more personal to make it easier for your guests.'
         */
        longHandleWarning: T;

        /**
         * @default 'Your Custom Link'
         */
        customLinkTitle: T;

        /**
         * @default 'Contact Collector'
         */
        contactCollectorTitle: T;

        /**
         * @default 'Please ensure you're using the correct format, starting with http:// or https://'
         */
        invalidCustomLinkError: T;
      };
      editLinkAndPassword: {
        /**
         * @default 'URL'
         */
        url: T;

        /**
         * @default 'This URL is available'
         */
        urlAvailable: T;

        /**
         * @default ' This URL is not available'
         */
        urlNotAvailable: T;

        /**
         * @default '/60 Max Chars'
         */
        urlCharsCounter: T;

        /**
         * @default 'URL cannot be blank.'
         */
        urlRequiredError: T;

        /**
         * @default 'URL must be less than 60 characters long.'
         */
        urlLengthError: T;

        /**
         * @default 'Only letters, numbers and '-' are allowed in your URL.'
         */
        urlCharactersError: T;

        /**
         * @default 'Your old Joy URL will no longer work. If you have a custom domain, you’ll need to update your forwarding URL with your domain provider.'
         */
        urlWarning: T;

        /**
         * @default 'Password'
         */
        password: T;

        /**
         * @default 'Password cannot be blank.'
         */
        passwordRequiredError: T;

        /**
         * @default 'Your password can only contain letters and numbers.'
         */
        passwordCharactersError: T;

        /**
         * @default 'If you change your password, guests will no longer be able to use your current password to unlock your event.'
         */
        passwordWarning: T;

        /**
         * @default 'Cancel'
         */
        cancel: T;

        /**
         * @default 'Update Details'
         */
        updateDetails: T;

        /**
         * @default 'Your Event Website has been updated.'
         */
        successToast: T;

        /**
         * @default 'An error occurred while updating your Event Website. Please try again.'
         */
        errorToast: T;
      };
    };
    exitCustomizationWidget: {
      /**
       * @default 'Exit Customization'
       */
      iconTitle: T;

      /**
       * @default 'We saved your draft!'
       */
      title: T;

      /**
       * @default 'You can always find it in the Drafts tab in the main navigation on the top and keep customizing your card.'
       */
      description: T;

      /**
       * @default 'View Drafts'
       */
      viewDraftsCta: T;
    };
    exitEditingWidget: {
      /**
       * @default 'Are you sure you want to exit editing mode?'
       */
      title: T;

      /**
       * @default 'No changes will be made to the card in your original order.'
       */
      description: T;

      /**
       * @default 'Go Back to Editing'
       */
      backToEditingCta: T;

      /**
       * @default 'Keep Original Card'
       */
      keepOriginalCta: T;
    };
    topNavProgressBar: {
      desktop: {
        steps: {
          /**
           * @default 'Front'
           */
          cardFront: T;

          /**
           * @default 'Back'
           */
          cardBack: T;

          /**
           * @default 'Envelope'
           */
          envelope: T;

          /**
           * @default 'Recipients'
           */
          recipients: T;

          /**
           * @default 'Review'
           */
          review: T;

          /**
           * @default 'Front'
           */
          digitalCardFront: T;

          /**
           * @default 'Back'
           */
          digitalCardBack: T;

          /**
           * @default 'Review'
           */
          digitalCardReview: T;
        };
      };

      /**
       * @default 'Step'
       */
      step: T;
    };
    promoCodeBlock: {
      /**
       * @default 'Promo Code'
       */
      title: T;

      /**
       * @default 'Apply'
       */
      applyCta: T;

      /**
       * @default 'Remove'
       */
      removeCta: T;

      /**
       * @default 'Enter code'
       */
      inputPlaceholder: T;

      /**
       * @default 'Promo code {{code}} applied'
       */
      promoCodeApplied: T;

      /**
       * @default 'This is not a valid promo code.'
       */
      invalidPromoCode: T;
    };
    courierErrorMessage: {
      /**
       * @default 'There seems to be an issue with your order delivery. Please visit'
       */
      text1: T;

      /**
       * @default 'the courier page'
       */
      link: T;

      /**
       * @default 'to check the details.'
       */
      text2: T;
    };
    priceSummary: {
      /**
       * @default 'Order Summary'
       */
      orderSummaryLabel: T;

      discountSection: {
        /**
         * @default 'Promo code {{code}} applied <br />(excludes shipping)'
         */
        label: T;

        /**
         * @default '-{{discount}}%'
         */
        value: T;
      };

      /**
       * @default 'Total'
       */
      totalLabel: T;

      /**
       * @default 'Sales Tax'
       */
      taxLabel: T;
    };
    addressDialog: {
      /**
       * @default 'Cancel'
       */
      cancel: T;

      /**
       * @default 'Save'
       */
      save: T;

      /**
       * @default 'Edit Address'
       */
      editAddress: T;

      /**
       * @default 'Confirm Address'
       */
      confirmAddress: T;

      formValidation: {
        /**
         * @default 'Required'
         */
        required: T;

        /**
         * @default 'We currently support only contiguous US.'
         */
        contiguousUS: T;

        /**
         * @default 'Invalid Email'
         */
        invalidEmail: T;

        /**
         * @default 'This is not a valid ZIP Code.'
         */
        invalidZipCode: T;
      };
      addressSugestion: {
        /**
         * @default 'It is possible the following modified address will ensure a more successful delivery.'
         */
        title: T;

        /**
         * @default 'Please select the address you’d like to use.'
         */
        subTitle: T;

        /**
         * @default 'We couldn’t validate your address, please make sure it’s correct before proceeding to the next step.'
         */
        errorTitle: T;

        /**
         * @default 'Original Address'
         */
        original: T;

        /**
         * @default 'Suggested Address'
         */
        suggested: T;

        /**
         * @default 'Address Provided'
         */
        provided: T;
      };
    };
    checkoutErrorDialog: {
      generic: {
        /**
         * @default 'We’re having trouble placing your order.'
         */
        title: T;

        /**
         * @default 'Open Drafts'
         */
        buttonLabel: T;
      };
      address: {
        /**
         * @default 'Please check your address.'
         */
        title: T;

        /**
         * @default 'Your shipping address could not be validated. Check that the zip/postal code is correct.'
         */
        error: T;

        /**
         * @default 'Edit Shipping Address'
         */
        buttonLabel: T;
      };
      billingAddress: {
        /**
         * @default 'Please check your address.'
         */
        title: T;

        /**
         * @default 'Your billing address could not be validated. Check that the zip/postal code is correct.'
         */
        error: T;

        /**
         * @default 'Edit Billing Address'
         */
        buttonLabel: T;
      };
    };
    contactDialog: {
      /**
       * @default 'Add New Recipient'
       */
      newRecipientTitle: T;

      /**
       * @default 'Edit Recipient’s Details'
       */
      updateRecipientTitle: T;

      /**
       * @default 'There was an error saving the contact'
       */
      mutationError: T;

      /**
       * @default 'Cancel'
       */
      cancel: T;

      /**
       * @default 'Save'
       */
      save: T;

      /**
       * @default 'Edit Address'
       */
      editAddress: T;

      /**
       * @default 'Confirm Address'
       */
      confirmAddress: T;

      formValidation: {
        /**
         * @default 'Required'
         */
        required: T;

        /**
         * @default 'Invalid Email'
         */
        invalidEmail: T;
      };
      formFields: {
        /**
         * @default 'First Name'
         */
        firstName: T;

        /**
         * @default 'Last Name'
         */
        lastName: T;

        /**
         * @default 'Email'
         */
        email: T;

        /**
         * @default 'Phone Number'
         */
        phoneNumber: T;

        /**
         * @default 'Address Line'
         */
        address: T;

        /**
         * @default 'Address Line 1'
         */
        address1: T;

        /**
         * @default 'Address Line 2'
         */
        address2: T;

        /**
         * @default 'City'
         */
        city: T;

        /**
         * @default 'State'
         */
        state: T;

        /**
         * @default 'State/Province/Territory'
         */
        stateOrProvince: T;

        /**
         * @default 'Country/Region'
         */
        country: T;

        /**
         * @default 'Zip/Postal Code'
         */
        postalCode: T;

        /**
         * @default 'Mailing Address'
         */
        mailingAddress: T;

        /**
         * @default 'Guest Details'
         */
        guestDetails: T;

        /**
         * @default 'Invalid Phone Number'
         */
        invalidPhone: T;

        /**
         * @default 'Address'
         */
        address1Placeholder: T;

        /**
         * @default 'Apartment, Suite, etc. (optional)'
         */
        address2Placeholder: T;

        /**
         * @default 'Envelope Name'
         */
        envelopeName: T;

        /**
         * @default 'The Smith Family, Mr. and Mrs. Smith, etc'
         */
        envelopeNamePlaceholder: T;
      };
      addressSugestion: {
        /**
         * @default 'It is possible the following modified address will ensure a more successful delivery.'
         */
        title: T;

        /**
         * @default 'Please select the address you’d like to use.'
         */
        subTitle: T;

        /**
         * @default 'We couldn’t validate your address, please make sure it’s correct before proceeding to the next step.'
         */
        errorTitle: T;

        /**
         * @default 'Original Address'
         */
        original: T;

        /**
         * @default 'Suggested Address'
         */
        suggested: T;

        /**
         * @default 'Address Provided'
         */
        provided: T;
      };
    };
    quantityDialog: {
      /**
       * @default 'Oops, you chose more recipients than cards with envelopes.'
       */
      titleGoToReview: T;

      /**
       * @default 'Oops, you might need more cards with envelopes.'
       */
      titleChangeQuantity: T;

      /**
       * @default 'You selected {{recipientsLength}} recipients, so if you change the quantity of the cards with envelopes to {{quantity}}, that won’t match.'
       */
      subtitle: T;

      /**
       * @default 'You selected {{recipientsLength}} recipients, we currently offer suites up to {{maxQuantity}} cards with envelopes. Please get in touch with our support team, so they can make it happen.'
       */
      cardsNeededInvalid: T;

      /**
       * @default 'cards with envelopes'
       */
      cardsWithEnvelopes: T;

      /**
       * @default 'recipients selected'
       */
      recipientsSelected: T;

      /**
       * @default 'cards still needed'
       */
      cardsStillNeeded: T;

      /**
       * @default 'Get in Touch'
       */
      getInTouch: T;

      /**
       * @default 'Increase Card Quantity to {{getNewQuantity}}'
       */
      increaseQuantity: T;

      /**
       * @default 'Adjust Recipient Selection'
       */
      adjustSelection: T;
    };
    noGuestsDialog: {
      /**
       * @default 'Oops, you didn’t choose any recipients.'
       */
      title: T;

      /**
       * @default 'You requested Recipient Addressing, but no recipients were selected. Please choose recipients to print their addresses on the envelopes.'
       */
      subtitle: T;

      /**
       * @default 'If you don’t select recipients, we will proceed with printing return address, and leave the recipient address field blank, so you can address them yourself.'
       */
      warningReturnAddress: T;

      /**
       * @default 'If you don’t select recipients, we will send you blank envelopes with your cards, so you can address them yourself.'
       */
      warningNoReturnAddress: T;

      /**
       * @default 'Adjust Recipient Selection'
       */
      adjustSelection: T;

      /**
       * @default 'Proceed to Review'
       */
      proceedToReview: T;
    };
    weddingWebsiteLink: {
      /**
       * @default 'Wedding Website Link'
       */
      title: T;

      /**
       * @default 'Joy URL'
       */
      joyURL: T;

      /**
       * @default 'Event Password'
       */
      eventPassword: T;

      /**
       * @default 'Edit'
       */
      editButton: T;

      /**
       * @default 'Link Copied.'
       */
      linkCopiedToast: T;

      /**
       * @default 'Password copied.'
       */
      passwordCopiedToast: T;
    };
    urlDialog: {
      /**
       * @default 'Joy URL'
       */
      title: T;

      /**
       * @default 'URL'
       */
      url: T;

      /**
       * @default 'Please Note:'
       */
      urlNoteTitle: T;

      /**
       * @default 'Your old Joy URL will no longer work. If you have a custom domain, you’ll need to update your forwarding URL with your domain provider.'
       */
      urlNoteMessage: T;

      /**
       * @default 'Cancel'
       */
      cancelButton: T;

      /**
       * @default 'Save'
       */
      saveButton: T;

      /**
       * @default 'URL cannot be blank.'
       */
      handleRequiredError: T;

      /**
       * @default 'URL must be less than 60 characters long.'
       */
      handleLengthError: T;

      /**
       * @default 'Only letters, numbers and '-' are allowed in your URL.'
       */
      handleCharactersError: T;

      /**
       * @default 'Your Event Website has been updated.'
       */
      successUpdateToast: T;

      /**
       * @default 'An error occurred while updating your Event Website. Please try again.'
       */
      errorUpdateToast: T;

      /**
       * @default 'This URL is available'
       */
      urlAvailableLabel: T;

      /**
       * @default 'This URL is not available'
       */
      urlUnavailableLabel: T;

      /**
       * @default '/60 Max Chars'
       */
      maxChars: T;
    };
    passwordDialog: {
      /**
       * @default 'Event Password'
       */
      title: T;

      /**
       * @default 'Copy'
       */
      copy: T;

      /**
       * @default 'Cancel'
       */
      cancelButton: T;

      /**
       * @default 'Save'
       */
      saveButton: T;

      /**
       * @default 'Password'
       */
      password: T;

      /**
       * @default 'Password cannot be blank.'
       */
      passwordRequiredError: T;

      /**
       * @default 'Your password can only contain letters and numbers.'
       */
      passwordCharactersError: T;

      /**
       * @default 'Your Event Password has been updated.'
       */
      successUpdateToast: T;

      /**
       * @default 'An error occurred while updating your Event Password. Please try again.'
       */
      errorUpdateToast: T;

      /**
       * @default 'Password copied.'
       */
      passwordCopiedToast: T;

      /**
       * @default 'Please Note:'
       */
      passwordNoteTitle: T;

      /**
       * @default 'If you change your password, guests will no longer be able to use your current password to unlock your event.'
       */
      passwordNoteMessage: T;
    };
    paperTypesDialog: {
      /**
       * @default 'Our Paper Collection'
       */
      title: T;

      /**
       * @default 'Select the paper that matches your vision. All paper is sourced from Mohawk for their renowned archival quality and commitment to environmental stewardship.'
       */
      description: T;

      /**
       * @default 'Done'
       */
      done: T;
    };
    lowResolutionDialog: {
      /**
       * @default 'Oops, this photo is low resolution.'
       */
      title: T;

      /**
       * @default 'The photo you uploaded is low resolution and won’t look great on the printed card. Please find a different photo that meets the following criteria.'
       */
      message: T;

      /**
       * @default 'Please make sure you find the original photo. Upscaling or resizing the photo might get you past this warning, but will still not look good when printed.'
       */
      instructions: T;

      /**
       * @default 'Upload Different Photo'
       */
      uploadDifferentPhoto: T;
    };
    envelope: {
      addressValidation: {
        /**
         * @default 'Please fill in your return address first.'
         */
        addressText: T;

        /**
         * @default 'Please confirm that the return address is correct by ticking the checkbox.'
         */
        checkboxText: T;
      };

      /**
       * @default 'Edit Envelope'
       */
      panelTitle: T;

      quantity: {
        /**
         * @default 'Quantity'
         */
        title: T;

        /**
         * @default '{{quantity}} Cards'
         */
        value: T;
      };
      returnAddressing: {
        /**
         * @default 'Return Addressing'
         */
        title: T;

        /**
         * @default 'Print your return address on the front or back of the envelope.'
         */
        subtitle: T;

        /**
         * @default 'Address Placement'
         */
        placementTitle: T;

        /**
         * @default 'FREE'
         */
        price: T;

        /**
         * @default 'Address Design'
         */
        designTitle: T;
      };
      shippingAddressDialog: {
        /**
         * @default 'Your Return Address'
         */
        title: T;

        /**
         * @default 'Return Address'
         */
        modalTitle: T;

        /**
         * @default 'Please double check your address details or fill in the new one that you’d like to be printed on the envelope as a return address.'
         */
        modalDescription: T;

        /**
         * @default 'Add Return Address'
         */
        emptyButton: T;

        /**
         * @default 'Show Country/Region'
         */
        showCountry: T;
      };
      recipientAddressing: {
        /**
         * @default 'Recipient Addressing'
         */
        title: T;

        /**
         * @default 'Have your guest’s addresses printed on the front of each envelope.'
         */
        subtitle: T;

        /**
         * @default 'FREE'
         */
        price: T;

        /**
         * @default 'Print Addresses'
         */
        firstOption: T;

        /**
         * @default 'Leave Blank'
         */
        secondOption: T;

        /**
         * @default 'The same design you selected applies to recipient addressing. Next we’ll help you add your recipient addresses.'
         */
        description: T;
      };
      checkbox: {
        /**
         * @default 'Please confirm your return address.'
         */
        title: T;

        /**
         * @default 'My return address is correct and ready to be printed on the envelope'
         */
        description: T;
      };
      stickyCta: {
        /**
         * @default 'Next'
         */
        next: T;

        /**
         * @default 'Recipients'
         */
        recipients: T;

        /**
         * @default 'Review'
         */
        review: T;
      };

      /**
       * @default 'Edit Return Address'
       */
      ctaEditReturnAddress: T;

      /**
       * @default 'Add Return Address'
       */
      ctaAddReturnAddress: T;
    };
    recipients: {
      /**
       * @default 'Edit Recipients'
       */
      panelTitle: T;

      quantity: {
        /**
         * @default 'Quantity'
         */
        title: T;

        /**
         * @default '{{quantity}} Cards'
         */
        value: T;

        /**
         * @default 'Changing Quantity is not available in edit mode. Please contact customer support.'
         */
        icon: T;
      };
      recipientAddressing: {
        /**
         * @default 'Recipient Addressing'
         */
        title: T;

        /**
         * @default 'Have your guests’ addresses printed on the front of each envelope.'
         */
        subtitle: T;

        /**
         * @default 'FREE'
         */
        price: T;

        /**
         * @default 'Leave Blank'
         */
        firstOption: T;

        /**
         * @default 'Print Addresses'
         */
        secondOption: T;

        /**
         * @default 'The same design you selected applies to recipient addressing. Next we’ll help you add your recipient addresses.'
         */
        description: T;
      };
      printRecipientAddresses: {
        /**
         * @default 'Print Recipient Addresses'
         */
        title: T;

        /**
         * @default 'Select an option to upload your guests’ addresses to be printed on the envelope.'
         */
        subtitle: T;

        /**
         * @default 'Skip and Add After Ordering'
         */
        firstOption: T;

        /**
         * @default 'Skip and Edit Later'
         */
        firstOptionEditMode: T;

        /**
         * @default 'Select Recipients Now'
         */
        secondOption: T;

        /**
         * @default 'Submit a Spreadsheet'
         */
        thirdOption: T;
      };
      info: {
        /**
         * @default 'Print Guest Addresses'
         */
        title: T;

        /**
         * @default 'These addresses will be printed on the envelopes.'
         */
        subtitle: T;

        /**
         * @default 'envelopes'
         */
        envelopes: T;

        /**
         * @default 'addressed'
         */
        addressed: T;

        /**
         * @default 'blank'
         */
        blank: T;
      };
      contactCollector: {
        /**
         * @default 'Don’t have your guest addresses yet?'
         */
        text1: T;

        /**
         * @default 'Alternatively, you can add them manually.'
         */
        text2: T;
      };

      /**
       * @default 'Add New Recipient'
       */
      addNewRecipient: T;

      stickyCta: {
        /**
         * @default 'Next: Review'
         */
        next: T;
      };

      /**
       * @default 'Please confirm that the recipients number is correct by ticking the checkbox.'
       */
      quantityConfirmation: T;

      howItWorks: {
        /**
         * @default 'How it works:'
         */
        title: T;

        steps: [
          {
            /**
             * @default 'Select “Print Addresses” and place your order. You’ll choose your recipients after the order is placed.'
             */
            title: T;
          },
          {
            /**
             * @default 'After ordering, you’ll be able to access your order and select your guests for envelope addressing from your Joy guest list or Contact Collector.'
             */
            title: T;
          },
          {
            /**
             * @default 'Your order will be paused until we receive your addresses, and sent to print when you’re ready.'
             */
            title: T;
          }
        ];

        /**
         * @default 'Changed your mind? No problem! Send your order to be printed without recipient addressing at any time.'
         */
        subTitle: T;

        /**
         * @default 'Learn More'
         */
        learnMore: T;
      };
      skipPrintType: {
        /**
         * @default 'After placing an order, you can come back any time to add and select your recipients. Your order will be paused until then.'
         */
        title: T;

        /**
         * @default 'I understand my order will be paused until I add recipient addressing or send it to print without it.'
         */
        checkBox: T;
      };
      spreadsheetPrintType: {
        steps: [
          {
            /**
             * @default 'Please download the template'
             */
            title: T;
          },
          {
            /**
             * @default 'We’ll send a digital preview of your final addressed envelopes within 2 business days of receiving the address spreadsheet.'
             */
            title: T;
          },
          {
            /**
             * @default 'When you confirm you’re happy with it, our support team will send your order to printing.'
             */
            title: T;
          }
        ];

        /**
         * @default 'here'
         */
        link: T;

        /**
         * @default 'and send it to our support team at'
         */
        sendIt: T;

        /**
         * @default 'along with your order number.'
         */
        along: T;

        /**
         * @default 'I understand my order will be paused until I submit my spreadsheet and confirm the preview as described above.'
         */
        checkBox: T;
      };

      /**
       * @default 'You sent a CSV file to'
       */
      spreadsheetPrintTypeAlreadySent1: T;

      /**
       * @default 'when you placed your order. Envelopes are typically created from your CSV within 2 business days. If it has been more than 2 business days and you have not been contacted, please reach out to'
       */
      spreadsheetPrintTypeAlreadySent2: T;

      /**
       * @default 'Edit Guest Details'
       */
      ctaEditGuestDetails: T;

      /**
       * @default 'Add Missing Address'
       */
      ctaAddMissingAddress: T;

      /**
       * @default 'Address Formatting Issue'
       */
      legacyAddressLineBreakTitle: T;

      /**
       * @default ''Street Address' and 'City, State, ZIP Code' should be on separate lines. Click Edit Guest Details to add a line break.'
       */
      legacyAddressLineBreakDescription: T;

      /**
       * @default 'Learn more.'
       */
      legacyAddressLineBreakLink: T;
    };
  };
  premiumDigitalCardCustomizer: {
    cardFront: {
      /**
       * @default 'Customize your card whenever you’d like; now, after purchase, or even after sending some cards.'
       */
      editCard: T;
    };
    digitalReview: {
      /**
       * @default 'Please add your billing details.'
       */
      addressFieldErrorMessage: T;

      /**
       * @default 'Please confirm that you checked all the details by ticking the checkbox below.'
       */
      checkboxFieldErrorMessage: T;

      /**
       * @default 'Make sure to carefully check everything before you finalize your order.'
       */
      stepDescription: T;

      /**
       * @default 'Billing Address'
       */
      billingAddressTitle: T;

      /**
       * @default 'Add Billing Address'
       */
      billingAddressEmptyMessage: T;

      /**
       * @default 'Go to Checkout'
       */
      ctaText: T;

      /**
       * @default 'Submit Changes'
       */
      ctaTextOrder: T;

      /**
       * @default 'I understand that any changes submitted will apply to previously sent cards, as well as future cards.'
       */
      orderCtaDescription: T;

      /**
       * @default 'You'll be able to send your Digital Card via personalized email to your guests at the next step.'
       */
      deliveryDescription: T;

      /**
       * @default 'You'll be able to send your Digital Card via personalized email to your guests at the next step.'
       */
      multiOptionsDeliveryDescription: T;

      /**
       * @default 'Delivery Methods'
       */
      deliveryTitle: T;

      /**
       * @default 'Email'
       */
      standardDeliveryTitle: T;

      /**
       * @default 'Personalized Emails'
       */
      standardDeliveryDescription: T;

      /**
       * @default 'Text, Links & Email'
       */
      premiumDeliveryTitle: T;

      /**
       * @default 'Text messaging to USA and Canada'
       */
      premiumDeliveryDescription: T;

      /**
       * @default 'Up to {{count}} guests'
       */
      emailDeliveryLimits: T;

      /**
       * @default 'Included'
       */
      included: T;

      /**
       * @default 'Personalized Emails'
       */
      valuePropEmail: T;

      /**
       * @default 'Shareable Card Link'
       */
      valuePropLink: T;

      /**
       * @default 'Text messaging to USA and Canada'
       */
      valuePropPremium: T;

      /**
       * @default 'International Text *'
       */
      valuePropDiamond: T;

      /**
       * @default 'Email'
       */
      basic: T;

      /**
       * @default 'Text Messaging'
       */
      premium: T;

      /**
       * @default 'International Text Message'
       */
      diamond: T;

      /**
       * @default '*Some country restrictions apply.'
       */
      coutryRestriction: T;

      /**
       * @default 'Learn more'
       */
      learnMore: T;
    };
    digitalDeliveryMethod: {
      /**
       * @default 'Personalized email up to {{count}} guests'
       */
      digitalEmailFor: T;
    };
    postCheckout: {
      /**
       * @default 'Track Cards Sent'
       */
      trackCardsSent: T;

      greetingBlock: {
        /**
         * @default 'Order Confirmed! Up next:'
         */
        eyebrow: T;

        /**
         * @default 'Select recipients and send your card.'
         */
        title: T;
      };
      stepBlocks: {
        step1: {
          /**
           * @default 'First, let's get your email looking great.'
           */
          label: T;

          /**
           * @default 'Edit Subject'
           */
          editSubjectCta: T;

          /**
           * @default 'Send Test Email'
           */
          sendTestEmailCta: T;

          /**
           * @default '💌 Your test email was sent to: {{email}}'
           */
          sendTestEmailToast: T;
        };
        step2: {
          /**
           * @default 'Then, select your recipients and send.'
           */
          label: T;

          /**
           * @default 'Select Recipients'
           */
          selectRecipientsCta: T;

          /**
           * @default 'Modify {{count}} Recipients...'
           */
          modifyRecipients: T;

          /**
           * @default 'Send Card'
           */
          sendCardCta: T;

          /**
           * @default 'Send Card to {{count}} Recipients'
           */
          sendCardToSelectedRecipientsCta: T;
        };
      };
      emailPreview: {
        /**
         * @default 'Subject:'
         */
        subjectLabel: T;

        /**
         * @default 'View The Card'
         */
        viewCardCta: T;

        /**
         * @default 'To: {{name}}'
         */
        toMessage: T;
      };
      printCardSellBanner: {
        /**
         * @default 'Add Beautiful Paper Cards'
         */
        title: T;

        /**
         * @default 'Some guests prefer to receive a physical card. Create a matching (or unique) paper card for them. All your details are ready. ☺️'
         */
        description: T;

        /**
         * @default 'See it on Paper'
         */
        ctaText: T;
      };
      linkShareSection: {
        /**
         * @default 'Want to do it yourself?'
         */
        eyebrow: T;

        /**
         * @default 'Share your card link any way you want.'
         */
        title: T;

        /**
         * @default 'Copy Card Link'
         */
        ctaText: T;

        /**
         * @default '🎉 Your link has been copied.'
         */
        copyToast: T;
      };
      emailEditorDialog: {
        /**
         * @default 'Edit Your Message'
         */
        header: T;

        senderNameField: {
          /**
           * @default 'Sender Name'
           */
          label: T;
        };
        subjectField: {
          /**
           * @default 'Subject'
           */
          label: T;

          /**
           * @default 'Your message will appear as an email subject and text message.'
           */
          inputCaption: T;
        };

        /**
         * @default 'Cancel'
         */
        cancelCta: T;

        /**
         * @default 'Save'
         */
        saveCta: T;
      };
    };
    successStep: {
      /**
       * @default 'Congratulations! Your card has been sent.'
       */
      title: T;

      /**
       * @default 'Your message has been sent successfully.'
       */
      messageSuccess: T;

      /**
       * @default '{{count}} Messages sent'
       */
      messageSendCount: T;

      /**
       * @default '1 Message sent'
       */
      messageSendCountSingle: T;

      /**
       * @default 'Messages sent'
       */
      messageSentNoCount: T;

      /**
       * @default 'Track which guests have received and opened your card.'
       */
      trackDescription: T;

      /**
       * @default 'Track Cards Sent'
       */
      trackCardsSentCta: T;

      /**
       * @default 'View Order'
       */
      viewOrderCta: T;
    };
  };
  dashboard: {
    primaryNav: {
      desktop: {
        /**
         * @default 'Browse Designs'
         */
        home: T;

        /**
         * @default 'Imagine Your Design'
         */
        imagine: T;

        /**
         * @default 'Drafts'
         */
        drafts: T;

        /**
         * @default 'Orders'
         */
        orders: T;

        /**
         * @default 'Favorites'
         */
        favorites: T;
      };
      mobile: {
        /**
         * @default 'Browse'
         */
        home: T;

        /**
         * @default 'Imagine'
         */
        imagine: T;

        /**
         * @default 'Drafts'
         */
        drafts: T;

        /**
         * @default 'Orders'
         */
        orders: T;

        /**
         * @default 'Favorites'
         */
        favorites: T;
      };
    };
    existingDraftsWarningDialog: {
      variants: {
        single: {
          /**
           * @default 'You already have a draft of this design. What would you like to do?'
           */
          message: T;

          /**
           * @default 'Edit Existing Draft'
           */
          ctaText: T;
        };
        multiple: {
          /**
           * @default 'You have multiple drafts of this design. What would you like to do?'
           */
          message: T;

          /**
           * @default 'View Existing Drafts'
           */
          ctaText: T;
        };
      };

      /**
       * @default 'Start New Draft'
       */
      startNewDraftCtaText: T;
    };
    restrictedInternationalShippingWarningDialog: {
      print: {
        /**
         * @default 'Do you have a supported address?'
         */
        title: T;

        /**
         * @default 'The supported country is {{countries}}.'
         */
        supportedCountriesSingular: T;

        /**
         * @default 'The supported countries are {{countries}}.'
         */
        supportedCountriesPlural: T;

        /**
         * @default 'Paper cards are currently available for the supported addresses only.'
         */
        warningMessageNonStdOrInvitationText: T;

        /**
         * @default 'Paper cards are currently available for the supported addresses only. If you are not in the supported countries you can still '
         */
        warningMessageEcardText: T;

        /**
         * @default 'If you don't have a supported address, you can send digital thank you messages to your guests'
         */
        warningMessageEmailText: T;

        /**
         * @default 'create a free basic digital card.'
         */
        warningMessageLink: T;

        /**
         * @default 'here.'
         */
        warningMessageLinkThankYou: T;

        /**
         * @default 'I have a supported address'
         */
        ctaText: T;
      };
      digital: {
        /**
         * @default 'Do you have a supported address?'
         */
        title: T;

        /**
         * @default 'The supported country is {{countries}}.'
         */
        supportedCountriesSingular: T;

        /**
         * @default 'The supported countries are {{countries}}.'
         */
        supportedCountriesPlural: T;

        /**
         * @default 'Premium digital cards are currently available for the supported addresses only.'
         */
        warningMessageNonStdOrInvitationText: T;

        /**
         * @default 'Premium digital cards are currently available for the supported addresses only. If you are not in the supported countries you can still '
         */
        warningMessageEcardText: T;

        /**
         * @default 'If you don't have a supported address, you can send digital thank you messages to your guests'
         */
        warningMessageEmailText: T;

        /**
         * @default 'create a free basic digital card.'
         */
        warningMessageLink: T;

        /**
         * @default 'here.'
         */
        warningMessageLinkThankYou: T;

        /**
         * @default 'I have a supported address'
         */
        ctaText: T;
      };
    };
    designsGallery: {
      /**
       * @default 'Learn More'
       */
      learnMore: T;

      filters: {
        photo: {
          /**
           * @default 'With Photos'
           */
          with: T;

          /**
           * @default 'Without Photos'
           */
          without: T;
        };
        foil: {
          /**
           * @default 'Foil'
           */
          label: T;

          /**
           * @default 'With Foil'
           */
          with: T;

          /**
           * @default 'Without Foil'
           */
          without: T;
        };
        price: {
          /**
           * @default 'Price'
           */
          label: T;

          /**
           * @default 'Premium Only'
           */
          premium: T;

          /**
           * @default 'Free Digital Option Available'
           */
          basic: T;
        };
      };

      /**
       * @default 'Explore {{designs}}'
       */
      exploreDesigns: T;

      /**
       * @default 'Matches Your Site'
       */
      websiteMatch: T;

      filtersDrawer: {
        /**
         * @default 'Filters'
         */
        header: T;

        /**
         * @default 'Reset All Filters'
         */
        resetCta: T;

        /**
         * @default 'Show Results'
         */
        showResultsCta: T;
      };
      pagination: {
        /**
         * @default 'Previous'
         */
        previous: T;

        /**
         * @default 'Next'
         */
        next: T;

        /**
         * @default 'Page {{page}} / {{totalPages}}'
         */
        page: T;
      };
    };
    favoritesGallery: {
      emptyState: {
        /**
         * @default 'You don't have any favorites just yet!'
         */
        title: T;

        /**
         * @default 'When you browse the gallery, you can add all the designs you like clicking the heart in the top right, and you can come back and see them here.'
         */
        description: T;

        /**
         * @default 'Browse Designs'
         */
        cta: T;
      };
    };
    draftsGallery: {
      /**
       * @default 'Your Drafts'
       */
      sectionTitle: T;

      /**
       * @default 'Edited {{timeAgo}}'
       */
      editedAtLabel: T;

      /**
       * @default 'You don’t have any drafts just yet!'
       */
      emptyStateTitle: T;

      /**
       * @default 'Once you start designing your cards, we’ll autosave them for you here, so you can access them at any point and continue where you left off.'
       */
      emptyStateMessage: T;

      stationerySection: {
        title: {
          /**
           * @default 'Paper Cards'
           */
          print: T;

          /**
           * @default 'Premium Digital Cards'
           */
          digital: T;
        };
        emptyStateText: {
          desktop: {
            /**
             * @default 'Once you start designing your cards, we’ll autosave them for you here, so you can access them at any point and continue where you left off.'
             */
            print: T;

            /**
             * @default 'Once you start designing your cards, we’ll autosave them for you here, so you can access them at any point and continue where you left off.'
             */
            digital: T;
          };
          mobile: {
            /**
             * @default 'Once you start designing your cards, we’ll autosave them for you here, so you can access them at any point and continue where you left off.'
             */
            print: T;

            /**
             * @default 'Once you start designing your cards, we’ll autosave them for you here, so you can access them at any point and continue where you left off.'
             */
            digital: T;
          };
        };
        emptyStateCTA: {
          /**
           * @default 'Browse Paper Cards'
           */
          print: T;

          /**
           * @default 'Browse Premium Digital Cards'
           */
          digital: T;
        };

        /**
         * @default 'Show All'
         */
        buttonTitle: T;
      };
      digitalSection: {
        /**
         * @default 'Basic Digital Cards'
         */
        title: T;

        /**
         * @default 'No Basic Digital Drafts'
         */
        emptyStateTitle: T;

        /**
         * @default 'Art Save the Date'
         */
        saveTheDate: T;

        /**
         * @default 'Photo Save the Date'
         */
        photoSaveTheDate: T;

        /**
         * @default 'Invitation'
         */
        invitation: T;

        /**
         * @default 'Custom Card'
         */
        custom: T;
      };
      deleteDraftDialog: {
        /**
         * @default 'Are you sure you want to delete this draft permanently?'
         */
        title: T;

        /**
         * @default 'You can always create a new draft, but the details saved here will no longer be saved.'
         */
        description: T;

        /**
         * @default 'Cancel'
         */
        cancelCta: T;

        /**
         * @default 'Delete Draft'
         */
        deleteCta: T;

        /**
         * @default 'Draft deleted successfully'
         */
        successToast: T;

        /**
         * @default 'Failed to delete draft'
         */
        errorToast: T;
      };
    };
    banner: {
      /**
       * @default 'PROMO CODE: {{code}}'
       */
      pill: T;

      promo: {
        /**
         * @default 'Get {{percentageDiscount}} Off with Joy Paper.'
         */
        title: T;

        subtitle: {
          /**
           * @default 'For a limited time, print your Save the Dates and Invitations with a discount.'
           */
          desktop: T;

          /**
           * @default 'Print your Save the Dates and Invitations with a discount.'
           */
          mobile: T;
        };
      };
      nonPromo: {
        /**
         * @default 'Welcome to Joy's Paper Early Access Program.'
         */
        title: T;

        subtitle: {
          /**
           * @default 'Choose your perfect design and print your Save the Dates and Invitations starting today.'
           */
          desktop: T;

          /**
           * @default 'Print your Save the Dates and Invitations with Joy.'
           */
          mobile: T;
        };
      };
    };
    conciergeServiceSection: {
      carousel: {
        /**
         * @default 'Design Concierge Service'
         */
        eyebrow: T;

        title: {
          /**
           * @default 'Premium Save the Dates'
           */
          saveTheDate: T;

          /**
           * @default 'Premium Invitation Suites'
           */
          invitation: T;
        };
        tileDescription: {
          /**
           * @default 'Invitation Suite'
           */
          invitation: T;

          /**
           * @default 'Save the Date'
           */
          saveTheDate: T;
        };
      };
      dialog: {
        overviewScreen: {
          /**
           * @default 'COMING SOON'
           */
          comingSoonLabel: T;

          costLabel: {
            /**
             * @default 'From {{cost}} each for order of 100'
             */
            saveTheDate: T;

            /**
             * @default 'From {{cost}} each for order of 100 suites'
             */
            invitation: T;
          };

          /**
           * @default 'Notify Me'
           */
          notifyMeCta: T;

          /**
           * @default 'Order Now'
           */
          orderNowCta: T;

          sections: {
            suiteHighlights: {
              title: {
                /**
                 * @default 'Includes'
                 */
                saveTheDate: T;

                /**
                 * @default 'Suite Includes'
                 */
                invitation: T;
              };
            };
            overview: {
              /**
               * @default 'Design Concierge Service'
               */
              title: T;

              /**
               * @default 'After placing your order, we will provide a link to our wording submission form. You can provide your wedding details to put on the card and introduce tweaks you wish to make.'
               */
              description: T;
            };
            howItWorks: {
              /**
               * @default 'How It Works, Step By Step'
               */
              title: T;

              steps: [
                {
                  /**
                   * @default 'Customization Form'
                   */
                  title: T;

                  /**
                   * @default 'Fill out your wedding details and select your options in the customization form.'
                   */
                  description: T;
                },
                {
                  /**
                   * @default 'Design Preview (Within 2 Days)'
                   */
                  title: T;

                  /**
                   * @default 'Your designer begins working on your customized design. You will receive an email when its time to view your proof.'
                   */
                  description: T;
                },
                {
                  /**
                   * @default 'Feedback & Changes (3-5 Business days)'
                   */
                  title: T;

                  /**
                   * @default 'Work with your designer to tweak your proof and get all the details correct. We won’t move to printing until you approve your design.'
                   */
                  description: T;
                },
                {
                  /**
                   * @default 'Printing ({{quantity}} Business Days)'
                   */
                  title: T;

                  /**
                   * @default 'After you let your design team know your proof is ready, we move to printing phase to make your custom order. Processing times vary between suites given the use of special print techniques, such as white ink or gold foil.'
                   */
                  description: T;
                },
                {
                  /**
                   * @default 'Shipping (3-4 Days)'
                   */
                  title: T;

                  /**
                   * @default 'Orders include free express DHL shipping reaching most destinations in 3-4 business days.'
                   */
                  description: T;
                }
              ];
            };
          };
        };
        successScreen: {
          /**
           * @default 'Success!'
           */
          title: T;

          /**
           * @default 'We'll be in touch as soon as the concierge suites are available on Joy Paper.'
           */
          description: T;

          /**
           * @default 'All Done'
           */
          allDoneCta: T;
        };
      };
    };
    ordersList: {
      digitalOrder: {
        /**
         * @default 'Order Placed'
         */
        orderPlaced: T;

        /**
         * @default 'Order Number'
         */
        orderNumber: T;

        /**
         * @default 'Track Delivery'
         */
        trackDelivery: T;

        /**
         * @default 'Send More or Resend'
         */
        sendMore: T;

        /**
         * @default 'Select Recipients and Send'
         */
        selectRecipients: T;

        /**
         * @default 'Order Matching Paper Card'
         */
        orderMatchingPaperCard: T;
      };
    };
  };
  printJobDetails: {
    progressTracker: {
      statusMilestones: {
        /**
         * @default 'Order Placed'
         */
        orderPlaced: T;

        /**
         * @default 'In Progress'
         */
        inProgress: T;

        /**
         * @default 'Ready to Ship'
         */
        readyToShip: T;

        /**
         * @default 'Shipped'
         */
        shipped: T;

        /**
         * @default 'Delivered'
         */
        delivered: T;

        /**
         * @default 'Canceled'
         */
        canceled: T;

        /**
         * @default 'Refunded'
         */
        refunded: T;
      };
    };
  };
  pdpModal: {
    breadcrumbs: {
      /**
       * @default 'Cards'
       */
      root: T;

      category: {
        /**
         * @default 'Save the Dates'
         */
        saveTheDate: T;

        /**
         * @default 'Invitations'
         */
        invitation: T;
      };
    };
    previewPane: {
      assetLabels: {
        /**
         * @default 'Front of the Card'
         */
        front: T;

        /**
         * @default 'Back of the Card'
         */
        back: T;

        /**
         * @default 'Envelope'
         */
        envelope: T;
      };
    };
    customizationPane: {
      customizeCta: {
        /**
         * @default 'Subtotal'
         */
        subtotalLabel: T;

        /**
         * @default 'Customize Now'
         */
        ctaText: T;

        /**
         * @default 'Instant Delivery via Email'
         */
        digitalDeliverySecondaryLabel: T;
      };
      printTemplateConfig: {
        inlineValueProps: {
          /**
           * @default 'Matching Website Designs'
           */
          websiteDesign: T;

          /**
           * @default 'Contact Collector'
           */
          contactCollector: T;

          /**
           * @default 'QR codes for Easy Access'
           */
          qrCodes: T;
        };
        listValueProps: {
          /**
           * @default 'All Joy Prints Include:'
           */
          sectionTitle: T;

          /**
           * @default 'Digital printing on our luxurious signature card stock with a subtle velvety texture'
           */
          paperType: T;

          /**
           * @default 'Add rounded corners and customize your card the way you want'
           */
          silhouette: T;

          /**
           * @default 'QR codes that include your website password for seamless guest digital RSVP'
           */
          qrCodes: T;

          /**
           * @default 'Premium white envelope with complimentary return address printing'
           */
          envelope: T;
        };
      };
      digitalTemplateConfig: {
        /**
         * @default 'Try Premium'
         */
        tryPremiumCta: T;

        inlineValueProps: {
          /**
           * @default 'Matching Website Designs'
           */
          websiteDesign: T;

          /**
           * @default 'Contact Collector'
           */
          contactCollector: T;

          /**
           * @default 'Easy Delivery and Tracking'
           */
          delivery: T;
        };
        listValueProps: {
          /**
           * @default 'All Joy Digital Cards Include:'
           */
          sectionTitle: T;

          /**
           * @default 'Beautiful, unique designs to pick from'
           */
          designs: T;

          /**
           * @default 'Easily tracking which guests have received and opened your Digital Card'
           */
          tracking: T;

          /**
           * @default 'Seamless guests info gathering with Contact Collector'
           */
          contactCollector: T;

          /**
           * @default 'The possibility to link to your wedding website, RSVP, Add to Calendar, Registry and more'
           */
          linking: T;
        };
      };
      premiumDigitalTemplateConfig: {
        listValueProps: {
          /**
           * @default 'Premium Digital Perks'
           */
          sectionTitle: T;

          premium: {
            /**
             * @default 'Premium Designer'
             */
            title: T;

            /**
             * @default 'Customize your card exactly how you like it, with hundreds of font, photo, and layout options.'
             */
            description: T;
          };
          matchingPaperCards: {
            /**
             * @default 'Matching Paper Cards and Website'
             */
            title: T;

            /**
             * @default 'Order printed cards for special guests or keepsakes and match your cards with a free wedding website.'
             */
            description: T;
          };
          textMessage: {
            /**
             * @default 'Text Message Delivery'
             */
            title: T;

            /**
             * @default 'Text messaging to USA and Canada with recipient tracking.'
             */
            description: T;
          };
          personalizedEmail: {
            /**
             * @default 'Personalized Email Delivery'
             */
            title: T;

            /**
             * @default 'Send and easily track which guests have received and opened your digital card.'
             */
            description: T;
          };
          shareableLink: {
            /**
             * @default 'Shareable Link'
             */
            title: T;

            /**
             * @default 'Get a link to your digital card that you can share directly with family and friends.'
             */
            description: T;
          };
          contactCollector: {
            /**
             * @default 'Contact Collector'
             */
            title: T;

            /**
             * @default 'Magically collect guests' contact info in your guest list for sending cards and thank you notes.'
             */
            description: T;
          };
        };
      };

      overviewAccordionTabs: [
        {
          /**
           * @default 'Why Joy?'
           */
          title: T;

          /**
           * @default 'Choose timeless elegance with the Minimalistic Arch design suite. Printed on luxe paper, this design features a classic mix of typography and calligraphy in a chic layout. We think it's a stunning choice for any wedding venue, from formal hotel ballroom to industrial loft.'
           */
          description: T;
        },
        {
          /**
           * @default 'Shipping'
           */
          title: T;

          /**
           * @default 'Choose timeless elegance with the Minimalistic Arch design suite. Printed on luxe paper, this design features a classic mix of typography and calligraphy in a chic layout. We think it's a stunning choice for any wedding venue, from formal hotel ballroom to industrial loft.'
           */
          description: T;
        },
        {
          /**
           * @default 'Need help designing?'
           */
          title: T;

          /**
           * @default 'Choose timeless elegance with the Minimalistic Arch design suite. Printed on luxe paper, this design features a classic mix of typography and calligraphy in a chic layout. We think it's a stunning choice for any wedding venue, from formal hotel ballroom to industrial loft.'
           */
          description: T;
        }
      ];
      foilTooltip: {
        /**
         * @default 'Learn more about foil'
         */
        cta: T;

        /**
         * @default 'Premium Foil Stamping'
         */
        title: T;

        /**
         * @default 'Elevate your design with real foil in gold, rose gold, or silver. Foil stamping uses a machine to press the foil color onto the paper for a smooth, metallic effect.'
         */
        description: T;
      };
    };
  };
  imagineCardAiApp: {
    header: {
      /**
       * @default 'Dream It, Design It.'
       */
      heading: T;

      /**
       * @default 'Your Perfect Card Awaits'
       */
      subheading: T;
    };
    promptbar: {
      /**
       * @default 'Type what you’d like to imagine...'
       */
      placeholder: T;

      /**
       * @default 'Type what you imagine'
       */
      placeholderMobile: T;

      /**
       * @default 'Imagine'
       */
      submit: T;

      /**
       * @default 'These are some fun ideas to get you started'
       */
      subheadingPopular: T;
    };
    cards: {
      hover: {
        /**
         * @default 'Restyle'
         */
        styles: T;

        /**
         * @default 'Refine'
         */
        tweaks: T;

        /**
         * @default 'Customize'
         */
        draft: T;

        /**
         * @default 'Reimagine'
         */
        reimagine: T;
      };

      /**
       * @default 'Paper Card - From $1.09'
       */
      pricing: T;
    };
  };
  digitalFlowWelcomeNotification: {
    /**
     * @default 'Welcome to Premium Card Designer'
     */
    title: T;

    /**
     * @default 'Learn how it works.'
     */
    description: T;

    modal: {
      /**
       * @default 'Learn How the Premium Card Designer Works'
       */
      title: T;

      /**
       * @default 'Customize Your Card'
       */
      cta: T;
    };
  };
  premiumDigitalRecipientViewError: {
    /**
     * @default 'Hmm… we can't find that.'
     */
    title: T;

    /**
     * @default 'This digital card may have been changed or is temporarily unavailable.'
     */
    subtitle: T;

    /**
     * @default 'Find a Couple'
     */
    linkText: T;
  };
  editCardBanner: {
    /**
     * @default 'Noticed a mistake on your card? Don’t worry, that happens! Let’s get it right.'
     */
    paperDescription: T;

    /**
     * @default 'Still working on your card? Any changes you make will be applied to previously sent cards, as well as future ones.'
     */
    digitalDescription: T;

    /**
     * @default 'Edit Your Card'
     */
    cta: T;
  };
  premiumDigitalOrderTracking: {
    header: {
      /**
       * @default 'Card Tracking'
       */
      title: T;

      category: {
        /**
         * @default 'Digital Save the Date'
         */
        saveTheDate: T;

        /**
         * @default 'Digital Invitation'
         */
        invitation: T;

        /**
         * @default 'Digital Thank You'
         */
        thankYou: T;

        /**
         * @default 'Digital Holiday Card'
         */
        holiday: T;

        /**
         * @default 'Digital Enclosure Card'
         */
        enclosure: T;

        /**
         * @default 'Digital Card'
         */
        default: T;
      };
    };

    /**
     * @default 'Send More Cards'
     */
    sendMoreCardsCta: T;

    /**
     * @default 'Send More'
     */
    sendMoreCardsCtaMobile: T;

    /**
     * @default 'Preview as Guest'
     */
    previewAsGuestCta: T;

    /**
     * @default 'Preview'
     */
    previewAsGuestCtaMobile: T;

    statusTrackingBar: {
      sent: {
        /**
         * @default 'Recipients'
         */
        label: T;

        /**
         * @default 'Sent'
         */
        iconTitle: T;
      };
      delivered: {
        /**
         * @default 'Delivered'
         */
        label: T;

        /**
         * @default 'Delivered'
         */
        iconTitle: T;
      };
      failed: {
        /**
         * @default 'Failed'
         */
        label: T;

        /**
         * @default 'Failed'
         */
        iconTitle: T;
      };
      viewed: {
        /**
         * @default 'Viewed'
         */
        label: T;

        /**
         * @default 'Viewed'
         */
        iconTitle: T;
      };
    };
    trackingStatusPill: {
      labels: {
        /**
         * @default 'Delivered'
         */
        delivered: T;

        /**
         * @default 'Failed'
         */
        failed: T;

        /**
         * @default 'Viewed'
         */
        viewed: T;

        /**
         * @default 'Sent'
         */
        sent: T;
      };
    };
    tableHeaders: {
      /**
       * @default 'Recipient'
       */
      recipient: T;

      /**
       * @default 'Status'
       */
      status: T;

      /**
       * @default 'Resend'
       */
      resend: T;
    };
    resendMenuTooltip: {
      /**
       * @default 'Resend the card'
       */
      title: T;

      /**
       * @default 'Choose the best way to reach {{name}}.'
       */
      description: T;
    };
    contactActions: {
      copyLink: {
        /**
         * @default 'Copy {{name}}'s link'
         */
        title: T;
      };
      whatsapp: {
        /**
         * @default 'Send to {{name}} via WhatsApp'
         */
        title: T;

        /**
         * @default 'If you have WhatsApp app on desktop, click the button below.'
         */
        desktopSubText: T;

        /**
         * @default 'Hi {{name}} view our card!'
         */
        textMessage: T;
      };
      sms: {
        /**
         * @default 'Send to {{name}} via text message'
         */
        title: T;

        /**
         * @default 'If you have text messaging app on desktop, click the button below.'
         */
        desktopSubText: T;

        /**
         * @default 'Hi {{name}} view our card!'
         */
        textMessage: T;
      };
      email: {
        /**
         * @default 'Send to {{name}} via email'
         */
        title: T;

        /**
         * @default 'Hi {{name}} view our card!'
         */
        subject: T;

        /**
         * @default 'Click here to view to view our card:'
         */
        body: T;
      };
    };

    /**
     * @default '✨ {{name}}'s link has been copied.'
     */
    copyLinkToast: T;

    shareLinkDialog: {
      /**
       * @default 'Send to {{name}} via WhatsApp'
       */
      title: T;

      /**
       * @default 'This is a personalized link for {{name}} to allow tracking. Don’t share it with other guests.'
       */
      infoMessage: T;

      sendFromDesktop: {
        /**
         * @default 'Send from desktop'
         */
        title: T;

        /**
         * @default 'Send Now'
         */
        cta: T;
      };
      sendFromMobile: {
        /**
         * @default 'Send from mobile'
         */
        title: T;

        /**
         * @default 'Scan QR code and switch to your mobile device.'
         */
        description: T;
      };
    };
  };
  publicShop: {
    meta: {
      saveTheDate: {
        /**
         * @default 'Beautiful Wedding Save the Date Cards | Joy'
         */
        title: T;

        /**
         * @default 'Kick off your wedding journey with Joy's Save the Dates. Opt for the ease of digital cards, the timeless elegance of print, or a mix of both. Affordable, elegant, and customizable, they’re the perfect way to share your big news.'
         */
        description: T;
      };
      invitation: {
        /**
         * @default 'Elegant, Affordable Wedding Invitations | Joy'
         */
        title: T;

        /**
         * @default 'Craft your dream wedding invitations with Joy. Discover stunning designs and personalize every detail. Choose between the convenience of digital cards or the timeless appeal of printed invitations to perfectly capture the spirit of your special day.'
         */
        description: T;
      };
      fallback: {
        /**
         * @default 'Joy - Wedding Website, Guest List, Invitations & More'
         */
        title: T;
      };
    };
  };
};

export type StationeryTemplateCategoryTranslations<T extends LeafNodeValue = TranslateFunction> = {
  designsGalleryPdpBreadcrumbCategory: {
    /**
     * @default 'Save the Dates'
     */
    saveTheDate: T;

    /**
     * @default 'Invitations'
     */
    invitation: T;

    /**
     * @default 'Thank You Cards'
     */
    thankYou: T;

    /**
     * @default 'Holiday Cards'
     */
    holiday: T;

    /**
     * @default 'Enclosure Cards'
     */
    enclosure: T;

    /**
     * @default 'All Cards'
     */
    default: T;
  };
  stationeryTemplateCategoryToTile: {
    /**
     * @default 'Save the Date'
     */
    saveTheDate: T;

    /**
     * @default 'Invitation'
     */
    invitation: T;

    /**
     * @default 'Thank You Cards'
     */
    thankYou: T;

    /**
     * @default 'Holiday Cards'
     */
    holiday: T;

    /**
     * @default 'Enclosure Cards'
     */
    enclosure: T;

    /**
     * @default 'Napkins'
     */
    napkin: T;

    /**
     * @default 'Menus'
     */
    menu: T;

    /**
     * @default 'Signs'
     */
    sign: T;

    /**
     * @default 'Paper Card'
     */
    default: T;
  };
  stationeryTemplateCategoryToDigitalTile: {
    /**
     * @default 'Digital Save the Date'
     */
    saveTheDate: T;

    /**
     * @default 'Digital Invitation'
     */
    invitation: T;

    /**
     * @default 'Digital Thank You Cards'
     */
    thankYou: T;

    /**
     * @default 'Digital Holiday Cards'
     */
    holiday: T;

    /**
     * @default 'Digital Enclosure Cards'
     */
    enclosure: T;

    /**
     * @default 'Digital Card'
     */
    default: T;
  };
  exitCustomizationWidgetBrowseDesignCta: {
    /**
     * @default 'Browse Invitations'
     */
    invitation: T;

    /**
     * @default 'Browse Save the Dates'
     */
    saveTheDate: T;

    /**
     * @default 'Browse Thank You Cards'
     */
    thankYou: T;

    /**
     * @default 'Browse Holiday Cards'
     */
    holiday: T;

    /**
     * @default 'Browse Enclosure Cards'
     */
    enclosure: T;

    /**
     * @default 'Browse Designs'
     */
    default: T;
  };
  premiumDigitalCardCustomizerInitialEmailSubjectByType: {
    /**
     * @default 'Save the Date!'
     */
    saveTheDate: T;

    /**
     * @default 'You're Invited!'
     */
    invitation: T;

    /**
     * @default 'Thank You!'
     */
    thankYou: T;

    /**
     * @default 'Happy Holidays!'
     */
    holiday: T;

    /**
     * @default 'Enclosure Cards'
     */
    enclosure: T;

    /**
     * @default 'Thank You!'
     */
    default: T;
  };
  dashboardCategoryNav: {
    /**
     * @default 'Save the Dates'
     */
    saveTheDate: T;

    /**
     * @default 'Invitations'
     */
    invitation: T;

    /**
     * @default 'Thank You Cards'
     */
    thankYou: T;

    /**
     * @default 'Holiday Cards'
     */
    holiday: T;

    /**
     * @default 'Enclosure Cards'
     */
    enclosure: T;

    /**
     * @default 'Napkins'
     */
    napkin: T;

    /**
     * @default 'Menus'
     */
    menu: T;

    /**
     * @default 'Signs'
     */
    sign: T;

    /**
     * @default ''
     */
    default: T;
  };
  dashboardOrderListDigitalOrderOrderType: {
    /**
     * @default 'Digital Save the Date'
     */
    saveTheDate: T;

    /**
     * @default 'Digital Invitation'
     */
    invitation: T;

    /**
     * @default 'Digital Thank You Card'
     */
    thankYou: T;

    /**
     * @default 'Digital Holiday Card'
     */
    holiday: T;

    /**
     * @default 'Digital Enclosure Card'
     */
    enclosure: T;

    /**
     * @default 'Digital Card'
     */
    default: T;
  };
  premiumDigitalOrderTrackingCategory: {
    /**
     * @default 'Digital Save the Date'
     */
    saveTheDate: T;

    /**
     * @default 'Digital Invitation'
     */
    invitation: T;

    /**
     * @default 'Digital Thank You'
     */
    thankYou: T;

    /**
     * @default 'Digital Holiday Card'
     */
    holiday: T;

    /**
     * @default 'Digital Enclosure Card'
     */
    enclosure: T;

    /**
     * @default 'Digital Card'
     */
    default: T;
  };
};

export type StaysTranslations<T extends LeafNodeValue = TranslateFunction> = {
  datesOccupants: {
    /**
     * @default 'Find Hotel Rooms'
     */
    cta: T;

    /**
     * @default 'Rooms starting from'
     */
    roomsStartingFrom: T;

    /**
     * @default 'Night'
     */
    night: T;

    /**
     * @default 'See Available Rooms'
     */
    seeAvailableRooms: T;

    /**
     * @default 'Adults'
     */
    adults: T;

    numberOfAdults: {
      /**
       * @default '1 Adult'
       * @param {number} count - The number of ocurrencies.
       * - singular: 1 Adult
       * - plural: {{count}} Adults
       */
      adults: T;
    };
    numberOfRooms: {
      /**
       * @default '1 Room'
       * @param {number} count - The number of ocurrencies.
       * - singular: 1 Room
       * - plural: {{count}} Rooms
       */
      rooms: T;
    };

    /**
     * @default '{{children}} Children'
     */
    numberOfChildren: T;

    /**
     * @default 'Rooms'
     */
    rooms: T;

    /**
     * @default 'Children'
     */
    children: T;

    /**
     * @default 'Guests'
     */
    guests: T;

    /**
     * @default 'Add Room'
     */
    addRoom: T;

    /**
     * @default 'Remove Room'
     */
    removeRoom: T;

    /**
     * @default 'Occupancy'
     */
    occupancy: T;

    /**
     * @default 'Dates'
     */
    dates: T;

    /**
     * @default 'Check-In'
     */
    checkIn: T;

    /**
     * @default 'Check-Out'
     */
    checkOut: T;

    /**
     * @default 'Location'
     */
    location: T;
  };
  hotelPDPPage: {
    seo: {
      /**
       * @default 'Hotel'
       */
      title: T;
    };
    heroPhotos: {
      /**
       * @default 'See all photos'
       */
      seeAllPhotos: T;
    };
    offer: {
      /**
       * @default 'More Details'
       */
      moreDetails: T;

      /**
       * @default 'Free Breakfast'
       */
      freeBreakfast: T;

      /**
       * @default 'Free Wifi'
       */
      freeWifi: T;

      /**
       * @default 'Room Information'
       */
      roomInformation: T;

      /**
       * @default 'Room Facilities'
       */
      roomFacilities: T;

      /**
       * @default 'Room Policy'
       */
      roomPolicy: T;

      /**
       * @default 'Cancellation Policy'
       */
      cancellationPolicy: T;

      /**
       * @default 'View Policy'
       */
      viewPolicy: T;

      /**
       * @default '{{total}} total includes taxes and fees'
       */
      totalPrice: T;

      /**
       * @default 'Select Room'
       */
      selectRoom: T;

      /**
       * @default 'Select {{rooms}} Rooms'
       */
      selectRooms: T;

      /**
       * @default 'You will not be charged yet'
       */
      notChargedYet: T;

      priceForAdults: {
        /**
         * @default 'Price for {{count}} adult'
         * @param {number} count - The number of ocurrencies.
         * - singular: Price for {{count}} adult
         * - plural: Price for {{count}} adults
         */
        price: T;
      };
      priceForAdultsAndChildren: {
        /**
         * @default 'Price for {{count}} adult and {{children}} children'
         * @param {number} count - The number of ocurrencies.
         * - singular: Price for {{count}} adult and {{children}} children
         * - plural: Price for {{count}} adults and {{children}} children
         */
        price: T;
      };
    };

    /**
     * @default 'Note from {{eventDisplayName}}'
     */
    noteFrom: T;

    /**
     * @default 'Less'
     */
    less: T;

    /**
     * @default 'More'
     */
    more: T;

    /**
     * @default 'About the hotel'
     */
    aboutHotel: T;

    /**
     * @default 'Hotel Overview'
     */
    hotelTooltipTitle: T;

    /**
     * @default 'Discover essential information about our hotel.'
     */
    hotelTooltipDescription: T;

    /**
     * @default 'Read more'
     */
    readMore: T;

    /**
     * @default 'Read less'
     */
    readLess: T;

    /**
     * @default 'Choose your room'
     */
    chooseYourRoom: T;

    offersNotFound: {
      /**
       * @default 'No Available Rooms'
       */
      title: T;

      /**
       * @default 'Modify your search criteria and try again.'
       */
      message: T;
    };
  };
};

export type SuggestionsTranslations<T extends LeafNodeValue = TranslateFunction> = {
  eventSuggestions: {
    'reg-get-started': {
      /**
       * @default 'Get Started'
       */
      cta1: T;

      /**
       * @default ''
       */
      dek: T;

      /**
       * @default 'Start your everything wedding registry today with Joy.'
       */
      hed: T;
    };
    'reg-shop': {
      /**
       * @default 'Add Gifts'
       */
      cta1: T;

      /**
       * @default ''
       */
      dek: T;

      /**
       * @default 'Shop the best brands, gift cards, and experiences in one place.'
       */
      hed: T;
    };
    'reg-connect-registries': {
      /**
       * @default 'Learn How'
       */
      cta1: T;

      /**
       * @default ''
       */
      dek: T;

      /**
       * @default 'Add gifts from any store or an entire registry.'
       */
      hed: T;
    };
    'reg-browse-cash-funds': {
      /**
       * @default 'Explore Cash Funds'
       */
      cta1: T;

      /**
       * @default ''
       */
      dek: T;

      /**
       * @default 'Create a zero-fee cash fund and save for anything.'
       */
      hed: T;
    };
    'reg-discount': {
      /**
       * @default 'Add More Gifts'
       */
      cta1: T;

      /**
       * @default ''
       */
      dek: T;

      /**
       * @default 'Get 20% off after the big day with our newlywed discount.'
       */
      hed: T;
    };
    'onboarding-designs': {
      /**
       * @default 'Edit Design'
       */
      cta1: T;

      /**
       * @default ''
       */
      dek: T;

      /**
       * @default 'Style your registry and give guests one seamless place to shop.'
       */
      hed: T;
    };
    'new-update-designs-2020-01-14': {
      /**
       * @default 'Browse Designs'
       */
      cta1: T;

      /**
       * @default ''
       */
      dek: T;

      /**
       * @default 'Design your website with 70+ new designs.'
       */
      hed: T;
    };
    'onboarding-add-page-photos': {
      /**
       * @default 'Edit Your Photo'
       */
      cta1: T;

      /**
       * @default ''
       */
      dek: T;

      /**
       * @default 'Have you added photos?'
       */
      hed: T;
    };
    writersblock: {
      /**
       * @default 'Try it out'
       */
      cta1: T;

      /**
       * @default ''
       */
      dek: T;

      /**
       * @default 'Overcome Wedding Writer’s Block with AI.'
       */
      hed: T;
    };
  };
  adminFeed: {
    /**
     * @default 'You're all set!

Take a breath, you're doing great.'
*/
    zeroStateMessage: T;

    /**
     * @default 'View Past Suggestions'
     */
    zeroStateViewPast: T;
  };
};

export type ThanksForRsvpTranslations<T extends LeafNodeValue = TranslateFunction> = {
  congratulationsCard: {
    /**
     * @default 'Back to Website'
     */
    backToWebsite: T;

    /**
     * @default 'the couple'
     */
    theCouple: T;

    /**
     * @default 'There was an error: {{msg}}. Please try again or contact support.'
     */
    error: T;

    /**
     * @default 'Your RSVP'
     */
    yourRSVP: T;

    /**
     * @default 'and card have'
     */
    andCardHave: T;

    /**
     * @default 'has'
     */
    has: T;

    /**
     * @default 'been sent{{coupleName}}!'
     */
    beenSent: T;

    /**
     * @default 'Your RSVP has been sent.'
     */
    yourRSVPComplete: T;

    /**
     * @default 'Want to send a note of congrats?'
     */
    noteOfCongrats: T;

    /**
     * @default 'Congratulations Note'
     */
    noteTitle: T;

    /**
     * @default 'Your Note'
     */
    yourNoteTitle: T;

    /**
     * @default 'Add a note to the couple'
     */
    notePlaceholder: T;

    /**
     * @default 'Free'
     */
    free: T;

    /**
     * @default 'Congratulations eCard'
     */
    wrapTitle: T;

    /**
     * @default 'Wrap your note in a beautiful design.'
     */
    wrapDescription: T;

    /**
     * @default 'Back to Event Website'
     */
    backToEventWebsite: T;

    /**
     * @default 'Preview Your Note'
     */
    previewYourNote: T;

    /**
     * @default 'Send Message'
     */
    sendMessage: T;

    /**
     * @default 'Continue to Purchase'
     */
    continueToPurchase: T;

    /**
     * @default 'Your Email (Required for Replies)'
     */
    yourEmail: T;

    /**
     * @default 'Are you sure you want to continue without editing the congrats message?'
     */
    uneditedWarningMessage: T;

    /**
     * @default 'Next steps from Joy wedding experts'
     */
    whatNext: T;

    /**
     * @default 'Next steps from Joy wedding experts'
     */
    whatNextV2: T;

    accommodations: {
      /**
       * @default 'We’ve secured discounted rates on a hotel near our venue.'
       */
      title: T;

      /**
       * @default 'Where To Stay'
       */
      titleNoRoomBlock: T;

      /**
       * @default 'Book soon while rooms are available.'
       */
      description: T;

      /**
       * @default 'We have listed our recommended hotel options for your convenience. Book soon while rooms are available.'
       */
      descriptionNoRoomBlock: T;

      /**
       * @default 'View Accommodations'
       */
      ctaText: T;
    };
    nearbyHotels: {
      withCityCopy: {
        /**
         * @default 'Book Your Stay'
         */
        heading: T;

        /**
         * @default 'Check out discounted rates in {{city}} for event guests.'
         */
        subHeading: T;

        /**
         * @default 'Browse Hotels'
         */
        ctaText: T;
      };
      genericCopy: {
        /**
         * @default 'Book Your Stay'
         */
        heading: T;

        /**
         * @default 'Check out discounted rates for event guests'
         */
        subHeading: T;

        /**
         * @default 'Browse Hotels'
         */
        ctaText: T;
      };
    };
    accommodationsV2: {
      /**
       * @default 'Where to Stay'
       */
      title: T;

      /**
       * @default 'We’ve reserved room blocks our special group'
       */
      descriptionRoomBlockOnly: T;

      /**
       * @default 'Here’s are a few places we’d love for everyone to stay.'
       */
      descriptionMultipleAccommodations: T;

      /**
       * @default 'Here’s where we recommend you stay near in the area.'
       */
      descriptionAccommodationPlaceOnly: T;

      /**
       * @default '{{miles}} miles from the venue'
       */
      distanceFromVenue: T;

      /**
       * @default 'Group Rate Ends {{date}}'
       */
      groupRateEnds: T;

      /**
       * @default 'View Room Block'
       */
      ctaRoomBlockOnly: T;

      /**
       * @default 'View Options'
       */
      ctaMultipleAccommodations: T;

      /**
       * @default 'View Stay'
       */
      ctaAccommodationPlaceOnly: T;
    };
    registry: {
      /**
       * @default 'View Registry'
       */
      ctaText: T;

      /**
       * @default 'Browse Registry'
       */
      heading: T;

      /**
       * @default 'Explore gifts {{eventName}} added to their registry.'
       */
      subHeadingWithName: T;
    };
  };
  accommodationsPushStep: {
    /**
     * @default 'Add Your Stay'
     */
    title: T;

    /**
     * @default 'Book your stay for the event and celebrate without a worry in the world.'
     */
    message: T;

    valueProps: {
      refundable: {
        /**
         * @default 'Stay Flexible'
         */
        title: T;

        /**
         * @default 'Pick fully refundable rates for peace of mind.'
         */
        description: T;
      };
      lowestRate: {
        /**
         * @default 'Lowest Rates'
         */
        title: T;

        /**
         * @default 'Exclusive rates for Joy events not available to the public.'
         */
        description: T;
      };
    };
    genericOfferingCard: {
      /**
       * @default 'Book Before Rooms Sell Out'
       */
      sellingFastBadge: T;

      /**
       * @default 'Hotels For Your Stay'
       */
      title: T;

      /**
       * @default 'Browse Hotels'
       */
      browseHotelsCta: T;
    };
    noAccomOrTravelCohort: {
      /**
       * @default 'Add Your Stay'
       */
      title: T;

      /**
       * @default 'Up to 20% off'
       */
      rateDisplayOverride: T;
    };
    hasOwnAccommodationsCohort: {
      /**
       * @default '{{eventDisplayName}}'s Preferred Hotels'
       */
      titleWithDisplayName: T;

      /**
       * @default 'Preferred Hotels'
       */
      genericTitle: T;

      /**
       * @default 'Stay near the venue, so you can spend more time with friends and family.'
       */
      message: T;

      /**
       * @default 'Select Hotel'
       */
      selectHotelCtaText: T;
    };
    offeringCard: {
      rateSection: {
        /**
         * @default 'Rate From'
         */
        title: T;

        rateType: {
          /**
           * @default 'Per Night'
           */
          daily: T;
        };
      };
      roomBlockRateSection: {
        /**
         * @default 'Book Before'
         */
        bookBefore: T;

        /**
         * @default 'Group Rate'
         */
        groupRate: T;

        /**
         * @default '{{price}} / Night'
         */
        pricePerNight: T;
      };
      distanceFromVenue: {
        /**
         * @default '{{distance}} miles from the venue'
         */
        mi: T;

        /**
         * @default '{{distance}} km from the venue'
         */
        km: T;
      };
    };
    footer: {
      /**
       * @default 'Not ready to book your stay?'
       */
      message: T;

      /**
       * @default 'Skip For Now'
       */
      ctaText: T;
    };
  };
};

export type TranslationTranslations<T extends LeafNodeValue = TranslateFunction> = {};

export type TravelEditorTranslations<T extends LeafNodeValue = TranslateFunction> = {
  travelItemList: {
    /**
     * @default 'to'
     */
    to: T;

    /**
     * @default 'Listed Items'
     */
    listedItems: T;
  };
  delete: {
    /**
     * @default 'Are you sure you want to delete this item?'
     */
    title: T;

    /**
     * @default 'Are you sure you want to delete “{{travelItemTitle}}”? This cannot be undone.'
     */
    message: T;

    /**
     * @default 'Delete Travel Tip'
     */
    deleteCta: T;

    /**
     * @default 'Cancel'
     */
    cancelCta: T;

    /**
     * @default 'An error occurred while deleting your travel Item. Please try again.'
     */
    deleteError: T;

    /**
     * @default 'Success! Travel item have been deleted.'
     */
    deleteSuccess: T;
  };
  sort: {
    /**
     * @default 'An error occurred while sorting your travel Item. Please try again.'
     */
    sortError: T;

    /**
     * @default 'Success! Your Travel item order has been updated.'
     */
    sortSuccess: T;
  };
  addTravelTip: {
    /**
     * @default 'Add Travel Tip'
     */
    title: T;

    /**
     * @default 'Share travel plans with guests, including transportation options and general tips.'
     */
    subtitle: T;

    /**
     * @default 'General'
     */
    groupGeneral: T;

    /**
     * @default 'Transportation'
     */
    groupTransportation: T;

    /**
     * @default 'Accommodations'
     */
    groupAccommodations: T;

    /**
     * @default 'Share places to stay on the new Where to Stay page. 🎉'
     */
    groupAccommodationsSubtitle: T;

    /**
     * @default 'Learn More'
     */
    learnMore: T;

    booking: {
      /**
       * @default 'Booking Assistant'
       */
      title: T;
    };
    ferry: {
      /**
       * @default 'Ferry'
       */
      title: T;
    };
    flight: {
      /**
       * @default 'Flight / Airport'
       */
      title: T;
    };
    hotel: {
      /**
       * @default 'Hotel'
       */
      title: T;
    };
    list: {
      /**
       * @default 'Travel List'
       */
      title: T;
    };
    note: {
      /**
       * @default 'Travel Note'
       */
      title: T;
    };
    rentalCar: {
      /**
       * @default 'Rental Car'
       */
      title: T;
    };
    rentalHome: {
      /**
       * @default 'House / Rental'
       */
      title: T;
    };
    shuttle: {
      /**
       * @default 'Shuttle Schedule'
       */
      title: T;
    };
    taxi: {
      /**
       * @default 'Taxi Service'
       */
      title: T;
    };
    tent: {
      /**
       * @default 'Tents / Campsite'
       */
      title: T;
    };
    train: {
      /**
       * @default 'Train'
       */
      title: T;
    };
  };
  addTravelItem: {
    /**
     * @default 'Save'
     */
    save: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Title'
     */
    title: T;

    /**
     * @default 'List Title'
     */
    listTitle: T;

    /**
     * @default 'Link'
     */
    link: T;

    /**
     * @default 'website.com'
     */
    linkFieldPlaceholder: T;

    /**
     * @default 'Update Url'
     */
    updateLink: T;

    /**
     * @default 'Note for Guests'
     */
    noteForGuests: T;

    /**
     * @default 'Venue Location'
     */
    venueLocation: T;

    /**
     * @default 'Address'
     */
    address: T;

    /**
     * @default 'Check In'
     */
    checkIn: T;

    /**
     * @default 'Check Out'
     */
    checkOut: T;

    /**
     * @default 'Item Description'
     */
    itemDescription: T;

    /**
     * @default 'Item Link'
     */
    itemLink: T;

    /**
     * @default 'website.com'
     */
    itemLinkPlaceholder: T;

    /**
     * @default 'Discount Code'
     */
    discountCode: T;

    /**
     * @default 'Remove Item'
     */
    removeItem: T;

    /**
     * @default 'Add Item'
     */
    addItem: T;

    /**
     * @default '01/01/2024'
     */
    datePlaceholder: T;

    /**
     * @default 'Location'
     */
    location: T;

    /**
     * @default 'Phone'
     */
    phone: T;

    /**
     * @default '+1-555-555-5555'
     */
    phonePlaceholder: T;

    /**
     * @default 'Pick-Up Date'
     */
    pickUpDate: T;

    /**
     * @default 'Start Time'
     */
    startTime: T;

    /**
     * @default 'End Time'
     */
    endTime: T;

    /**
     * @default '12:00pm'
     */
    timePlaceholder: T;

    /**
     * @default 'Shuttle Description'
     */
    shuttleDescription: T;

    /**
     * @default 'Pick Up Description'
     */
    shuttleDescriptionPlaceholder: T;

    /**
     * @default 'Please add a title'
     */
    titleError: T;

    /**
     * @default 'Please search for venue location'
     */
    addressError: T;

    /**
     * @default 'Please add a check in date.'
     */
    checkInDateError: T;

    /**
     * @default 'Please add a check out date.'
     */
    checkOutDateError: T;

    /**
     * @default 'Check in date must be before check out date'
     */
    checkInDateRangeError: T;

    /**
     * @default 'Check out date must be after check in date'
     */
    checkOutDateRangeError: T;

    /**
     * @default 'Your url is invalid.'
     */
    invalidURL: T;

    /**
     * @default 'The description has to be less than 5000 characters long.'
     */
    descriptionError: T;

    /**
     * @default 'Please add a note for guests'
     */
    guestNoteError: T;

    /**
     * @default 'Please add a location'
     */
    locationError: T;

    /**
     * @default 'Please add a pick-up date'
     */
    pickUpDateError: T;

    /**
     * @default 'Please add a start time'
     */
    startTimeError: T;

    /**
     * @default 'Please add an end time'
     */
    endTimeError: T;

    /**
     * @default 'An error occurred while addding your travel item. Please try again.'
     */
    addTravelItemError: T;

    /**
     * @default 'An error occurred while updating your travel item. Please try again.'
     */
    updateTravelItemError: T;

    /**
     * @default 'Success! Travel item have been saved.'
     */
    addTravelItemSuccess: T;

    /**
     * @default 'Delete Travel Tip'
     */
    deleteTravelTip: T;

    /**
     * @default 'Discounted Rates on Rental Cars'
     */
    hertzTitleDefaultText: T;

    /**
     * @default 'All of our guests receive up to 25% off car rentals. Extend your visit and enjoy your trip before and/or after our big day!'
     */
    hertzDescriptionDefaultText: T;

    booking: {
      title: {
        /**
         * @default 'Add Booking Assistant'
         */
        add: T;

        /**
         * @default 'Booking Assistant'
         */
        edit: T;
      };

      /**
       * @default 'Help your guests find and book hotels and rental cars near your venue.'
       */
      subtitle: T;
    };
    ferry: {
      title: {
        /**
         * @default 'Add Ferry'
         */
        add: T;

        /**
         * @default 'Ferry'
         */
        edit: T;
      };

      /**
       * @default 'Share ferry ports, a link to the schedule, and any other valuable info.'
       */
      subtitle: T;
    };
    flight: {
      title: {
        /**
         * @default 'Add Flight / Airport'
         */
        add: T;

        /**
         * @default 'Flight / Airport'
         */
        edit: T;
      };

      /**
       * @default 'Share flight, airport suggestions, or other instructions and details.'
       */
      subtitle: T;
    };
    hotel: {
      title: {
        /**
         * @default 'Add Hotel'
         */
        add: T;

        /**
         * @default 'Hotel'
         */
        edit: T;
      };

      /**
       * @default 'Share a hotel recommendation.'
       */
      subtitle: T;
    };
    list: {
      title: {
        /**
         * @default 'Add Travel List'
         */
        add: T;

        /**
         * @default 'Travel List'
         */
        edit: T;
      };

      /**
       * @default 'Create a list of things to do, what to bring, helpful links—anything!'
       */
      subtitle: T;
    };
    note: {
      title: {
        /**
         * @default 'Add Travel Note'
         */
        add: T;

        /**
         * @default 'Travel Note'
         */
        edit: T;
      };

      /**
       * @default 'This flexible travel tip allows you to write anything you want.'
       */
      subtitle: T;
    };
    rentalCar: {
      title: {
        /**
         * @default 'Add Rental Car'
         */
        add: T;

        /**
         * @default 'Rental Car'
         */
        edit: T;
      };

      /**
       * @default 'Share a rental car recommendation, location, and any other valuable info.'
       */
      subtitle: T;
    };
    rentalHome: {
      title: {
        /**
         * @default 'Add House / Rental'
         */
        add: T;

        /**
         * @default 'House / Rental'
         */
        edit: T;
      };

      /**
       * @default 'Share info about rentals or non-hotel accommodation options.'
       */
      subtitle: T;
    };
    shuttle: {
      title: {
        /**
         * @default 'Add Shuttle Schedule'
         */
        add: T;

        /**
         * @default 'Shuttle Schedule'
         */
        edit: T;
      };

      /**
       * @default 'Share details about your shuttle, the schedule, and any other valuable info.'
       */
      subtitle: T;
    };
    taxi: {
      title: {
        /**
         * @default 'Add Taxi Service'
         */
        add: T;

        /**
         * @default 'Taxi Service'
         */
        edit: T;
      };

      /**
       * @default 'Share a ride share or taxi service recommendation and any valuable info.'
       */
      subtitle: T;
    };
    tent: {
      title: {
        /**
         * @default 'Add Tents / Campsite'
         */
        add: T;

        /**
         * @default 'Tents / Campsite'
         */
        edit: T;
      };

      /**
       * @default 'Share info about camping.'
       */
      subtitle: T;
    };
    train: {
      title: {
        /**
         * @default 'Add Train'
         */
        add: T;

        /**
         * @default 'Train'
         */
        edit: T;
      };

      /**
       * @default 'Share train lines, stations, instructions, and any other valuable info.'
       */
      subtitle: T;
    };
  };
  hertz: {
    /**
     * @default 'Guests get up to 25% off with Hertz.'
     */
    bannerTitle: T;

    /**
     * @default 'Remove Offer'
     */
    bannerCTA: T;

    /**
     * @default 'Are you sure you want to remove this exclusive deal for your guests?'
     */
    dialogTitleRemove: T;

    /**
     * @default 'Are you sure you want to delete this exclusive deal for your guests?'
     */
    dialogTitleDelete: T;

    /**
     * @default 'Car rentals are the second largest travel expense for your guests after they arrive. Joy has partnered with Hertz to provide your guests up to 25% off their rentals.'
     */
    dialogBody: T;

    /**
     * @default 'Keep discount for my guests'
     */
    dialogCTA1: T;

    /**
     * @default 'Remove this discount'
     */
    dialogCTA2Remove: T;

    /**
     * @default 'Delete this discount'
     */
    dialogCTA2Delete: T;
  };
  accommodationLinkDialog: {
    /**
     * @default 'Introducing Where to Stay'
     */
    title: T;

    /**
     * @default 'Inspired by couples’ feedback, we’ve added a Where to Stay page to help you find and recommend the best accommodations for your guests. This page showcases your top picks and lets you see where guests are staying, making planning easier.'
     */
    subTitle: T;

    /**
     * @default 'Edit Page'
     */
    editPageCta: T;

    /**
     * @default 'I'll do it later'
     */
    willDoLaterCta: T;
  };
};

export type UniversalRecommendationsTranslations<T extends LeafNodeValue = TranslateFunction> = {
  productTile: {
    /**
     * @default 'While copying your gifts we may have found something better.'
     */
    title: T;

    /**
     * @default 'Added to your registry!'
     */
    postAddTitle: T;

    /**
     * @default 'You might also like this:'
     */
    postAddSubtitle: T;

    /**
     * @default 'Tell Me More'
     */
    TellMeMoreBtn: T;

    /**
     * @default 'Skip'
     */
    SkipBtn: T;

    /**
     * @default 'Swap Gift'
     */
    SwapGiftBtn: T;

    /**
     * @default 'Remind Me Later'
     */
    RemindMeLater: T;
  };
  notifiers: {
    /**
     * @default 'You’ve got recomendations'
     */
    notification: T;

    /**
     * @default 'All your registries have been transferred'
     */
    bannerTitle: T;

    /**
     * @default 'We found some recommendations that you may like!'
     */
    bannerSubtitle: T;

    /**
     * @default 'View Recommendations'
     */
    bannerBtn: T;

    /**
     * @default 'Registry {{currentIndex}} of {{totalItems}}'
     */
    registryCount: T;
  };
};

export type VendorTranslations<T extends LeafNodeValue = TranslateFunction> = {
  addCollections: {
    /**
     * @default 'Create'
     */
    create: T;

    /**
     * @default 'Description'
     */
    description: T;

    /**
     * @default 'Create Collection'
     */
    formTitle: T;

    /**
     * @default 'Name'
     */
    name: T;
  };
  addTestimonialDialog: {
    /**
     * @default 'Add'
     */
    add: T;

    /**
     * @default 'Add Testimonial'
     */
    addTestimonial: T;

    /**
     * @default 'Content'
     */
    content: T;

    /**
     * @default 'Reviewer'
     */
    reviewer: T;

    /**
     * @default 'Save'
     */
    save: T;

    /**
     * @default 'Update Testimonial'
     */
    updateTestimonial: T;
  };
  adminEditCollections: {
    /**
     * @default 'New Collection'
     */
    addCollectionButtonTitle: T;

    /**
     * @default 'Create photo collections to curate and highlight your work to couples.'
     */
    pageDescriptionTest: T;

    /**
     * @default 'Collections'
     */
    pageTitle: T;
  };
  adminEditProfile: {
    /**
     * @default 'Edit Profile'
     */
    pageTitle: T;
  };
  adminEditSingleCollection: {
    /**
     * @default 'Add Photo'
     */
    addCollectionItemButtonTitle: T;

    /**
     * @default 'Collection items could not be saved'
     */
    generalError: T;

    /**
     * @default 'Saving'
     */
    savingCollection: T;
  };

  /**
   * @default 'Recent'
   */
  adminVendorListMostRecent: T;

  collections: {
    /**
     * @default 'More Collections'
     */
    moreCollections: T;
  };

  /**
   * @default 'Create Vendor'
   */
  createVendor: T;

  createVendorDialog: {
    /**
     * @default 'Choose a vendor type'
     */
    chooseAType: T;

    /**
     * @default 'Done'
     */
    done: T;

    /**
     * @default 'Enter vendor information'
     */
    enterVendorInfo: T;

    /**
     * @default 'Failed to create vendor'
     */
    failedToMakeVendor: T;

    /**
     * @default 'Next'
     */
    next: T;

    /**
     * @default 'Submitted'
     */
    submitted: T;

    /**
     * @default 'You made a vendor'
     */
    youMadeAVendor: T;
  };
  createVendorForm: {
    /**
     * @default 'Address'
     */
    addressTitle: T;

    /**
     * @default 'Bio'
     */
    bioTitle: T;

    /**
     * @default 'Contact Email'
     */
    contactEmailTitle: T;

    /**
     * @default 'Handle'
     */
    handleTitle: T;

    /**
     * @default 'Name'
     */
    nameTitle: T;

    /**
     * @default 'Website'
     */
    websiteTitle: T;
  };

  /**
   * @default 'Edit About'
   */
  editAbout: T;

  editCollection: {
    /**
     * @default 'Description'
     */
    description: T;

    /**
     * @default 'Edit Collection'
     */
    formTitle: T;

    /**
     * @default 'Name'
     */
    name: T;

    /**
     * @default 'Save'
     */
    submitText: T;
  };

  /**
   * @default 'Edit Collections'
   */
  editCollections: T;

  /**
   * @default 'Edit Profile'
   */
  editProfile: T;

  /**
   * @default 'Edit Vendor'
   */
  editVendor: T;

  /**
   * @default 'Put your best foot forward. Edit what guests and your peers see when you’re showcased on your couple’s wedding websites.'
   */
  editWhatGuestsSee: T;

  experienceHeader: {
    /**
     * @default 'Display your services and create beautiful curated collections to highlight your work.'
     */
    bulletADescription: T;

    /**
     * @default 'Present yourself as a professional'
     */
    bulletATitle: T;

    /**
     * @default 'Simple for couples to connect and make sure your services is right for them.'
     */
    bulletBDescription: T;

    /**
     * @default 'Grow your business'
     */
    bulletBTitle: T;

    /**
     * @default 'Grow your photography business with your first upload. Our algorithm surfaces local new photographs and photographers to couples on Joy.'
     */
    description: T;

    /**
     * @default 'Immediate Exposure'
     */
    title: T;
  };
  headerMenu: {
    /**
     * @default 'Account'
     */
    account: T;

    /**
     * @default 'Dashboard'
     */
    dashboard: T;

    /**
     * @default 'Ideabooks'
     */
    ideabooks: T;

    /**
     * @default 'Sign Out'
     */
    signout: T;

    /**
     * @default 'View Profile'
     */
    viewProfile: T;

    /**
     * @default 'Liked Photos'
     */
    likedPhotos: T;

    /**
     * @default 'Vendor Shortlist'
     */
    shortlist: T;

    /**
     * @default 'Sent Messages'
     */
    messages: T;

    /**
     * @default 'Sign Up'
     */
    signup: T;

    /**
     * @default 'Sign In'
     */
    signin: T;
  };

  /**
   * @default 'Here are the Joy couples who have showcased your work on their wedding website.'
   */
  howCouplesSeeYou: T;

  inquiry: {
    /**
     * @default 'Accept Lead'
     */
    acceptButtonTitle: T;

    /**
     * @default 'Unauthorized to accept lead'
     */
    acceptErrorUnauthorized: T;

    /**
     * @default 'Lead Accepted'
     */
    leadAccepted: T;

    /**
     * @default 'Respond'
     */
    replyButtonTitle: T;

    /**
     * @default '{{name}} would like to work with you.'
     */
    title: T;
  };
  lightbox: {
    /**
     * @default 'in {{categoryName}}'
     */
    categoryText: T;

    /**
     * @default 'More from {{collectionName}}'
     */
    moreFrom: T;
  };
  manageCollections: {
    /**
     * @default 'Add Collection'
     */
    addCollection: T;

    /**
     * @default 'Back to Vendor Info'
     */
    backToVendorInfo: T;

    /**
     * @default 'No Collections'
     */
    noCollections: T;
  };
  manageSingleCollection: {
    /**
     * @default 'Add photo'
     */
    addNewPhoto: T;

    /**
     * @default 'Back to vendor collections'
     */
    backToVendorCollections: T;

    /**
     * @default 'Edit Collection'
     */
    editCollection: T;
  };
  manageVendorInfo: {
    /**
     * @default 'Add Review'
     */
    addTestimonial: T;

    /**
     * @default 'Add Vendor User'
     */
    addVendorUserSectionTitle: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Copy Claim Token'
     */
    copyClaimToken: T;

    /**
     * @default 'Vendor CRUD Dashboard'
     */
    dashBoardTitle: T;

    /**
     * @default 'Edit Review'
     */
    editTestimonial: T;

    /**
     * @default 'Edit'
     */
    editVendorUserSectionTitle: T;

    /**
     * @default 'Manage Collections'
     */
    manageCollections: T;

    /**
     * @default 'Refresh Claim Token'
     */
    refreshClaimToken: T;

    /**
     * @default 'Save'
     */
    save: T;

    /**
     * @default 'Status: {{status}}'
     */
    status: T;

    /**
     * @default 'What folks are saying'
     */
    testimonials: T;

    /**
     * @default 'Help guide couples on if their budget if right for your services by providing some pricing guidelines'
     */
    testimonialsSubTitle: T;

    /**
     * @default 'Title: {{title}}'
     */
    title: T;

    /**
     * @default 'Vendor Users'
     */
    vendorUserSectionTitle: T;

    /**
     * @default 'View Profile'
     */
    viewVendorProfileButton: T;
  };
  menu: {
    /**
     * @default 'About'
     */
    about: T;

    /**
     * @default 'Collections'
     */
    collections: T;

    /**
     * @default 'Photos'
     */
    highlights: T;

    /**
     * @default 'Reviews'
     */
    reviews: T;
  };
  messageVendor: {
    /**
     * @default 'Cancel'
     */
    cancelButtonTitle: T;

    /**
     * @default 'create an account'
     */
    createAccount: T;

    /**
     * @default 'Get Pricing'
     */
    messageButtonTitle: T;

    /**
     * @default 'Get Pricing'
     */
    messageButtonTitleLonger: T;

    /**
     * @default 'Check Availability'
     */
    messageButtonCheckAvailability: T;

    /**
     * @default 'Contact <VendorName/>'
     */
    messageDialogTitle: T;

    /**
     * @default 'Message'
     */
    messageFieldTitle: T;

    /**
     * @default 'Sign in to send your message.'
     */
    mustBeSignedIn: T;

    /**
     * @default 'For a prompt and detailed response, include your date, location, budget, and what draws you to the work.'
     */
    prompt: T;

    /**
     * @default 'Sign-in'
     */
    signIn: T;

    /**
     * @default '<SignIn/> or <CreateAccount/> to continue'
     */
    signInOrCreate: T;

    /**
     * @default 'Send'
     */
    submitButtonTitle: T;

    /**
     * @default 'Your first name'
     */
    ownerInputLabel: T;

    /**
     * @default 'Samantha'
     */
    ownerInputPlaceholder: T;

    /**
     * @default 'Your partner’s first name'
     */
    fianceeInputLabel: T;

    /**
     * @default 'John'
     */
    fianceeInputPlaceholder: T;

    /**
     * @default 'Where are you getting married'
     */
    locationInputLabel: T;

    /**
     * @default 'Search Google Maps'
     */
    locationInputPlaceholder: T;

    /**
     * @default 'On this date'
     */
    dateInputLabel: T;

    /**
     * @default 'I don't have a date yet'
     */
    noDateInputLabel: T;

    /**
     * @default 'Around how much is your budget'
     */
    budgetInputLabel: T;

    /**
     * @default '25,000'
     */
    budgetInputPlaceholder: T;

    /**
     * @default 'Message'
     */
    messageInputLabel: T;

    /**
     * @default 'Message'
     */
    messageInputLabelMultiple: T;

    /**
     * @default 'Hi! We're looking for a photographer to help us remember our rustic barn wedding, which will be a little nontraditional and casual. We are hoping for mostly candids that capture the fun, and a few formal and family photos.'
     */
    messageInputPlaceholder: T;

    /**
     * @default 'Sign up to send your message (we saved it).'
     */
    bannerMessage: T;

    /**
     * @default 'Photo by {{credit}}'
     */
    photoCredit: T;

    /**
     * @default 'Your message is on the way.'
     */
    messageSentSuccess: T;

    /**
     * @default 'Browse photo ideas on Joy and save them to your ideabooks. It helps the photographers you message get a sense of your style.'
     */
    messageSentContent: T;

    /**
     * @default 'Hi, <ownerFirstName/> & <fianceeFirstName/>. We're getting married at <location/> on <date/> and our wedding budget is <budget/>.'
     */
    formParagraph: T;

    /**
     * @default 'Got It'
     */
    gotIt: T;

    /**
     * @default 'Please select atleast one photo'
     */
    errorMessage: T;

    /**
     * @default 'About Us'
     */
    formInfoTitle: T;
  };

  /**
   * @default 'See More Work'
   */
  moreWork: T;

  /**
   * @default 'No route match for: {{pathname}}'
   */
  noPathFound: T;

  pricing: {
    /**
     * @default 'Packages'
     */
    photosStartAtTitle: T;

    /**
     * @default 'Start at <Price/>'
     */
    startAt: T;

    /**
     * @default 'Typical Spend'
     */
    typicalSpendTitle: T;
  };

  /**
   * @default 'Profile'
   */
  profile: T;

  profileAbout: {
    /**
     * @default 'About <NameOfVendor/>'
     */
    aboutTitle: T;

    /**
     * @default 'What folks are saying'
     */
    testimonialHeader: T;
  };

  /**
   * @default 'Search for vendor by id'
   */
  searchForVendorById: T;

  /**
   * @default 'Search for vendor by name'
   */
  searchForVendorByName: T;

  updateVendorUser: {
    /**
     * @default 'Email'
     */
    emailFieldTitle: T;

    /**
     * @default 'First Name'
     */
    firstNameFieldTitle: T;

    /**
     * @default 'Update Vendor User'
     */
    formTitle: T;

    /**
     * @default 'Last Name'
     */
    lastNameFieldTitle: T;

    /**
     * @default 'Phone'
     */
    phoneFieldTitle: T;

    /**
     * @default 'Role'
     */
    roleFieldTitle: T;

    /**
     * @default 'Update'
     */
    updateButton: T;
  };
  vendorContactEdit: {
    /**
     * @default 'As displayed in your profile header. This is the first thing couples, guests and your peers will see.'
     */
    aboutSubtitle: T;

    /**
     * @default 'About'
     */
    aboutTitle: T;

    /**
     * @default 'Bio'
     */
    bioFieldTitle: T;

    /**
     * @default 'General contact information about your business'
     */
    contactSubTitle: T;

    /**
     * @default 'Business Contact'
     */
    contactTitle: T;

    /**
     * @default 'Delete'
     */
    delete: T;

    /**
     * @default 'Email'
     */
    emailFieldTitle: T;

    /**
     * @default 'Handle'
     */
    handleFieldTitle: T;

    /**
     * @default 'Location'
     */
    locationFieldTitle: T;

    /**
     * @default 'Business Name'
     */
    nameFieldTitle: T;

    /**
     * @default 'Phone'
     */
    phoneFieldTitle: T;

    /**
     * @default 'Help guide couples on if their budget if right for your services by providing some pricing guidelines.'
     */
    pricingSubtitle: T;

    /**
     * @default 'Pricing'
     */
    pricingTitle: T;

    /**
     * @default 'Photo Packages Start At'
     */
    startingPriceFieldTitle: T;

    /**
     * @default 'Submit'
     */
    submit: T;

    /**
     * @default 'Help guide couples on if their budget if right for your services by providing some pricing guidelines.'
     */
    testimonialSubtitle: T;

    /**
     * @default 'What folks are saying'
     */
    testimonialTitle: T;

    /**
     * @default 'Couples Typically Spend'
     */
    typicalSpendFieldTitle: T;

    /**
     * @default 'Upload Photo'
     */
    uploadPhoto: T;

    /**
     * @default 'Website'
     */
    websiteFieldTitle: T;
  };
  vendorContactLine: {
    /**
     * @default 'Visit Website'
     */
    viewWebsite: T;
  };

  /**
   * @default 'Vendor Dashboard'
   */
  vendorDashboard: T;

  /**
   * @default 'View Public Profile'
   */
  viewPublicProfile: T;

  /**
   * @default 'View Showcase'
   */
  viewShowcase: T;

  /**
   * @default 'Weddings you’re showcased in'
   */
  weddingsYouIn: T;

  /**
   * @default 'Your profile on Joy.'
   */
  youProfileOnJoy: T;

  informativeDialog: {
    /**
     * @default 'Cool!'
     */
    GotIt: T;

    /**
     * @default 'That little ❤︎ of yours automagically...'
     */
    Message: T;

    /**
     * @default 'Photo by'
     */
    subMessage: T;

    /**
     * @default '🌅 Creates ideabooks'
     */
    p1: T;

    /**
     * @default 'to share with your partner and vendors,'
     */
    p2: T;

    /**
     * @default '📋 Keeps track of photographers'
     */
    p3: T;

    /**
     * @default 'so you can easily reach out, and'
     */
    p4: T;

    /**
     * @default '🙌 Helps photographers'
     */
    p5: T;

    /**
     * @default 'get noticed by more couples.'
     */
    p6: T;
  };
  likingValues: {
    /**
     * @default 'Start with a like'
     */
    valueLikeTitle: T;

    /**
     * @default 'Discover and like thousands of photos from Bay Area wedding photographers.'
     */
    valueLikeSubtitle: T;

    /**
     * @default 'Automagically organize'
     */
    valueOrganizeTitle: T;

    /**
     * @default 'We’ll organize them and save a list of the photographers behind the photos.'
     */
    valueOrganizeSubtitle: T;

    /**
     * @default 'Get pricing'
     */
    valuePriceTitle: T;

    /**
     * @default 'Instantly reach out to all the photographers behind the photos you liked, and book the right one.'
     */
    valuePriceSubtitle: T;
  };
};

export type VerifyEmailTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Let’s verify your email address'
   */
  headerText: T;

  /**
   * @default 'We just sent an email to <Email/>, with the subject “Please verify your email”. Click the link in that email and you’ll be all set to go.'
   */
  emailSentExplanation: T;

  /**
   * @default 'Your verification email has been re-sent.'
   */
  emailReSentCopy: T;

  /**
   * @default 'Sending...'
   */
  emailSendingCopy: T;

  /**
   * @default 'Can't find the email?'
   */
  emailNotFoundCopy: T;

  /**
   * @default 'Resend verification email'
   */
  reSendEmailAction: T;
};

export type WebsiteDesignerTranslations<T extends LeafNodeValue = TranslateFunction> = {
  /**
   * @default 'Design'
   */
  titleDesign: T;

  /**
   * @default 'Select a Design Template'
   */
  selectTemplateTitle: T;

  /**
   * @default 'Select a Template'
   */
  selectTemplateTitleV2: T;

  /**
   * @default 'Save and Open'
   */
  saveAndOpen: T;

  /**
   * @default 'Add your custom CSS here'
   */
  cssCodePlaceholder: T;

  /**
   * @default 'Custom CSS is an experimental feature. We can't guarantee your custom CSS will work indefinitely.'
   */
  cssWarning: T;

  /**
   * @default 'Using CSS requires a separate preview.'
   */
  cssPreviewTitle: T;

  /**
   * @default 'To see your changes, publish your design and open your live website.'
   */
  cssPreviewContent: T;

  /**
   * @default 'Edit Design'
   */
  editDesign: T;

  /**
   * @default 'Preview'
   */
  preview: T;

  /**
   * @default 'Preview Site'
   */
  previewSite: T;

  /**
   * @default 'Publish'
   */
  ctaPublish: T;

  /**
   * @default 'Apply Template'
   */
  ctaApplyTemplate: T;

  /**
   * @default 'Back'
   */
  ctaBack: T;

  /**
   * @default 'Design'
   */
  design: T;

  /**
   * @default 'Back to Dashboard'
   */
  back: T;

  /**
   * @default 'Preview site with this theme'
   */
  previewSiteWithThisTheme: T;

  /**
   * @default 'Publish'
   */
  publish: T;

  /**
   * @default 'Publish Changes'
   */
  publishChanges: T;

  /**
   * @default 'Publishing...'
   */
  publishing: T;

  /**
   * @default 'Saving...'
   */
  saving: T;

  /**
   * @default 'Nice work! Now, check out your site.'
   */
  niceWork: T;

  /**
   * @default 'View current site without your changes.'
   */
  viewCurrentSite: T;

  /**
   * @default 'Not seeing your changes on your website? Make sure to apply changes.'
   */
  notSeeingChangesMessage: T;

  /**
   * @default 'View Site'
   */
  viewSite: T;

  /**
   * @default 'Updates have been published'
   */
  changesPublishedSuccessfully: T;

  /**
   * @default 'Preview Website'
   */
  'titlePreview.desktop': T;

  /**
   * @default 'Preview Mobile'
   */
  'titlePreview.mobile': T;

  /**
   * @default 'Preview Stationery'
   */
  'titlePreview.stationery': T;

  /**
   * @default 'Sorry but your theme is no longer available to preview.'
   */
  themeNotSupportedText: T;

  /**
   * @default 'Learn Why'
   */
  themeNotSupportedButton: T;

  /**
   * @default 'Explore Matching Prints'
   */
  stationeryExplorePrints: T;

  /**
   * @default 'Select unique print stationery from'
   */
  stationerySelectUnique: T;

  /**
   * @default 'Or pair your theme with free matching digital stationery'
   */
  stationaryDigitalOption: T;

  /**
   * @default 'Pair your theme with free matching digital stationery'
   */
  stationaryDigitalOptionPair: T;

  /**
   * @default 'Create Save the Date'
   */
  stationarySaveTheDate: T;

  /**
   * @default 'Create Invitation'
   */
  stationaryInvitation: T;

  /**
   * @default 'Save the Date'
   */
  saveTheDate: T;

  /**
   * @default 'Invitation'
   */
  invitation: T;

  /**
   * @default 'Current'
   */
  current: T;

  /**
   * @default 'Headers'
   */
  headers: T;

  /**
   * @default 'Filter'
   */
  filter: T;

  /**
   * @default 'Filters'
   */
  filters: T;

  /**
   * @default 'Clear'
   */
  clear: T;

  /**
   * @default 'reset'
   */
  reset: T;

  /**
   * @default 'Graphic Accent'
   */
  graphicAccent: T;

  /**
   * @default 'Layout'
   */
  layout: T;

  /**
   * @default 'Accent Color'
   */
  accentColor: T;

  /**
   * @default ' is used for links and buttons. It can also be used as a background color for some themes using the single page layout.'
   */
  accentColorTooltip: T;

  /**
   * @default 'Fonts'
   */
  fonts: T;

  /**
   * @default 'Background'
   */
  background: T;

  /**
   * @default 'Links'
   */
  links: T;

  /**
   * @default 'Art'
   */
  art: T;

  /**
   * @default 'Border'
   */
  border: T;

  /**
   * @default 'Transparency'
   */
  transparency: T;

  /**
   * @default 'Sorry, there&apos;s been an error. Refresh the page.'
   */
  dataLoadError: T;

  /**
   * @default 'Multi-Page'
   */
  multiPage: T;

  /**
   * @default 'Collection'
   */
  collection: T;

  /**
   * @default 'Single Page'
   */
  singlePage: T;

  /**
   * @default 'No Exact Matches'
   */
  noMatches: T;

  /**
   * @default 'Clear All Filters'
   */
  clearAllFilters: T;

  /**
   * @default 'Show All'
   */
  showAllButton: T;

  /**
   * @default 'Show All Templates'
   */
  showAllButton_template: T;

  /**
   * @default 'Show All Fonts'
   */
  showAllButton_font: T;

  /**
   * @default 'Show All Themes'
   */
  showAllButton_theme: T;

  /**
   * @default 'Show it'
   */
  showOneButton: T;

  /**
   * @default 'Show 1 Template'
   */
  showOneButton_template: T;

  /**
   * @default 'Show 1 Font'
   */
  showOneButton_font: T;

  /**
   * @default 'Show 1 Theme'
   */
  showOneButton_theme: T;

  /**
   * @default 'Show them'
   */
  showManyButton: T;

  /**
   * @default 'Show {{count}} Templates'
   */
  showManyButton_template: T;

  /**
   * @default 'Show {{count}} Fonts'
   */
  showManyButton_font: T;

  /**
   * @default 'Show {{count}} Themes'
   */
  showManyButton_theme: T;

  /**
   * @default 'Filter Options'
   */
  filterBaseTitle: T;

  /**
   * @default 'Filter Templates'
   */
  filterBaseTitle_template: T;

  /**
   * @default 'Filter Fonts'
   */
  filterBaseTitle_font: T;

  /**
   * @default 'Filter Themes'
   */
  filterBaseTitle_theme: T;

  smartTip: {
    /**
     * @default 'SMART TIP'
     */
    label: T;

    /**
     * @default 'Easily link to your website with a QR code on your cards.'
     */
    browse: T;

    /**
     * @default 'Link to your website with a QR code on matching cards.'
     */
    matching: T;

    /**
     * @default 'Browse Cards'
     */
    browseButton: T;

    /**
     * @default 'Add QR Code'
     */
    matchingButton: T;
  };
  stationeryTabV2: {
    externalProviderBlock: {
      /**
       * @default 'Beautiful Matching Paper Cards from {{providerName}}'
       */
      title: T;

      /**
       * @default 'Customize and send beautiful Save the Dates and Invitations matching your wedding site.'
       */
      description: T;

      /**
       * @default 'Customize on {{providerName}}'
       */
      primaryCta: T;
    };
    joyProviderBlock: {
      /**
       * @default 'Beautiful Paper & Digital Cards to Match Your Wedding Website'
       */
      defaultTitle: T;

      /**
       * @default 'Digital Cards to Match Your Wedding Website'
       */
      externalProviderTitle: T;

      /**
       * @default 'Customize and send beautiful Save the Dates and Invitations matching your wedding site.'
       */
      description: T;

      /**
       * @default 'Explore Print Designs'
       */
      primaryCta: T;

      /**
       * @default 'Explore Digital Designs'
       */
      secondaryCta: T;
    };
  };
  matchingPrintBanner: {
    /**
     * @default 'Create a card that matches your wedding website.'
     */
    description: T;

    /**
     * @default 'See Matching Card'
     */
    button: T;

    /**
     * @default 'Smart Tip'
     */
    label: T;
  };

  /**
   * @default 'Favorites'
   */
  favorites: T;

  emptyResults: {
    /**
     * @default 'Clear Filters'
     */
    clearFilters: T;

    /**
     * @default 'Clear Search'
     */
    clearSearch: T;

    /**
     * @default 'Once you favorite fonts, you’ll see them here.'
     */
    fontsFavoritesSubTitle: T;

    state: {
      filters: {
        /**
         * @default 'No results for those filters.'
         */
        title: T;

        /**
         * @default 'Try changing them or using fewer filters.'
         */
        subTitle: T;
      };
      search: {
        /**
         * @default 'No results found.'
         */
        title: T;

        /**
         * @default 'Look out for typos or try a different search.'
         */
        subTitle: T;
      };
      favorite: {
        /**
         * @default 'Press the heart button on anything you love to favorite it.'
         */
        title: T;

        /**
         * @default 'Your favorite designs will be collected here.'
         */
        subTitle: T;
      };
    };

    /**
     * @default 'Looking for something we don’t have yet?'
     */
    notFoundMessage: T;

    /**
     * @default 'Let us know!'
     */
    letUsKnow: T;
  };
  search: {
    /**
     * @default 'Search for {{tab}}'
     */
    placeholder: T;
  };
  localStorageError: {
    /**
     * @default 'LocalStorage Not Available'
     */
    title: T;

    /**
     * @default 'Close'
     */
    closeCta: T;

    /**
     * @default 'It looks like LocalStorage is not available in your browser. LocalStorage is required for favorite application to function correctly.'
     */
    applicationMessage: T;

    /**
     * @default 'Please ensure that LocalStorage is enabled in your browser settings. You can usually find these settings under privacy or site-specific settings.'
     */
    privacyMessage: T;

    /**
     * @default 'If you are using incognito mode or a privacy-focused browser, try switching to a normal browsing window or adjusting your privacy settings.'
     */
    incognitoMessage: T;
  };
  fontPacks: {
    /**
     * @default 'Customize Fonts'
     */
    customizeFonts: T;

    /**
     * @default 'Font Sets'
     */
    fontSets: T;

    categories: {
      /**
       * @default 'Buttons'
       */
      BUTTONS: T;

      /**
       * @default 'Event Date'
       */
      DATE: T;

      /**
       * @default 'Event Name'
       */
      EVENT_TITLE: T;

      /**
       * @default 'Heading'
       */
      HEADING: T;

      /**
       * @default 'Event Location'
       */
      LOCATION: T;

      /**
       * @default 'Navigation'
       */
      NAVIGATION: T;

      /**
       * @default 'Paragraph'
       */
      PARAGRAPH: T;

      /**
       * @default 'Subhead'
       */
      SUB_HEADING: T;

      /**
       * @default 'Caption'
       */
      CAPTION: T;

      /**
       * @default 'Page Title'
       */
      PAGE_TITLE: T;
    };

    /**
     * @default 'Reset All Fonts'
     */
    resetAllFonts: T;

    /**
     * @default 'All Styles'
     */
    allStyles: T;

    /**
     * @default '{{category}} Style'
     */
    fontCategoryTitle: T;

    /**
     * @default '{{category}} Size'
     */
    fontCategorySize: T;

    /**
     * @default '{{category}} style updated.'
     */
    fontConfigEditSuccess: T;

    /**
     * @default 'Error updating font configuration. Please try again later.'
     */
    fontConfigEditError: T;

    /**
     * @default 'A'
     */
    a: T;

    /**
     * @default 'Search by name, style, or vibes'
     */
    fontPackSearchPlaceholder: T;

    /**
     * @default 'Select a font pack for customization'
     */
    selectAFontPackForCustomization: T;

    sampleFontText: {
      /**
       * @default 'The Ceremony'
       */
      heading: T;

      /**
       * @default 'Rehearsal Dinner'
       */
      subHeading: T;

      /**
     * @default 'Please see our Schedule 
 for the dress code.'
*/
      paragraph: T;

      /**
       * @default 'Don't forget to RSVP'
       */
      buttons: T;

      /**
       * @default 'Schedule    Travel    Registry'
       */
      navigation: T;

      /**
       * @default '10:00 AM'
       */
      caption: T;

      /**
       * @default 'Schedule'
       */
      pageTitle: T;
    };
    discardDialog: {
      /**
       * @default 'Do you want to discard font customizations?'
       */
      title: T;

      /**
       * @default 'You will lose the custom page fonts you have selected if you switch to this font set.'
       */
      subtitle: T;

      /**
       * @default 'Keep Custom Fonts'
       */
      CTA1: T;

      /**
       * @default 'Discard Custom Fonts'
       */
      CTA2: T;
    };
  };
};

export type WebsiteEditorTranslations<T extends LeafNodeValue = TranslateFunction> = {
  topBar: {
    /**
     * @default 'Home'
     */
    home: T;

    /**
     * @default 'Page Settings'
     */
    pageSettings: T;

    /**
     * @default 'New'
     */
    new: T;

    /**
     * @default 'Publish'
     */
    publish: T;

    /**
     * @default 'Publish Changes'
     */
    publishChanges: T;

    /**
     * @default 'Publishing...'
     */
    publishing: T;

    /**
     * @default 'Save'
     */
    save: T;

    /**
     * @default 'Save Changes'
     */
    saveChanges: T;

    /**
     * @default 'Saving...'
     */
    saving: T;

    /**
     * @default 'Not seeing your changes on your website? Make sure to apply changes.'
     */
    notSeeingChangesMessage: T;

    /**
     * @default 'Nice work! Now, check out your page.'
     */
    niceWork: T;

    /**
     * @default 'View current page without your changes.'
     */
    viewCurrentPage: T;

    /**
     * @default 'View Page'
     */
    viewPage: T;

    visibility: {
      /**
       * @default 'Hidden'
       */
      hidden: T;

      /**
       * @default 'Password Protected'
       */
      protected: T;

      /**
       * @default 'Public'
       */
      visible: T;
    };

    /**
     * @default 'An error occurred while updating your {{resource}} Visibility. Please try again.'
     */
    changeVisibilityFailed: T;

    /**
     * @default 'Your {{resource}} is now {{visibility}}.'
     */
    changeVisibilitySuccessful: T;

    /**
     * @default 'An error occurred while updating your {{resource}} Password. Please try again.'
     */
    changePasswordFailed: T;

    /**
     * @default 'Your Event Password has been updated.'
     */
    changePasswordSuccessful: T;

    /**
     * @default 'An error occurred while updating your page. Please try again.'
     */
    updatePageError: T;
  };
  panel: {
    /**
     * @default 'Page Photo'
     */
    pagePhoto: T;

    /**
     * @default 'Settings'
     */
    settings: T;

    /**
     * @default 'Add Label'
     */
    addLabel: T;

    /**
     * @default 'View Page'
     */
    viewPage: T;

    /**
     * @default 'Edit Page'
     */
    editPage: T;

    /**
     * @default 'Add Content'
     */
    addContent: T;

    /**
     * @default 'Skip'
     */
    skip: T;
  };
  customPage: {
    /**
     * @default 'An error occurred while creating page container. Please try again.'
     */
    createPageContainerError: T;

    /**
     * @default 'An error occurred while updating page container. Please try again.'
     */
    updatePageContainerError: T;

    /**
     * @default 'An error occurred while creating travelMap component. Please try again.'
     */
    travelMapComponentError: T;

    /**
     * @default 'An error occurred while updating your {{resource}} Visibility. Please try again.'
     */
    changeVisibilityFailed: T;

    /**
     * @default 'Your {{resource}} is now {{visibility}}.'
     */
    changeVisibilitySuccessful: T;

    /**
     * @default 'An error occurred while updating your {{resource}} Password. Please try again.'
     */
    changePasswordFailed: T;

    /**
     * @default 'Your Event Password has been updated.'
     */
    changePasswordSuccessful: T;

    /**
     * @default 'An error occurred while updating hotel order. Please try again.'
     */
    changeBlockOrderFailed: T;

    /**
     * @default 'Your Hotel order has been updated.'
     */
    changeBlockOrderSuccess: T;

    /**
     * @default 'An error occurred while updating component order. Please try again.'
     */
    changeComponentOrderFailed: T;

    /**
     * @default 'Your component order has been updated.'
     */
    changeComponentOrderSuccess: T;

    /**
     * @default 'Save'
     */
    save: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    emptyState: {
      /**
       * @default 'Dive in and start creating.'
       */
      title: T;

      /**
       * @default 'Add your first content block to start crafting your perfect page.'
       */
      content: T;
    };
    addNewPage: {
      /**
       * @default 'Add New Page'
       */
      title: T;

      /**
       * @default 'Name'
       */
      name: T;

      /**
       * @default 'A Memorable Name'
       */
      placeholder: T;

      /**
       * @default 'Create Page'
       */
      createPage: T;

      /**
       * @default 'Cancel'
       */
      cancel: T;

      /**
       * @default 'An error occurred while creating page. Please try again.'
       */
      addPageError: T;

      /**
       * @default 'Page path unavailable for the entered title. Please choose a different one.'
       */
      pagePathNotAvailable: T;
    };
    deletePage: {
      /**
       * @default 'Are you sure you want to delete this custom page?'
       */
      title: T;

      /**
       * @default 'The contents of this page will be lost. This action can’t be undone.'
       */
      subTitle: T;

      /**
       * @default 'Delete Custom Page'
       */
      deleteCustomPageCta: T;

      /**
       * @default 'Cancel'
       */
      cancelCta: T;

      /**
       * @default 'Success! Page have been deleted.'
       */
      deletePageSuccess: T;

      /**
       * @default 'An error occurred while deleting your page. Please try again.'
       */
      deletePageError: T;
    };
    addCustomComponent: {
      /**
       * @default 'Start Creating'
       */
      title: T;

      /**
       * @default 'Add a content block and continue crafting your page.'
       */
      subTitle: T;

      /**
       * @default 'Add your first content block to craft your perfect page.'
       */
      subTitleFirst: T;

      supportedCustomComponents: {
        text: {
          /**
           * @default 'Text'
           */
          title: T;

          /**
           * @default 'Tell your guests what to expect here—make it fun, exciting, and uniquely you.'
           */
          subTitle: T;
        };
        photo: {
          /**
           * @default 'Photo'
           */
          title: T;
        };
        travel: {
          /**
           * @default 'Travel'
           */
          title: T;
        };
        video: {
          /**
           * @default 'Video'
           */
          title: T;
        };

        /**
         * @default 'Accommodations'
         */
        accommodations: T;
      };
    };
    richText: {
      form: {
        /**
         * @default 'Please enter a text.'
         */
        textRequiredError: T;

        /**
         * @default 'Share a great story with your guests'
         */
        placeholder: T;

        /**
         * @default 'Save'
         */
        saveCta: T;

        /**
         * @default 'Cancel'
         */
        cancelCta: T;

        /**
         * @default 'Success! Text have been saved.'
         */
        saveComponentSuccess: T;

        /**
         * @default 'An error occurred while saving your text. Please try again.'
         */
        saveComponentError: T;

        /**
         * @default 'You have reached the block limit. To add more blocks please create a new page or replace an existing block.'
         */
        saveComponentLimitError: T;

        /**
         * @default 'Delete Text Block'
         */
        deleteCta: T;

        /**
         * @default 'Are you sure you want to delete this text block?'
         */
        deleteTitle: T;

        /**
         * @default 'The contents of this section will be lost. This action can’t be undone.'
         */
        deleteSubtitle: T;

        /**
         * @default 'Success! Text have been deleted.'
         */
        deleteComponentSuccess: T;

        /**
         * @default 'An error occurred while deleting your text. Please try again.'
         */
        deleteComponentError: T;
      };
    };
    photo: {
      /**
       * @default 'Save'
       */
      saveCta: T;

      /**
       * @default 'Cancel'
       */
      cancelCta: T;

      /**
       * @default 'Add Your Photo'
       */
      addTitle: T;

      /**
       * @default 'We support jpeg and PNG images.'
       */
      addSubtitle: T;

      /**
       * @default 'Upload Photo'
       */
      addCta: T;

      /**
       * @default 'Replace Photo'
       */
      replaceCta: T;

      /**
       * @default 'Delete Photo Block'
       */
      deleteCta: T;

      /**
       * @default 'Success! Photo have been saved.'
       */
      saveComponentSuccess: T;

      /**
       * @default 'An error occurred while saving your photo. Please try again.'
       */
      saveComponentError: T;

      /**
       * @default 'You have reached the block limit. To add more blocks please create a new page or replace an existing block.'
       */
      saveComponentLimitError: T;

      /**
       * @default 'Are you sure you want to delete this photo block?'
       */
      deleteTitle: T;

      /**
       * @default 'The contents of this section will be lost. This action can’t be undone.'
       */
      deleteSubtitle: T;

      /**
       * @default 'Success! Photo have been deleted.'
       */
      deleteComponentSuccess: T;

      /**
       * @default 'An error occurred while deleting your photo. Please try again.'
       */
      deleteComponentError: T;
    };
    video: {
      /**
       * @default 'Save'
       */
      saveCta: T;

      /**
       * @default 'Cancel'
       */
      cancelCta: T;

      /**
       * @default 'Video URL'
       */
      videoUrlLabel: T;

      /**
       * @default 'Video Caption'
       */
      captionLabel: T;

      /**
       * @default 'Delete Video Block'
       */
      deleteCta: T;

      /**
       * @default 'Success! Video have been saved.'
       */
      saveComponentSuccess: T;

      /**
       * @default 'An error occurred while saving your video. Please try again.'
       */
      saveComponentError: T;

      /**
       * @default 'You have reached the block limit. To add more blocks please create a new page or replace an existing block.'
       */
      saveComponentLimitError: T;

      /**
       * @default 'Are you sure you want to delete this video block?'
       */
      deleteTitle: T;

      /**
       * @default 'The contents of this section will be lost. This action can’t be undone.'
       */
      deleteSubtitle: T;

      /**
       * @default 'Success! Video have been deleted.'
       */
      deleteComponentSuccess: T;

      /**
       * @default 'An error occurred while deleting your video. Please try again.'
       */
      deleteComponentError: T;

      /**
       * @default 'Please enter a valid video URL.'
       */
      formInvalidError: T;

      helpDialog: {
        /**
         * @default 'Adding a Video'
         */
        title: T;

        /**
         * @default 'Add a video to your website using a share link (URL) from Youtube or Vimeo. For the best quality, use Vimeo.'
         */
        description: T;

        /**
         * @default 'Got It'
         */
        cta: T;
      };
    };
    itemTypes: {
      travelMapVenue: {
        title: {
          /**
           * @default 'Add Venue'
           */
          add: T;

          /**
           * @default 'Venue'
           */
          edit: T;

          /**
           * @default 'Delete Venue'
           */
          deleteCta: T;
        };

        /**
         * @default 'Your event location.'
         */
        subTitle: T;
      };
      travelMapHotels: {
        title: {
          /**
           * @default 'Add Hotel Room Block'
           */
          add: T;

          /**
           * @default 'Hotel Room Block'
           */
          edit: T;

          /**
           * @default 'Delete Hotel Room Block'
           */
          deleteCta: T;

          /**
           * @default 'Cancel Room Block'
           */
          cancelCta: T;
        };

        /**
         * @default 'Your reserved room block.'
         */
        subTitle: T;
      };
      travelMapAccommodationsCustom: {
        title: {
          /**
           * @default 'Add Custom Property'
           */
          add: T;

          /**
           * @default 'Custom Property'
           */
          edit: T;

          /**
           * @default 'Delete Custom Property'
           */
          deleteCta: T;
        };
        list: {
          /**
           * @default 'Custom Property'
           */
          title: T;

          /**
           * @default 'Existing Room Blocks, homes, and other unique stays.'
           */
          subtitle: T;
        };
        listV2: {
          /**
           * @default 'Other'
           */
          title: T;

          /**
           * @default 'Add custom link. From a rental home to a campsite to an existing room block.'
           */
          subtitle: T;
        };

        /**
         * @default 'Share an existing room block, homes or other unique stays.'
         */
        subTitle: T;
      };
      travelMapAccommodationsGetRoomBlock: {
        listV2: {
          /**
           * @default 'Get Hotel Room Blocks'
           */
          title: T;

          /**
           * @default 'Work with your Joy concierge to get the best room block rates for you and your guests.'
           */
          subtitle: T;
        };
      };
      travelMapAccommodationsHotel: {
        title: {
          /**
           * @default 'Add Hotel'
           */
          add: T;

          /**
           * @default 'Hotel'
           */
          edit: T;

          /**
           * @default 'Delete Hotel'
           */
          deleteCta: T;
        };
        list: {
          /**
           * @default 'Hotel'
           */
          title: T;

          /**
           * @default 'Share a hotel with your guests.'
           */
          subtitle: T;
        };
        listV2: {
          /**
           * @default 'Add Hotel'
           */
          title: T;

          /**
           * @default 'Add hotels to your page. Need more options? Explore nearby hotels with top discounts.'
           */
          subtitle: T;
        };

        /**
         * @default 'Share a hotel with your guests.'
         */
        subTitle: T;
      };
      travelMapAccommodationsRoomblock: {
        title: {
          /**
           * @default 'Add Hotel Room Block'
           */
          add: T;

          /**
           * @default 'Hotel Room Block'
           */
          edit: T;

          /**
           * @default 'Delete Hotel Room Block'
           */
          deleteCta: T;

          /**
           * @default 'Cancel Room Block'
           */
          cancelCta: T;
        };
        list: {
          /**
           * @default 'Hotel Room Block'
           */
          title: T;

          /**
           * @default 'Reserve a block of rooms for your guests.'
           */
          subtitle: T;
        };
        listV2: {
          /**
           * @default 'Add Hotel Room Blocks'
           */
          title: T;

          /**
           * @default 'Add a Joy room block to the page.'
           */
          subtitle: T;
        };

        /**
         * @default 'Your reserved room block.'
         */
        subTitle: T;
      };
    };
    travelMap: {
      venue: {
        /**
         * @default 'Your Venue'
         */
        title: T;

        /**
         * @default 'Add Venue'
         */
        addVenue: T;

        addEditForm: {
          /**
           * @default 'Success! Venue details have been saved.'
           */
          submitSuccess: T;

          /**
           * @default 'An error occurred while saving your venue. Please try again.'
           */
          submitError: T;

          /**
           * @default 'An error occurred while deleting your venue. Please try again.'
           */
          deleteError: T;

          form: {
            /**
             * @default 'Venue'
             */
            venue: T;

            /**
             * @default 'Latitude'
             */
            latitude: T;

            /**
             * @default 'Longitude'
             */
            longitude: T;

            /**
             * @default '-34.5628781'
             */
            latitudePlaceholder: T;

            /**
             * @default '-58.4600246'
             */
            longitudePlaceholder: T;

            /**
             * @default 'Please enter a venue.'
             */
            venueError: T;

            /**
             * @default 'Please enter a valid latitude.'
             */
            latitudeError: T;

            /**
             * @default 'Please enter a valid longitude.'
             */
            longitudeError: T;

            /**
             * @default 'Please enter a valid latitude.'
             */
            latitudeTypeError: T;

            /**
             * @default 'Please enter a valid longitude.'
             */
            longitudeTypeError: T;
          };
        };
        eventVenueDialog: {
          createVenue: {
            /**
             * @default 'Add your venue'
             */
            title: T;

            /**
             * @default 'Seamlessly auto-populate venue details across all of Joy and get personalized guest travel itineraries near your event.'
             */
            message: T;

            /**
             * @default 'Save Venue'
             */
            cta: T;

            /**
             * @default 'Event Venue'
             */
            searchLabel: T;

            /**
             * @default 'SmoggShoppe, Los Angeles'
             */
            searchPlaceholder: T;

            /**
             * @default 'Please enter event venue.'
             */
            saveVenueError: T;
          };
          updateVenue: {
            /**
             * @default 'Update your venue'
             */
            title: T;

            /**
             * @default 'Changing your venue location will update wherever it’s used. I.e. if you’ve already added hotels, distance and driving directions will be updated to this new location. '
             */
            message: T;

            /**
             * @default 'Update Venue'
             */
            cta: T;

            /**
             * @default 'Cancel'
             */
            cancel: T;

            /**
             * @default 'Event Venue'
             */
            searchLabel: T;

            /**
             * @default 'SmoggShoppe, Los Angeles'
             */
            searchPlaceholder: T;

            /**
             * @default 'Please enter event venue.'
             */
            saveVenueError: T;
          };
          confirmVenue: {
            /**
             * @default 'Confirm your venue'
             */
            title: T;

            /**
             * @default 'Let’s make sure your venue is up-to-date. Easily include venue details on your website. Get personalized guest travel itineraries near your event that you can customize.'
             */
            message: T;

            /**
             * @default 'Looks Good'
             */
            cta: T;

            /**
             * @default 'Event Venue'
             */
            searchLabel: T;

            /**
             * @default 'SmoggShoppe, Los Angeles'
             */
            searchPlaceholder: T;

            /**
             * @default 'Please enter event venue.'
             */
            saveVenueError: T;
          };

          creatingVenue: [
            {
              /**
               * @default 'Memory updated'
               */
              title: T;

              /**
               * @default 'We’ll remember this venue when you use Joy'
               */
              subTitle: T;
            },
            {
              /**
               * @default 'Searching the web'
               */
              title: T;

              /**
               * @default 'For availability, flexible options and great deals'
               */
              subTitle: T;
            },
            {
              /**
               * @default 'Curating recommendations'
               */
              title: T;

              /**
               * @default 'What other Joy couples are using near you'
               */
              subTitle: T;
            },
            {
              /**
               * @default 'Building your page'
               */
              title: T;

              /**
               * @default 'What other Joy couples are using near you'
               */
              subTitle: T;
            }
          ];
          createdVenue: {
            /**
             * @default 'Save your guests time and money'
             */
            title: T;

            /**
             * @default 'Find and share places to stay with the best rates only available to you and your guests, thanks to the millions of people using Joy.'
             */
            message: T;

            /**
             * @default 'View Your Page'
             */
            cta: T;
          };
          error: {
            /**
             * @default 'Oops! Something Went Wrong.'
             */
            title: T;

            /**
             * @default 'We’re unable to store event venue.'
             */
            message: T;

            /**
             * @default 'Please try again'
             */
            cta: T;
          };
        };
      };
      hotel: {
        /**
         * @default 'Hotels'
         */
        hotels: T;

        /**
         * @default 'Places to Stay'
         */
        placesToStay: T;

        /**
         * @default 'Accommodations'
         */
        accommodations: T;

        /**
         * @default 'Share the perfect stay with your guests.'
         */
        noHotelsAdded: T;

        /**
         * @default 'Deal ends:'
         */
        dealends: T;

        /**
         * @default 'Add Hotel'
         */
        addHotelCta: T;

        /**
         * @default 'Add Accommodations'
         */
        addAccommodationCta: T;

        /**
         * @default 'Add Stay'
         */
        addStayCta: T;

        /**
         * @default 'Add hotels, room blocks or custom links'
         */
        addStaySubtitle: T;

        /**
         * @default 'SUPER ADMIN ONLY'
         */
        superAdminOnly: T;

        /**
         * @default 'Room Block'
         */
        labelRoomblock: T;

        /**
         * @default 'Favorite'
         */
        labelFavorite: T;

        /**
         * @default 'Manage Your Hotels'
         */
        manageYourHotels: T;

        /**
     * @default 'You can’t drag an item 
 above your preferred hotel.'
*/
        changeOrderInfo: T;

        addEditForm: {
          /**
           * @default 'Name'
           */
          name: T;

          /**
           * @default 'Location'
           */
          location: T;

          /**
           * @default 'website.com'
           */
          linkFieldPlaceholder: T;

          /**
           * @default 'Use My Own Booking Link'
           */
          updateLink: T;

          /**
           * @default 'Link'
           */
          link: T;

          /**
           * @default 'ZentrumHub ID'
           */
          zhId: T;

          /**
           * @default 'ZentrumHub ID (Super Admin Only)'
           */
          zhIdLabel: T;

          /**
           * @default '+1-555-555-5555'
           */
          phonePlaceholder: T;

          /**
           * @default 'Phone'
           */
          phone: T;

          /**
           * @default 'Note for Guests'
           */
          noteForGuests: T;

          /**
           * @default 'Add Hotel'
           */
          addHotel: T;

          /**
           * @default 'Hotel Photo'
           */
          hotelPhoto: T;

          /**
           * @default 'Add Hotel Photo'
           */
          addHotelPhoto: T;

          /**
           * @default 'Add Photo'
           */
          addPhoto: T;

          /**
           * @default 'Edit Photo'
           */
          editPhoto: T;

          /**
           * @default 'Edit Hotel'
           */
          editHotel: T;

          /**
           * @default 'Delete Hotel'
           */
          deleteCta: T;

          /**
           * @default 'Hotel'
           */
          hotel: T;

          /**
           * @default 'Mark as favorite hotel'
           */
          markAsFavoriteHotel: T;

          /**
           * @default 'Latitude'
           */
          latitude: T;

          /**
           * @default 'Longitude'
           */
          longitude: T;

          /**
           * @default 'Booking Link'
           */
          bookingLink: T;

          /**
           * @default 'Star Rating'
           */
          starRating: T;

          /**
           * @default 'Check in'
           */
          checkIn: T;

          /**
           * @default 'Check out'
           */
          checkOut: T;

          /**
           * @default 'Cutoff Date'
           */
          cutoffDate: T;

          /**
           * @default 'Rooms Booked'
           */
          numberOfRooms: T;

          /**
           * @default 'Rooms Guest Booked'
           */
          numberOfRoomsGuestBooked: T;

          /**
           * @default 'Nights Booked'
           */
          nightsBooked: T;

          /**
           * @default 'Nights Guest Booked'
           */
          nightsGuestBooked: T;

          /**
           * @default 'Initial Price'
           */
          initialPrice: T;

          /**
           * @default 'Joy Price'
           */
          joyPrice: T;

          /**
           * @default 'Hotel Note'
           */
          hotelNote: T;

          /**
           * @default 'Save'
           */
          saveCta: T;

          /**
           * @default 'Cancel'
           */
          cancelCta: T;

          /**
           * @default 'Search for hotels'
           */
          namePlaceholder: T;

          /**
           * @default '-34.5628781'
           */
          latitudePlaceholder: T;

          /**
           * @default '-58.4600246'
           */
          longitudePlaceholder: T;

          /**
           * @default 'www.hotel.com/mywedding'
           */
          bookingLinkPlaceholder: T;

          /**
           * @default '4.5'
           */
          starRatingPlaceholder: T;

          /**
           * @default '20'
           */
          numberOfRoomsPlaceholder: T;

          /**
           * @default '15'
           */
          numberOfRoomsGuestBookedPlaceholder: T;

          /**
           * @default '45'
           */
          numberOfNightsBookedPlaceholder: T;

          /**
           * @default '30'
           */
          numberOfNightsGuestBookedPlaceholder: T;

          /**
           * @default 'Add a date'
           */
          addADatePlaceholder: T;

          /**
           * @default 'When does this deal expire?'
           */
          cutoffDatePlaceholder: T;

          /**
           * @default '400'
           */
          initialPricePlaceholder: T;

          /**
           * @default '200'
           */
          joyPricePlaceholder: T;

          /**
           * @default 'A note to share with your guests about this hotel.'
           */
          notePlaceholder: T;

          /**
           * @default 'Please search for a hotel'
           */
          nameError: T;

          /**
           * @default 'Please add a hotel photo.'
           */
          photoError: T;

          /**
           * @default 'Please add a hotel booking link.'
           */
          bookingLinkError: T;

          /**
           * @default 'Please add a check in date.'
           */
          checkInDateError: T;

          /**
           * @default 'Please add a check out date.'
           */
          checkOutDateError: T;

          /**
           * @default 'Check in date must be before check out date'
           */
          checkInDateRangeError: T;

          /**
           * @default 'Check out date must be after check in date'
           */
          checkOutDateRangeError: T;

          /**
           * @default 'Please add cutoff date.'
           */
          cutoffDateError: T;

          /**
           * @default 'Please add the Initial price for the hotel.'
           */
          initialPriceError: T;

          /**
           * @default 'Please enter the valid Initial price for the hotel.'
           */
          initialPriceTypeError: T;

          /**
           * @default 'Please add the Joy price for the hotel.'
           */
          joyPriceError: T;

          /**
           * @default 'Please enter the valid Joy price for the hotel.'
           */
          joyPriceTypeError: T;

          /**
           * @default 'Please enter a valid latitude.'
           */
          latitudeTypeError: T;

          /**
           * @default 'Please enter a valid longitude.'
           */
          longitudeTypeError: T;

          /**
           * @default 'Please enter a valid star rating.'
           */
          starRatingTypeError: T;

          /**
           * @default 'An error occurred while saving your hotel. Please try again.'
           */
          submitError: T;

          /**
           * @default 'An error occurred while deleting your hotel. Please try again.'
           */
          deleteError: T;

          /**
           * @default 'User not submitted hotel room blocks request.'
           */
          hotelRoomBlocksError: T;

          /**
           * @default 'Success! Hotel details have been saved.'
           */
          savedHotelSuccess: T;

          /**
           * @default 'Please enter the number of rooms.'
           */
          numberOfRoomsError: T;

          /**
           * @default 'Please enter a valid number of rooms.'
           */
          numberOfRoomsTypeError: T;

          /**
           * @default 'Please add an address'
           */
          addressError: T;

          /**
           * @default 'An error occurred while saving your photo. Please try again.'
           */
          photoSaveError: T;

          /**
           * @default 'An error occurred while selecting the hotel. Please try again.'
           */
          selectHotelError: T;
        };
      };
      leadState: {
        comingSoon: {
          /**
           * @default 'Coming Soon'
           */
          eyebrowTitle: T;

          /**
           * @default 'Joy's Free Hotel Concierge Service'
           */
          title: T;

          /**
           * @default 'Add courtesy blocks at discounted rates and zero deposit requirements with our free hotel concierge service.'
           */
          message: T;

          /**
           * @default 'I'm Interested'
           */
          cta: T;

          /**
           * @default 'Interest Confirmed!'
           */
          toastMessage: T;
        };
        pitch: {
          /**
           * @default 'Introducing Joy Room Blocks'
           */
          eyebrowTitle: T;

          /**
           * @default 'Get discounted rates for you and your guests.'
           */
          title: T;

          /**
           * @default 'Let our concierge team call hotels on your behalf and unlock all the benefits below so that you can focus on what matters most.'
           */
          message: T;

          /**
           * @default 'Get Started'
           */
          cta: T;

          /**
           * @default 'Save up to 30% off room rates'
           */
          valuePropOneTitle: T;

          /**
           * @default 'Enjoy exclusive group rate discounts with your favorite hotels and resorts.'
           */
          valuePropOneDescription: T;

          /**
           * @default 'No Financial Commitments'
           */
          valuePropTwoTitle: T;

          /**
           * @default 'We’ll take on the risk—that means no cancelation fees if your hotel room blocks aren’t fully booked.'
           */
          valuePropTwoDescription: T;

          /**
           * @default 'Perks'
           */
          valuePropThreeTitle: T;

          /**
           * @default 'From bridal suite upgrades and spa experiences, to in‑room amenities for you and your guests.'
           */
          valuePropThreeDescription: T;

          /**
           * @default 'Dedicated Concierge Team'
           */
          valuePropFourTitle: T;

          /**
           * @default 'Your personal hotel consultant can help  book rooms, and answer all your questions before your event.'
           */
          valuePropFourDescription: T;
        };
        inProgress: {
          /**
           * @default 'Your room blocks request is being processed.'
           */
          title: T;

          /**
           * @default 'The typical request takes 6 days. We'll send you an email when your rates are ready.'
           */
          text: T;

          /**
           * @default 'Once you receive your booking link, we'll populate this page with your hotels and help you share your room block with guests.'
           */
          subText: T;

          /**
           * @default 'View Hotel Request'
           */
          cta: T;
        };
        getAccommodations: {
          /**
           * @default 'Your hotels will soon be visible in your accommodations page.'
           */
          title: T;

          /**
           * @default 'If you have questions please contact our concierge team.'
           */
          subtext: T;

          /**
           * @default 'Manage Hotel Request'
           */
          cta: T;
        };
        adminAccommodations: {
          /**
           * @default 'To update your hotels and venue content, please contact our concierge team.'
           */
          message: T;

          /**
           * @default 'Contact Concierge'
           */
          contactCta: T;

          /**
           * @default 'Manage Room Blocks'
           */
          manageCta: T;
        };
      };
      leadStateV2: {
        getAccommodations: {
          /**
           * @default 'Unlock exclusive group rates with our free hotel concierge service.'
           */
          title: T;

          /**
           * @default 'We’ll show you which hotels couples and guests consistently choose and negotiate exclusive rates on your behalf.'
           */
          subText: T;

          /**
           * @default 'Get Started'
           */
          cta: T;
        };
        inProgress: {
          /**
           * @default 'Your room blocks request is being processed.'
           */
          title: T;

          /**
           * @default 'The typical request takes 6 days. We'll send you an email when your rates are ready.'
           */
          subText: T;

          /**
           * @default 'View Hotel Request'
           */
          cta: T;
        };
      };
      updateRoomBlockMessage: {
        /**
         * @default 'To update your hotels and venue content, please contact our concierge team.'
         */
        message: T;

        /**
         * @default 'Contact Concierge'
         */
        contactCta: T;

        /**
         * @default 'Manage Room Blocks'
         */
        manageCta: T;
      };
      updateExploreCTA: {
        /**
         * @default 'Explore Other Options'
         */
        label: T;

        /**
         * @default 'Success! 'Explore Other Options' has been updated.'
         */
        successMessage: T;

        /**
         * @default 'An error occurred while updating. Please try again.'
         */
        failedMessage: T;
      };
      ourPicks: {
        /**
         * @default 'We’ve added some awesome hotels to get you started. ✨'
         */
        title: T;

        picks: [
          {
            /**
             * @default 'Save Guests Money'
             */
            title: T;

            /**
             * @default 'Because we help millions of guests, we are able to get cheaper group rates than Expedia—up to 40% off nightly rates!'
             */
            subTitle: T;
          },
          {
            /**
             * @default 'Something for Everyone'
             */
            title: T;

            /**
             * @default 'A range of price points that include hotels with the best event friendly amenities.'
             */
            subTitle: T;
          },
          {
            /**
             * @default 'Stay Close to the Venue'
             */
            title: T;

            /**
             * @default 'Balancing travel time and distance, we’ve picked the most popular and conveniently located hotels near your event.'
             */
            subTitle: T;
          }
        ];
      };
    };
    delete: {
      /**
       * @default 'Are you sure you want to delete this item?'
       */
      title: T;

      /**
       * @default 'This cannot be undone.'
       */
      message: T;

      /**
       * @default 'Cancel'
       */
      cancelCta: T;

      travelMapVenue: {
        /**
         * @default 'Delete Venue'
         */
        deleteCta: T;
      };
      travelMapHotels: {
        /**
         * @default 'Delete Hotel'
         */
        deleteCta: T;
      };
      travelMapAccommodationsHotel: {
        /**
         * @default 'Delete Accommodation'
         */
        deleteCta: T;
      };
      travelMapAccommodationsCustom: {
        /**
         * @default 'Delete Custom Property'
         */
        deleteCta: T;
      };
      travelMapAccommodationsRoomblock: {
        /**
         * @default 'Delete Room Block'
         */
        deleteCta: T;
      };
    };
    cancelRoomBlock: {
      /**
       * @default 'Request to Cancel Your Room Block'
       */
      title: T;

      /**
       * @default 'Let us know if you need to cancel your room block, and we’ll follow up via email within 3 business days.'
       */
      message: T;

      /**
       * @default 'Reason for cancelling'
       */
      label: T;

      /**
       * @default 'Please enter a reason for canceling the room block.'
       */
      inputError: T;

      /**
       * @default 'An error occurred while canceling your room block. Please try again.'
       */
      submitError: T;

      /**
       * @default 'Your room block cancellation request has been sent.'
       */
      successMessage: T;

      /**
       * @default 'Request Cancellation'
       */
      cancelRoomBlockCta: T;

      /**
       * @default 'Cancel'
       */
      cancelCta: T;
    };
    registryLinks: {
      /**
       * @default 'Welcome Note'
       */
      welcomeNoteLabel: T;

      /**
       * @default 'Manage Registry'
       */
      manageRegistryCta: T;

      welcomeWidget: {
        /**
         * @default 'Welcome to your registry.'
         */
        title: T;

        /**
         * @default 'With Joy, your website IS your registry, so you’ll never share an awkward link or worry about people finding it. Guests can access your gifts, cash funds, and more all in one place.'
         */
        description: T;
      };
      registryActions: {
        /**
         * @default 'Guests will give you gifts no matter what—why not pick things you’ll actually love?'
         */
        title: T;
      };

      /**
       * @default 'Build Your Registry'
       */
      buildRegistry: T;

      /**
       * @default 'The registry trusted by millions.'
       */
      heroTitle: T;

      /**
       * @default 'Add Gifts'
       */
      addGifts: T;

      /**
       * @default 'Track Gifts'
       */
      trackGifts: T;
    };
  };
};

export type WebsiteOverviewTranslations<T extends LeafNodeValue = TranslateFunction> = {
  header: {
    /**
     * @default 'Website'
     */
    website: T;

    /**
     * @default 'Share'
     */
    share: T;

    /**
     * @default 'Preview'
     */
    preview: T;

    /**
     * @default 'Support'
     */
    support: T;

    /**
     * @default 'Settings'
     */
    settings: T;
  };
  tabs: {
    /**
     * @default 'Overview'
     */
    overview: T;

    /**
     * @default 'Design'
     */
    design: T;

    /**
     * @default 'Edit Pages'
     */
    editPages: T;

    /**
     * @default 'New'
     */
    newBadge: T;

    /**
     * @default 'Photos'
     */
    photos: T;
  };
  inviteAdminDialog: {
    /**
     * @default 'Event Admins'
     */
    title: T;
  };
  overview: {
    /**
     * @default 'Get Started'
     */
    getStarted: T;

    /**
     * @default '{{completedCount}} / {{totalCount}} Done'
     */
    completedCount: T;

    /**
     * @default 'Edit Design'
     */
    editDesign: T;

    /**
     * @default 'Share Website'
     */
    shareWebsite: T;

    /**
     * @default 'Settings'
     */
    settings: T;

    /**
     * @default 'Event URL copied.'
     */
    eventUrlCopiedToast: T;

    /**
     * @default 'RSVP is On'
     */
    rsvpOnText: T;

    /**
     * @default 'RSVP is Off'
     */
    rsvpOffText: T;

    /**
     * @default 'Event URL updated'
     */
    eventUrlUpdateSuccess: T;

    /**
     * @default 'Error updating event URL. Please try again later.'
     */
    eventUrlUpdateError: T;
  };
  photos: {
    /**
     * @default 'Albums'
     */
    albums: T;

    /**
     * @default 'New Album'
     */
    newAlbum: T;

    /**
     * @default 'Public'
     */
    public: T;

    /**
     * @default 'Hidden'
     */
    hidden: T;

    /**
     * @default 'View'
     */
    view: T;

    /**
     * @default 'Manage Album'
     */
    manageAlbum: T;

    /**
     * @default 'Edit'
     */
    edit: T;

    /**
     * @default 'Delete'
     */
    Delete: T;

    /**
     * @default 'Item'
     */
    item: T;

    /**
     * @default 'Items'
     */
    items: T;

    /**
     * @default 'Photos'
     */
    photos: T;

    /**
     * @default 'We support JPEG and PNG images.'
     */
    photoLabel: T;

    /**
     * @default 'Upload Photo'
     */
    uploadPhoto: T;

    /**
     * @default 'Add Photos'
     */
    addPhotos: T;

    /**
     * @default 'Manage Photos'
     */
    managePhotos: T;

    /**
     * @default 'Add Album'
     */
    addAlbum: T;

    /**
     * @default 'Edit Album'
     */
    editAlbum: T;

    /**
     * @default 'Name'
     */
    name: T;

    /**
     * @default 'Save'
     */
    save: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Allow Uploads'
     */
    allowUploads: T;

    /**
     * @default 'Link'
     */
    link: T;

    /**
     * @default 'Magic Link'
     */
    magicLink: T;

    /**
     * @default 'Generating...'
     */
    magicLinkPlaceholder: T;

    /**
     * @default 'Share Link'
     */
    shareLink: T;

    /**
     * @default 'Share'
     */
    share: T;

    /**
     * @default 'Copy Upload Link'
     */
    copyUploadLink: T;

    /**
     * @default 'Share Link copied.'
     */
    shareLinkCopied: T;

    /**
     * @default 'Send this link to your guests. They'll be able to upload all their photos straight into your collection. Please note this link will also allow anyone to see photos uploaded to this album.'
     */
    uploadLinkInfo: T;

    noCollections: {
      /**
       * @default 'Introducing Shared Photo Albums'
       */
      eyebrow: T;

      /**
       * @default 'Collect and share memories from friends and family before and after the big day.'
       */
      title: T;

      /**
       * @default 'From your childhood photos and early days together to the ceremony and after party, friends and family can now upload photos and notes before and after your big day.'
       */
      description: T;
    };

    /**
     * @default 'Create Album'
     */
    createAlbum: T;

    /**
     * @default 'An error occurred while saving your photo. Please try again.'
     */
    savePhotoError: T;

    /**
     * @default 'Upload'
     */
    upload: T;

    /**
     * @default 'Looking for Moments?'
     */
    lookingForMoments: T;

    /**
     * @default ' View your Moments'
     */
    viewYourMoments: T;

    /**
     * @default 'Looking to set your website page photos?'
     */
    lookingForPagePhotos: T;

    /**
     * @default 'Set page photos'
     */
    setPagePhotos: T;

    faqs: {
      questions: [
        {
          /**
           * @default 'What is Joy Collections?'
           */
          Q: T;

          /**
           * @default 'Joy Collections is your dedicated service for organizing, collecting, and sharing all your wedding photos and videos in one beautifully designed platform. Guests can easily upload their moments, and you can relive every magical memory with ease.'
           */
          A: T;
        },
        {
          /**
           * @default 'How do I invite guests to share their photos?'
           */
          Q: T;

          /**
           * @default 'You can invite guests to upload their photos through your wedding website on Joy. Simply share your unique link via email, text, or on your event website. Guests can upload directly without needing to create an account.'
           */
          A: T;
        },
        {
          /**
           * @default 'Is there a limit to the number of photos or videos we can collect?'
           */
          Q: T;

          /**
           * @default 'Nope! Joy Collections offers unlimited uploads, so you won’t miss a single shot of your big day.'
           */
          A: T;
        },
        {
          /**
           * @default 'Can we organize the photos into albums?'
           */
          Q: T;

          /**
           * @default 'Yes! You can create albums for different events (e.g., rehearsal dinner, ceremony, reception) or themes (e.g., candid moments, dance floor fun).'
           */
          A: T;
        }
      ];
    };

    /**
     * @default 'Album Options'
     */
    albumOptions: T;

    /**
     * @default 'Options'
     */
    options: T;

    /**
     * @default 'Album name is required'
     */
    albumNameRequired: T;

    /**
     * @default 'Name should be 30 characters or less.'
     */
    maxCharacters: T;

    /**
     * @default 'This album has no images.'
     */
    noImages: T;

    /**
     * @default 'An error occurred while updating your album settings. Please try again.'
     */
    mediaSettingsError: T;

    /**
     * @default 'All Photos'
     */
    allPhotos: T;

    photoPickerDialog: {
      /**
       * @default 'How would you like to add photos to this album?'
       */
      title: T;

      /**
       * @default 'Select from Library'
       */
      selectFromLibrary: T;

      /**
       * @default 'Upload New Photos'
       */
      uploadNewPhotos: T;

      /**
       * @default 'Add from Library'
       */
      addFromLibrary: T;

      /**
       * @default 'Select All'
       */
      selectAll: T;

      /**
       * @default 'Deselect All'
       */
      deselectAll: T;

      /**
       * @default 'Add to Album'
       */
      addToAlbum: T;

      /**
       * @default 'Add'
       */
      add: T;

      /**
       * @default 'Cancel'
       */
      cancel: T;
    };

    /**
     * @default 'Photos added to album.'
     */
    addMediaToCollectionSuccess: T;

    /**
     * @default 'Some of the items could not be added. Please check the album and try again.'
     */
    addMediaToCollectionPartialSuccess: T;

    /**
     * @default 'An error occurred while adding photos to your album. Please try again.'
     */
    addMediaToCollectionError: T;

    /**
     * @default 'This album is hidden. Make it public to enable guest uploads.'
     */
    toggleTooltipText: T;

    /**
     * @default 'Select Photos…'
     */
    selectPhotosCTALabel: T;

    /**
     * @default 'Select…'
     */
    selectCTALabel: T;

    /**
     * @default 'Select photos'
     */
    selectPhotos: T;

    /**
     * @default 'Add to Album…'
     */
    addToAlbumCTALabel: T;

    /**
     * @default '{{count}} Photos Selected'
     */
    selectedPhotosCount: T;

    /**
     * @default '{{count}} Photo Selected'
     */
    selectedPhotoCount: T;

    /**
     * @default 'Add to Album'
     */
    addToAlbum: T;

    /**
     * @default 'Remove from album'
     */
    removeFromAlbum: T;
  };
};

export type WebsitePagesTranslations<T extends LeafNodeValue = TranslateFunction> = {
  general: {
    /**
     * @default 'New'
     */
    new: T;

    /**
     * @default 'Pages'
     */
    pages: T;
  };
  pages: {
    websiteOverview: {
      /**
       * @default 'Overview'
       */
      title: T;
    };
    design: {
      /**
       * @default 'Design'
       */
      title: T;
    };
    settings: {
      /**
       * @default 'Event Settings'
       */
      title: T;
    };
    welcome: {
      /**
       * @default 'Home'
       */
      title: T;
    };
    video: {
      /**
       * @default 'Video'
       */
      title: T;
    };
    story: {
      /**
       * @default 'Story & Tidbits'
       */
      title: T;
    };
    schedule: {
      /**
       * @default 'Schedule'
       */
      title: T;
    };
    travel: {
      /**
       * @default 'Travel'
       */
      title: T;
    };
    accommodations: {
      /**
       * @default 'Accommodations'
       */
      title: T;

      /**
       * @default 'Where to Stay'
       */
      titleV2: T;
    };
    guest: {
      /**
       * @default 'Guest Q & A'
       */
      title: T;
    };
    weddingParty: {
      /**
       * @default 'Wedding Party'
       */
      title: T;
    };
    registry: {
      /**
       * @default 'Registry'
       */
      title: T;
    };
    addNewPage: {
      /**
       * @default 'Add New Page'
       */
      title: T;
    };
    managePages: {
      /**
       * @default 'Manage Pages'
       */
      title: T;
    };
  };
};

export type WebsitePreviewTranslations<T extends LeafNodeValue = TranslateFunction> = {
  websitePreview: {
    /**
     * @default 'Preview'
     */
    preview: T;

    /**
     * @default 'Save and Open'
     */
    saveAndOpen: T;

    /**
     * @default 'Sorry but your theme is no longer available to preview.'
     */
    themeNotSupportedText: T;

    /**
     * @default 'Learn Why'
     */
    themeNotSupportedButton: T;

    titlePreview: {
      /**
       * @default 'Preview Website'
       */
      desktop: T;

      /**
       * @default 'Preview Mobile'
       */
      mobile: T;

      /**
       * @default 'Preview Stationery'
       */
      stationery: T;
    };
    stationery: {
      /**
       * @default 'Explore Matching Prints'
       */
      explorePrints: T;

      /**
       * @default 'Select unique print stationery from'
       */
      selectUnique: T;

      /**
       * @default 'Or pair your theme with free matching digital stationery'
       */
      digitalOption: T;

      /**
       * @default 'Pair your theme with free matching digital stationery'
       */
      digitalOptionPair: T;

      /**
       * @default 'Create Save the Date'
       */
      saveTheDate: T;

      /**
       * @default 'Create Invitation'
       */
      invitation: T;
    };
  };
};

export type WeddingPartyTranslations<T extends LeafNodeValue = TranslateFunction> = {
  titles: {
    /**
     * @default 'Bride'
     */
    Bride: T;

    /**
     * @default 'Groom'
     */
    Groom: T;

    /**
     * @default 'Bridesmaid'
     */
    Bridesmaid: T;

    /**
     * @default 'Groomsman'
     */
    Groomsman: T;

    /**
     * @default 'Mother In Law'
     */
    motherInLaw: T;

    /**
     * @default 'Matron of Honor'
     */
    'Matron of Honor': T;

    array: [
      {
        /**
         * @default 'Howdy!'
         */
        greeting: T;

        /**
         * @default '/groom.jpg'
         */
        _url: string;
      }
    ];
  };

  /**
   * @default 'Learn more about {{name}}'
   */
  learnAbout: T;
};

export type YourRegistryTranslations<T extends LeafNodeValue = TranslateFunction> = {
  registrySection: {
    /**
     * @default 'External Registries'
     */
    title: T;

    /**
     * @default 'For tracking gifts on your linked registries, visit those registries directly.'
     */
    subtitle: T;

    /**
     * @default 'Drag and drop to reorder your list.'
     */
    dndLabel: T;

    /**
     * @default 'Modify'
     */
    modify: T;

    /**
     * @default 'Reorder List'
     */
    reorder: T;

    /**
     * @default 'Finish'
     */
    finish: T;

    /**
     * @default 'Looks like you haven’t added any registries yet.'
     */
    emptyRegistries: T;

    /**
     * @default 'No worries. Go ahead and <CreateOneLink/>.'
     */
    goAheadAnd: T;

    /**
     * @default 'create one'
     */
    createOne: T;

    /**
     * @default 'Syncing'
     */
    registrySyncing: T;

    /**
     * @default 'Synced'
     */
    registrySynced: T;

    /**
     * @default 'Transferred'
     */
    registryTransferred: T;

    /**
     * @default 'Transferring'
     */
    registryTransferInProgress: T;

    /**
     * @default 'Linked'
     */
    registryLinked: T;

    /**
     * @default 'Update'
     */
    registryUpdate: T;

    /**
     * @default 'Edit'
     */
    actionEdit: T;

    /**
     * @default 'View'
     */
    actionView: T;

    /**
     * @default 'Waiting on updates?'
     */
    registryToolTipText: T;

    /**
     * @default 'Connected registries sync daily.'
     */
    syncDaily: T;

    /**
     * @default '{{count}} External Registries'
     */
    subTitleManageRegistry: T;

    /**
     * @default 'We will display any external registries that you add here on your Joy registry. We also support item syncing for retailers like Amazon and Target and we are continuously adding more stores.'
     */
    helpText: T;

    /**
     * @default 'link an existing registry'
     */
    linkExistingRegistry: T;
  };
  validation: {
    /**
     * @default 'Invalid URL'
     */
    invalidDomain: T;

    /**
     * @default 'Required'
     */
    required: T;
  };
  updateRegistryDialog: {
    /**
     * @default 'Update Registry'
     */
    title: T;

    /**
     * @default 'Update Registry'
     */
    actionText: T;

    /**
     * @default 'Cancel'
     */
    cancelActionText: T;

    /**
     * @default 'Registry Link'
     */
    urlInputLabel: T;

    /**
     * @default 'Registry Title'
     */
    nameInputLabel: T;

    /**
     * @default 'Link'
     */
    urlInputPlaceholder: T;

    /**
     * @default 'Title'
     */
    nameInputPlaceholder: T;

    /**
     * @default 'Registry Image'
     */
    registryImageLabel: T;

    /**
     * @default 'Delete Registry'
     */
    deleteActionText: T;
  };
  errorDialog: {
    /**
     * @default 'Connection trouble.'
     */
    titleText: T;

    /**
     * @default 'Apologies, we weren’t able to '
     */
    messageTextStart: T;

    /**
     * @default 'your changes. Please click 'Refresh' to reconnect.'
     */
    messageTextEnd: T;

    /**
     * @default 'Refresh'
     */
    actionText: T;
  };
  publishPage: {
    /**
     * @default 'Make Registry Visible'
     */
    newTitle: T;

    /**
     * @default 'Allow guests to see your registry'
     */
    info: T;

    /**
     * @default 'On'
     */
    labelOn: T;

    /**
     * @default 'Off'
     */
    labelOff: T;

    /**
     * @default 'Info'
     */
    allyLabel: T;

    /**
     * @default 'Manage Visibility and Sharing'
     */
    newDialogTitle: T;

    /**
     * @default 'Turning this on makes your registry available to gift givers.'
     */
    newToggleDescription: T;

    /**
     * @default 'Registry URL'
     */
    registryUrlLabel: T;

    /**
     * @default 'Add'
     */
    addLink: T;

    /**
     * @default 'Edit'
     */
    editLink: T;

    /**
     * @default 'Save'
     */
    actionButton: T;

    /**
     * @default 'Cancel'
     */
    secondaryButton: T;

    /**
     * @default 'Your registry is visible'
     */
    registryVisibleText: T;

    /**
     * @default 'Your registry is hidden'
     */
    registryHiddenText: T;

    /**
     * @default 'Modify registry visibility'
     */
    registryHoverText: T;

    /**
     * @default '✨ Link Copied ✨'
     */
    linkCopied: T;

    /**
     * @default 'Share it with gift givers so they can shop in one place!'
     */
    linkCopiedDescription: T;

    /**
     * @default 'Shipping Address'
     */
    shippingAddressLabel: T;

    /**
     * @default 'Your shipping address is missing. Let gift givers know where to send your gifts.'
     */
    shippingAddressWarning: T;

    /**
     * @default 'Let gift givers know where to send your gifts.'
     */
    emptyShippingAddressMsg: T;
  };
  registrySettingsDialog: {
    /**
     * @default 'Your Registry settings were updated successfully'
     */
    updateSuccessToast: T;

    /**
     * @default 'An error occurred when updating your settings. Please try again'
     */
    updateErrorToast: T;

    /**
     * @default 'Save'
     */
    saveButton: T;

    /**
     * @default 'Cancel'
     */
    cancelButton: T;

    /**
     * @default 'A first name is required'
     */
    firstNameRequired: T;

    /**
     * @default 'URL copied'
     */
    urlCopiedToast: T;

    /**
     * @default 'Joy URL'
     */
    url: T;

    /**
     * @default 'Edit'
     */
    edit: T;

    /**
     * @default 'Partner One'
     */
    partnerOne: T;

    /**
     * @default 'Partner Two'
     */
    partnerTwo: T;

    /**
     * @default 'Date'
     */
    date: T;

    dateInfo: {
      /**
       * @default 'Show Date in your Registry and App'
       */
      general: T;

      /**
       * @default 'Show Date in your Registry and App'
       */
      wedding: T;

      /**
       * @default 'Show Date in your Registry'
       */
      babyRegistry: T;
    };

    /**
     * @default 'Your date is currently hidden'
     */
    dateHidden: T;

    /**
     * @default 'Changes may take time to take effect.'
     */
    hideRegistryInfo: T;

    /**
     * @default 'Registry Settings'
     */
    registrySettingsDialogTitle: T;

    /**
     * @default 'Registry URL'
     */
    registrySettingsDialogTitleRegistryUrl: T;

    /**
     * @default 'Registry Display Name'
     */
    registrySettingsDialogDisplayNameLabel: T;

    /**
     * @default 'Prevent my registry from appearing in search results when guests search the Joy Website'
     */
    registrySettingsDialoghideRegistryLabel: T;

    /**
     * @default 'URL cannot be blank.'
     */
    urlRequired: T;

    /**
     * @default 'URL must be less than 60 characters long.'
     */
    urlLengthLimit: T;

    /**
     * @default 'Only letters, numbers and '-' are allowed in your URL.'
     */
    urlCharacterValidation: T;

    /**
     * @default 'URL is not available'
     */
    urlNotAvailable: T;

    /**
     * @default 'Your old Joy URL will no longer work. If you have a custom domain, you’ll need to update your forwarding URL with your domain provider.'
     */
    warningBoxText: T;

    /**
     * @default 'Please Note:'
     */
    warningBoxHeader: T;

    /**
     * @default 'This URL is available'
     */
    urlAvailable: T;

    /**
     * @default 'This URL is not available'
     */
    urlUnavailable: T;

    /**
     * @default '/60 Max Chars'
     */
    maxChars: T;

    /**
     * @default 'Enter an Epic Registry display name'
     */
    eventNamePlaceholder: T;

    /**
     * @default 'Your Registry name can't be left blank.'
     */
    eventNameRequired: T;

    /**
     * @default 'The Registry name should be 30 characters or less.'
     */
    eventNameLengthLimit: T;

    /**
     * @default 'Please re-enter the date in MM/DD/YYYY format'
     */
    invalidDate: T;
  };
  visibilityDialog: {
    /**
     * @default '{{resource}} Visibility'
     */
    title: T;

    /**
     * @default 'Hidden'
     */
    hidden: T;

    /**
     * @default 'Public'
     */
    public: T;

    /**
     * @default 'Password Protected'
     */
    passwordProtected: T;

    /**
     * @default 'Save'
     */
    saveButton: T;

    /**
     * @default 'Cancel'
     */
    cancelButton: T;

    /**
     * @default 'Password copied.'
     */
    passwordCopiedToast: T;

    /**
     * @default 'Event Password'
     */
    eventPassword: T;

    /**
     * @default 'Edit'
     */
    edit: T;

    /**
     * @default 'Please Note:'
     */
    passwordNoteTitle: T;

    /**
     * @default 'If you change your password, guests will no longer be able to use your current password to unlock your event.'
     */
    passwordNoteMessage: T;

    /**
     * @default 'An error occurred while updating your {{resource}} Visibility. Please try again.'
     */
    changeVisibilityFailed: T;

    /**
     * @default 'Your {{resource}} is now {{visibility}}.'
     */
    changeVisibilitySuccessful: T;

    /**
     * @default 'An error occurred while updating your {{resource}} Password. Please try again.'
     */
    changePasswordFailed: T;

    /**
     * @default 'Your Event Password has been updated.'
     */
    changePasswordSuccessful: T;

    /**
     * @default 'Your password can only contain letters and numbers.'
     */
    passwordError: T;

    /**
     * @default 'Registry'
     */
    registry: T;
  };
  inviteAdminDialog: {
    /**
     * @default 'Registry Admins'
     */
    registryInviteAdminDialogTitle: T;

    /**
     * @default 'Invite Collaborator'
     */
    addAdminTitle: T;

    /**
     * @default 'Revoke Collaborator Invitation'
     */
    revokeAdminInvitationTitle: T;

    /**
     * @default 'Are you sure you want to revoke <fullname/> admin privileges?'
     */
    revokeAdminMessage: T;

    /**
     * @default 'Transfer Ownership'
     */
    transferOwnershipTitle: T;

    /**
     * @default 'Are you sure you want to transfer the ownership of this Joy event to <fullname/>?'
     */
    transferOwnershipMessage: T;

    /**
     * @default '<fullname/> will become the primary account owner for this event and your current account will be downgraded to an admin account.'
     */
    transferOwnershipConfirmation: T;

    /**
     * @default 'Type “{{confirmationWord}}” in the input below to confirm this change'
     */
    transferOwnershipInputLabel: T;

    /**
     * @default 'Owner'
     */
    owner: T;

    /**
     * @default 'Collaborators'
     */
    collaborators: T;

    /**
     * @default 'Transfer'
     */
    transfer: T;

    /**
     * @default 'Revoke'
     */
    revoke: T;

    /**
     * @default 'Cancel'
     */
    cancel: T;

    /**
     * @default 'Done'
     */
    done: T;

    /**
     * @default 'Invite Collaborator'
     */
    inviteNewAdmin: T;

    /**
     * @default 'Add {{page}} Admin'
     */
    addAdmin: T;

    /**
     * @default 'Name'
     */
    name: T;

    /**
     * @default 'Email'
     */
    email: T;

    /**
     * @default 'An email invitation has been sent'
     */
    sentInvitation: T;

    /**
     * @default 'Resend'
     */
    resend: T;

    /**
     * @default 'Pending Invitations'
     */
    pendingInvitations: T;

    /**
     * @default 'Send Invitation'
     */
    sendInvitation: T;

    /**
     * @default 'Confirm Transfer'
     */
    confirmTransfer: T;

    /**
     * @default 'Verify your email address to invite collaborators'
     */
    verifyYourEmail: T;

    /**
     * @default 'First name is required'
     */
    firstNameRequired: T;

    /**
     * @default 'Last name is required'
     */
    lastNameRequired: T;

    /**
     * @default 'Email is required'
     */
    emailRequired: T;

    /**
     * @default 'Invalid email format'
     */
    invalidEmail: T;

    /**
     * @default 'First name'
     */
    firstNameAriaLabel: T;

    /**
     * @default 'First name'
     */
    firstNamePlaceholder: T;

    /**
     * @default 'Last name'
     */
    lastNameAriaLabel: T;

    /**
     * @default 'Last name'
     */
    lastNamePlaceholder: T;

    /**
     * @default 'Email'
     */
    emailAriaLabel: T;

    /**
     * @default 'email@example.com'
     */
    emailPlaceholder: T;

    /**
     * @default 'An email invitation has been sent'
     */
    invitationHasBeenSent: T;

    /**
     * @default 'An error occurred while send your invitation'
     */
    errorSendingInvitation: T;

    /**
     * @default 'We resent your invitation'
     */
    weResentYourInvitation: T;

    /**
     * @default 'Please wait a short time to resend this invitation'
     */
    errorResendingYourInvitation: T;

    /**
     * @default 'You have transferred ownership of this Joy event'
     */
    transferredOwnership: T;

    /**
     * @default 'Error transfering ownership'
     */
    errorTransferingOwnership: T;

    /**
     * @default 'This invitation has been cancelled'
     */
    invitationCancelled: T;

    /**
     * @default 'Error cancelling invitation'
     */
    errorCancellingInvitation: T;

    /**
     * @default 'Admin removed'
     */
    adminRemoved: T;

    /**
     * @default 'Error removing admin'
     */
    errorRemovingAdmin: T;
  };
  registryProductRow: {
    /**
     * @default 'Gift Purchased'
     */
    giftPurchased: T;

    /**
     * @default '{{amount}} Reserved'
     */
    giftsPurchased: T;

    /**
     * @default '{{amount}} Requested'
     */
    giftsRequested: T;

    /**
     * @default 'Favorite'
     */
    favorite: T;

    /**
     * @default '{{amount}} Still Needed'
     */
    giftStillNeeded: T;

    rewardPhrasesCompleted: [
      /**
       * @default 'Hooray! 🎉'
       */
      T,
      /**
       * @default 'A fabulous choice! 😍'
       */
      T,
      /**
       * @default 'Purchased with love! 💕'
       */
      T,
      /**
       * @default 'Someone got you this! 🎁'
       */
      T,
      /**
       * @default 'Gift purchased! 💃'
       */
      T,
      /**
       * @default 'Congratulations! 🎊'
       */
      T,
      /**
       * @default 'Oh, the generosity! 🫶'
       */
      T,
      /**
       * @default 'Consider it gifted! 🥳'
       */
      T
    ];

    /**
     * @default 'Item Still Needed'
     */
    itemStillNeeded: T;

    /**
     * @default 'Items Still Needed'
     */
    itemsStillNeeded: T;

    /**
     * @default 'Reorder Gifts'
     */
    reorderGifts: T;

    /**
     * @default 'Done'
     */
    doneReordering: T;

    /**
     * @default 'Reserved'
     */
    reserved: T;

    /**
     * @default 'Purchased'
     */
    purchased: T;

    /**
     * @default 'Reserved by You'
     */
    reservedByYou: T;

    /**
     * @default 'Purchased by You'
     */
    purchasedByYou: T;

    /**
     * @default 'Reserved by'
     */
    reservedByColumnHeader: T;

    /**
     * @default 'Purchased by'
     */
    purchasedByColumnHeader: T;

    /**
     * @default '{{initialEntry}} & {{remaining}} others...'
     */
    purchasedByOverflow: T;

    /**
     * @default 'Removing gift...'
     */
    removingGift: T;

    /**
     * @default 'Cash Fund'
     */
    categoryCashFundText: T;

    /**
     * @default 'Charity Fund'
     */
    categoryCharityFundText: T;

    /**
     * @default 'Drag to reorder items'
     */
    reorderMessage: T;

    /**
     * @default 'gifted so far'
     */
    giftedSoFarText: T;

    /**
     * @default 'Reorder'
     */
    reorder: T;

    /**
     * @default 'Gift Reserved'
     */
    giftReserved: T;

    /**
     * @default '{{action}} from'
     */
    storeAction: T;

    /**
     * @default '{{store}} Registry'
     */
    storeName: T;

    /**
     * @default 'Remove filters to reorder your items.'
     */
    disabledReorderText: T;

    /**
     * @default ' Hidden Gifts ({{count}}) '
     */
    hideItemText: T;

    /**
     * @default 'New'
     */
    newItem: T;

    /**
     * @default 'Funded'
     */
    funded: T;

    /**
     * @default 'Most Wanted'
     */
    mostWanted: T;

    /**
     * @default 'Marked as Most Wanted'
     */
    markedAsMostWanted: T;

    /**
     * @default 'Removed as Most Wanted'
     */
    removedAsMostWanted: T;
  };
  registryStats: {
    /**
     * @default 'Gifts Added'
     */
    giftsAdded: T;

    /**
     * @default 'Reserved'
     */
    reserved: T;

    /**
     * @default 'Still Needed'
     */
    stillNeeded: T;
  };
  amazonRegistry: {
    /**
     * @default 'Amazon Registry'
     */
    heading: T;

    /**
     * @default 'Last Synced'
     */
    lastSync: T;

    /**
     * @default 'Manage Registry'
     */
    manageReg: T;

    /**
     * @default 'We are grabbing your gifts right now. They’ll be ready to view soon.'
     */
    inProgressSync: T;

    /**
     * @default 'Syncing...'
     */
    syncing: T;
  };
  registry: {
    /**
     * @default ''s Registry'
     */
    heading: T;
  };
  emptyFilter: {
    /**
     * @default 'Cleaned out the aisles'
     */
    stillNeededTitle: T;

    /**
     * @default 'Looks like your guests purchased everything currently on your registry. If you find anything new that suits your fancy, add  them your registry so there are items for all your guests to buy.'
     */
    stillNeededSubtitle: T;

    /**
     * @default 'Nothing gifted yet'
     */
    purchasedTitle: T;

    /**
     * @default 'Once your guests purchase items on your registry,  you'll see the items here.'
     */
    purchasedSubtitle: T;

    /**
     * @default 'Looks like you don’t have any favorites yet'
     */
    favoriteTitle: T;

    /**
     * @default 'That’s totally cool, but if you did they’d show up here to help your guests purchase what you really want.'
     */
    favoriteSubtitle: T;

    /**
     * @default 'Looks like you don’t have any items on your registry'
     */
    defaultTitle: T;

    /**
     * @default 'Please add items to your registry'
     */
    defaultSubTitle: T;

    /**
     * @default 'No gifts added yet'
     */
    noSyncableRegistryTitle: T;

    /**
     * @default 'Once you add gifts or sync a registry, they will appear here.'
     */
    noSyncableRegistrySubtitle: T;
  };
  publishRegistryItems: {
    /**
     * @default 'Display Gifts In Registry'
     */
    title: T;

    /**
     * @default 'Show or hide products synced from this registry. If products are not displayed, guests can still shop for them using the external registry link.'
     */
    info: T;

    /**
     * @default 'Show'
     */
    labelOn: T;

    /**
     * @default 'Hide'
     */
    labelOff: T;
  };
  filterSectionRegistry: {
    /**
     * @default 'Everything'
     */
    everything: T;

    /**
     * @default 'Gifts Available'
     */
    stillNeeded: T;

    /**
     * @default 'Purchased'
     */
    purchased: T;

    /**
     * @default 'Reserved'
     */
    reserved: T;

    /**
     * @default 'Reserved & Purchased'
     */
    reservedAndPurchased: T;
  };
  productDialog: {
    /**
     * @default 'Gift added from {{storeName}}'
     */
    giftAddedFrom: T;

    /**
     * @default 'To edit or remove this gift you’ll need to do it on your'
     */
    alertEditRegistry: T;

    /**
     * @default 'linked registry'
     */
    linkRegistry: T;

    /**
     * @default 'Remove from Registry'
     */
    removedCustomItem: T;

    /**
     * @default 'was removed from your registry.'
     */
    giftRemoved: T;

    /**
     * @default 'Edit Gift'
     */
    giftEditDetails: T;
  };
  productListHeaderButtons: {
    /**
     * @default 'Add Gift'
     */
    addGift: T;

    /**
     * @default 'Filter By'
     */
    filterBy: T;

    /**
     * @default 'Manage Gifts'
     */
    title: T;

    /**
     * @default 'New Gift'
     */
    newGift: T;
  };
  giftTypeOptionsButtons: {
    /**
     * @default 'Product'
     */
    product: T;

    /**
     * @default 'Cash Fund'
     */
    cashFund: T;

    /**
     * @default 'Charity'
     */
    charity: T;
  };
  addGiftFromUrl: {
    /**
     * @default 'Save time and <browserButtonCta/> or <appCta/>
'
*/
    saveTime: T;

    /**
     * @default 'install the browser button
'
*/
    browserButtonCta: T;

    /**
     * @default 'get the app'
     */
    appCta: T;

    /**
     * @default 'Continue adding from
'
*/
    continueAdding: T;
  };
  addressDialog: {
    /**
     * @default 'Edit shipping address'
     */
    editShippingAddressButton: T;

    /**
     * @default 'Your shipping address'
     */
    yourShippingAddressButton: T;

    /**
     * @default 'Shipping Settings'
     */
    shippingSettingsTitle: T;

    /**
     * @default 'Let gift givers know where to send their gifts.'
     */
    note: T;

    /**
     * @default 'Items sold by Joy are only shippable within the contiguous U.S. (excludes Alaska/Hawaii).'
     */
    shippingPolicyExceptions: T;

    /**
     * @default 'Name'
     */
    name: T;

    /**
     * @default 'Phone'
     */
    phone: T;

    /**
     * @default 'Only one recipient name is recommended'
     */
    nameCaption: T;

    /**
     * @default 'Address Line 1'
     */
    address1: T;

    /**
     * @default 'We cannot ship to P.O. boxes'
     */
    poBoxWarningCaption: T;

    /**
     * @default 'Address Line 2'
     */
    address2: T;

    /**
     * @default 'City'
     */
    city: T;

    /**
     * @default 'State/Region/Province'
     */
    state: T;

    /**
     * @default 'Zip/Postal Code'
     */
    postalCode: T;

    /**
     * @default 'Country'
     */
    country: T;

    /**
     * @default 'Country Code'
     */
    countryCode: T;

    /**
     * @default 'Save'
     */
    saveButton: T;

    /**
     * @default 'Cancel'
     */
    cancelButton: T;

    /**
     * @default 'Clear Address'
     */
    resetButton: T;

    /**
     * @default '*Required fields'
     */
    requiredFields: T;

    /**
     * @default 'Other'
     */
    countryOtherByCode: T;

    /**
     * @default 'Confirm Shipping Address'
     */
    addressConfirmationTitle: T;

    /**
     * @default 'To ensure accurate delivery, we suggest the slightly modified address below. Please select the address you’d like to use.'
     */
    addressConfirmationNote: T;

    /**
     * @default 'Original Address'
     */
    originalAddressText: T;

    /**
     * @default 'Suggested Address'
     */
    suggestedAddressText: T;

    /**
     * @default 'Back'
     */
    backButton: T;

    /**
     * @default 'Confirm Address'
     */
    confirmButton: T;

    /**
     * @default 'Full name'
     */
    fullName: T;

    /**
     * @default '555-555-5555'
     */
    phonePlaceholder: T;

    /**
     * @default 'Address'
     */
    address: T;

    /**
     * @default 'Apartment, suite, etc. (optional)'
     */
    addressPlaceholder2: T;
  };
  delayShipping: {
    /**
     * @default 'You decide when you want your gifts to ship. Exclusively for gifts sold by Joy.'
     */
    delayShippingDescription: T;

    /**
     * @default 'Note: Previously delayed gifts will need your review before they can be shipped.'
     */
    widgetCounterNoteMessage: T;

    /**
     * @default 'Gifts to Review'
     */
    counterLabel: T;

    /**
     * @default 'Review and Ship'
     */
    reviewAndShip: T;

    /**
     * @default 'Activate Delay Shipping'
     */
    activateDelayShippingTitle: T;

    /**
     * @default 'Deactivate Delay Shipping'
     */
    deactivateDelayShippingTitle: T;

    /**
     * @default 'By enabling Delay Shipping, any new orders you receive will not be shipped until you give us the go-ahead. You can easily turn off Delay Shipping from the Track page at any time. Are you sure you want to confirm this action?'
     */
    activateDelayShippingDescription: T;

    /**
     * @default 'You are about to disable Delay Shipping. Newly placed orders will ship automatically as soon as they’re received. Previously delayed orders must still be shipped from the Gift Tracker.'
     */
    deactivateDelayShippingDescription: T;

    /**
     * @default 'Got it'
     */
    confirmButton: T;

    /**
     * @default ' Newly received gifts will ship automatically unless Delay Shipping is on. Please note: toggling delay shipping only impacts new orders. Existing delayed orders will still require action.'
     */
    tooltipDescription: T;

    /**
     * @default 'Delay Shipping is '
     */
    toggleLabel: T;

    /**
     * @default 'Confirm Shipping'
     */
    confirmShipping: T;

    /**
     * @default 'Ship Order Now'
     */
    shipOrderNow: T;

    /**
     * @default 'Ship {{count}} Orders'
     */
    shipOrdersNow: T;

    /**
     * @default 'Cancel'
     */
    cancelButton: T;

    /**
     * @default 'Review Orders'
     */
    reviewOrders: T;

    /**
     * @default 'Your order is being processed.'
     */
    inProcessSingular: T;

    /**
     * @default 'Your orders are being processed.'
     */
    inProcessPlural: T;

    /**
     * @default 'Most items will ship via Standard Ground Shipping and arrive within 5-10 business days from when the order was processed. We’ll send you an email once it ships!'
     */
    inProcessDescription: T;

    /**
     * @default 'Show Details'
     */
    showDetailsText: T;

    /**
     * @default 'Hide Details'
     */
    hideDetailsText: T;

    /**
     * @default 'You have {{count}} Orders Pending Shipment'
     */
    countMessage: T;

    /**
     * @default 'Shipping to:'
     */
    shipTo: T;

    /**
     * @default 'One or more orders was placed with an old shipping address. Which address would like to use?'
     */
    shippingConflict: T;

    /**
     * @default 'Please select if you’d prefer this address:'
     */
    newAddressOnFile: T;

    /**
     * @default 'Mmm... Something went wrong. Please try again or contact support'
     */
    mutationGenericError: T;

    /**
     * @default 'Shipping is limited to the continental USA; PO boxes are not eligible.'
     */
    invalidShippingAddress: T;
  };
  addressAutocompleteField: {
    /**
     * @default 'Address not found'
     */
    addressNotFound: T;

    /**
     * @default 'Keep typing to display more results'
     */
    firstOptionAddress1Field: T;

    /**
     * @default '-- Add Address Manually --'
     */
    lastOptionAddress1Field: T;

    /**
     * @default 'Click for {{entries}} more entries'
     */
    addressWithMoreThanOneSuggestion: T;
  };
  giftProgressTracker: {
    progressBarStateStart: [
      /**
       * @default 'Let's get it started!'
       */
      T,
      /**
       * @default 'Start your engines!'
       */
      T,
      /**
       * @default 'It's good to have goals!'
       */
      T,
      /**
       * @default 'There is so much potential here!'
       */
      T
    ];

    progressBarStateComplete: [
      /**
       * @default 'We did it! 🎉'
       */
      T,
      /**
       * @default 'Cash fund complete! ✅'
       */
      T,
      /**
       * @default 'Mission accomplished! 🏆'
       */
      T,
      /**
       * @default 'Goal crushed! ⭐️'
       */
      T,
      /**
       * @default 'Time to celebrate! 🎊'
       */
      T
    ];

    /**
     * @default '{{goal}} Goal'
     */
    cashGiftLabel: T;

    /**
     * @default '{{goal}} Goal Reached!'
     */
    cashGiftLabelComplete: T;

    /**
     * @default '{{amount}} Received'
     */
    cashGiftProgress: T;

    /**
     * @default '{{amount}} Gifted'
     */
    groupGiftProgress: T;

    /**
     * @default '{{requested}} Requested'
     */
    physicalGiftLabel: T;

    /**
     * @default '{{requested}} Received!'
     */
    physicalGiftLabelComplete: T;

    /**
     * @default '{{purchased}} of {{requested}} reserved/purchased'
     */
    physicalGiftProgress: T;

    /**
     * @default '{{amount}} still needed'
     */
    remainingText: T;

    /**
     * @default '{{amount}} Contribution'
     */
    contributionText: T;

    /**
     * @default '{{amount}} Contributed'
     */
    contributedText: T;

    /**
     * @default 'Contribute what you wish'
     */
    contributionAnyAmountText: T;

    /**
     * @default 'Group Gift'
     */
    groupGifting: T;
  };
  itemPurchaseTracker: {
    /**
     * @default 'Purchased By'
     */
    tableHeaderPurchasedBy: T;

    /**
     * @default 'Total'
     */
    tableHeaderTotal: T;

    /**
     * @default 'Copy'
     */
    copyButton: T;

    /**
     * @default 'Copied'
     */
    copied: T;
  };
  customItemDetailsDialog: {
    /**
     * @default 'Payment via {{provider}} @{{handle}}'
     */
    paymentSource: T;

    /**
     * @default 'Fulfilled by {{retailer}}'
     */
    fulfillmentSource: T;

    /**
     * @default 'No one has purchased this (yet).'
     */
    zeroStateMsgHeader: T;

    /**
     * @default 'After someone has purchased this gift, you'll be able to see it here.'
     */
    zeroStateMsgBody: T;

    /**
     * @default 'Edit Gift'
     */
    editGiftButton: T;

    /**
     * @default 'Remove from Registry'
     */
    removeGiftButton: T;

    /**
     * @default 'Remove registry gift?'
     */
    removeGiftDialogHeader: T;

    /**
     * @default 'This item will be permanently deleted from your registry.'
     */
    removeGiftDialogBody: T;

    /**
     * @default 'Nevermind'
     */
    cancelRemoveGiftButton: T;

    /**
     * @default 'Are you sure you want to remove this gift?'
     */
    removeGiftDialogHeaderHasPurchases: T;

    /**
     * @default 'Someone has already reserved it, and they (or your future self) may be confused if you delete it from your registry.'
     */
    removeGiftDialogBodyHasPurchases: T;

    /**
     * @default 'gifted so far'
     */
    giftedSoFarText: T;

    /**
     * @default 'View Item on {{storeName}}'
     */
    viewOnWebsite: T;

    /**
     * @default 'View Contribution link'
     */
    contributionLink: T;

    /**
     * @default 'cash'
     */
    cash: T;

    /**
     * @default 'Gift Message'
     */
    noteLabel: T;

    /**
     * @default 'and Cash/Check'
     */
    cashOrCheckFulfillmentText: T;

    /**
     * @default 'Credit card'
     */
    creditCardFullfillmentText: T;

    /**
     * @default 'Note to Family and Friends'
     */
    noteToFamilyTitle: T;

    /**
     * @default 'Tracking Unavailable on Joy'
     */
    noTrackingTitle: T;

    /**
     * @default 'This item was purchased on an external registry prior to being transferred to Joy.'
     */
    noTrackingSubtitle: T;

    /**
     * @default 'To track this gift, please refer to your'
     */
    noTrackingPreText: T;

    /**
     * @default 'external'
     */
    noTrackingExternalText: T;

    /**
     * @default 'registry.'
     */
    noTrackingPostText: T;

    /**
     * @default 'This gift cannot be removed from your registry because it has been reserved or purchased.'
     */
    cannotRemoveExplanation: T;

    /**
     * @default 'Hide from Registry'
     */
    hideButton: T;

    /**
     * @default 'Show on Registry'
     */
    showButton: T;

    /**
     * @default '🙈 {{title}} has been hidden from your registry.'
     */
    hideTostText: T;

    /**
     * @default '{{title}} is now being shown on your registry.'
     */
    showTostText: T;
  };
  cancelReservationDialog: {
    /**
     * @default 'Are you sure you want to cancel this reservation?'
     */
    cancelReservationHeader: T;

    /**
     * @default 'This will make the gift available for reservation on your registry again.'
     */
    cancelReservationBody: T;

    /**
     * @default 'Nevermind'
     */
    cancelReservationCancelButton: T;

    /**
     * @default 'Confirm Cancellation'
     */
    cancelReservationConfirmButton: T;

    /**
     * @default 'Cancel Reservation'
     */
    cancelReservationButtonText: T;
  };
  previewSendEmailReminderDialog: {
    /**
     * @default 'Need tracking info for your gift? We’ve got you.'
     */
    header: T;

    /**
     * @default 'We’ll send a one-time email to your guest to request the Order ID, Tracking Number and Shipping Carrier for this gift. Once provided, the information will appear in your gift tracker.'
     */
    body: T;

    /**
     * @default 'Your guest may have forgotten to complete the gift reservation process. If you’d like, we’ll send them a friendly nudge. The email will come right from Joy, they won’t ever know you asked us to remind them.'
     */
    bodyCashFundOrCharity: T;

    /**
     * @default 'Sample Email:'
     */
    sampleText: T;

    /**
     * @default 'unknown'
     */
    unknown: T;

    /**
     * @default 'Cancel'
     */
    cancelButton: T;

    /**
     * @default 'Send Request'
     */
    confirmButton: T;

    /**
     * @default 'Haven’t received your gift yet? We can send a reminder email to your guest for you.'
     */
    prompt: T;

    /**
     * @default 'Preview and Send Reminder'
     */
    buttonText: T;

    /**
     * @default 'Reminder Sent'
     */
    successText: T;
  };
  downloadDialog: {
    /**
     * @default 'Add Gifts on the Go'
     */
    downloadDialogEyebrowText: T;

    /**
     * @default 'Use the app to add items wherever, whenever'
     */
    downloadDialogHeaderText: T;

    /**
     * @default 'You can download the app on both Android and iOS.'
     */
    downloadDialogBodyText: T;
  };
  addRegistry: {
    /**
     * @default 'anyregistry.com/our-registry'
     */
    inputPlaceholder: T;

    /**
     * @default 'Registry URL'
     */
    inputLabel: T;

    /**
     * @default 'Add Registry'
     */
    buttonText: T;

    /**
     * @default 'Maybe Later'
     */
    maybeLater: T;

    /**
     * @default 'Already have another registry?'
     */
    connectRegistriesTitle: T;

    /**
     * @default 'Connect external registries for your guests’ convenience.'
     */
    connectRegistriesSubtitle: T;

    /**
     * @default '🙌 ️Sounds good. ✨'
     */
    soundsGood: T;

    /**
     * @default 'You can connect another registry at any time in the sidebar or on the build page.'
     */
    connectMessage: T;

    /**
     * @default 'Already have a registry?'
     */
    connectTitle: T;
  };
  addGiftDialog: {
    /**
     * @default 'Add your own gift'
     */
    title: T;
  };
  giftTypeSection: {
    /**
     * @default 'Get Gift Ideas'
     */
    getGiftIdeas: T;

    /**
     * @default 'Add Gift Manually'
     */
    addGiftManually: T;

    /**
     * @default 'Add Item'
     */
    addProduct: T;

    /**
     * @default 'Add Cash Fund'
     */
    addCashFund: T;

    /**
     * @default 'Add Charity'
     */
    addCharity: T;

    /**
     * @default 'Browse Products'
     */
    browseProducts: T;

    /**
     * @default 'Explore Cash Funds'
     */
    exploreCashFunds: T;

    /**
     * @default 'See Top Picks For You'
     */
    topPicks: T;

    /**
     * @default 'Start Your Checklist'
     */
    checklist: T;

    /**
     * @default 'Install the Joy Quick Add Button'
     */
    quickAddSectionTitle: T;

    /**
     * @default 'Joy Mobile App'
     */
    quickAddSectionTitleMobile: T;

    /**
     * @default 'Add anything as you browse the web.'
     */
    quickAddSectionSubtitle: T;

    /**
     * @default 'Add anything as you browse the web on your phone.'
     */
    quickAddSectionSubtitleMobile: T;

    /**
     * @default 'Get the button'
     */
    quickAddSectionBtn: T;

    /**
     * @default 'Get the app'
     */
    quickAddSectionBtnMobile: T;

    /**
     * @default 'Joy Quick Add'
     */
    quickAddSectionImgAlt: T;

    /**
     * @default 'Shop from Any Store'
     */
    shopFromAnyStore: T;
  };
  newAddRegistryDialog: {
    /**
     * @default 'Have Another Registry? Let’s Connect!'
     */
    landingTitle: T;

    /**
     * @default 'Keep your registries in one place for easy access.'
     */
    landingSubtitle: T;

    /**
     * @default 'Hmm… we were unable to add this registry.'
     */
    errorTitle: T;

    /**
     * @default 'Please try again later or contact us if you’re still having trouble.'
     */
    errorSubtitle: T;

    /**
     * @default 'Awesome. We’re syncing your gifts so gift givers can shop in one place.'
     */
    syncingTitle: T;

    /**
     * @default 'Your gifts from {{storeName}} will soon appear in your Joy registry.'
     */
    syncingSubtitle: T;

    /**
     * @default 'Awesome. We’ve added a link on your Joy registry so gift givers will easily find it.'
     */
    linkedTitle: T;

    /**
     * @default 'Test Registry Link'
     */
    testRegistryLink: T;

    /**
     * @default 'Add Registry'
     */
    addRegistry_short: T;

    /**
     * @default 'Add Another Registry'
     */
    addAnotherRegistry: T;

    /**
     * @default 'Make sure the registry you’re connecting is set to "Public".'
     */
    makeSureRegistryIsPublic: T;

    /**
     * @default 'How does it work?'
     */
    howItWorks_short: T;

    /**
     * @default 'How does syncing work?'
     */
    howItWorks_long: T;
  };
  transferRegistry: {
    /**
     * @default 'Transfer Your {{retailer}} Registry to Joy'
     */
    title: T;

    /**
     * @default 'Make a one-time registry transfer. All your items will be hosted and managed through Joy so you don’t have to worry about juggling multiple registries.'
     */
    subtitle: T;

    /**
     * @default 'Start Transfer'
     */
    button: T;

    /**
     * @default 'I’d rather manage my gifts through {{retailer}}'
     */
    secondaryButton: T;

    /**
     * @default 'Add, update, and track gifts in one place'
     */
    ctaOne: T;

    /**
     * @default 'Guests look for available gifts on Joy'
     */
    ctaThree: T;

    /**
     * @default 'Shop anywhere with the Joy Browser Button'
     */
    ctaTwo: T;

    /**
     * @default 'Hang Tight! We’re Transferring Your Registry…'
     */
    loadingTitle: T;

    /**
     * @default 'In a moment your {{retailer}} items will appear in your Joy registry.'
     */
    loadingSubtitle: T;

    /**
     * @default 'Your gifts are now in Joy!'
     */
    completeTitle: T;

    /**
     * @default 'Next let’s hide your old registry.'
     */
    completeTitleLineTwo: T;

    /**
     * @default 'By making your {{retailer}} registry private, your guests won’t accidentally purchase the same Mickey Mouse Waffle Maker twice. 😉'
     */
    completeSubtitle: T;

    /**
     * @default 'Sit tight, your transfer is still in progress.'
     */
    stillTransferingTitle: T;

    /**
     * @default 'Refresh the page in a few minutes and it should be complete.'
     */
    stillTransferingSubtitle: T;

    /**
     * @default 'Thousands of unique gifts and brands you love are waiting for you. Shop with Joy!'
     */
    shopPromoTitle: T;

    /**
     * @default 'Shop Now'
     */
    shopPromoButton: T;

    /**
     * @default 'Next'
     */
    nextButton: T;

    /**
     * @default 'Add gifts from {{retailer}} or any store easily with the Joy Browser Button'
     */
    joyButtonTitle: T;

    /**
     * @default 'Drag the button below to your browser’s Bookmark bar to save it.'
     */
    joyButtonSubtitle: T;

    /**
     * @default 'Add gifts easily'
     */
    joyAppTitle: T;

    /**
     * @default 'Download the Joy app & add new items from {{retailer}} or any store.'
     */
    joyAppSubtitle: T;

    /**
     * @default 'Need help?'
     */
    needHelp: T;

    /**
     * @default 'Read our step-by-step guide.'
     */
    readOurGuide: T;

    /**
     * @default 'Edit Old Registry Settings'
     */
    editOldButton: T;

    /**
     * @default 'Want to add more gifts to your registry? Get the '
     */
    tryBrowserButton: T;

    /**
     * @default 'Joy Browser Button!'
     */
    joyBrowserButton: T;

    /**
     * @default 'Delete Registry and Gifts'
     */
    deleteRegistry: T;

    /**
     * @default 'As of {{date}} your registry is still set to Public.'
     */
    asOfPublic: T;

    /**
     * @default 'Set your {{storeName}} registry to private to avoid confusion and duplicate gifts.'
     */
    turnToPrivate: T;

    /**
     * @default 'Fix Registry Privacy'
     */
    fixRegPrivacy: T;

    /**
     * @default 'Please note: if you already have purchased gifts on your other registry, your gift tracking and purchase data will NOT be transferred to Joy.'
     */
    transferWarning: T;

    /**
     * @default 'Edit Registry'
     */
    viewRegistry: T;

    /**
     * @default 'How does this work?'
     */
    howTo: T;

    /**
     * @default 'Want to add more gifts from {{storeName}}?'
     */
    browserButtonCTATitle: T;

    /**
     * @default 'Get the Joy Browser Button'
     */
    browserButtonCTASubtitle: T;

    /**
     * @default 'Please hide your {{storeName}} registry'
     */
    hideRegistryTitle: T;

    /**
     * @default 'Transferring'
     */
    registryTransferInProgress: T;

    /**
     * @default 'We only check this once a day, so if it doesn't seem accurate please check back tomorrow.'
     */
    bannerTooltip: T;

    giftCounts: {
      /**
       * @default '{{count}} gift transferred'
       * @param {number} count - The number of ocurrencies.
       * - singular: {{count}} gift transferred
       * - plural: {{count}} gifts transferred
       */
      transferredGiftCount: T;
    };
  };
  addGiftsPostTransfer: {
    /**
     * @default 'New gifts found on your {{storeName}} registry'
     */
    bannerTitle: T;

    /**
     * @default 'New gifts found on your {{storeNames}} registries'
     */
    bannerTitlePlural: T;

    /**
     * @default 'New gifts found.'
     */
    bannerTitleMobile: T;

    /**
     * @default 'Bring them into your Joy registry.'
     */
    bannerSubTitle: T;

    /**
     * @default 'Transferred Registry'
     */
    tooltipHeader: T;

    /**
     * @default 'If you’ve added new gifts after transferring your registry, Joy will let you bring those gifts in to keep everything in sync. Just click on the Transfer All button to make it happen.'
     */
    tooltipText: T;

    /**
     * @default 'Transfer All'
     */
    bannerButtonCTA: T;

    /**
     * @default '{{giftCount}} New Gifts Found'
     */
    newGiftsTitle: T;

    /**
     * @default 'New gifts found on your {{storeName}} registry. Would you like to add them to Joy?'
     */
    newGiftsSubtitle: T;

    /**
     * @default 'New gifts found in your {{storeNames}} registries. Would you like to add them to Joy?'
     */
    newGiftsSubtitlePlural: T;

    /**
     * @default 'Price (ea)'
     */
    priceTitle: T;

    /**
     * @default 'Quantity'
     */
    quantityTitle: T;

    /**
     * @default 'Maybe Later'
     */
    secondaryCTA: T;

    /**
     * @default 'Add Gifts to Joy'
     */
    newGiftsPrimaryCTA: T;

    /**
     * @default 'Your new gifts have been copied to your Joy registry.'
     */
    giftsAddedConfirmation: T;

    /**
     * @default 'Tired of updating your gifts?
There’s a better way!'
*/
    getButtonTitle: T;

    /**
     * @default 'The Joy Quick Add Button makes it easy to add new items from any store directly into your Joy registry.'
     */
    getButtonSubtitle: T;

    /**
     * @default 'Get the Button'
     */
    getButtonPrimaryCTA: T;
  };
  syncRegistry: {
    /**
     * @default 'Sync Your Registry'
     */
    title: T;

    /**
     * @default 'Keep your current {{retailer}} registry and sync your items daily to your Joy registry. Guests will be sent to {{retailer}} to purchase your gifts.'
     */
    subtitle: T;

    /**
     * @default 'Start Sync'
     */
    button: T;

    /**
     * @default 'I want to manage my gifts on Joy'
     */
    secondaryButton: T;

    /**
     * @default 'Add items on your other registry'
     */
    ctaOne: T;

    /**
     * @default 'Manage and track gifts through your other registry'
     */
    ctaTwo: T;

    /**
     * @default 'Guests browse for gifts on your Joy site'
     */
    ctaThree: T;
  };
  giftTracker: {
    /**
     * @default 'Qty {{qty}}'
     */
    quantityTitle: T;

    /**
     * @default 'Order #{{orderNumber}}'
     */
    orderTitle: T;

    /**
     * @default 'Order number or tracking not available'
     */
    orderNotAvailableInfo: T;

    /**
     * @default 'Add Order & Tracking Info'
     */
    addOrderInfo: T;

    /**
     * @default 'Re: Gift tracking from Joy'
     */
    mailSubject: T;

    /**
     * @default 'Track Shipment'
     */
    trackShipmentLinkText: T;

    /**
     * @default 'Where's my gift?'
     */
    mailLinkText: T;

    /**
     * @default 'To be sent via <storeLink/>'
     */
    toBeSent: T;

    /**
     * @default 'Sent via <storeLink/>'
     */
    sentBy: T;

    /**
     * @default 'Gift Giver'
     */
    tableName: T;

    /**
     * @default 'Thank You Manager'
     */
    tableGift: T;

    /**
     * @default 'Track Gifts & Send Thanks'
     */
    trackingGiftTitle: T;

    /**
     * @default 'Tracking'
     */
    trackGiftNavOption: T;

    /**
     * @default 'Gifts & Registries'
     */
    giftsAndRegistriesNavOption: T;

    /**
     * @default '{{guestName}} marked this as purchased.'
     */
    tooltipPurchased: T;

    /**
     * @default '{{guestName}} marked this as given.'
     */
    tooltipGiven: T;

    /**
     * @default '{{guestName}} intends to get you this gift, but they have not marked it as purchased yet.'
     */
    tooltipReserved: T;

    /**
     * @default 'These gifts have been marked as purchased by your guests.'
     */
    tooltipReservedPurchased: T;

    /**
     * @default 'Your guests intend to get you these gifts, but they have not yet marked them as purchased.'
     */
    tooltipReservedRequested: T;

    /**
     * @default 'This gift has not been shipped yet'
     */
    statusTooltipNotShipped: T;

    /**
     * @default 'We will provide tracking information as soon as it is available.'
     */
    statusTooltipTracking: T;

    /**
     * @default 'This gift has been shipped.'
     */
    statusTooltipShipped: T;

    /**
     * @default 'Request Refund'
     */
    requestRefund: T;

    /**
     * @default 'Mark as not purchased'
     */
    markAsNotPurchased: T;

    /**
     * @default 'Contact Support'
     */
    contact: T;

    /**
     * @default 'Return Policy'
     */
    returnPolicy: T;

    /**
     * @default 'Cancel Reservation'
     */
    cancelReservation: T;

    /**
     * @default 'Mark as not given'
     */
    markAsNotGiven: T;

    /**
     * @default 'The order was cancelled'
     */
    orderCancelledText: T;

    /**
     * @default 'Cancelling reservation...'
     */
    cancellingReservation: T;

    /**
     * @default 'order has not selected'
     */
    errorOrderNotSelected: T;

    /**
     * @default 'Hidden from registry'
     */
    hideGiftText: T;

    /**
     * @default 'More Options...'
     */
    moreOptions: T;

    /**
     * @default 'This gift has an issue with the delivery address'
     */
    noticeTitleText: T;

    /**
     * @default 'Instructions for how to fix...'
     */
    noticeInstructionsText: T;

    /**
     * @default 'Write Thank You'
     */
    thankYouLabelLong: T;

    /**
     * @default 'Thank'
     */
    thankYouLabelShort: T;

    /**
     * @default 'Thank You Sent'
     */
    thankedLabelLong: T;

    /**
     * @default 'Thanked'
     */
    thankedLabelShort: T;

    /**
     * @default 'Gift'
     */
    giftLabelSingular: T;

    /**
     * @default 'Gifts'
     */
    giftLabelPlural: T;

    /**
     * @default 'Tracking Info requested from guest'
     */
    trackingInfoRequested: T;

    /**
     * @default 'Reserved'
     */
    reserved: T;

    /**
     * @default 'Purchased'
     */
    purchased: T;

    /**
     * @default 'Given'
     */
    given: T;

    /**
     * @default 'Processing'
     */
    draft: T;

    /**
     * @default 'Shipped'
     */
    shipping: T;

    /**
     * @default 'Delay Active'
     */
    readyToFulfill: T;

    /**
     * @default 'Processing'
     */
    fulFillmentStarted: T;

    /**
     * @default 'Needs Action'
     */
    failed: T;

    /**
     * @default 'Delivered'
     */
    delivered: T;

    /**
     * @default 'Processing'
     */
    processing: T;

    /**
     * @default 'Needs Action'
     */
    pendingRefundAction: T;

    /**
     * @default 'Ship Now'
     */
    shipNow: T;

    /**
     * @default 'Status'
     */
    statusColumn: T;

    /**
     * @default 'Cash Received'
     */
    cashReceived: T;

    /**
     * @default 'Resolve Issue'
     */
    resolveIssue: T;

    /**
     * @default 'Manage {{count}} unresolved issues.'
     */
    manageUnresolvedIssues: T;

    /**
     * @default 'Swapped for cash'
     */
    swappedForCash: T;

    /**
     * @default 'Refunded Guest'
     */
    guestRefunded: T;

    /**
     * @default 'Exchanged for cash'
     */
    swappedForCashOrderLabel: T;

    /**
     * @default 'Guest Refund Issued'
     */
    guestRefundedOrderLabel: T;

    /**
     * @default 'Out For Delivery'
     */
    outForDelivery: T;

    /**
     * @default 'Canceled'
     */
    canceled: T;
  };
  refundDialog: {
    /**
     * @default 'Action needed'
     */
    header: T;

    /**
     * @default 'We’re unable to fulfill your order.'
     */
    title: T;

    /**
     * @default 'We can give you cash or refund your guest.'
     */
    subTitle: T;

    /**
     * @default 'Convert {{amount}} to Cash'
     */
    swapAmountForCash: T;

    /**
     * @default 'We won’t tell your guests you swapped the physical gift for cash. A Stripe account is required for this option.'
     */
    swapForCashDesc: T;

    /**
     * @default 'Refund {{amount}} to Guest'
     */
    refundAmount: T;

    /**
     * @default 'Your gift’s status will be reverted back to “Available” and your guest will receive an email notifying them of the refund. Refunds usually takes 3-5 business days.'
     */
    refundDesc: T;

    /**
     * @default 'Learn More'
     */
    learnMore: T;

    /**
     * @default 'Use Stripe to enable credit card payments and transfer cash to your bank account or debit card.'
     */
    stripeInfo: T;

    /**
     * @default 'Stripe needs more information.'
     */
    stripeInfoError: T;

    /**
     * @default 'Credit card payment enabled.'
     */
    stripeInfoReady: T;

    /**
     * @default 'Cancel'
     */
    cancelCta: T;

    /**
     * @default 'Confirm'
     */
    confirmCta: T;

    /**
     * @default 'Close'
     */
    close: T;

    /**
     * @default '{{amount}} will be refunded to your guest.'
     */
    refundHandledTitle: T;

    /**
     * @default 'Your guest will receive an email notifying them of the refund. Refunds usually takes 3-5 business days. Your gift’s status will be reverted back to Available.'
     */
    refundHandledSubtitle: T;

    /**
     * @default '{{amount}} has been added to your Joy Wallet.'
     */
    sg4cHandledTitle: T;

    /**
     * @default 'You’ve chosen to swap the gift for cash. Your gift’s status will be reverted back to Available.'
     */
    sg4cHandledSubtitle: T;
  };
  giftTrackerDialog: {
    /**
     * @default 'Returning a gift?'
     */
    returnDialogTitle: T;

    /**
     * @default 'customer support'
     */
    dialogEdeliveryLine2Contact: T;

    /**
     * @default 'Returns for this product are handled directly by the store it was purchased from.'
     */
    returnDialogLine1: T;

    /**
     * @default 'This gift was given by {{guestName}} and purchased at {{retailer}}.'
     */
    returnDialogLine2: T;

    /**
     * @default 'Got It'
     */
    buttonText: T;

    /**
     * @default 'Quantity {{qty}}'
     */
    quantityTitle: T;

    /**
     * @default 'If unredeemed, this experience can be returned for a merchandise credit.'
     */
    spurText: T;

    /**
     * @default 'Request a refund for this gift or exchange within 90 days of purchase or event date.'
     */
    dropshipText: T;

    /**
     * @default 'Gift cards and eGift cards are non-refundable.'
     */
    eDeliveryText: T;

    /**
     * @default 'See Details'
     */
    linkText: T;

    /**
     * @default 'Credit Card for Cash and Get Cash Equivalent purchases are non-refundable.'
     */
    gceText: T;
  };
  giftTrackerEDeliveryDialog: {
    /**
     * @default 'Where is my gift?'
     */
    dialogTitle: T;

    /**
     * @default 'This item is scheduled for eDelivery to your email.'
     */
    dialogEdeliveryLine1: T;

    /**
     * @default 'If you have not received your gift via email after 1 -2 days Please contact <customerLink/>'
     */
    dialogEdeliveryLine2: T;

    /**
     * @default 'customer support'
     */
    dialogEdeliveryLine2Contact: T;

    /**
     * @default 'Ok'
     */
    buttonText: T;
  };
  giftTrackerZeroState: {
    /**
     * @default 'Gifts you’ve received and thank you notes sent will appear here.'
     */
    title: T;
  };
  currency: {
    /**
     * @default 'Registry Currency'
     */
    currencyDialogTitle: T;

    /**
     * @default 'Select the currency displayed on your registry.'
     */
    currencyDialogSubtitle: T;

    /**
     * @default 'We will use this currency symbol for any gift that isn’t added from the Joy Shop or synced from an external registry.'
     */
    currencyDialogSecondarySubtitle: T;

    /**
     * @default 'If you have an existing cash fund with contributions, the currency for that gift will not be changed.'
     */
    currencyDialogThirdSubtitle: T;

    /**
     * @default 'Save'
     */
    currencyDialogActionButton: T;

    /**
     * @default 'Cancel'
     */
    currencyDialogSecondaryButton: T;
  };
  hideShowDialog: {
    /**
     * @default 'Are you sure you want to {{status}} this gift?'
     */
    title: T;

    /**
     * @default 'This will allow guests to see it in your registry.'
     */
    showTitle: T;

    /**
     * @default 'This will prevent anyone from seeing this gift on your registry. You can still view any reservations for this gift in the Tracking tab.'
     */
    hideTitle: T;

    /**
     * @default 'Please note that you may still receive the gift if a guest has already purchased it.'
     */
    hideSubtitle: T;

    /**
     * @default 'Cancel'
     */
    cancelButton: T;

    /**
     * @default 'Yes, Show on Registry'
     */
    showButton: T;

    /**
     * @default 'Hide Gift'
     */
    hideButton: T;
  };
  quickAdd: {
    /**
     * @default 'Use the Joy Quick Add Button to add items from any store.'
     */
    title: T;

    /**
     * @default 'Just drag the button below to your<lineBreak/>Bookmarks Bar to get started.'
     */
    subtitle: T;

    /**
     * @default 'Add to Joy'
     */
    addButton: T;

    /**
     * @default 'Verify your Bookmarks Bar is visible.'
     */
    step1: T;

    /**
     * @default 'View'
     */
    viewChrome: T;

    /**
     * @default 'View > Toolbars > Bookmarks Toolbar'
     */
    viewFirefox: T;

    /**
     * @default 'Always Show Bookmarks Bar'
     */
    bookmarksChrome: T;

    /**
     * @default 'Always Show'
     */
    bookmarksFirefox: T;

    /**
     * @default 'In <browser/>’s menu, go to <view/> and make sure there’s a checkmark next to <bookmarks/>.'
     */
    step1Description: T;

    /**
     * @default 'Click and drag the Joy Quick Add Button to your Bookmarks Bar, found above this page.'
     */
    step2: T;

    /**
     * @default 'Now try it out! Visit any store you love, find a gift you want, and then click the Joy Quick Add Button.'
     */
    step3: T;

    /**
     * @default 'We’re still working on the Joy Quick Add Button for Safari, but in the meantime, you can add the button in <chromeLink/> or <firefoxLink/> instead.'
     */
    stepSafari: T;

    /**
     * @default 'You can also get the “Add to Joy” Button for <link/>.'
     */
    footer: T;

    emptyCatalogState: {
      /**
       * @default 'Add Gifts from Anywhere!'
       */
      title: T;

      /**
       * @default 'Just drag the this Joy Quick Add button to your browser bookmark bar in the header and add gifts from anywhere.'
       */
      subtitle: T;

      /**
       * @default 'Add to Joy'
       */
      addButton: T;

      /**
       * @default 'Show me how it works'
       */
      showMeLink: T;
    };
  };
  transferRegistryDialog: {
    screens: {
      selectRegistry: {
        /**
         * @default 'Welcome to Your Universal Registry'
         */
        subTitle: T;

        /**
         * @default 'Select the registry you’d like to connect'
         */
        title: T;

        ukCambium: {
          /**
           * @default 'We've partnered with the best gift lists in the United Kingdom.'
           */
          title: T;

          /**
           * @default 'Where would you like to start?'
           */
          subTitle: T;
        };

        /**
         * @default 'Other Registries'
         */
        allStoresHeader: T;

        /**
         * @default 'Connect a different registry…'
         */
        showAllStores: T;
      };
      createExternalRegistryLink: {
        /**
         * @default 'Let’s get your gift list started'
         */
        title: T;

        /**
         * @default 'You can start a new list or connect an existing list.'
         */
        subTitle: T;

        /**
         * @default 'Back'
         */
        backButton: T;

        /**
         * @default 'Create New Gift List'
         */
        createNewButton: T;

        /**
         * @default 'Connect Existing Gift List'
         */
        transferExistingButton: T;
      };
      enterLink: {
        title: {
          /**
           * @default 'Let’s connect to {{registry}}'
           */
          transfer: T;

          /**
           * @default 'Let’s connect to {{registry}}'
           */
          sync: T;

          /**
           * @default 'Let’s connect to {{registry}}'
           */
          linkToStore: T;

          /**
           * @default 'Let’s connect your registries in one place'
           */
          link: T;
        };
        subTitle: {
          /**
           * @default 'Add your registry link below. After we’ve found your registry, you’ll choose whether to make a one-time copy of your gifts into Joy or sync daily.'
           */
          transfer: T;

          /**
           * @default 'Add your registry link below and we’ll get started.'
           */
          sync: T;

          /**
           * @default 'Add your registry link below and we’ll get started.'
           */
          link: T;
        };
        errors: {
          /**
           * @default 'Please enter a valid registry link.'
           */
          invalidDomain: T;

          /**
           * @default '{{brandName}} registry link should include {{hostname}}.'
           */
          invalidBrandUrl: T;

          /**
           * @default 'Please enter a registry link.'
           */
          required: T;

          /**
           * @default 'This registry can be synced or transferred. Please choose your registry accordingly.'
           */
          canSyncOrTransfer: T;

          /**
           * @default 'Hmm... we can’t find your registry. Check your URL or make sure your registry is set to “Public.”'
           */
          genericError: T;
        };

        /**
         * @default 'Learn More'
         */
        learnButton: T;

        /**
         * @default 'Get Started'
         */
        startButton: T;

        /**
         * @default 'Next'
         */
        next: T;

        /**
         * @default 'Connect Registry'
         */
        connectButton: T;

        /**
         * @default 'Back'
         */
        backButton: T;

        /**
         * @default 'Make sure the registry you’re connecting is set to "Public".'
         */
        makeSureRegistryIsPublic: T;
      };
      linkAdded: {
        /**
         * @default 'Awesome. We’ve added a link on your Joy registry so gift givers will easily find it.'
         */
        title: T;

        /**
         * @default 'Add Another Registry'
         */
        addButton: T;

        /**
         * @default 'View My Registry'
         */
        viewRegistry: T;

        /**
         * @default 'Learn about other ways to add gifts'
         */
        learnPhrase: T;
      };
      registryFound: {
        /**
         * @default 'Registry Found'
         */
        title: T;

        /**
         * @default '{{coupleName}}'s Registry'
         */
        coupleSubtitle: T;

        /**
         * @default 'Now let’s import and supercharge it with Joy.'
         */
        newTitle: T;

        /**
         * @default 'We found your registry at {{brandName}}'
         */
        subtitle: T;

        /**
         * @default 'Move Registry to Joy'
         */
        moveButton: T;

        /**
         * @default 'No thanks, I’d like to continue adding gifts through {{brandName}}'
         */
        syncInsteadButton: T;

        /**
         * @default 'No thanks, just display a link to {{brandName}}.'
         */
        linkInsteadButton: T;

        /**
         * @default 'How does this work?'
         */
        howButton: T;

        /**
         * @default 'Sync Registry'
         */
        syncButton: T;

        /**
         * @default 'Learn more about syncing'
         */
        learnAboutSyncButton: T;

        /**
         * @default 'Back'
         */
        backButton: T;

        /**
         * @default 'Add gifts from ANY store, not just {{brandName}}.'
         */
        addGiftsFromAnyStore: T;

        /**
         * @default 'We’ll hold your packages during your honeymoon!'
         */
        holdPackagesDuringHoneymoon: T;

        /**
         * @default 'Shop right on your website (no annoying link-outs).'
         */
        shopOnWebsite: T;

        /**
         * @default 'Zero-fee cash funds help you reach your goals faster.'
         */
        zeroFeeCashFunds: T;

        /**
         * @default 'Price match promise guarantees you and your guests get the best price.'
         */
        priceMatchPromise: T;

        /**
         * @default 'It’s easy to be grateful with automagic thank you cards.'
         */
        automagicThankYouCards: T;
      };
      gettingGifts: {
        /**
         * @default 'We’re getting your gifts from {{registry}}'
         */
        title: T;

        /**
         * @default 'In the meantime, feel free to explore Registry. Refresh your Manage Gifts page shortly to see your gifts.'
         */
        subTitle: T;

        /**
         * @default 'You’ll begin seeing your gifts in the next few minutes, and we’ll update changes, pricing, and availability once daily.'
         */
        subTitleVariant: T;

        /**
         * @default 'Connect Another Registry'
         */
        addAnotherButton: T;

        /**
         * @default 'Learn about other ways to add gifts'
         */
        learnAboutButton: T;

        /**
         * @default 'Done'
         */
        done: T;
      };
      gettingGiftsForSync: {
        /**
         * @default 'We’re getting your gifts from {{registry}}'
         */
        title: T;

        /**
         * @default 'This could take up to a few minutes. Refresh your Manage page shortly to see your synced gifts. If you have another registry to add, you can do that now.'
         */
        subTitle: T;

        /**
         * @default 'Add Another Registry'
         */
        addAnotherButton: T;

        /**
         * @default 'Learn about other ways to add gifts'
         */
        learnAboutButton: T;
      };
      linkInstead: {
        /**
         * @default 'Great! Before we link your registry, some things to keep in mind:'
         */
        title: T;

        /**
         * @default 'Guests will have to leave your website to purchase gifts.'
         */
        listItem1: T;

        /**
         * @default 'You won’t have control over when your packages arrive on your doorstep.'
         */
        listItem2: T;

        /**
         * @default 'Gifts outside of Joy aren’t eligible for Joy’s AI-assisted thank you cards.'
         */
        listItem3: T;

        /**
         * @default 'I understand and I still want to link this Registry.'
         */
        checkboxLabel: T;

        /**
         * @default 'Nevermind, transfer gifts to Joy'
         */
        transferButton: T;

        /**
         * @default 'Continue Without Transferring'
         */
        linkButton: T;

        /**
         * @default 'Learn about other ways to add gifts'
         */
        learnAboutButton: T;
      };
      gettingGiftsForTransfer: {
        /**
         * @default 'We’re bringing your {{registry}} gifts into Joy'
         */
        title: T;

        /**
         * @default 'one-time copy'
         */
        boldSubtitle: T;

        /**
     * @default 'In a few minutes, this <bold/> will be complete.

🎁 If you plan to add more items from {{registry}} or other stores, you’ll need to use the Quick Add Button.

💡 Once your gifts are copied, we also recommend turning your registry to private to avoid potential duplicate gifts.'
*/
        subTitle: T;

        /**
         * @default 'Next'
         */
        nextButton: T;
      };
      reminderShippingAddressForTransfer: {
        /**
         * @default 'Back'
         */
        backButton: T;

        /**
         * @default 'Now let’s add your shipping address'
         */
        title: T;

        /**
         * @default 'Avoid missing packages and awkward texts! We’ll make sure gift givers know where to send your gifts.'
         */
        body: T;

        /**
         * @default 'Add Shipping Address'
         */
        addButton: T;

        /**
         * @default 'I’ll Do It Later'
         */
        laterButton: T;
      };
      syncInstead: {
        /**
         * @default 'Sync this registry instead'
         */
        title: T;

        /**
         * @default 'While we recommend moving your registry to Joy so you can <strong>do everything in one place</strong>, syncing is another option if you want to continue using your {{retailer}} registry.'
         */
        subTitle: T;

        /**
         * @default 'Move to Joy'
         */
        moveToJoy: T;

        /**
         * @default 'Syncing'
         */
        syncing: T;

        /**
         * @default 'Adding Gifts'
         */
        addingGifts: T;

        /**
         * @default 'Tracking Gifts'
         */
        trackingGifts: T;

        /**
         * @default 'Buying Gifts'
         */
        buyingGifts: T;

        /**
         * @default 'Completion Discount'
         */
        completionDiscount: T;

        /**
         * @default 'Add and update items in Joy, not {{retailer}}, changes reflect instantly'
         */
        addingTransfer: T;

        /**
         * @default 'Add and update items in {{retailer}}, they’ll sync daily'
         */
        addingSyncing: T;

        /**
         * @default 'Manage and track your gifts, across all stores, in Joy'
         */
        trackingTransfer: T;

        /**
         * @default 'Manage and track registries one-by-one on each site'
         */
        trackingSyncing: T;

        /**
         * @default 'Guests can buy their gifts on {{retailer}} or Joy'
         */
        buyingTransfer: T;

        /**
         * @default 'Guests are taken to your {{retailer}} registry to purchase'
         */
        buyingSyncing: T;

        /**
         * @default 'Get access to Joy’s completion discount on Joy gifts in addition to a completion discount from {{retailer}}'
         */
        completionDiscountTransfer: T;

        /**
     * @default 'Get access to Joy's completion discount on Joy gifts.

Note: With guests purchasing gifts off of your Joy registry instead of your {{retailer}} registry, you are not likely to qualify for {{retailer}}'s completion discount, which requires $500 to be bought off of the original {{retailer}} registry.'
*/
        noCompletionDiscountTransfer: T;

        /**
         * @default 'Receive a completion discount from {{retailer}}'
         */
        completionDiscountSyncing: T;

        /**
         * @default 'Go Back'
         */
        backButton: T;

        /**
         * @default 'Sync Registry'
         */
        syncRegistryButton: T;
      };
      seamlessCheckout: {
        /**
         * @default 'Seamless Checkout'
         */
        title: T;

        /**
         * @default 'Simplify your guests checkout experience for eligible gifts.'
         */
        subTitle: T;

        /**
         * @default 'Seamless Checkout is On'
         */
        seamlessCheckoutOn: T;

        /**
         * @default 'Seamless Checkout is Off'
         */
        seamlessCheckoutOff: T;

        /**
         * @default 'Go Back'
         */
        backButton: T;

        /**
         * @default 'Next'
         */
        nextButton: T;

        /**
         * @default 'With Seamless Checkout:'
         */
        withSeamelessCheckout: T;

        /**
         * @default 'Without Seamless Checkout:'
         */
        withoutSeamlessCheckout: T;

        /**
         * @default 'Still have questions?'
         */
        stillHaveQuestions: T;

        /**
         * @default 'Learn more'
         */
        learnMore: T;

        seamlessCheckoutValueProps: {
          /**
           * @default 'Your guests will check out easily without leaving your website.'
           */
          guestsCheckoutEasily: T;

          /**
           * @default 'Guests won’t need to know your address, confirm purchase, or give you their purchase details.'
           */
          guestsNoAddressNeeded: T;

          /**
           * @default 'Gifts are tracked on Joy.'
           */
          giftsTrackedOnJoy: T;

          /**
           * @default 'Hassle-free returns with Joy.'
           */
          hassleFreeReturns: T;

          /**
           * @default '24/7 order support from Joy.'
           */
          orderSupport24_7: T;
        };
        risks: {
          /**
           * @default 'Your guests will leave your Joy website to complete purchase.'
           */
          guestsLeaveSite: T;

          /**
           * @default 'Guests will have to come back to your Joy website to add their purchase details.'
           */
          guestsReturnToAddDetails: T;

          /**
           * @default 'You are more likely to receive duplicate gifts.'
           */
          moreDuplicateGifts: T;

          /**
           * @default 'You’ll work with your retailer for customer support and returns.'
           */
          retailerSupportAndReturns: T;
        };

        /**
         * @default 'Don’t Optimize Checkout'
         */
        dontOptimize: T;

        /**
         * @default 'Enable Seamless Checkout'
         */
        enableSeamless: T;
      };
      quickAdd: {
        /**
         * @default 'Back'
         */
        backButton: T;

        step1: {
          /**
           * @default 'Shop on {{retailer}} and any other store with the Joy Quick Add Button'
           */
          title: T;

          /**
           * @default 'Add items from anywhere with the Joy Quick Add Button'
           */
          linkedTitle: T;

          /**
           * @default 'Install it today to keep shopping with a simple click.'
           */
          subTitle: T;

          /**
           * @default 'Learn More'
           */
          learnMoreLink: T;

          /**
           * @default 'I’ll Do It Later'
           */
          laterButton: T;

          /**
           * @default 'Get the Button'
           */
          getButton: T;
        };
        step2: {
          /**
           * @default 'Install the Joy Quick Add Button in Chrome/Firefox'
           */
          title: T;

          /**
           * @default 'Just drag the button below to your browser’s bookmark bar.'
           */
          subTitle: T;

          /**
           * @default 'Add to Joy'
           */
          browserButton: T;

          /**
           * @default 'I’ve Installed It'
           */
          doneButton: T;

          /**
           * @default 'Need Help? Read our'
           */
          help: T;

          /**
           * @default 'step-by-step guide'
           */
          helpLink: T;
        };
      };
      joyApp: {
        /**
         * @default 'Add & track gifts on the go'
         */
        title: T;

        /**
         * @default 'Download the Joy App to shop, edit, and manage your registry all from your phone.'
         */
        subTitle: T;

        /**
         * @default 'Get the App'
         */
        downloadButton: T;

        /**
         * @default 'View My Registry'
         */
        viewRegistryButton: T;

        /**
         * @default 'Download the Joy Mobile App'
         */
        qrCodeAltText: T;

        /**
         * @default 'Add Gifts'
         */
        addGiftsAltText: T;

        /**
         * @default 'Back'
         */
        backButton: T;
      };
    };
  };
  confirmRegistryNotification: {
    /**
     * @default 'No, Contact Support'
     */
    contactSupport: T;

    /**
     * @default 'Yes'
     */
    yes: T;

    /**
     * @default 'Do your gifts from {{storeName}} look correct?'
     */
    looksCorrect: T;

    /**
     * @default 'We’ve copied your gifts from {{storeName}}. Make sure everything looks good.'
     */
    confirmRegistry: T;

    /**
     * @default 'Review Gifts'
     */
    reviewGifts: T;

    /**
     * @default 'Maybe Later'
     */
    maybeLater: T;
  };
  sidebar: {
    greeting: {
      /**
       * @default 'Happy {{dayOfWeek}}!'
       */
      dailyMessage: T;

      /**
       * @default '{{count}} days ago ️💘'
       */
      countdownPostWedding: T;

      /**
       * @default 'Today’s the big day! 🥂'
       */
      weddingDay: T;

      /**
       * @default 'Happy {{dayOfWeek}}!'
       * @param {number} count - The number of ocurrencies.
       * - singular: Happy {{dayOfWeek}}!
       * - plural: {{count}} days to go 🎉
       */
      countdownToWedding: T;
    };
    counters: {
      /**
       * @default 'Gifts Available'
       */
      giftsAddedLabel: T;

      /**
       * @default 'Reserved & Purchased'
       */
      reservedLabel: T;
    };
    quickActions: {
      /**
       * @default 'Close'
       */
      mobileToggleButtonOn: T;

      /**
       * @default 'Quick Actions'
       */
      mobileToggleButtonOff: T;

      /**
       * @default 'Your registry is hidden'
       */
      visibilityPrivate: T;

      /**
       * @default 'Your registry is visible'
       */
      visibilityPublic: T;

      /**
       * @default 'Registry is hidden'
       */
      visibilityOptionHidden: T;

      /**
       * @default 'Registry is visible'
       */
      visibilityOptionPublic: T;

      /**
       * @default 'Registry is password protected'
       */
      visibilityOptionPasswordProtected: T;

      /**
       * @default 'Change Privacy'
       */
      changeVisibility: T;

      /**
       * @default 'Add Shipping Address'
       */
      addShippingAddress: T;

      /**
       * @default 'Edit Shipping Address'
       */
      editShippingAddress: T;

      /**
       * @default 'Set Currency'
       */
      setCurrency: T;

      /**
       * @default 'View as Guest'
       */
      viewAsGuest: T;

      /**
       * @default 'Preview Registry'
       */
      previewRegistry: T;

      /**
       * @default 'Copy Registry Link'
       */
      copyGuestsiteUrl: T;

      /**
       * @default 'Get the Quick Add Button'
       */
      getBrowserButton: T;

      /**
       * @default 'Connect a Registry'
       */
      linkAnotherRegistry: T;

      /**
       * @default 'Download the App'
       */
      downloadApp: T;

      /**
       * @default 'Registry Completion Discount'
       */
      postWeddingDiscount: T;

      /**
       * @default '✨ Link Copied ✨'
       */
      linkCopied: T;

      /**
       * @default 'Share it with gift givers so they can shop in one place!'
       */
      linkCopiedMessage: T;

      /**
       * @default 'Edit Welcome Note'
       */
      editNote: T;

      /**
       * @default 'External Registries'
       */
      externalRegistries: T;

      /**
       * @default 'Registry Settings'
       */
      registrySettings: T;

      /**
       * @default 'Registry URL'
       */
      registrySettingsNonStandalone: T;

      /**
       * @default 'Invite a Collaborator'
       */
      inviteCollaborator: T;
    };
  };
  shippingAddressWarningBanner: {
    /**
     * @default 'Your shipping address is missing'
     */
    emptyAddressMessage: T;

    /**
     * @default 'It looks like your shipping address is invalid.'
     */
    noValidatedAddressMessage: T;

    /**
     * @default 'Update it so guests can send you your gifts'
     */
    noValidatedAddressSubTextMessage: T;

    /**
     * @default 'Add it so guests can send you your gifts!'
     */
    addressSubTextMessage: T;

    /**
     * @default 'Add Address'
     */
    buttonText: T;
  };
  completionDiscountBanner: {
    /**
     * @default 'Registry Completion Discount'
     */
    title: T;

    /**
     * @default 'Get 20% off on all the gifts your guests didn't buy.'
     */
    subTitle: T;

    /**
     * @default 'View Discount'
     */
    button: T;
  };
  completionDiscountCountdown: {
    /**
     * @default 'Register now, save later.'
     */
    registerMessage: T;

    /**
     * @default 'Now Available'
     */
    nowAvailableMessage: T;

    /**
     * @default 'Registry Completion Discount'
     */
    discountMessage: T;

    /**
     * @default 'Available in {{days}} days'
     */
    availableMessage: T;

    /**
     * @default 'Save 20% on all remaining items on your registry.'
     */
    saveMessage: T;

    /**
     * @default 'Shop Now'
     */
    shopButton: T;

    /**
     * @default 'Add More Gifts'
     */
    addMoreButton: T;

    /**
     * @default 'Discounts available for gifts added before your event date. 
Gifts added after won’t qualify. U.S Shipping Only. 
<learnMoreLink/>'
*/
    learnMoreMessage: T;

    /**
     * @default 'Learn more'
     */
    learnMoreCta: T;

    congratulationsMessage: {
      /**
       * @default 'Congratulations on your wedding! As our gift to you, we’d like to help you purchase any of the remaining gifts in your registry with a 20% discount.'
       */
      wedding: T;

      /**
       * @default 'Congratulations on your wedding! As our gift to you, we’d like to help you purchase any of the remaining gifts in your registry with a 20% discount.'
       */
      babyRegistry: T;

      /**
       * @default 'Congratulations! As our gift to you, we’d like to help you purchase any of the remaining gifts in your registry with a 20% discount.'
       */
      general: T;
    };

    /**
     * @default 'Expires in {{days}} days'
     */
    expiresInMessage: T;

    /**
     * @default 'Discounts available for gifts added before your event date. Gifts added after won’t qualify. U.S Shipping Only. Offer valid only until {{timestamp}}. <learnMoreLink/>'
     */
    availabilityMessage: T;
  };
  discountGiftList: {
    /**
     * @default 'Total Savings {{total}}'
     */
    totalSavings: T;

    /**
     * @default 'Sorry, it looks like none of your gifts currently qualify for the discount! <learnMoreLink/>'
     */
    emptyStateMessage: T;

    /**
     * @default 'Learn more'
     */
    learnMoreCta: T;
  };
  discountGift: {
    /**
     * @default 'Save {{discount}}'
     */
    save: T;

    /**
     * @default 'Buy Now'
     */
    buyNowCta: T;
  };
  externalRegistries: {
    /**
     * @default 'Already registered somewhere else? Connect it to Joy to make your registry a success.'
     */
    emptyListMessage: T;

    /**
     * @default 'Learn More'
     */
    emptyListLink: T;

    /**
     * @default 'New Gifts'
     */
    newGifts: T;

    /**
     * @default 'Update All'
     */
    updateAll: T;

    /**
     * @default 'Connect Registry'
     */
    connectRegistry: T;
  };
  eventPreview: {
    /**
     * @default 'Edit Photo & Note'
     */
    showMore: T;
  };
  paidAds: {
    /**
     * @default 'Shop Top Brands'
     */
    brandCarouselTitle: T;

    /**
     * @default 'Shop All Brands'
     */
    brandCarouselLink: T;

    /**
     * @default 'Sponsored'
     */
    sponsored: T;

    /**
     * @default 'Shop Now'
     */
    shopNow: T;
  };
  favoriteRetailer: {
    /**
     * @default '👋 Hey, we’re trying to build the best universal registry! Help us by picking your favorite retailers.'
     */
    bannerMessage: T;

    /**
     * @default 'Choose Now'
     */
    bannerButtonLabel: T;

    /**
     * @default 'Add gifts from Amazon, Crate, or anywhere with our <link/>:'
     */
    universalSearchMessage: T;

    /**
     * @default 'Shop your favorite stores and easily add items using our <link/> button:'
     */
    quickAddMessage: T;

    /**
     * @default '<link/> your other registries to Joy with a simple link:'
     */
    connectRegistryMessage: T;

    /**
     * @default 'Shop from anywhere with our mobile app:'
     */
    mobileAppMessage: T;

    /**
     * @default 'Available in the <appStoreLink/> and <playStoreLink/>'
     */
    mobileAppDownloadMessage: T;

    /**
     * @default 'Universal Search'
     */
    universalSearchLabel: T;

    /**
     * @default 'Quick Add'
     */
    quickAddLabel: T;

    /**
     * @default 'Connect'
     */
    connectLabel: T;

    /**
     * @default 'Add to Joy'
     */
    quickAddButtonLabel: T;

    /**
     * @default 'Available on Chrome and Firefox'
     */
    quickAddAvailabilityMessage: T;

    /**
     * @default 'Drag this button to your bookmarks bar.'
     */
    quickAddDragMessage: T;

    /**
     * @default 'Fantastic! You can have them all with Joy.'
     */
    universalMessageDialogTitle: T;

    /**
     * @default 'Get more gifts with less stress when you keep them all in one place.'
     */
    universalMessageDialogSubTitle: T;

    /**
     * @default 'Select the retailers you’d consider building a registry with:'
     */
    selectFavoriteRetailerDialogTitle: T;

    /**
     * @default 'Next'
     */
    nextButtonLabel: T;

    /**
     * @default 'App Store'
     */
    appStore: T;

    /**
     * @default 'Google Play'
     */
    playStore: T;
  };
  paperThankYouBanner: {
    /**
     * @default 'Show your gratitude with paper thank you cards.'
     */
    title: T;

    /**
     * @default 'Choose from a variety of customizable art and photo cards, or upload your own design, to share your heartfelt thanks.'
     */
    subTitle: T;

    /**
     * @default 'Browse Thank Yous'
     */
    button: T;
  };
};

export type TranslationsByNamespace<T extends LeafNodeValue = TranslateFunction> = {
  account: AccountTranslations<T>;
  actionBar: ActionBarTranslations<T>;
  addFromFriendsRegistry: AddFromFriendsRegistryTranslations<T>;
  addGiftFromStoresPopup: AddGiftFromStoresPopupTranslations<T>;
  addToCalendar: AddToCalendarTranslations<T>;
  adminDashboard: AdminDashboardTranslations<T>;
  adminDashboardV2: AdminDashboardV2Translations<T>;
  adminDashboardV3: AdminDashboardV3Translations<T>;
  bnpl: BnplTranslations<T>;
  bookingAssistant: BookingAssistantTranslations<T>;
  bookmarklet: BookmarkletTranslations<T>;
  catalogRegistry: CatalogRegistryTranslations<T>;
  companyPolicies: CompanyPoliciesTranslations<T>;
  contactCollector: ContactCollectorTranslations<T>;
  createBabyRegistry: CreateBabyRegistryTranslations<T>;
  createEvent: CreateEventTranslations<T>;
  createRegistry: CreateRegistryTranslations<T>;
  createWedding: CreateWeddingTranslations<T>;
  curationsRegistry: CurationsRegistryTranslations<T>;
  customDomain: CustomDomainTranslations<T>;
  dates: DatesTranslations<T>;
  discountedRates: DiscountedRatesTranslations<T>;
  emailPreferences: EmailPreferencesTranslations<T>;
  emailsAndEcards: EmailsAndEcardsTranslations<T>;
  errors: ErrorsTranslations<T>;
  eventCountdown: EventCountdownTranslations<T>;
  eventMenu: EventMenuTranslations<T>;
  eventSite: EventSiteTranslations<T>;
  eventSiteApps: EventSiteAppsTranslations<T>;
  exampleConfigUsage: ExampleConfigUsageTranslations<T>;
  focusPoint: FocusPointTranslations<T>;
  generalFormValidation: GeneralFormValidationTranslations<T>;
  guestKit: GuestKitTranslations<T>;
  guestRegistry: GuestRegistryTranslations<T>;
  guestSiteCustom: GuestSiteCustomTranslations<T>;
  guestSiteTravel: GuestSiteTravelTranslations<T>;
  guestSiteWelcome: GuestSiteWelcomeTranslations<T>;
  guestUnlock: GuestUnlockTranslations<T>;
  headerHelpMenu: HeaderHelpMenuTranslations<T>;
  hotelBlock: HotelBlockTranslations<T>;
  hotelReminder: HotelReminderTranslations<T>;
  ideabook: IdeabookTranslations<T>;
  ideas: IdeasTranslations<T>;
  impermissible: ImpermissibleTranslations<T>;
  joykit: JoykitTranslations<T>;
  layoutEngine: LayoutEngineTranslations<T>;
  marcom: MarcomTranslations<T>;
  pageNotFound: PageNotFoundTranslations<T>;
  pageSettingsDialog: PageSettingsDialogTranslations<T>;
  planningAccommodations: PlanningAccommodationsTranslations<T>;
  postOffice: PostOfficeTranslations<T>;
  productSelectionDialog: ProductSelectionDialogTranslations<T>;
  publishReminder: PublishReminderTranslations<T>;
  registryChecklist: RegistryChecklistTranslations<T>;
  registryOnboardingSurvey: RegistryOnboardingSurveyTranslations<T>;
  registryOnboardingSurveyV2: RegistryOnboardingSurveyV2Translations<T>;
  registryOverview: RegistryOverviewTranslations<T>;
  retargetTravelDialog: RetargetTravelDialogTranslations<T>;
  rsvpReminder: RsvpReminderTranslations<T>;
  settings: SettingsTranslations<T>;
  sharedComponents: SharedComponentsTranslations<T>;
  shareDialog: ShareDialogTranslations<T>;
  sharedRegistry: SharedRegistryTranslations<T>;
  slapAdsRegistry: SlapAdsRegistryTranslations<T>;
  ssrServer: SsrServerTranslations<T>;
  stationery: StationeryTranslations<T>;
  stationeryTemplateCategory: StationeryTemplateCategoryTranslations<T>;
  stays: StaysTranslations<T>;
  suggestions: SuggestionsTranslations<T>;
  thanksForRSVP: ThanksForRsvpTranslations<T>;
  translation: TranslationTranslations<T>;
  travelEditor: TravelEditorTranslations<T>;
  universalRecommendations: UniversalRecommendationsTranslations<T>;
  vendor: VendorTranslations<T>;
  verifyEmail: VerifyEmailTranslations<T>;
  websiteDesigner: WebsiteDesignerTranslations<T>;
  websiteEditor: WebsiteEditorTranslations<T>;
  websiteOverview: WebsiteOverviewTranslations<T>;
  websitePages: WebsitePagesTranslations<T>;
  websitePreview: WebsitePreviewTranslations<T>;
  weddingParty: WeddingPartyTranslations<T>;
  yourRegistry: YourRegistryTranslations<T>;
};

export type TranslationNamespaces = keyof TranslationsByNamespace;

export const translations = {
  en: {
    account: enAccount,
    actionBar: enActionBar,
    addFromFriendsRegistry: enAddFromFriendsRegistry,
    addGiftFromStoresPopup: enAddGiftFromStoresPopup,
    addToCalendar: enAddToCalendar,
    adminDashboard: enAdminDashboard,
    adminDashboardV2: enAdminDashboardV2,
    adminDashboardV3: enAdminDashboardV3,
    bnpl: enBnpl,
    bookingAssistant: enBookingAssistant,
    bookmarklet: enBookmarklet,
    catalogRegistry: enCatalogRegistry,
    companyPolicies: enCompanyPolicies,
    contactCollector: enContactCollector,
    createBabyRegistry: enCreateBabyRegistry,
    createEvent: enCreateEvent,
    createRegistry: enCreateRegistry,
    createWedding: enCreateWedding,
    curationsRegistry: enCurationsRegistry,
    customDomain: enCustomDomain,
    dates: enDates,
    discountedRates: enDiscountedRates,
    emailPreferences: enEmailPreferences,
    emailsAndEcards: enEmailsAndEcards,
    errors: enErrors,
    eventCountdown: enEventCountdown,
    eventMenu: enEventMenu,
    eventSite: enEventSite,
    eventSiteApps: enEventSiteApps,
    exampleConfigUsage: enExampleConfigUsage,
    focusPoint: enFocusPoint,
    generalFormValidation: enGeneralFormValidation,
    guestKit: enGuestKit,
    guestRegistry: enGuestRegistry,
    guestSiteCustom: enGuestSiteCustom,
    guestSiteTravel: enGuestSiteTravel,
    guestSiteWelcome: enGuestSiteWelcome,
    guestUnlock: enGuestUnlock,
    headerHelpMenu: enHeaderHelpMenu,
    hotelBlock: enHotelBlock,
    hotelReminder: enHotelReminder,
    ideabook: enIdeabook,
    ideas: enIdeas,
    impermissible: enImpermissible,
    joykit: enJoykit,
    layoutEngine: enLayoutEngine,
    marcom: enMarcom,
    pageNotFound: enPageNotFound,
    pageSettingsDialog: enPageSettingsDialog,
    planningAccommodations: enPlanningAccommodations,
    postOffice: enPostOffice,
    productSelectionDialog: enProductSelectionDialog,
    publishReminder: enPublishReminder,
    registryChecklist: enRegistryChecklist,
    registryOnboardingSurvey: enRegistryOnboardingSurvey,
    registryOnboardingSurveyV2: enRegistryOnboardingSurveyV2,
    registryOverview: enRegistryOverview,
    retargetTravelDialog: enRetargetTravelDialog,
    rsvpReminder: enRsvpReminder,
    settings: enSettings,
    sharedComponents: enSharedComponents,
    shareDialog: enShareDialog,
    sharedRegistry: enSharedRegistry,
    slapAdsRegistry: enSlapAdsRegistry,
    ssrServer: enSsrServer,
    stationery: enStationery,
    stationeryTemplateCategory: enStationeryTemplateCategory,
    stays: enStays,
    suggestions: enSuggestions,
    thanksForRSVP: enThanksForRsvp,
    translation: enTranslation,
    travelEditor: enTravelEditor,
    universalRecommendations: enUniversalRecommendations,
    vendor: enVendor,
    verifyEmail: enVerifyEmail,
    websiteDesigner: enWebsiteDesigner,
    websiteEditor: enWebsiteEditor,
    websiteOverview: enWebsiteOverview,
    websitePages: enWebsitePages,
    websitePreview: enWebsitePreview,
    weddingParty: enWeddingParty,
    yourRegistry: enYourRegistry
  },
  es: {
    createRegistry: esCreateRegistry,
    emailsAndEcards: esEmailsAndEcards,
    eventCountdown: esEventCountdown,
    guestSiteWelcome: esGuestSiteWelcome,
    weddingParty: esWeddingParty,
    yourRegistry: esYourRegistry
  }
};

export const manifest = {
  en: {
    account: '/bliss-apps/joy-web/static/locales/en/account.b5c4c57b.json',
    actionBar: '/bliss-apps/joy-web/static/locales/en/actionBar.a2a023e3.json',
    addFromFriendsRegistry: '/bliss-apps/joy-web/static/locales/en/addFromFriendsRegistry.9e68360b.json',
    addGiftFromStoresPopup: '/bliss-apps/joy-web/static/locales/en/addGiftFromStoresPopup.6f5e0141.json',
    addToCalendar: '/bliss-apps/joy-web/static/locales/en/addToCalendar.8b347153.json',
    adminDashboard: '/bliss-apps/joy-web/static/locales/en/adminDashboard.6aafcb2a.json',
    adminDashboardV2: '/bliss-apps/joy-web/static/locales/en/adminDashboardV2.814e8450.json',
    adminDashboardV3: '/bliss-apps/joy-web/static/locales/en/adminDashboardV3.1adf0597.json',
    bnpl: '/bliss-apps/joy-web/static/locales/en/bnpl.81bafaa3.json',
    bookingAssistant: '/bliss-apps/joy-web/static/locales/en/bookingAssistant.47f868e1.json',
    bookmarklet: '/bliss-apps/joy-web/static/locales/en/bookmarklet.7e784e7f.json',
    catalogRegistry: '/bliss-apps/joy-web/static/locales/en/catalogRegistry.87ca90ed.json',
    companyPolicies: '/bliss-apps/joy-web/static/locales/en/companyPolicies.96a27ec9.json',
    contactCollector: '/bliss-apps/joy-web/static/locales/en/contactCollector.32615aa7.json',
    createBabyRegistry: '/bliss-apps/joy-web/static/locales/en/createBabyRegistry.682c96ea.json',
    createEvent: '/bliss-apps/joy-web/static/locales/en/createEvent.2b1114a5.json',
    createRegistry: '/bliss-apps/joy-web/static/locales/en/createRegistry.384d1bcc.json',
    createWedding: '/bliss-apps/joy-web/static/locales/en/createWedding.a2ea4ba1.json',
    curationsRegistry: '/bliss-apps/joy-web/static/locales/en/curationsRegistry.c0aa8170.json',
    customDomain: '/bliss-apps/joy-web/static/locales/en/customDomain.d22247ac.json',
    dates: '/bliss-apps/joy-web/static/locales/en/dates.de83d888.json',
    discountedRates: '/bliss-apps/joy-web/static/locales/en/discountedRates.677bb57c.json',
    emailPreferences: '/bliss-apps/joy-web/static/locales/en/emailPreferences.2d6eac91.json',
    emailsAndEcards: '/bliss-apps/joy-web/static/locales/en/emailsAndEcards.082a3718.json',
    errors: '/bliss-apps/joy-web/static/locales/en/errors.3512b33b.json',
    eventCountdown: '/bliss-apps/joy-web/static/locales/en/eventCountdown.3365fd79.json',
    eventMenu: '/bliss-apps/joy-web/static/locales/en/eventMenu.ba2346ea.json',
    eventSite: '/bliss-apps/joy-web/static/locales/en/eventSite.1e1fc32f.json',
    eventSiteApps: '/bliss-apps/joy-web/static/locales/en/eventSiteApps.2ad37bd1.json',
    exampleConfigUsage: '/bliss-apps/joy-web/static/locales/en/exampleConfigUsage.cc50a20b.json',
    focusPoint: '/bliss-apps/joy-web/static/locales/en/focusPoint.991ce776.json',
    generalFormValidation: '/bliss-apps/joy-web/static/locales/en/generalFormValidation.4955b197.json',
    guestKit: '/bliss-apps/joy-web/static/locales/en/guestKit.70c98382.json',
    guestRegistry: '/bliss-apps/joy-web/static/locales/en/guestRegistry.96302d72.json',
    guestSiteCustom: '/bliss-apps/joy-web/static/locales/en/guestSiteCustom.559f1f6d.json',
    guestSiteTravel: '/bliss-apps/joy-web/static/locales/en/guestSiteTravel.632f9342.json',
    guestSiteWelcome: '/bliss-apps/joy-web/static/locales/en/guestSiteWelcome.7c47867e.json',
    guestUnlock: '/bliss-apps/joy-web/static/locales/en/guestUnlock.4896790a.json',
    headerHelpMenu: '/bliss-apps/joy-web/static/locales/en/headerHelpMenu.ee71f6c3.json',
    hotelBlock: '/bliss-apps/joy-web/static/locales/en/hotelBlock.02555770.json',
    hotelReminder: '/bliss-apps/joy-web/static/locales/en/hotelReminder.812ced16.json',
    ideabook: '/bliss-apps/joy-web/static/locales/en/ideabook.dddc6055.json',
    ideas: '/bliss-apps/joy-web/static/locales/en/ideas.c4f253fe.json',
    impermissible: '/bliss-apps/joy-web/static/locales/en/impermissible.81146127.json',
    joykit: '/bliss-apps/joy-web/static/locales/en/joykit.838dd8cb.json',
    layoutEngine: '/bliss-apps/joy-web/static/locales/en/layoutEngine.6bd75f79.json',
    marcom: '/bliss-apps/joy-web/static/locales/en/marcom.a6d82934.json',
    pageNotFound: '/bliss-apps/joy-web/static/locales/en/pageNotFound.aad2bb88.json',
    pageSettingsDialog: '/bliss-apps/joy-web/static/locales/en/pageSettingsDialog.57d576b3.json',
    planningAccommodations: '/bliss-apps/joy-web/static/locales/en/planningAccommodations.4c87e02c.json',
    postOffice: '/bliss-apps/joy-web/static/locales/en/postOffice.44c11574.json',
    productSelectionDialog: '/bliss-apps/joy-web/static/locales/en/productSelectionDialog.fbe2ea73.json',
    publishReminder: '/bliss-apps/joy-web/static/locales/en/publishReminder.495cb221.json',
    registryChecklist: '/bliss-apps/joy-web/static/locales/en/registryChecklist.fef61ee2.json',
    registryOnboardingSurvey: '/bliss-apps/joy-web/static/locales/en/registryOnboardingSurvey.c169e9da.json',
    registryOnboardingSurveyV2: '/bliss-apps/joy-web/static/locales/en/registryOnboardingSurveyV2.c0855d6c.json',
    registryOverview: '/bliss-apps/joy-web/static/locales/en/registryOverview.9a3f6355.json',
    retargetTravelDialog: '/bliss-apps/joy-web/static/locales/en/retargetTravelDialog.c964fd71.json',
    rsvpReminder: '/bliss-apps/joy-web/static/locales/en/rsvpReminder.fb7a8ee9.json',
    settings: '/bliss-apps/joy-web/static/locales/en/settings.d9976bac.json',
    sharedComponents: '/bliss-apps/joy-web/static/locales/en/sharedComponents.14793290.json',
    shareDialog: '/bliss-apps/joy-web/static/locales/en/shareDialog.95e40018.json',
    sharedRegistry: '/bliss-apps/joy-web/static/locales/en/sharedRegistry.a396f1dc.json',
    slapAdsRegistry: '/bliss-apps/joy-web/static/locales/en/slapAdsRegistry.b3db140f.json',
    ssrServer: '/bliss-apps/joy-web/static/locales/en/ssrServer.24259b78.json',
    stationery: '/bliss-apps/joy-web/static/locales/en/stationery.ba2172ba.json',
    stationeryTemplateCategory: '/bliss-apps/joy-web/static/locales/en/stationeryTemplateCategory.b95f74ed.json',
    stays: '/bliss-apps/joy-web/static/locales/en/stays.8120e05b.json',
    suggestions: '/bliss-apps/joy-web/static/locales/en/suggestions.327b70e6.json',
    thanksForRSVP: '/bliss-apps/joy-web/static/locales/en/thanksForRSVP.0350b15c.json',
    translation: '/bliss-apps/joy-web/static/locales/en/translation.99914b93.json',
    travelEditor: '/bliss-apps/joy-web/static/locales/en/travelEditor.85e41404.json',
    universalRecommendations: '/bliss-apps/joy-web/static/locales/en/universalRecommendations.309737e0.json',
    vendor: '/bliss-apps/joy-web/static/locales/en/vendor.5ca56cd3.json',
    verifyEmail: '/bliss-apps/joy-web/static/locales/en/verifyEmail.6ce1b9a3.json',
    websiteDesigner: '/bliss-apps/joy-web/static/locales/en/websiteDesigner.b67ce9fa.json',
    websiteEditor: '/bliss-apps/joy-web/static/locales/en/websiteEditor.992b829a.json',
    websiteOverview: '/bliss-apps/joy-web/static/locales/en/websiteOverview.78504701.json',
    websitePages: '/bliss-apps/joy-web/static/locales/en/websitePages.920d2fbd.json',
    websitePreview: '/bliss-apps/joy-web/static/locales/en/websitePreview.9bd55a76.json',
    weddingParty: '/bliss-apps/joy-web/static/locales/en/weddingParty.9b78bbd3.json',
    yourRegistry: '/bliss-apps/joy-web/static/locales/en/yourRegistry.e36405d4.json'
  },
  es: {
    createRegistry: '/bliss-apps/joy-web/static/locales/es/createRegistry.1f8df0f7.json',
    emailsAndEcards: '/bliss-apps/joy-web/static/locales/es/emailsAndEcards.8fcac984.json',
    eventCountdown: '/bliss-apps/joy-web/static/locales/es/eventCountdown.99914b93.json',
    guestSiteWelcome: '/bliss-apps/joy-web/static/locales/es/guestSiteWelcome.431c1f47.json',
    weddingParty: '/bliss-apps/joy-web/static/locales/es/weddingParty.e356b2b2.json',
    yourRegistry: '/bliss-apps/joy-web/static/locales/es/yourRegistry.a102d3c0.json'
  }
};
